import React, { Fragment, useCallback, useContext, useState } from 'react'
import moment from 'moment'
import cn from 'classnames'
import { Button, Text, Icon, Divider } from '../../atoms'
import { IMower } from '../../../models'
import { SurepathText } from '../../molecules'
import { SurepathPerformanceTimeline } from '..'
import { EventEmitter } from '../../../utils/event_emitter'
import { TIMELINE_TIME_INTERVALS } from '../SurepathPerformanceTimeline/utils'
import { EActiveOverlay } from '../../../pages/AppRoot/reducers/types'
import { AppRootContext } from '../../../pages/AppRoot'
import { IDevice } from '../../../models_v2/entity/device'

interface SurepathPerformanceCardProps {
  mower?: IDevice
  onClose?: () => void
}

export type SurepathPerformanceEvents =
  | 'date:decrement_one_day'
  | 'date:increment_one_day'
  | 'time_interval:change'

export type SurepathPerformanceTimeInterval = 6 | 12 | 24

const eventEmitter = new EventEmitter<SurepathPerformanceEvents>()

const SurepathPerformanceCard: React.FC<SurepathPerformanceCardProps> =
  props => {
    const { dispatch } = useContext(AppRootContext)
    const { mower, onClose } = props
    const todayDate = new Date()

    const [timeInterval, setTimeInterval] =
      useState<SurepathPerformanceTimeInterval>(6)
    const [currentDate, setCurrentDate] = useState<Date>(new Date())

    const isCurrentDateSameAsToday = moment(currentDate).isSame(
      todayDate,
      'date'
    )

    const handleDecrementDate = useCallback(() => {
      setCurrentDate(prev => {
        const newDate = moment(prev).subtract(1, 'day').toDate()
        eventEmitter.emit('date:decrement_one_day', newDate)
        return newDate
      })
    }, [])

    const handleIncrementDate = useCallback(() => {
      setCurrentDate(prev => {
        const newDate = moment(prev).add(1, 'day').toDate()
        eventEmitter.emit('date:increment_one_day', newDate)
        return newDate
      })
    }, [])

    const handleTimeIntervalChange = useCallback(
      (interval: SurepathPerformanceTimeInterval) => {
        if (timeInterval === interval) return
        setTimeInterval(interval)
        eventEmitter.emit('time_interval:change', interval)
      },
      [timeInterval]
    )

    const onCloseClick = () => {
      dispatch?.({
        type: 'SET_ACTIVE_OVERLAY',
        payload: { name: EActiveOverlay.NONE }
      })
      onClose?.()
    }

    return (
      <div className=' bg-app-main-light-full dark:bg-app-background bg-no-repeat bg-cover rounded-2xl border border-[#363636]'>
        <div className='mx-6 my-2 flex gap-2 justify-between items-center'>
          <SurepathText containerClassName='dark:text-white' suffix='PERFORMANCE' />
          <div className='flex gap-2 text-white'>
            <Icon
              name='caret_left'
              className='cursor-pointer invert dark:invert-0'
              onClick={handleDecrementDate}
            />
            <Text.Body.MediumSemiBold style={{fontVariantNumeric: "tabular-nums"}} className='text-black dark:text-white'>
              {moment(currentDate).format('MM/DD/YY')}
            </Text.Body.MediumSemiBold>
            <Icon
              name='caret_right'
              className={cn('cursor-pointer invert dark:invert-0', {
                invisible: isCurrentDateSameAsToday
              })}
              onClick={handleIncrementDate}
            />
          </div>
          <div className='flex gap-2 dark:text-white items-center'>
            {TIMELINE_TIME_INTERVALS.map((item, index) => (
              <Fragment key={index}>
                <Text.Body.MediumSemiBold
                  className={cn('cursor-pointer', {
                    'text-primary': item === timeInterval
                  })}
                  onClick={() => handleTimeIntervalChange(item)}>
                  {`${item}H`}
                </Text.Body.MediumSemiBold>
                {index !== TIMELINE_TIME_INTERVALS.length - 1 && (
                  <Divider
                    className='border-black dark:border-white'
                    size='sm'
                    orientation='vertical'
                  />
                )}
              </Fragment>
            ))}
          </div>
          <Button
            variant='outlined'
            size='sm'
            titleSize='sm'
            title='Close'
            className='!bg-black/20 !text-black dark:!text-white font-medium'
            onClick={onCloseClick}
          />
        </div>
        <div className='border-[#363636] border-t pt-4 pb-3 px-3'>
          <SurepathPerformanceTimeline
            timeInterval={timeInterval}
            mower={mower}
            currentDate={currentDate}
            onChangeDate={setCurrentDate}
            eventEmitter={eventEmitter}
          />
        </div>
      </div>
    )
  }

export default SurepathPerformanceCard
