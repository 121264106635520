import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface TrackingSliceState {
  selected_device_details_id?: string
}

const initialState: TrackingSliceState = {
  selected_device_details_id: ''
}

export const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    setSelectedDeviceDetailsId: (state, action: PayloadAction<string | undefined>) => {
      state.selected_device_details_id = action.payload
    },
    resetTrackingState: () => initialState
  }
})

export const { setSelectedDeviceDetailsId, resetTrackingState } = trackingSlice.actions

export const trackingSliceReducer = trackingSlice.reducer
export default trackingSlice.reducer
