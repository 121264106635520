import { gql } from '@apollo/client'

export const UPDATE_TELEMETRY = gql`
  mutation putTelemetry($input: DeviceInput!) {
    result: putTelemetry(input: $input) {
      productSerial
      latestTelemetry {
        type
        location
        payload
        timestamp
        status
      }
      telemetries {
        type
        location
        payload
        timestamp
        status
      }
      inventory {
        source
        name
        model
        factoryModel
        dealer
        softwareVersion
      }
      metadata {
        productSerial
        metadataId
        groupId
        faultCode
        payload
        timestamp
        createdAt
        active
        state
        notes
      }
      #analytic {
      #  last24Hours
      #  last7Days
      #  last30Days
      #}
    }
  }
`

export const UPDATE_INVENTORY = gql`
  mutation putInventory($input: InventoryInput!) {
    result: putInventory(input: $input) {
      productSerial
      latestTelemetry {
        type
        location
        payload
        timestamp
        status
      }
      inventory {
        source
        name
        model
        factoryModel
        dealer
        softwareVersion
        deviceType
        companyId
        groupId
      }
      metadata {
        productSerial
        metadataId
        groupId
        faultCode
        payload
        timestamp
        createdAt
        active
        state
        notes
      }
    }
  }
`

export const UPDATE_METADATA = gql`
  mutation putMetadata($input: MetadataInput!) {
    result: putMetadata(input: $input) {
      productSerial
      metadataId
      groupId
      faultCode
      payload
      timestamp
      createdAt
      active
      state
      notes
    }
  }
`

export const GENERATE_EXPORT = gql`
  mutation generateExport($input: ExportInput!) {
    result: generateExport(input: $input) {
      url
      generatedAt
    }
  }
`

export const UPDATE_GROUP = gql`
  mutation putGroup($input: GroupInput!) {
    result: putGroup(input: $input) {
      companyId
      groupId
      groupName
      productSerial
    }
  }
`
