import React, { Fragment } from 'react'
import cn from 'classnames'
import { Divider, Icon, Text } from '..'

export interface ISelectionListItem {
  id: string
  label: string
}

interface SelectionListProps {
  className?: string
  items: ISelectionListItem[]
  onRemove?: (data: ISelectionListItem) => void
}

const SelectionList: React.FC<SelectionListProps> = props => {
  const { className, items = [], onRemove } = props

  return (
    <div
      className={cn(
        'min-h-[200px] border-solid border-[1px] border-black-700 bg-black',
        'flex flex-col overflow-x-auto h-full',
        className
      )}>
      {items.map(item => (
        <Fragment key={item.id}>
          <div className='flex justify-between items-center p-2'>
            <Text.Body.Medium className='text-primary'>
              {item.label}
            </Text.Body.Medium>
            <Icon
              name='close_circle'
              className='cursor-pointer h-3 w-3'
              onClick={() => onRemove?.(item)}
            />
          </div>
          <Divider size='sm' className='!border-black-700' />
        </Fragment>
      ))}
    </div>
  )
}

export default SelectionList
