import { Device, IBattery, TNetworkStatus } from '..'
import { resolveToStandardValueWrapper } from '../../utils/common'

export const mapDeviceToBattery = (device: Device): IBattery | null => {
  const { name, source, productSerial, assignment, sourceId, location, networkStatus } =
    device

  let parsedLocation
  let parsedNetworkStatus

  try {
    const locationParsedResult = resolveToStandardValueWrapper<
      Device['location']['value']
    >(location as unknown as string)
    parsedLocation = locationParsedResult.value

    const networkStatusParsedResult = resolveToStandardValueWrapper<
      Device['networkStatus']['value']
    >(networkStatus as unknown as string)
    parsedNetworkStatus = networkStatusParsedResult.value
  } catch (error) {
    // Ignore parsing error due to bad data
  }

  const coordinates_data =
    parsedLocation?.latitude && parsedLocation?.longitude
      ? {
          coordinates: {
            lat: Number(parsedLocation?.latitude),
            lng: Number(parsedLocation?.longitude)
          }
        }
      : {}

  return {
    assignment,
    ...coordinates_data,
    id: productSerial,
    source,
    productSerial: productSerial,
    name: name || sourceId || '',
    network_status: parsedNetworkStatus?.toUpperCase?.() as TNetworkStatus,
    status: ''
  }
}
