import React, { useCallback, useMemo } from 'react'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import { ReportingPageProps } from './types'
import { Routes } from '../../routes'
import SubNavigationBar from '../../components/organisms/SubNavigationBar'
import { NavigationBarItem } from '../../components/organisms/SubNavigationBar/types'
import TodayLabel from './TodayLabel'

const ReportingPage: React.FC<ReportingPageProps> = props => {
  const navigate = useNavigate()

  const matchReportingPage = useMatch(Routes['reporting_page.wildcard'])
  const navigationItems: NavigationBarItem[] = useMemo(() => {
    return [
      // {
      //   id: 'schedule',
      //   label: 'Schedule'
      // },
      {
        id: 'exports',
        label: 'Exports'
      }
      // {
      //   id: 'connectors',
      //   label: 'Connectors'
      // }
    ]
  }, [])

  const selectedIndex = useMemo(() => {
    return navigationItems.findIndex(
      item => item.id === matchReportingPage?.params['*']?.split('/')?.[0]
    )
  }, [matchReportingPage?.params, navigationItems])

  const handleChange = useCallback((item: NavigationBarItem, index) => {
    navigate(Routes[`reporting_page.${item.id}` as keyof typeof Routes])
  }, [])

  return (
    <div className='h-full flex flex-col'>
      <SubNavigationBar
        items={navigationItems}
        selectedIndex={selectedIndex}
        onChange={handleChange}
        trailingComponent={<TodayLabel />}
      />
      <Outlet />
    </div>
  )
}

export default ReportingPage
