import React, { useEffect, useMemo, useState } from 'react'
import { BackToListButton } from '../../../../molecules'
import { Button, Dialog, Text } from '../../../../atoms'
import cn from 'classnames'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import {
  clearAssetSelection,
  removeSelectedCompanyId,
  selectAllSelectedAssets,
  selectSelectedCompany,
  setIsSelectingAssets,
  setSelectedAssets,
  toggleSelectedAsset,
  selectDefaultCompany
} from '../../../../../store/slices/organizationCompanySlice'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../../routes'
import SelectionList, {
  ISelectionListItem
} from '../../../../atoms/SelectionList'
import { IDevice } from '../../../../../models_v2/entity/device'
import { useInventoryUpdate } from '../../../../../hooks'
import { DeviceInputDto } from '../../../../../models_v2/dto/inventory_input'
import { IInventory } from '../../../../../models_v2/entity/inventory'
import { selectDevices } from '../../../../../store/slices/deviceSliceV2/selectors'
import {
  updateManyDevicesWithSameUpdate,
  updateDevice
} from '../../../../../store/slices/deviceSliceV2'

interface SettingsOrganizationCompanyAssignAssetSidebarProps {}

const SettingsOrganizationCompanyAssignAssetSidebar: React.FC<SettingsOrganizationCompanyAssignAssetSidebarProps> =
  () => {
    const navigate = useNavigate()
    const [showAssignAssetConfirmation, setShowAssignAssetConfirmation] =
      useState(false)
    const isSelectingAssets = useAppSelector(
      state => state.organizationCompany.is_selecting_assets
    )
    const selectedCompany = useAppSelector(selectSelectedCompany)
    const defaultCompany = useAppSelector(selectDefaultCompany)
    const dispatch = useAppDispatch()
    const assetsSelected = useAppSelector(selectAllSelectedAssets)
    const assetsSelectedCount = Object.keys(assetsSelected).length
    const assetSelectedText = useMemo(() => {
      if (!assetsSelectedCount) return 'Choose Assets'
      else if (assetsSelectedCount === 1)
        return `${assetsSelectedCount} Asset Selected`
      return `${assetsSelectedCount} Assets Selected`
    }, [assetsSelectedCount])
    const { selected_assets } = useAppSelector(
      state => state.organizationCompany
    )
    const devices = useAppSelector(selectDevices)
    const [mutate] = useInventoryUpdate()

    const assetConfirmationText = useMemo(() => {
      let prefix = ''
      if (assetsSelectedCount <= 1) {
        prefix = `${assetsSelectedCount} Asset`
      } else {
        prefix = `${assetsSelectedCount} Assets`
      }

      // @ts-ignore
      return `${prefix} Assigned to ${selectedCompany?.inventory?.name || ''}`
    }, [assetsSelectedCount, selectedCompany])

    const handleToggleSelectAsset = () => {
      dispatch(setIsSelectingAssets(!isSelectingAssets))
    }

    //TODO: DO we want to replace this with something from backend
    const handleAssignAssetSubmit = () => {
      const assets = devices.filter(
        device => !!selected_assets[device.productSerial]
      )
      const assetsMutations: ReturnType<typeof mutate>[] = []

      assets?.forEach(asset => {
        const { groupId, source, groupName, ...restInventoryInput } =
          asset.inventory
        const device = new DeviceInputDto({
          ...(restInventoryInput as unknown as IInventory),
          productSerial: asset.productSerial,
          companyId: selectedCompany?.companyId
        })
        assetsMutations.push(
          mutate({
            variables: device.toJSON()
          })
        )
      })

      const handleSubmitAllUpdates = async () => {
        const results = await Promise.all(assetsMutations)
        const updatedDevices = results
          .filter(result => !!result.data)
          .map(result => result.data?.result) as IDevice[]

        dispatch(
          updateManyDevicesWithSameUpdate({
            devicesProductSerials: updatedDevices.map(
              device => device.productSerial
            ),
            device: {
              inventory: {
                companyId: selectedCompany?.companyId
              }
            }
          })
        )

        setShowAssignAssetConfirmation(false)
        dispatch(clearAssetSelection())
        navigate(Routes.companies)
      }
      handleSubmitAllUpdates()
    }

    const handleMapSelectedAssets = () => {
      return devices
        .filter(device => !!assetsSelected[device.productSerial])
        .map(device => ({
          id: device.productSerial,
          label: device.inventory.name
        }))
    }

    const handleRemoveSelectedAsset = async (item: ISelectionListItem) => {
      const assetIndex = devices.findIndex(
        device => device.productSerial === item?.id
      )
      const asset = devices[assetIndex]

      const { groupId, source, groupName, ...restInventoryInput } =
        asset.inventory ?? {}
      const device = new DeviceInputDto({
        ...(restInventoryInput as unknown as IInventory),
        productSerial: asset.productSerial,
        companyId: defaultCompany?.companyId
      })

      mutate({
        variables: device.toJSON()
      })

      dispatch(
        updateDevice({
          device: {
            productSerial: asset.productSerial,
            inventory: {
              companyId: defaultCompany?.companyId
            }
          }
        })
      )
      dispatch(toggleSelectedAsset({ id: item.id }))
    }

    useEffect(() => {
      const selectedCompanyDevicesIds = devices
        .filter(
          device => device.inventory.companyId === selectedCompany?.companyId
        )
        .map(device => device.productSerial)
      dispatch(setSelectedAssets({ ids: selectedCompanyDevicesIds }))
      return () => {
        dispatch(clearAssetSelection())
        dispatch(removeSelectedCompanyId())
      }
    }, [])

    return (
      <>
        <Dialog
          show={showAssignAssetConfirmation}
          headerTitle='Assets Assigned'
          borderVariant='primary'
          onClose={() => setShowAssignAssetConfirmation(false)}>
          <div className='flex flex-col gap-7'>
            <Text.Body.Medium className='text-primary'>
              {assetConfirmationText}
            </Text.Body.Medium>

            <div className='text-center'>
              <Button
                title='Confirm'
                titleSize='sm'
                className='w-full sm:w-1/4'
                onClick={handleAssignAssetSubmit}
              />
            </div>
          </div>
        </Dialog>

        <div className='h-full w-full flex flex-col gap-4'>
          <BackToListButton onClick={() => navigate(Routes.companies)} />
          <div className='w-full'>
            <Text.Body.Medium className='dark:text-white mb-4'>
              Assigning assets for:{' '}
              <span className='ml-1 font-bold text-base'>
                {(selectedCompany as unknown as IDevice)?.inventory?.name}
              </span>
            </Text.Body.Medium>
            <Text.Body.Medium
              className={cn('mb-2 dark:text-white', {
                'text-primary italic': assetsSelectedCount
              })}>
              {assetSelectedText}
            </Text.Body.Medium>
            <Button
              className={cn('w-full', {
                '!bg-black-500 hover:!bg-black-700': !isSelectingAssets,
                '!bg-primary hover:!bg-primary/80': !!isSelectingAssets
              })}
              color='secondary'
              titleSize='sm'
              titleClassname='font-semibold'
              title='Select Items'
              onClick={handleToggleSelectAsset}
            />
          </div>
          <div className='flex-1 py-3 overflow-hidden'>
            <Text.Body.MediumSemiBold className='dark:text-white mb-2'>
              Add Assets
            </Text.Body.MediumSemiBold>
            <SelectionList
              items={handleMapSelectedAssets()}
              onRemove={handleRemoveSelectedAsset}
            />
          </div>
          <Button
            title='Assign Assets'
            disabled={!assetsSelectedCount}
            onClick={() => setShowAssignAssetConfirmation(true)}
          />
        </div>
      </>
    )
  }

export default SettingsOrganizationCompanyAssignAssetSidebar
