import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {IQueryOptions} from './types';
import {useApolloClient, ApolloClient, isApolloError, ServerError} from '@apollo/client';
import { apolloRequest, restRequest } from './utilities';
import { useContext, useEffect } from 'react';
import { AppContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../routes';

type IQueryOptionsMutation = Omit<IQueryOptions, "options"> & {
  options?: UseMutationOptions<unknown, unknown, unknown, string> & {
    forceReAuth?: boolean
  }
};

const useDynamicMutation = (param: IQueryOptionsMutation) => {
  const queryLanguage = param.queryLanguage || process.env.REACT_APP_QUERY_LANGUAGE
  const apolloClient:  ApolloClient<object> =  useApolloClient();
  const { handleLogout } = useContext(AppContext)
  const navigate = useNavigate()

  let request: any;
  if(queryLanguage === "GraphQL") {
    request = (data: any) => apolloRequest({ 
      apolloClient, 
      params: param as IQueryOptions, 
      data,
      isMutation: true,
    });
  } else if (queryLanguage === "REST") {
    request = (data: any) => restRequest({
      data, 
      params: param as IQueryOptions
    });
  }

  const mutation = useMutation(request, param.options)

  useEffect(() =>{
    const mutationError = mutation.error as Error
    if ( param.options?.forceReAuth && mutation.error && isApolloError(mutationError) && (mutationError.networkError as ServerError).statusCode===401 && handleLogout) {
      const logout = async() =>{
        await handleLogout()

        navigate(Routes.login_page)
      }

      logout()
    }
  }, [param.options?.forceReAuth, mutation.error])

  return mutation
}

export default useDynamicMutation
