import { useEffect } from 'react';
import { IGroup } from '../../models_v2/entity/groups';
import { useApolloQuery } from '../useApolloQuery'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setGroups } from '../../store/slices/deviceGroupSlice';
import { setTrackingGroups } from '../../store/slices/trackingGroupSlice';
import { groupList } from '../../store/slices/deviceGroupSlice/selectors';

export const useGroups = (params: {companies: string}) => {
  const dispatch = useAppDispatch()
  const result = useApolloQuery<IGroup[], typeof params>({
    query: 'GET_GROUPS_BY_COMPANY_ID',
    options: {
      // variables: params
      variables: {
        companies: params.companies
      }
    },
    forceReAuth: true
  })
  const groups = useAppSelector(groupList)

  useEffect(() => {
    if (result.isFetched) {
      
      if ( groups.length ) {

        return
      }

      setTimeout(() => {
        dispatch(setGroups(result.data ?? []))
        dispatch(setTrackingGroups(result.data ?? []))
        
      },500)
    }
  }, [result.isFetched])

  return result
}
