import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { DeviceCounterItem, SearchTextField } from '../../../../../molecules'
import { Divider } from '../../../../../atoms'
import GarageGlobalFleetDeviceList from '../../../../GarageGlobalFleetDeviceList'
import {
  useBatterySearch,
  useDynamicQuery,
  useMowerSearch,
  useToolTagSearch
} from '../../../../../../hooks'
import { AppRootContext } from '../../../../../../pages/AppRoot'
import { IBattery, IMower, IToolTag } from '../../../../../../models'
import { useSearchParams } from 'react-router-dom'
import { TDevice } from '../../../../../../models/device'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import {
  addSelectedAsset,
  removeSelectedAsset,
  selectAllSelectedAssets
} from '../../../../../../store/slices/organizationCompanySlice'

interface OrganizationCompanyAssetAssignerProps {}

const MOWER_HIDDEN_COLUMNS: string[] = [
  'registering_dealer',
  'has_subscription',
  'network_status',
  'actions'
]

const BATTERY_HIDDEN_COLUMNS: string[] = [
  'has_subscription',
  'network_status',
  'actions'
]

const TOOL_TAG_HIDDEN_COLUMNS: string[] = ['actions']

export const OrganizationCompanyAssetAssigner: React.FC<OrganizationCompanyAssetAssignerProps> =
  () => {
    const { state, dispatch } = useContext(AppRootContext)

    const appDispatch = useAppDispatch()
    const selectedAssets = useAppSelector(selectAllSelectedAssets)
    const isSelectingAssets = useAppSelector(
      state => state.organizationCompany.is_selecting_assets
    )

    const [searchParams, setSearchParams] = useSearchParams()

    const { activeList, searchFilter } = useAppSelector(
      state => state.organizationSettings
    )

    useEffect(() => {
      const type = searchParams.get('type') as TDevice
      if (['mower', 'battery', 'tool_tag'].includes(type)) {
        searchParams.set('type', type)
      } else {
        searchParams.set('type', 'mower')
      }
      setSearchParams(searchParams, {
        replace: true
      })
    }, [])

    const currentDeviceType = useMemo<TDevice>(() => {
      return searchParams.get('type') as TDevice
    }, [searchParams.get('type')])

    const isMowers = currentDeviceType === 'mower'
    const isBatteries = currentDeviceType === 'battery'
    const isToolTags = currentDeviceType === 'tool_tag'

    const {
      data: mowerData,
      isFetched: mowerIsFetched,
      isLoading: mowerIsLoading
    } = useDynamicQuery({
      queryKey: 'GET_TRACKING_MOWERS',
      mapperKey: 'device_to_mower'
    })

    const {
      data: batteryData,
      isFetched: batteryIsFetched,
      isLoading: batteryIsLoading
    } = useDynamicQuery({
      queryKey: 'GET_TRACKING_BATTERIES',
      mapperKey: 'device_to_battery'
    })

    const {
      data: toolTagData,
      isFetched: toolTagIsFetched,
      isLoading: toolTagIsLoading
    } = useDynamicQuery({
      queryKey: 'GET_TRACKING_TOOL_TAGS',
      mapperKey: 'device_to_tool_tag'
    })

    useEffect(() => {
      if (!mowerData || !mowerIsFetched) return

      dispatch?.({
        type: 'SET_MOWERS',
        payload: mowerData as IMower[]
      })
    }, [mowerData, mowerIsFetched])

    useEffect(() => {
      if (!batteryData || !batteryIsFetched) return

      dispatch?.({
        type: 'SET_BATTERIES',
        payload: batteryData as IBattery[]
      })
    }, [batteryData, batteryIsFetched])

    useEffect(() => {
      if (!toolTagData || !toolTagIsFetched) return

      dispatch?.({
        type: 'SET_TOOL_TAGS',
        payload: toolTagData as IToolTag[]
      })
    }, [toolTagData, toolTagIsFetched])

    const hasSearchText = useMemo(() => {
      return !!searchFilter.trim().length
    }, [searchFilter])

    const filteredMowers = useMowerSearch({
      items: state?.mowers ?? [],
      search: isMowers && hasSearchText ? searchFilter.trim() : ''
    })

    const filteredBatteries = useBatterySearch({
      items: state?.batteries ?? [],
      search: isBatteries && hasSearchText ? searchFilter.trim() : ''
    })

    const filteredToolTags = useToolTagSearch({
      items: state?.tool_tags ?? [],
      search: isToolTags && hasSearchText ? searchFilter.trim() : ''
    })

    const handleRowClick = useCallback(
      (row: unknown) => {
        const item = row as IMower | IBattery | IToolTag
        if (!selectedAssets[item.id]) {
          appDispatch(
            addSelectedAsset({
              id: item.id
            })
          )
        } else {
          appDispatch(
            removeSelectedAsset({
              id: item.id
            })
          )
        }
      },
      [selectedAssets]
    )

    const onRowClassNameBuilder = useCallback(
      (row: unknown) => {
        const item = row as IMower | IBattery | IToolTag
        if (!selectedAssets[item.id]) return ''
        return 'border-primary !text-primary'
      },
      [selectedAssets]
    )

    return (
      <>
        {isMowers && (
          <GarageGlobalFleetDeviceList
            type='mower'
            hiddenColumns={MOWER_HIDDEN_COLUMNS}
            isLoading={mowerIsLoading}
            items={[]}
            /* TODO: To be fixed when GraphQL v2 is finished, cannot be used since this component depends on GraphQL V1 */
            // items={hasSearchText ? filteredMowers : state?.mowers ?? []}
            tableProps={{
              onRowClick: !isSelectingAssets ? undefined : handleRowClick,
              rowClassName: onRowClassNameBuilder
            }}
          />
        )}
        {isBatteries && (
          <GarageGlobalFleetDeviceList
            type='battery'
            hiddenColumns={BATTERY_HIDDEN_COLUMNS}
            isLoading={batteryIsLoading}
            items={[]}
            /* TODO: To be fixed when GraphQL v2 is finished, cannot be used since this component depends on GraphQL V1 */
            // items={hasSearchText ? filteredBatteries : state?.batteries ?? []}
            tableProps={{
              onRowClick: !isSelectingAssets ? undefined : handleRowClick,
              rowClassName: onRowClassNameBuilder
            }}
          />
        )}
        {isToolTags && (
          <GarageGlobalFleetDeviceList
            type='tool_tag'
            hiddenColumns={TOOL_TAG_HIDDEN_COLUMNS}
            isLoading={toolTagIsLoading}
            items={[]}
            /* TODO: To be fixed when GraphQL v2 is finished, cannot be used since this component depends on GraphQL V1 */
            // items={hasSearchText ? filteredToolTags : state?.tool_tags ?? []}
            tableProps={{
              onRowClick: !isSelectingAssets ? undefined : handleRowClick,
              rowClassName: onRowClassNameBuilder
            }}
          />
        )}
      </>
    )
  }
