import React from 'react'
import { UserProfileForm } from '../../components/organisms'
import { ProfilePageProps } from './types'
import { MyProfileFormActions } from './components/MyProfileFormActions'
import { selectUser } from '../../store/slices/userSlice'
import { RootState } from '../../store/store'
import { useSelector } from 'react-redux'
import { FormModes } from '../../models/form'
import { SettingsTemplate } from '../../components/templates'
import { ProfileSubNavigationBar } from '../../components/organisms/ProfileSubNavigationBar'
import { useAppSelector } from '../../store/hooks'
import { FormProvider, useForm } from 'react-hook-form'

const ProfilePage: React.FC<ProfilePageProps> = props => {
  const user = useSelector((state: RootState) => selectUser(state))
  const useFormMethods = useForm()

  return (
    <FormProvider {...useFormMethods}>
      <SettingsTemplate
        subNavigation={<ProfileSubNavigationBar />}
        leftContent={<MyProfileFormActions />}
        topRightContent={<UserProfileForm mode={FormModes.Edit} data={user} showRole />}
      />
    </FormProvider>
  )
}

export default ProfilePage
