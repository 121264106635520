import { Device, IToolTag, TNetworkStatus } from '..'
import { resolveToStandardValueWrapper } from '../../utils/common'
import { TDeviceBatteryFaceLevel } from '../device_battery'
import { convertTimestamp } from '../utils'

export const mapDeviceToToolTag = (device: Device): IToolTag | null => {
  const {
    name,
    productSerial,
    assignment,
    source,
    sourceId,
    location,
    timestamp,
    networkStatus,
    coinHealth,
    lastSeenMower,
    BLEDeviceType
  } = device

  let parsedLocation
  let parsedCoinHealth
  let parsedNetworkStatus
  let parsedLastSeenMowerTimestamp
  let parsedLastSeenMowerValue
  let parsedBLEDeviceType

  try {
    const locationParsedResult = resolveToStandardValueWrapper<
      Device['location']['value']
    >(location as unknown as string)
    parsedLocation = locationParsedResult.value

    const networkStatusParsedResult = resolveToStandardValueWrapper<
      Device['networkStatus']['value']
    >(networkStatus as unknown as string)
    parsedNetworkStatus = networkStatusParsedResult.value

    const coinHealthParsedResult = resolveToStandardValueWrapper<
      Device['coinHealth']['value']
    >(coinHealth as unknown as string)
    parsedCoinHealth = coinHealthParsedResult.value

    const lastSeenMowerParsedResult = resolveToStandardValueWrapper<
      Device['lastSeenMower']['value']
    >(lastSeenMower as unknown as string)
    parsedLastSeenMowerTimestamp = lastSeenMowerParsedResult.timestamp

    if (lastSeenMowerParsedResult.value?.includes('_source')) {
      parsedLastSeenMowerValue = lastSeenMowerParsedResult.value
    } else {
      const splittedLastSeenMower = (
        lastSeenMowerParsedResult.value ?? ''
      ).split(' ')
      if (splittedLastSeenMower.length === 1) {
        parsedLastSeenMowerValue = lastSeenMowerParsedResult.value
      } else {
        const [factoryModel, ...restParts] = (
          lastSeenMowerParsedResult.value ?? ''
        )?.split(' ')
        parsedLastSeenMowerValue = restParts.join(' ')
      }
    }

    const BLEDeviceTypeParsedResult = resolveToStandardValueWrapper<
      Device['BLEDeviceType']['value']
    >(BLEDeviceType as unknown as string)
    parsedBLEDeviceType = BLEDeviceTypeParsedResult.value
  } catch (error) {
    // Ignore parsing error due to bad data
  }

  const coordinates_data =
    parsedLocation?.latitude && parsedLocation?.longitude
      ? {
          coordinates: {
            lat: Number(parsedLocation?.latitude),
            lng: Number(parsedLocation?.longitude)
          }
        }
      : {}

  let battery_level
  switch (parsedCoinHealth) {
    case '00':
      battery_level = 'excellent'
      break
    case '01':
      battery_level = 'good'
      break
    case '10':
      battery_level = 'fair'
      break
    case '11':
      battery_level = 'poor'
      break
  }

  return {
    assignment,
    ...coordinates_data,
    source,
    id: productSerial,
    name: name || sourceId || '',
    last_seen: parsedLastSeenMowerTimestamp
      ? convertTimestamp(parseInt(parsedLastSeenMowerTimestamp))
      : convertTimestamp(parseInt(timestamp)),
    network_status: parsedNetworkStatus?.toUpperCase?.() as TNetworkStatus,
    battery_level: battery_level as TDeviceBatteryFaceLevel,
    last_seen_by: parsedLastSeenMowerValue || '',
    status: '',
    mac_address: productSerial ?? sourceId,
    productSerial: productSerial,
    ble_device_type: parsedBLEDeviceType
  }
}
