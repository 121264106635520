import React from 'react'
import { IDevice } from '../../../../models_v2/entity/device'
import { DeviceDetailRowInformation } from '../../../molecules'
import { useAppSelector } from '../../../../store/hooks'
import { trackingGroups } from '../../../../store/slices/trackingGroupSlice/selectors'

interface OtherInformationProps {
  battery: Partial<IDevice>
}

const OtherInformation: React.FC<OtherInformationProps> = props => {
  const { battery } = props
  const { productSerial = '', inventory } = battery ?? {}
  const {
    softwareVersion = '',
    groupId = '',
    factoryModel = '',
    model = '',
    dealer = ''
  } = inventory ?? {}
  const groups = useAppSelector(trackingGroups)
  const assignedGroup = groups?.find(group => group?.groupId === groupId)

  return (
    <div className='flex flex-col gap-1'>
      <DeviceDetailRowInformation
        label='Assignment'
        value={assignedGroup?.groupName || 'Unassigned'}
      />
      <DeviceDetailRowInformation
        label='Serial Number'
        value={productSerial || ''}
      />
      <DeviceDetailRowInformation
        label='Factory Model #'
        value={factoryModel || ''}
      />
      <DeviceDetailRowInformation label={'Model'} value={model || ''} />
      <DeviceDetailRowInformation
        label='Registering Dealer'
        value={dealer || ''}
      />
    </div>
  )
}

export default OtherInformation
