import { IGroupInput } from '../gql/group_input'

export interface IGroupInputDto {
  input: IGroupInput
}

// Preparation for serializing the payload
export class GroupInputDto {
  #input: IGroupInput

  constructor(input: IGroupInput) {
    this.#input = input
  }

  toJSON(): IGroupInputDto {
    const { __typename, ...input } = this.#input
    return { input }
  }
}
