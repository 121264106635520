import LastSignal from './LastSignal'
import SurepathInfo from './SurepathInfo'
import SurepathLogo from './SurepathLogo'
import SurepathGps from './SurepathGps'
import SurepathCellCignal from './SurepathCellCignal'

export const MowerCommon = {
  LastSignal,
  SurepathInfo,
  SurepathLogo,
  SurepathGps,
  SurepathCellCignal
}
