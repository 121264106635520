import { IMower, Device, TNetworkStatus } from '..'
import {
  convertDeviceTimeLineToValidJSON,
  resolveToStandardValueWrapper
} from '../../utils/common'
import {
  EDeviceMowerStatus,
  IDeviceGpsMode,
  IDeviceOperationState,
  IDeviceRtkFixTime,
  IStatusTotal,
  IStatusTotalItem
} from '../device'
import { IDeviceFault } from '../entity'
import { TMowerStatus } from '../mower'
import { convertTimestamp } from '../utils'

// Patch the `faults` so that it can have the mowerHours from the activeFaults
const patchFaults = (
  faults: IDeviceFault[],
  activeFaults: IDeviceFault[]
): IDeviceFault[] => {
  const activeFaultsCollection = activeFaults.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.faultId]: curr
    }
  }, {} as Record<string, IDeviceFault>)

  return faults.map(item => {
    const matchedFault = activeFaultsCollection[item.faultId]

    if (!matchedFault) return item

    return {
      ...item,
      payload: matchedFault.payload
    }
  })
}

export const mapDeviceToMower = (device: Device): IMower | null => {
  const {
    source,
    sourceId,
    location,
    timestamp,
    mowerStatus,
    networkStatus,
    productSerial,
    name,
    model,
    factoryModel,
    dealer,
    softwareVersion,
    lastSeenMower,
    faults,
    statusTotals,
    operationState,
    gpsMode,
    rtkFixTime,
    rssi,
    rsrp,
    rsrq,
    activeFaults,
    mowerHours,
    software,
    timeLine = '[]'
  } = device

  let parsedLocation
  let parsedMowerStatus
  let parsedNetworkStatus
  let parsedLastSeenMower
  let parsedFaults
  let parsedActiveFaults
  let parsedStatusTotals
  let parsedOperationState
  let parsedGpsMode
  let parsedRtkFixTime
  let parsedRssi
  let parsedRsrp
  let parsedRsrq
  let parsedMowerHours
  let parsedTimeLine
  let parsedSoftware

  try {
    const locationParsedResult = resolveToStandardValueWrapper<
      Device['location']['value']
    >(location as unknown as string)
    parsedLocation = locationParsedResult.value

    const mowerStatusParsedResult = resolveToStandardValueWrapper<
      Device['mowerStatus']['value']
    >(mowerStatus as unknown as string)
    parsedMowerStatus = mowerStatusParsedResult.value

    const networkStatusParsedResult = resolveToStandardValueWrapper<
      Device['networkStatus']['value']
    >(networkStatus as unknown as string)
    parsedNetworkStatus = networkStatusParsedResult.value

    const lastSeenMowerResult = resolveToStandardValueWrapper<
      Device['lastSeenMower']['value']
    >(lastSeenMower as unknown as string)
    parsedLastSeenMower = lastSeenMowerResult.value

    const faultParsedResult = resolveToStandardValueWrapper<
      Device['faults']['value']
    >(faults as unknown as string)
    parsedFaults = faultParsedResult?.value ?? []

    if (parsedFaults.length) {
      parsedFaults = parsedFaults.map(item => {
        try {
          const value = JSON.parse(item.payload as unknown as string)

          return {
            ...item,
            payload: value
          }
        } catch (error) {
          // Ignore parsing of bad data
          return {
            ...item,
            payload: {}
          }
        }
      })
    }

    const activeFaultsParsedResult = resolveToStandardValueWrapper<
      Device['activeFaults']['value']
    >(activeFaults as unknown as string)
    parsedActiveFaults = activeFaultsParsedResult?.value ?? []

    if (parsedActiveFaults.length) {
      parsedActiveFaults = parsedActiveFaults.map(item => {
        try {
          const value = JSON.parse(item.payload as unknown as string)

          return {
            ...item,
            payload: value
          }
        } catch (error) {
          // Ignore parsing of bad data
          return {
            ...item,
            payload: {}
          }
        }
      })
    }

    const last7Days = JSON.parse(
      (statusTotals?.last7Days as unknown as string) ?? '[]'
    ) as IStatusTotalItem[]
    const last24Hours = JSON.parse(
      (statusTotals?.last24Hours as unknown as string) ?? '[]'
    ) as IStatusTotalItem[]
    const last30Days = JSON.parse(
      (statusTotals?.last30Days as unknown as string) ?? '[]'
    ) as IStatusTotalItem[]

    parsedStatusTotals = {
      last7Days,
      last24Hours,
      last30Days
    }

    const operationStateParsedResult = resolveToStandardValueWrapper<
      Device['operationState']['value']
    >(operationState as unknown as string)
    parsedOperationState = operationStateParsedResult.value

    const gpsModeParsedResult = resolveToStandardValueWrapper<
      Device['gpsMode']['value']
    >(gpsMode as unknown as string)
    parsedGpsMode = gpsModeParsedResult.value

    const rtkFixTimeParsedResult = resolveToStandardValueWrapper<
      Device['rtkFixTime']['value']
    >(rtkFixTime as unknown as string)
    parsedRtkFixTime = rtkFixTimeParsedResult.value

    const rssiParsedResult = resolveToStandardValueWrapper<
      Device['rssi']['value']
    >(rssi as unknown as string)
    parsedRssi = rssiParsedResult.value

    const rsrqParsedResult = resolveToStandardValueWrapper<
      Device['rsrq']['value']
    >(rsrq as unknown as string)
    parsedRsrq = rsrqParsedResult.value

    const rsrpParsedResult = resolveToStandardValueWrapper<
      Device['rsrp']['value']
    >(rsrp as unknown as string)
    parsedRsrp = rsrpParsedResult.value

    const mowerHoursParsedResult = resolveToStandardValueWrapper<
      Device['mowerHours']['value']
    >(mowerHours as unknown as string)
    parsedMowerHours = mowerHoursParsedResult.value

    parsedTimeLine = convertDeviceTimeLineToValidJSON(timeLine as string)

    parsedSoftware = resolveToStandardValueWrapper<
      Device['software']['value']
    >(software as unknown as string).value
  } catch (error) {
    // Ignore parsing error due to bad data
  }

  const coordinates_data =
    parsedLocation?.latitude && parsedLocation?.longitude
      ? {
          coordinates: {
            lat: Number(parsedLocation?.latitude),
            lng: Number(parsedLocation?.longitude)
          }
      }
      : {}

  const resolveMowerStatus = (
    mowerStatus: EDeviceMowerStatus
  ): TMowerStatus | undefined => {
    switch (mowerStatus) {
      case EDeviceMowerStatus.Unknown:
        return 'UNKNOWN'
      case EDeviceMowerStatus.Off:
        return 'OFF'
      case EDeviceMowerStatus.Idle:
        return 'IDLE'
      case EDeviceMowerStatus.Driving:
        return 'DRIVING'
      case EDeviceMowerStatus.Mowing:
      case EDeviceMowerStatus.MowingSurePathEnabled:
      case EDeviceMowerStatus.MowingSurePathReady:
        return 'MOWING'
      case EDeviceMowerStatus.Charging:
        return 'CHARGING'
      default:
        return undefined
    }
  }

  return {
    ...coordinates_data,
    source,
    id: productSerial,
    name: name || sourceId,
    // placeholder data to get batteries working for now
    // batteries: [
    //   {id: "1231", battery_state: "CHARGING", battery_charge: 100, battery_alert: "HOT_COLD_PACK"},
    //   {id: "1232", battery_state: "CHARGING", battery_charge: 70, battery_alert: "NO_PACK"},
    //   {id: "1233", battery_state: "CHARGING", battery_charge: 50, battery_alert: "PACK_FAULT"},
    //   {id: "1234", battery_state: "IN_USE", battery_charge: 30 },
    //   {id: "1235", battery_state: "IN_USE", battery_charge: 10, battery_alert: "HOT_COLD_PACK"},
    // ],
    last_refresh: convertTimestamp(+timestamp),
    crew: {
      name: ''
    },
    mower_status: resolveMowerStatus(parsedMowerStatus as EDeviceMowerStatus),
    network_status: parsedNetworkStatus?.toUpperCase?.() as TNetworkStatus,
    total_battery_charge: 0,
    total_battery_state: 'CHARGED',
    productSerial: productSerial,
    model_number: model,
    factory_model_number: factoryModel,
    registering_dealer: dealer,
    software_version: softwareVersion,
    last_seen_mower: parsedLastSeenMower,
    faults: patchFaults(
      parsedFaults as IDeviceFault[],
      parsedActiveFaults as IDeviceFault[]
    ),
    activeFaults: parsedActiveFaults,
    statusTotals: parsedStatusTotals as IStatusTotal,
    signal: {
      rssi: parsedRssi as number,
      rsrp: parsedRsrp as number,
      rsrq: parsedRsrq as number,
    },
    rssi: parsedRssi as number,
    rsrp: parsedRsrp as number,
    rsrq: parsedRsrq as number,
    operationState: parsedOperationState as IDeviceOperationState,
    gpsMode: parsedGpsMode as IDeviceGpsMode,
    rtkFixTime: parsedRtkFixTime as IDeviceRtkFixTime,
    mowerHours: parsedMowerHours,
    timeLine: parsedTimeLine ?? [],
    software: parsedSoftware
  }
}
