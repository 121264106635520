import { useCallback } from 'react';
import cn from 'classnames'
import { BatteryIcon } from '../../atoms';
import { BatteryValues } from '../../atoms/BatteryIcon/types';
import { BatteryIconListProps } from './types';
import { BATTERY_STATE, BatteryPack } from '../../../models_v2/entity/telemetry_payload';
import { IDevice } from '../../../models_v2/entity/device';

export const BatteryIconList: React.FC<BatteryIconListProps> = (props) => {
  const { className, batteries } = props

  const getBatteryItem = useCallback((battery: IDevice | null, index: number) => {
    const { IsFaultPresent, CurrentState, StateOfCharge } = battery?.latestTelemetry?.payload as unknown as BatteryPack ?? {}
    let batteryValue: BatteryValues | string = "";

    if (!battery) {
      batteryValue = 'no_battery'
    } else if (IsFaultPresent) {
      batteryValue = 'error'
    }else {
      const iconPrefix = CurrentState === BATTERY_STATE.CHARGING ? 'green' : 'cell'
      if (StateOfCharge >= 81 && StateOfCharge <= 100) {
        batteryValue = `${iconPrefix}_100`
      } else if (StateOfCharge  >= 61 && StateOfCharge  <= 80) {
        batteryValue = `${iconPrefix}_80`
      } else if (StateOfCharge  >= 41 && StateOfCharge  <= 60) {
        batteryValue = `${iconPrefix}_60`
      } else if (StateOfCharge  >= 21 && StateOfCharge  <= 40) {
        batteryValue = `${iconPrefix}_40`
      } else if (StateOfCharge  >= 6 && StateOfCharge  <= 20) {
        batteryValue = `${iconPrefix}_20`
      }  else if ( StateOfCharge  < 6 ) {
        batteryValue = `${iconPrefix}_0`
      }
  
      if (!batteryValue.length) {
        batteryValue = 'cell_0'
      }
    }

    return <BatteryIcon
      key={`batter-icon-${index}`}
      value={batteryValue} />
  }, [])


  return (
    <div className={cn('flex items-center', className)}>
      {batteries?.map(getBatteryItem)}
    </div>
  );
}

export default BatteryIconList;