import { IMetadataInputDto } from '../../models_v2/dto/metadata_input'
import { IMetadata } from '../../models_v2/entity/metadata'
import { useAppDispatch } from '../../store/hooks'
import { upsertMetadataByDevice } from '../../store/slices/deviceSliceV2'
import { useApolloMutation } from '../useApolloMutation'

export const useMetadataUpdate = () => {
  const dispatch = useAppDispatch()

  return useApolloMutation<IMetadata, IMetadataInputDto>({
    query: 'UPDATE_METADATA',
    options: {
      onCompleted: data => {
        dispatch(
          upsertMetadataByDevice({
            productSerial: data.result.productSerial,
            metadata: data.result
          })
        )
      }
    }
  })
}
