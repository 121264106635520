import React, { useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import TabListItem from './TabListItem'
import { TabListProps } from './types'

const TabList: React.FC<TabListProps> = props => {
  const {
    items = [],
    selectedIndex = 0,
    onChange,
    onTabClick,
    containerClassName,
    itemBuilder
  } = props

  const [didRender, setDidRender] = useState(false)

  useEffect(() => {
    setDidRender(true)
  }, [])

  // const isControlledValue = useMemo(() => {
  //   return typeof selectedIndex === 'number' && selectedIndex <= 0
  // }, [selectedIndex])

  const [currentIndex, setCurrentIndex] = useState<number | null | undefined>(
    selectedIndex ?? 0
  )

  useEffect(() => {
    // if (isControlledValue) {
    //   setCurrentIndex(selectedIndex!)
    // }

    setCurrentIndex(selectedIndex)
  }, [selectedIndex])

  useEffect(() => {
    if (!didRender) return

    if (typeof currentIndex === 'number' && items[currentIndex]) {
      onChange?.(items[currentIndex], currentIndex)
    }
  }, [currentIndex])

  const handleTabItemClick = useCallback(
    (index: number) => {
      setCurrentIndex(index)
    },
    [
      // isControlledValue
    ]
  )

  return (
    <div className={cn('flex h-5 gap-3', containerClassName)}>
      {items.map((item, index) => {
        if (itemBuilder) {
          return (
            <div
              key={index}
              onClick={() => {
                onTabClick?.(item, index)
                handleTabItemClick(index)
              }}
              style={{ display: 'inherit' }}>
              {itemBuilder({
                item,
                index,
                isActive: index === currentIndex,
                DefaultComponent: TabListItem
              })}
            </div>
          )
        }

        return (
          <TabListItem
            key={index}
            {...item}
            isActive={index === currentIndex}
            onClick={() => {
              onTabClick?.(item, index)
              handleTabItemClick(index)
            }}
          />
        )
      })}
    </div>
  )
}

export default TabList
