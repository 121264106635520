import cn from 'classnames'
import { Trans } from '@lingui/macro'
import {
  Icon,
  Text,
  Divider
} from '../../atoms'
import { GroupListItemProps } from './types'
import {  useMemo } from 'react'
import AssetListItem from '../AssetListItem'
import { IGroup } from '../../../models_v2/entity/groups'
import { IDevice } from '../../../models_v2/entity/device'
import { 
  resetSelectedTrackingGroup,
  setSelectedTrackingGroup, 
  setShowCollapsebleData } from '../../../store/slices/trackingGroupSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { currentIndexCollapsebleData } from '../../../store/slices/trackingGroupSlice/selectors'
import { selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import {useListItemClickEvents} from '../../../hooks/data/useListItemClickEvents';

const GroupListItem: React.FC<GroupListItemProps> = props => {
  const reduxDispatch = useAppDispatch()
  const { group, isActive = false, index } = props
  const { 
    onListItemClick
  } = useListItemClickEvents({deviceType: "group", device: group})
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const companyMowers = useAppSelector(
      selectFilteredDevices<IDevice>({
        deviceType: "mower", 
        operatingCompany
      })) as IDevice[]
  const indexCollapsebleData = useAppSelector(currentIndexCollapsebleData)
  const handleTwistIcon = (assetCount?: number) => {
    if(!assetCount) return

    reduxDispatch(setShowCollapsebleData(index))
    
    if ( indexCollapsebleData===index ) {
      
      return reduxDispatch(resetSelectedTrackingGroup())
    }  

    reduxDispatch(setSelectedTrackingGroup(group))
  }

  const processedGroup: IGroup = useMemo(() => {
    const devicesGroupIds = companyMowers.map((device: IDevice) => device.inventory.groupId)
    const assetCount = devicesGroupIds.filter(device => device === group.groupId).length
    const device_find: IDevice[] = companyMowers.filter((device: IDevice) => device.inventory.groupId === group.groupId)

    return { 
      ...group, 
      assetCount: assetCount, 
      assets: device_find, 
      notifications: 0 
    }
  }, [companyMowers, group])

  return (
    <div
      className={cn('text-black dark:text-white p-3 card cursor-pointer', {
        'outline outline-4 outline-offset-[-4px] outline-interaction-200':
          isActive
      })}
      onClick={() => {
        reduxDispatch(setSelectedTrackingGroup(processedGroup))
        onListItemClick()
      }
    }>
      <div className='flex flex-row gap-3'>
        <div
          className={cn('flex flex-1 flex-col gap-3')}
          onClick={e => {
            // onPreSelect?.()
            e.stopPropagation()
          }}>
          <div className={cn('flex items-center gap-2 cursor-pointer')}>
            <Text.Feature.Medium>{processedGroup.groupName}</Text.Feature.Medium>
          </div>
          <div
            onClick={() => handleTwistIcon(processedGroup.assetCount)}
            className={cn(`flex items-center gap-2 cursor-pointer`)}>
            <Icon
              name='arrow_right_alt'
              className={cn(`w-3 ${indexCollapsebleData === index ? "twist-arrow-groups": ""}`)}/>
          </div>
        </div>
        <div className={cn('flex flex-1 flex-col gap-3')}>
          <div className={cn('flex items-center gap-2')}>
            <Text.VariousRegular.Small>
              <Trans>Assets :</Trans>
            </Text.VariousRegular.Small>
            <Text.VariousBold.Small>
              {processedGroup.assetCount}
            </Text.VariousBold.Small>
          </div>
          <div className={cn('flex items-center gap-2')}>
            <Text.VariousRegular.Small>
              <Trans>Notifications :</Trans>
            </Text.VariousRegular.Small>
            <Text.VariousBold.Small>
              {processedGroup.notifications}
            </Text.VariousBold.Small>
          </div>
        </div>
      </div>

      {indexCollapsebleData === index &&
        processedGroup?.assets?.map((asset: IDevice, key: number) => {
          return (
            <div className={cn('mt-2')} key={key}>
              <Divider size='lg' />
              <AssetListItem asset={asset} model={"mower"} />
            </div>
          )
        })}
    </div>
  )
}

export default GroupListItem
