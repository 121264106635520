import { 
  useCallback, 
  useContext, 
  useEffect } from 'react'
import { 
  useAppDispatch, 
  useAppSelector } from '../../../store/hooks'
import { 
  useBatterySearchV2, 
  useDynamicQuery } from '../../../hooks'
import {
  selectAllSelectedAssets,
  toggleSelectedAsset } from '../../../store/slices/organizationCompanySlice'
import GarageGlobalFleetDeviceList from '../GarageGlobalFleetDeviceList'
import { AppRootContext } from '../../../pages/AppRoot'
import { IBattery } from '../../../models'
import { IDevice } from '../../../models_v2/entity/device'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import { selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors'


const BATTERY_HIDDEN_COLUMNS: string[] = [
  'has_subscription',
  'network_status',
  'actions',
  "inventory.companyName",
  "inventory.groupName"
]

export const AssetAssignBatteryList = () => {
  const { dispatch } = useContext(AppRootContext)
  const appDispatch = useAppDispatch()
  const { searchFilter } = useAppSelector(state => state.organizationSettings)
  const selectedAssets = useAppSelector(selectAllSelectedAssets)
  const isSelectingAssets = useAppSelector(state => state.organizationCompany.is_selecting_assets)
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const companyBatteries = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: "battery", 
      operatingCompany,
      withoutCompanyAssigned: true
    })) as IDevice[]
 
  const filteredBatteries = useBatterySearchV2({
    items: companyBatteries,
    search: searchFilter
  }) as unknown as IDevice[]
  
  const {
    data: batteryData,
    isFetched: batteryIsFetched,
    isLoading: batteryIsLoading } = useDynamicQuery({
    queryKey: 'GET_TRACKING_BATTERIES',
    mapperKey: 'device_to_battery'
  })

  const onRowClassNameBuilder = useCallback((row: unknown) => {
    const item = row as IBattery

    if (!selectedAssets[item.productSerial as string]) return ''

    return 'border-primary !text-primary'
  },[selectedAssets])

  const handleRowClick = useCallback((row: unknown) => {
    appDispatch(toggleSelectedAsset({ id: (row as IBattery).productSerial as string }))
  },[selectedAssets])

  useEffect(() => {
    if (!batteryData || !batteryIsFetched) return

    dispatch?.({
      type: 'SET_BATTERIES',
      payload: batteryData as IBattery[]
    })
  }, [batteryData, batteryIsFetched])

  return (
    <GarageGlobalFleetDeviceList
      type='battery'
      hiddenColumns={BATTERY_HIDDEN_COLUMNS}
      isLoading={batteryIsLoading}
      /* TODO: To be fixed when GraphQL v2 is finished, cannot be used since this component depends on GraphQL V1 */
      // items={searchFilter ? filteredBatteries : state?.batteries ?? []}
      items={filteredBatteries}
      tableProps={{
        onRowClick: !isSelectingAssets ? undefined : handleRowClick,
        rowClassName: onRowClassNameBuilder
      }}
    />
  )
}
