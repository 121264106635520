import React, { 
  createContext, 
  useReducer } from 'react'
import GaragePage from './GaragePage'
import { 
  reducer, 
  initialState } from './reducers'
import { 
  GaragePageProps, 
  TGaragePageContext } from './types'

export const GaragePageContext = createContext<TGaragePageContext>({})

const GaragePageWrapper: React.FC<GaragePageProps> = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  
  return (
    <GaragePageContext.Provider
      value={{
        state,
        dispatch,
      }}>
      <GaragePage />
    </GaragePageContext.Provider>
  )
}

export default GaragePageWrapper
