export type AppTheme = 'light' | 'dark'

export const Theme = {
  getTheme: (): AppTheme => {
    const theme = localStorage.getItem('theme')
    if (!theme) return 'dark'

    return theme as AppTheme
  },
  switchTheme: (theme: AppTheme) => {
    if (theme === 'light') {
      document.documentElement.classList.remove('dark')
    } else {
      document.documentElement.classList.remove('light')
    }

    document.documentElement.classList.add(theme)
    localStorage.setItem('theme', theme)
    window.dispatchEvent(new Event('theme'))
  },
  toggleTheme: function () {
    const theme = this.getTheme()
    return this.switchTheme(theme === 'dark' ? 'light' : 'dark')
  }
}
