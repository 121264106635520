import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Marker, OverlayView } from '@react-google-maps/api'
import { Clusterer } from '@react-google-maps/marker-clusterer'
import { AppRootContext } from '../../../pages/AppRoot'
import { TMapEvents } from '../../../pages/AppRoot/types'
import { useSearchParams } from 'react-router-dom'
import DeviceFaultMarker from './DeviceFaultMarker'
import { IMetadata } from '../../../models_v2/entity/metadata'
import { useAppSelector } from '../../../store/hooks'
import { ASSETS } from '../../../assets'
import { setActiveMapMarkerProductSerial } from '../../../store/slices/mapSlice'
import { useDispatch } from 'react-redux'

interface IProps {
  item: any // Unpredictable
  clusterer: Clusterer
  clickable: boolean
  onClick: (item: any) => void
  isClustered?: boolean
}

const MapMarker: React.FC<IProps> = props => {
  const { item, clusterer, clickable, onClick, isClustered = true } = props
  const dispatch = useDispatch()
  const { emitter } = useContext(AppRootContext)
  const { name = '' } = item?.inventory ?? {}
  const [focusedFault, setFocusedFault] = useState<IMetadata>()
  const [searchParams] = useSearchParams()
  const { activeMapMarkerProductSerial } = useAppSelector(state => state.map)

  const isMarkerActive = useMemo(
    () => activeMapMarkerProductSerial === item.productSerial,
    [activeMapMarkerProductSerial]
  )

  // highlight one when marker is active
  // or highlight ALL if none selected
  const shouldHighlightMarker = isMarkerActive || !activeMapMarkerProductSerial
  const shouldShowFaultMarkers = useMemo(() => {
    return !!searchParams.get('faultHistory') && isMarkerActive
  }, [searchParams.get('faultHistory'), isMarkerActive])

  const overlayRef = useRef()

  const faultsWithCoordinates = useMemo<IMetadata[]>(() => {
    const faults = (item?.metadata as IMetadata[]) ?? []

    if (!faults?.length) return [] as any

    const parsedPayload = ((faults ?? []) as IMetadata[])
      ?.map(item => {
        try {
          const payload =
            typeof item?.payload === 'string'
              ? JSON.parse(item?.payload)
              : item?.payload

          if (!payload || !payload?.location) return null

          const { latitude, longitude } = payload?.location || {}

          if (!latitude || !longitude) return null

          return {
            ...item,
            payload: {
              ...payload,
              location: {
                longitude: +longitude,
                latitude: +latitude
              }
            }
          } as IMetadata
        } catch (e) {
          console.error(e, item)
          return null
        }
      })
      .filter(item => !!item)

    return parsedPayload
  }, [item?.metadata])

  const markerIcon = useMemo(() => {
    return {
      url: isMarkerActive ? ASSETS.MAPS.map_pin_selected : ASSETS.MAPS.map_pin,
      labelOrigin: new google.maps.Point(90, 53),
      anchor: new google.maps.Point(0, 22)
    }
  }, [isMarkerActive])

  const markerOptions: google.maps.MarkerOptions = useMemo(() => {
    return {
      id: item.productSerial,
      raw: item,
      clickable,
      optimized: true
    }
  }, [item])

  // Work around as we can't set z-index of google maps overlay component with styles
  // instead we have to get a ref returned from its ref.
  useEffect(() => {
    const { containerRef } = (overlayRef.current as any) ?? {}
    if (containerRef?.current) {
      containerRef.current.style.zIndex = shouldHighlightMarker ? 99 : 1
    }
    // ignoring the weird nested ref pattern.
    // @ts-ignore
  }, [isMarkerActive, overlayRef.current?.containerRef?.current])

  useEffect(() => {
    const onMapEvent = (event: TMapEvents) => {
      switch (event.type) {
        case 'DEVICE_FAULT_FOCUS': {
          const { mower_id, fault } = event.payload
          if (item.productSerial !== mower_id) return

          setFocusedFault(fault)
          break
        }
        case 'DEVICE_FAULT_UNFOCUS': {
          const { mower_id } = event.payload
          if (item.productSerial !== mower_id) return
          setFocusedFault(undefined)
          break
        }
      }
    }

    emitter?.on('MAP:tracking', onMapEvent)

    return () => {
      emitter?.off('MAP:tracking', onMapEvent)
    }
  }, [])

  useEffect(() => {
    if (!shouldShowFaultMarkers) {
      setFocusedFault(undefined)
      return
    }

    if (isMarkerActive) {
      const faultAsMapMarkerData = (faultsWithCoordinates ?? [])?.map(item => ({
        coordinates: {
          lat: Number(item.payload.location?.latitude),
          lng: Number(item.payload.location?.longitude)
        }
      })) as []

      const timeout = setTimeout(() => {
        emitter?.emit('MAP:tracking', {
          type: 'FIT_BOUNDS_SELECTED',
          payload: [
            ...faultAsMapMarkerData,
            {
              coordinates: item?.coordinates
            }
          ]
        })
      }, 200)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [shouldShowFaultMarkers, faultsWithCoordinates, isMarkerActive])

  const handleOnFaultMarkerClick = (fault: IMetadata) => {
    const { payload } = fault
    const { location } = payload
    const { latitude, longitude } = location ?? {}

    emitter?.emit('MAP:tracking', {
      type: 'DEVICE_FAULT_FOCUS',
      payload: {
        mower_id: item.id,
        fault
      }
    })

    emitter?.emit('MAP:tracking', {
      type: 'DEVICE_FAULT_MARKER_CLICK',
      payload: {
        mower_id: item.id,
        fault
      }
    })

    emitter?.emit('MAP:tracking', {
      type: 'FIT_BOUNDS_SELECTED',
      payload: [
        {
          coordinates: {
            lat: Number(latitude),
            lng: Number(longitude)
          }
        }
      ]
    })
  }

  const faultMarkers = useMemo(() => {
    return (
      <>
        {faultsWithCoordinates?.map(item => {
          const { metadataId } = item
          const isFocusedFault = focusedFault?.metadataId === metadataId

          return (
            <DeviceFaultMarker
              key={item.metadataId}
              item={item}
              isFocused={isFocusedFault}
              onClick={() => handleOnFaultMarkerClick(item)}
              overlayViewStyle={{
                opacity: !focusedFault ? 1 : isFocusedFault ? 1 : 0.1,
                marginTop: -10.5,
                marginLeft: 21,
                height: 22
              }}
              markerStyle={{
                zIndex: !focusedFault ? 100 : isFocusedFault ? 100 : undefined,
                opacity: !focusedFault ? 1 : isFocusedFault ? 1 : 0.2
              }}
              isFaded={!!focusedFault && !isFocusedFault}
            />
          )
        })}
      </>
    )
  }, [isClustered, faultsWithCoordinates, focusedFault])

  const onMarkerClick = () => {
    dispatch(setActiveMapMarkerProductSerial(item.productSerial))
    onClick(item)
  }

  return (
    <div>
      {/* TODO: Uncomment if Map marker assets are updated */}
      {!isClustered && (
        <OverlayView
          // @ts-ignore
          ref={overlayRef}
          position={item.coordinates}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
          <div
            className='rounded-full bg-black pr-2 text-white flex items-center'
            style={{
              opacity: shouldHighlightMarker ? 1 : 0.2,
              marginTop: -10.5,
              marginLeft: 35,
              height: 23
            }}>
            <span className='mr-1 font-bold'>{name}</span>
          </div>
        </OverlayView>
      )}

      <Marker
        key={item.productSerial}
        position={item.coordinates}
        onClick={onMarkerClick}
        icon={markerIcon}
        clusterer={clusterer}
        options={markerOptions}
        // noClustererRedraw={true}
        opacity={shouldHighlightMarker ? 1 : 0.2}
        zIndex={shouldHighlightMarker ? 99 : 1}
      />

      {shouldShowFaultMarkers && faultMarkers}
    </div>
  )
}

export default memo(MapMarker)
