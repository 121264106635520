import React, { useMemo } from 'react'
import cn from 'classnames'
import { ASSETS } from '../../../assets'
import { Text, Image } from '../../atoms'
import { TStatus } from './types'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

interface StatusIndicatorProps {
  value?: TStatus
  showLabel?: boolean
  showStatusText?: boolean
  prefixText?: string
  prefixTextClassName?: string
  suffixText?: string
  hasWrapperInImage?: boolean
  containerClassName?: string
  imageClassName?: string
  imageOnClick?: () => void
  isOnline?: boolean
}

const StatusIndicator: React.FC<StatusIndicatorProps> = props => {
  const {
    value,
    prefixText,
    suffixText,
    showStatusText = true,
    showLabel = true,
    hasWrapperInImage = true,
    prefixTextClassName,
    containerClassName,
    imageClassName,
    imageOnClick,
    isOnline
  } = props
  const { i18n } = useLingui()

  const status = useMemo(() => {
    // return value.toUpperCase()

    let statusText = ''

    if (value === 'mowing' || isOnline) statusText = t`Online`
    // if (value === 'online') statusText = t`Online`
    else if (value === 'offline') statusText = t`Offline`
    else if (value === 'error') statusText = t`Error`

    if (suffixText) {
      if (!showStatusText) return suffixText
      return `${statusText} - ${suffixText}`
    }

    return showStatusText ? statusText : ''
  }, [value, suffixText, showStatusText, i18n.locale])

  const statusImage = useMemo(() => {
    if (value === 'mowing' || isOnline) return ASSETS.IMAGES.STATUS.online
    else if (value === 'offline') return ASSETS.IMAGES.STATUS.offline
    else if (value === 'error') return ASSETS.IMAGES.STATUS.error
    else if (value === 'danger') return ASSETS.IMAGES.STATUS.danger
    else if (value === 'danger_hd') return ASSETS.IMAGES.STATUS.danger_hd
    else if (value === 'default_hd') return ASSETS.IMAGES.STATUS.default_hd
    else if (value === 'warning_hd') return ASSETS.IMAGES.STATUS.warning_hd
    return ASSETS.IMAGES.STATUS.offline
  }, [value])

  return (
    <div className={cn('flex items-center', containerClassName)}>
      {prefixText && (
        <Text.Body.Small className={cn('mt-1 mr-1', prefixTextClassName)}>
          {i18n._(prefixText)}
        </Text.Body.Small>
      )}
      {showLabel && (
        <Text.Body.Small className='mr-1'>{i18n._(`${status}`)}</Text.Body.Small>
      )}
      {/* h-5 w-5 */}
      {hasWrapperInImage ? (
        <div className='relative h-[35px] w-[35px]'>
          <Image
            src={statusImage}
            className='status-indicator absolute top-[4.5px]'
            onClick={e => {
              e.stopPropagation()
              imageOnClick?.()
            }}
          />
        </div>
      ) : (
        <Image
          src={statusImage}
          className={imageClassName}
          onMouseDown={e => {
            e.stopPropagation()
            imageOnClick?.()
          }}
        />
      )}
    </div>
  )
}

export default StatusIndicator
