import { Row, SortByFn } from 'react-table'

export const reSplitAlphaNumeric = /([0-9]+)/gm

// Mixed sorting is slow, but very inclusive of many edge cases.
// It handles numbers, mixed alphanumeric combinations, and even
// null, undefined, and Infinity
const compareAlphanumeric = (aStr: string, bStr: string) => {
  // Split on number groups, but keep the delimiter
  // Then remove falsy split values
  const a = aStr.split(reSplitAlphaNumeric).filter(Boolean)
  const b = bStr.split(reSplitAlphaNumeric).filter(Boolean)

  // While
  while (a.length && b.length) {
    const aa = a.shift()!
    const bb = b.shift()!

    const an = parseInt(aa, 10)
    const bn = parseInt(bb, 10)

    const combo = [an, bn].sort((a, b) => a - b)

    // Both are string
    if (isNaN(combo[0]!)) {
      if (aa > bb) {
        return 1
      }
      if (bb > aa) {
        return -1
      }
      continue
    }

    // One is a string, one is a number
    if (isNaN(combo[1]!)) {
      return isNaN(an) ? -1 : 1
    }

    // Both are numbers
    if (an > bn) {
      return 1
    }
    if (bn > an) {
      return -1
    }
  }

  return a.length - b.length
}

const toString = (a: any) => {
  if (typeof a === 'number') {
    if (isNaN(a) || a === Infinity || a === -Infinity) {
      return ''
    }
    return String(a)
  }
  if (typeof a === 'string') {
    return a
  }
  return ''
}

const getRowValuesByColumnID = (row1: Row, row2: Row, columnId: string) => {
  return [row1.values[columnId], row2.values[columnId]]
}

// Case insensitive
const alphanumeric: SortByFn<any> = (rowA, rowB, columnId) => {
  const [rowAValue = '', rowBValue = ''] = getRowValuesByColumnID(
    rowA,
    rowB,
    columnId
  )

  // * Cause of incorrect sorting, will need to revisit
  // return compareAlphanumeric(
  //   toString(rowAValue).toLocaleLowerCase(),
  //   toString(rowBValue).toLocaleLowerCase()
  // )

  return toString(rowAValue).localeCompare(toString(rowBValue))
}

export const sortingFns = {
  alphanumeric
}
