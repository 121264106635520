import { createSlice } from '@reduxjs/toolkit'
import { IGroup } from '../../../models_v2/entity/groups'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IInitialState {
  groups: IGroup[]
  currentIndexCollapsebleData: number | null
  selectedGroup: IGroup
}

const initialSelectedGroup = {
  companyId: '',
  devicesSerialNumber: [],
  groupName: '',
  assetCount: 0,
  assets: [],
  groupId: '',
  notifications: 0,
  productSerial: ''
}

const initialState: IInitialState = {
  groups: [],
  currentIndexCollapsebleData: null,
  selectedGroup: { ...initialSelectedGroup }
}

export const trackingGroupSlice = createSlice({
  name: 'trackingGroups',
  initialState,
  reducers: {
    resetState: () => initialState,
    setTrackingGroups: (state, action: PayloadAction<IGroup[]>) => {
      return {
        ...state,
        groups: action.payload
      }
    },
    setSelectedTrackingGroup: (state, action: PayloadAction<IGroup>) => {
      return {
        ...state,
        selectedGroup: action.payload
      }
    },
    setShowCollapsebleData: (state, action: PayloadAction<number | null>) => {
      const indexCollapsebleData =
        state.currentIndexCollapsebleData === action.payload
          ? null
          : action.payload
      return {
        ...state,
        currentIndexCollapsebleData: indexCollapsebleData
      }
    },
    resetSelectedTrackingGroup: ( state ) =>{
      state.selectedGroup = { ...initialSelectedGroup }
    },
    resetTrackingGroupState: () => initialState
  }
})

export const {
  resetState,
  setTrackingGroups,
  setSelectedTrackingGroup,
  setShowCollapsebleData,
  resetSelectedTrackingGroup,
  resetTrackingGroupState
} = trackingGroupSlice.actions

export const trackingGroupsSliceReducer = trackingGroupSlice.reducer
export default trackingGroupSlice.reducer
