import React from 'react'
import styled from 'styled-components'
import { numberToPercentage } from '../../../utils/math'

interface ProgressBarProps {
  value: number
  progressBarColor?: string
}

const ProgressBar: React.FC<ProgressBarProps> = props => {
  const { value = 0, progressBarColor = '#ffffff' } = props
  return (
    <StyledDiv className='progress-bar w-full h-2 rounded-md overflow-clip'>
      <StyledBar
        className='h-full'
        style={{
          backgroundColor: progressBarColor,
          width: `${numberToPercentage(value)}%`
        }}
      />
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  background-color: #262626;
  border: 1px solid;
  border-top-color: #393939;
  border-left-color: #393939;
  border-right-color: #393939;
  border-bottom-color: #6A6969;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.4);
`

const StyledBar = styled.div`
  box-shadow: inset -1px 1px 3px rgba(0,0,0,0.25);
`

export default ProgressBar
