import { useMemo, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import cn from 'classnames'
import { Text } from '../'
import Icon from '../Icon'

export interface IMultiSelectOption<T> {
  label: string
  value: T
  disabled?: boolean
}

export interface MultiSelectProps<T = any>
  extends Pick<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    'onFocus'
  > {
  options: IMultiSelectOption<T>[]
  value?: Array<T>
  onChange?: (value: Array<T>) => void
  className?: string
  placeholder?: string
  disabled?: boolean
  dense?: boolean
}

const MultiSelect: React.FC<Partial<MultiSelectProps>> = props => {
  const {
    options = [],
    value = [],
    onChange,
    className,
    placeholder,
    disabled = false,
    dense = false,
    onFocus
  } = props

  const selectedOption = useMemo(() => {
    return options?.filter(option => value?.includes(option?.value))
  }, [options, value])

  return (
    <div
      className={cn('min-w-[200px] text-white', className)}
      onFocus={onFocus}>
      <Listbox value={value} onChange={onChange} multiple disabled={disabled}>
        {({ open }) => (
          <div className='relative'>
            <Listbox.Button
              className={cn(
                'min-h-[48px] relative w-full cursor-default px-3 py-[5px] bg-black-600 focus:outline-none border border-white rounded disabled:opacity-70 transition-all',
                {
                  'min-h-[40px]': dense,
                  '!bg-primary': open
                }
              )}>
              {selectedOption?.length ? (
                <div className='flex flex-wrap gap-2'>
                  {selectedOption?.map(item => (
                    <Text.Body.Large className='block truncate text-left p-1'>
                      {item?.label}
                    </Text.Body.Large>
                  ))}
                </div>
              ) : (
                <Text.Body.Large className='block truncate text-left pr-3'>
                  {placeholder}
                </Text.Body.Large>
              )}
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <Icon name='caret_down' />
              </span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              enter='transition-opacity duration-75'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity duration-150'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Listbox.Options className='absolute z-10 max-h-[200px] w-full overflow-auto focus:outline-none'>
                {options.map((item, index) => (
                  <Listbox.Option key={index} value={item.value} as={Fragment}>
                    {({ active }) => (
                      <li
                        className={cn(
                          'px-3 py-2 bg-black-500 focus:outline-none cursor-default',
                          {
                            '!bg-primary': active
                          }
                        )}>
                        {item.label}
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  )
}

export default MultiSelect
