/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { NavigationBarItem } from '../SubNavigationBar/types';
import SubNavigationBar from '../SubNavigationBar';

interface ProfileSubNavigationBar {}

export const ProfileSubNavigationBar: React.FC<ProfileSubNavigationBar> = () => {
  const navigationItems: NavigationBarItem[] = useMemo(() => {
    return [
      {
        id: 'profile',
        label: 'Your Profile'
      }
    ]
  }, [])
  
  return (
    <SubNavigationBar
      items={navigationItems}
      selectedIndex={0}
      showActiveBorder
    />  
  );
}
