import React from 'react'
import { Image } from '../../atoms'
import { ASSETS } from '../../../assets'

const AppLogo: React.FC = () => (
  <div>
    <Image src={ASSETS.IMAGES.APP.app_logo} className='h-7' />
  </div>
)

export default AppLogo
