import React from 'react'
import cn from 'classnames'
import * as Text from '../Typography'

interface AlertProps {
  variant: 'error' | 'success'
  message: string
  className?: string
}

export const Alert: React.FC<AlertProps> = props => {
  const { variant = 'success', message, className } = props

  return (
    <div
      className={cn(
        'bg-success border border-solid border-success-dark rounded py-2 px-3 mb-3 text-center dark:text-white',
        {
          '!bg-[#f8514926] !border-[#f8514966]': variant === 'error'
        },
        className
      )}>
      <Text.Body.SmallSemiBold>{message}</Text.Body.SmallSemiBold>
    </div>
  )
}
