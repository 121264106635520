import React, { memo, useCallback } from 'react'
import { HeaderGroup } from 'react-table'
import cn from 'classnames'
import { Text } from '../../../atoms'
import { ColumnSorter } from './ColumnSorter'
import { useTableContext } from '../useTableContext'

interface ColumnRendererProps {
  column: HeaderGroup<object>
}

const BaseColumnRenderer: React.FC<ColumnRendererProps> = props => {
  const { column } = props
  const { tableProps, tableInstance } = useTableContext()

  const { enableSorting, columnClassName } = tableProps
  const { toggleSortBy } = tableInstance

  const handleToggleSort = useCallback((column: HeaderGroup<object>) => {
    toggleSortBy(column.id, !column.isSortedDesc)
  }, [])

  return (
    <div
      className='flex items-center text-left px-4 py-3'
      {...column.getHeaderProps({
        style: {
          maxWidth: column.maxWidth,
          minWidth: column.minWidth,
          width: column.width
        }
      })}>
      <div
        className={cn('flex gap-2', {
          'cursor-pointer': enableSorting
        })}
        onClick={() => handleToggleSort(column)}>
        <Text.VariousBold.Small className={cn('text-white', columnClassName)}>
          {column.render('Header')}
        </Text.VariousBold.Small>

        {enableSorting && <ColumnSorter {...column} />}
      </div>
    </div>
  )
}

export const ColumnRenderer = memo(BaseColumnRenderer)
