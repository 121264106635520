import React, { ComponentProps, useEffect, useMemo, useState } from 'react'
import { TextField, Text, Button } from '../../atoms'
import { SubmitHandler, useForm } from 'react-hook-form'
import SelectionList from '../../atoms/SelectionList'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  addGroup,
  editGroup,
  resetState,
  toggleDeviceSelection
} from '../../../store/slices/deviceGroupSlice'
import {
  deviceEditFormContext,
  deviceGroupNameFormContext,
  groupList,
  selectDeviceGroupFormSelectedDevices
} from '../../../store/slices/deviceGroupSlice/selectors'
import { selectDevices } from '../../../store/slices/deviceSliceV2/selectors'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../routes'
import { useGroupUpdate } from '../../../hooks/data/useGroupUpdate'
import { GroupInputDto } from '../../../models_v2/dto/group_input'
import { BackToListButton } from '../../molecules'
import { IGroup } from '../../../models_v2/entity/groups'
import { updateManyDevicesWithSameUpdate } from '../../../store/slices/deviceSliceV2'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'

// temporary schema
interface IFormInput {
  name: string
  device_ids: string[] // array of ids of devices
}

interface GarageGlobalFleetDeviceGroupFormProps {}

const GarageGlobalFleetDeviceGroupForm: React.FC<GarageGlobalFleetDeviceGroupFormProps> =
  props => {
    const operatingCompany = useAppSelector(selectOperatingCompany)
    const [mutateGroup] = useGroupUpdate()
    const navigate = useNavigate()
    const reduxDispatch = useAppDispatch()
    const devices = useAppSelector(selectDevices)
    const deviceGroupFormSelectedDevices = useAppSelector(
      selectDeviceGroupFormSelectedDevices
    )
    const group_name = useAppSelector(deviceGroupNameFormContext)
    const { groupId } = useAppSelector(state => state.deviceGroup.form)
    const groups = useAppSelector(groupList)
    const isEdit = useAppSelector(deviceEditFormContext)
    const [isMutating, setIsMutating] = useState(false)
    const {
      register,
      handleSubmit,
      setError,
      clearErrors,
      setValue,
      formState: { errors: formErrors }
    } = useForm<IFormInput>({
      defaultValues: {
        name: group_name,
        device_ids: []
      }
    })

    const deviceSerialNumbers: string[] = useMemo(
      () =>
        deviceGroupFormSelectedDevices.map(
          deviceGroupFormSelectedDevice =>
            deviceGroupFormSelectedDevice.productSerial
        ),
      [deviceGroupFormSelectedDevices]
    )

    const onSubmit: SubmitHandler<IFormInput> = async data => {
      if (isMutating) {
        return
      }

      //TODO: Need to make a selector
      const foundGroupWithSameName = groups
        .filter(group => group.companyId === operatingCompany.companyId)
        .filter(group => group.groupName !== group_name)
        .find(group => group.groupName === data.name)

      if (foundGroupWithSameName) {
        return setError('name', { message: 'Group name already exist' })
      } else {
        clearErrors('name')
      }

      setIsMutating(true)
      const dto = new GroupInputDto(
        Object.assign(
          {
            devicesSerialNumber: deviceSerialNumbers,
            groupName: data.name,
            companyId: operatingCompany.companyId
          },
          groupId ? { groupId } : null
        )
      )

      const result = (await mutateGroup({ variables: dto.toJSON() })) as any
      const mutatedGroup: IGroup = {
        ...result.data.result[result.data.result.length - 1],
        assetCount: deviceGroupFormSelectedDevices.length,
        assets: deviceGroupFormSelectedDevices
      }

      if (!isEdit) {
        reduxDispatch(addGroup(mutatedGroup))
      } else {
        reduxDispatch(
          updateManyDevicesWithSameUpdate({
            devicesProductSerials: deviceSerialNumbers,
            device: {
              inventory: {
                groupId
              }
            }
          })
        )
        reduxDispatch(editGroup(mutatedGroup))
      }

      setIsMutating(false)
      navigate(Routes['garage_page.global-fleet'])
    }

    const selectionListItems: ComponentProps<typeof SelectionList>['items'] =
      useMemo(() => {
        return deviceGroupFormSelectedDevices.map((device: any) => {
          return {
            id: device.productSerial,
            label: device?.inventory?.name
          } as ComponentProps<typeof SelectionList>['items'][0]
        })
      }, [devices, deviceGroupFormSelectedDevices])

    const handleRemoveDevice = (
      row: ComponentProps<typeof SelectionList>['items'][0]
    ) => reduxDispatch(toggleDeviceSelection(row.id))

    useEffect(() => {
      if (!isEdit) {
        setValue('name', '')
        setValue('device_ids', [])
        reduxDispatch(resetState())
      } else {
        setValue('name', group_name)
      }
    }, [isEdit, group_name, setValue])

    return (
      <>
        <BackToListButton onClick={() => navigate(-2)} />
        <div className='h-full overflow-y-auto'>
          <form
            className='flex flex-col h-full'
            onSubmit={handleSubmit(onSubmit)}>
            <div className='flex-1 flex flex-col gap-3 mb-4'>
              <div className='flex flex-col gap-2'>
                <Text.Body.MediumSemiBold>Group Name</Text.Body.MediumSemiBold>
                <TextField
                  {...register('name', {
                    required: { message: 'Name is required', value: true },
                    minLength: { value: 1, message: 'Name should not be empty' }
                  })}
                  className='focus:outline-none focus:border-solid focus:border-white !text-primary'
                  type={'text'}
                />
                {formErrors.name && (
                  <Text.Body.MediumSemiBold className='text-error'>
                    {formErrors.name.message}
                  </Text.Body.MediumSemiBold>
                )}
              </div>
              <div className='flex flex-col gap-2 flex-1'>
                <Text.Body.MediumSemiBold>Add Assets</Text.Body.MediumSemiBold>
                <SelectionList
                  items={selectionListItems}
                  onRemove={handleRemoveDevice}
                />
              </div>
            </div>
            <Button
              type='submit'
              className='w-full bg-primary hover:bg-primary active:bg-primary text-black font-bold'
              title={`${isEdit ? 'Update' : 'Add'} Group`}
            />
          </form>
        </div>
      </>
    )
  }

export default GarageGlobalFleetDeviceGroupForm
