import React, { memo } from 'react'
import { Cell } from 'react-table'
import { Text } from '../../../atoms'

interface CellRendererProps {
  cell: Cell<object, any>
}

const BaseCellRenderer: React.FC<CellRendererProps> = props => {
  const { cell, ...restProps } = props

  return (
    <div {...restProps} className='px-4 break-words'>
      <Text.Body.Medium>{cell.render('Cell')}</Text.Body.Medium>
    </div>
  )
}

export const CellRenderer = memo(BaseCellRenderer)
