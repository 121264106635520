import cn from 'classnames'
import React from 'react'
import styled from 'styled-components'
import { DynamicListItem } from '..'
import { Text } from '../../atoms'

interface DeviceCounterItemProps {
  label: string
  value: string | number
  isActive?: boolean
  onClick?: () => void
}

const DeviceCounterItem: React.FC<DeviceCounterItemProps> = props => {
  const { label, value, isActive = false, onClick } = props

  return (
    <StyledDynamicListItem
      className={cn('w-[130px] px-[12px] py-2', {
        'cursor-pointer': !!onClick
      })}
      onClick={onClick}>
      {isActive && (
        <StyledBorder className='w-[3px] h-[calc(100%-6px)] bg-primary rounded-full mr-2' />
      )}
      <div className='flex flex-col'>
        <Text.VariousBold.Small>{label}</Text.VariousBold.Small>
        <span className='min-h-[35px] text-[35px] leading-none font-light'>
          {value}
        </span>
      </div>
    </StyledDynamicListItem>
  )
}

export default DeviceCounterItem

const StyledDynamicListItem = styled(DynamicListItem)`
  box-shadow: 0px 21px 64px rgba(0, 0, 0, 0.5);
`

export const StyledBorder = styled.div`
  box-shadow: 0px 0px 11px rgba(254, 189, 24, 0.57);
`
