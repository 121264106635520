import React, { useCallback } from 'react'
import cn from 'classnames'
import { Button, Text, Image, Icon } from '../../atoms'
import { userAccountStatusResolver } from '../../../models/utils/status'
import { useAppSelector } from '../../../store/hooks'
import { selectActiveAccountDetailsById } from '../../../store/slices/organizationSettingsSlice';
import { ASSETS } from '../../../assets'
import { Routes } from '../../../routes'
import { useMatch, useNavigate } from 'react-router-dom'
import { selectUserIsAdmin } from '../../../store/slices/userSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import moment from 'moment'
import { PatternFormat } from 'react-number-format'
import { IUserAccount } from '../../../models/user_account';

interface CompanyOrganizationProfileDetails { }

export const CompanyOrganizationProfileDetails: React.FC<CompanyOrganizationProfileDetails> = () => {
  const userIsAdmin = useSelector((state: RootState) =>
    selectUserIsAdmin(state)
  )

  const navigate = useNavigate()
  const accountDetails = useAppSelector(state =>
    selectActiveAccountDetailsById(state.organizationSettings))
 
  const isPeopleRoute = useMatch(Routes.people)
  const isCompanyRoute = useMatch(Routes.companies)

  const handleEditUserProfileClick = useCallback(() => {
    if (!accountDetails) return
    let route = ''
    let id = ''
    if (isPeopleRoute) {
      route = Routes.people_edit
      id = accountDetails.id
    } else if (isCompanyRoute) {
      route = Routes.companies_edit
      id = accountDetails.id
    }
    navigate(route.replace(':id', id))
  }, [accountDetails, isPeopleRoute])

  const handleAddProfileClick = useCallback(() => {
    if (isPeopleRoute) {
      navigate(Routes.people_invite)
    } else if (isCompanyRoute) {
      navigate(Routes.companies_create)
    }
  }, [isPeopleRoute])

  return (
    <div className='h-full overflow-y-auto'>
      <div className='h-full'>
        <div className='h-full flex flex-col'>
          <div
            className={cn('flex-1 flex flex-col dark:text-white px-[20px]', {
              'flex items-end': !accountDetails
            })}>
            <div className='flex-1'>
              {Boolean(Object.keys(accountDetails ?? {})?.length) && (
                <>
                  <Text.Headline.H3 className='!font-bold'>
                    Profile Details:
                  </Text.Headline.H3>
                  {/* <div className='my-4 h-[170px] w-[170px] m-auto relative'>
                    <Image
                      src={ASSETS.IMAGES.COMMON.user_profile}
                      className='h-full w-full p-[6px]'
                    />
                  </div> */}

                  <div style={{marginTop: 20}}>
                    <Text.Headline.H4 className='!font-bold'>
                      {`${accountDetails?.first_name} ${accountDetails?.last_name}`}
                    </Text.Headline.H4>
                    <Text.Headline.H5 className='!font-normal'>
                      {accountDetails?.role || ''}
                    </Text.Headline.H5>
                  </div>

                  <div className='my-3'>
                    <div className='flex gap-1'>
                      <Text.Body.Medium>Email:</Text.Body.Medium>
                      <Text.Body.Medium className='!font-bold'>
                        {accountDetails?.email}
                      </Text.Body.Medium>
                    </div>
                    <div className='flex gap-1'>
                      <Text.Body.Medium>Status:</Text.Body.Medium>
                      <Text.Body.Medium className='!font-bold'>
                        {accountDetails &&
                          userAccountStatusResolver(accountDetails as IUserAccount)}
                      </Text.Body.Medium>
                    </div>
                    <div className='flex gap-1'>
                      <Text.Body.Medium>Since:</Text.Body.Medium>
                      <Text.Body.Medium className='!font-bold'>
                        {moment(accountDetails?.created_date).format('MM/DD/YYYY')}
                      </Text.Body.Medium>
                    </div>
                    <div className='flex gap-1'>
                      <Text.Body.Medium>Phone #:</Text.Body.Medium>
                      <Text.Body.Medium className='!font-bold'>
                        <PatternFormat
                          key={accountDetails.id}
                          format='###-###-####'
                          displayType='text'
                          valueIsNumericString
                          value={accountDetails?.phone_number}
                        />
                      </Text.Body.Medium>
                    </div>

                    {userIsAdmin && (
                      <div className='my-3'>
                        <Button
                          className='w-full'
                          showOutline={false}
                          color='primary'
                          title='Edit Profile'
                          onClick={handleEditUserProfileClick}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>

            {userIsAdmin && (
              <Button
                className='w-full flex justify-between items-center'
                showOutline={false}
                color='secondary'
                titleSize='md'
                title='Add Profile'
                onClick={handleAddProfileClick}>
                <Icon name='add_circle' className='w-4' />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
