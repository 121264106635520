import cn from 'classnames'
import React from 'react'
import {
  ReactCalendarItemRendererProps,
  TimelineItemBase
} from 'react-calendar-timeline'
import {
  StyledIdlePill,
  StyledJobSessionPill,
  StyledManualDrivePill,
  StyledMowingPill,
  StyledSurepathEnabledPill
} from '../../molecules/SurepathTimelineLegend'
import styled from 'styled-components'
import { ETimelineGroup } from './utils'

interface SurepathTimelineItemRendererProps
  extends ReactCalendarItemRendererProps<TimelineItemBase<any>> {}

const SurepathTimelineItemRenderer: React.FC<SurepathTimelineItemRendererProps> =
  props => {
    const { item, getItemProps } = props
    const wrapperProps = getItemProps(item?.itemProps ?? {})
    const { group } = item

    let Bar = null

    if (group === ETimelineGroup.JOB_SESSION) {
      Bar = <StyledJobSessionPill className='!h-[10px] !w-full !rounded-full' />
    } else if (group === ETimelineGroup.MANUAL_DRIVE) {
      Bar = (
        <StyledManualDrivePill className='!h-[10px] !w-full !rounded-full' />
      )
    } else if (group === ETimelineGroup.SUREPATH_ENABLED) {
      Bar = (
        <StyledSurepathEnabledPill className='!h-[10px] !w-full !rounded-full' />
      )
    } else if (group === ETimelineGroup.MOWING) {
      Bar = <StyledMowingPill className='!h-[10px] !w-full !rounded-full' />
    } else if (group === ETimelineGroup.IDLE) {
      Bar = <StyledIdlePill className='!h-[10px] !w-full !rounded-full' />
    }

    return (
      <div
        {...wrapperProps}
        className={cn(wrapperProps.className, '!bg-transparent !border-0')}>
        {group === 1 && (
          <StyledJobSessionBackground className='absolute h-[84px] w-full rounded-md' />
        )}
        {Bar}
      </div>
    )
  }

export default SurepathTimelineItemRenderer

const StyledJobSessionBackground = styled.div`
  // background: linear-gradient(
  //   180deg,
  //   rgba(169, 121, 0, 0.3) 0%,
  //   rgba(255, 184, 0, 0.3) 100%
  // );
`
