import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Button, Dialog } from '../../../components/atoms'
import { useDynamicMutation } from '../../../hooks'
import { mapUserToUserEntity } from '../../../models/mapper/user_to_user_entity'
import { UpdatePasswordForm } from './UpdatePasswordForm'
import {useDispatch, useSelector} from 'react-redux';
import {selectUser, partialCurrentUserUpdate} from '../../../store/slices/userSlice';
import {RootState} from '../../../store/store';
import {useFormContext} from 'react-hook-form';
import {IUserFormInput} from '../../../components/organisms/SettingsUserAdministration/components/UserProfileForm';

interface MyProfileFormActionsProps {}

export const MyProfileFormActions: React.FC<MyProfileFormActionsProps> = () => {
  const reduxDispatch = useDispatch();
  const user = useSelector((state: RootState) => selectUser(state))

  const { handleSubmit } = useFormContext()
  
  const navigate = useNavigate()

  const {
    mutateAsync: updateUserAsync,
    isLoading: updateUserIsLoading,
    isError: updateUserIsError
  } = useDynamicMutation({
    graphqlEntity: 'users',
    queryKey: 'UPDATE_USER'
  })

  const [successMessage, setSuccessMessage] = useState('')
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false)

  const handleSaveClicked = () => {
    handleSubmit((data) => updateUser(data as IUserFormInput))();
  }

  const updateUser = async (user: IUserFormInput) => {
    setSuccessMessage('')
    const userUpdates = mapUserToUserEntity(user)

    await updateUserAsync({
      input: {
        id: userUpdates?.id,
        firstName: userUpdates?.firstName,
        lastName: userUpdates?.lastName,
        phone: userUpdates?.phone,
        companies: userUpdates?.companies,
      }
    })
    // TODO: block if error
    setSuccessMessage('Updated user profile successfully')
    reduxDispatch(partialCurrentUserUpdate(userUpdates))
  }

  if (!user) return null;

  return (
    <>
      <Dialog
        headerTitle='Set New Password'
        show={showChangePasswordDialog}
        onClose={() => setShowChangePasswordDialog(false)}>
        <UpdatePasswordForm />
      </Dialog>
      <div className='h-full overflow-y-auto'>
        <div className='h-full p-[30px]'>
          <div className='flex flex-col h-full'>
            <div className='flex-1 flex flex-col gap-3 mt-[78px] mb-3'>
              {updateUserIsError && (
                <Alert
                  variant='error'
                  message='Error in updating user profile'
                />
              )}
              {successMessage && (
                <Alert variant='success' message={successMessage} />
              )}

              <Button
                title={updateUserIsLoading ? 'Saving...' : 'Save'}
                onClick={handleSaveClicked}
                disabled={updateUserIsLoading}
              />

              <Button
                color='secondary'
                title='Cancel'
                className='border border-solid border-white'
                onClick={() => navigate(-1)}
                disabled={updateUserIsLoading}
              />
            </div>

            <Button
              type='submit'
              className='w-full bg-primary hover:bg-primary active:bg-primary text-black font-bold'
              title='Change Password'
              onClick={() => setShowChangePasswordDialog(true)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
