export enum EExportReportType {
  Inventory = 'Inventory',
  Hours = 'Hours',
  Activity = 'Activity',
  Faults = 'Faults'
}

export enum EExportFormat {
  CSV = 'CSV',
  JSON = 'JSON'
}

export interface IExportInput {
  devices: string[]
  reportType: EExportReportType
  format: EExportFormat
  /**
   * Format must be in YYYY-MM-DD
   */
  startDate?: string
  /**
   * Format must be in YYYY-MM-DD
   */
  endDate?: string
  downloadFileName?: string
}

export interface IExport {
  url: string
  generatedAt: string
}
