import React, { useMemo } from 'react';
import { ASSETS } from '../../../assets';
import { BatteryValues } from './types';
import { Image } from '..'
import { useAppTheme } from '../../../hooks';

interface BatteryIconProps {
  value: BatteryValues | string
}

const BatteryIcon: React.FC<BatteryIconProps> = (props) => {
  const { theme } = useAppTheme()
  const { value } = props;

  const renderImage = useMemo(() => {
    switch(value) {
      case 'cell_0': return theme==="dark"? ASSETS.ICONS.battery_cell_0 : ASSETS.ICONS.battery_cell_0_light;
      case 'cell_20': return theme==="dark"? ASSETS.ICONS.battery_cell_20 : ASSETS.ICONS.battery_cell_20_light;
      case 'cell_40': return theme==="dark"? ASSETS.ICONS.battery_cell_40 : ASSETS.ICONS.battery_cell_40_light;
      case 'cell_60': return theme==="dark"? ASSETS.ICONS.battery_cell_60 : ASSETS.ICONS.battery_cell_60_light;
      case 'cell_80': return theme==="dark"? ASSETS.ICONS.battery_cell_80 : ASSETS.ICONS.battery_cell_80_light;
      case 'cell_100': return theme==="dark"? ASSETS.ICONS.battery_cell_100 : ASSETS.ICONS.battery_cell_100_light;
      case 'green_0': return theme==="dark"? ASSETS.ICONS.battery_green_0 : ASSETS.ICONS.battery_green_0_light;
      case 'green_20': return theme==="dark"? ASSETS.ICONS.battery_green_20 : ASSETS.ICONS.battery_green_20_light;
      case 'green_40': return theme==="dark"? ASSETS.ICONS.battery_green_40 : ASSETS.ICONS.battery_green_40_light;
      case 'green_60': return theme==="dark"? ASSETS.ICONS.battery_green_60 : ASSETS.ICONS.battery_green_60_light;
      case 'green_80': return theme==="dark"? ASSETS.ICONS.battery_green_80 : ASSETS.ICONS.battery_green_80_light;
      case 'green_100': return theme==="dark"? ASSETS.ICONS.battery_green_100 : ASSETS.ICONS.battery_green_100_light;
      case 'white_0': return ASSETS.ICONS.battery_white_0;
      case 'white_20': return ASSETS.ICONS.battery_white_20;
      case 'white_40': return ASSETS.ICONS.battery_white_40;
      case 'white_60': return ASSETS.ICONS.battery_white_60;
      case 'white_80': return ASSETS.ICONS.battery_white_80;
      case 'white_100': return ASSETS.ICONS.battery_white_100;
      case 'error': return ASSETS.ICONS.battery_error_error;
      case 'no_battery': return ASSETS.ICONS.battery_error_no_battery;
      case 'temperature': return ASSETS.ICONS.battery_error_temperature;
      default: return ASSETS.ICONS.battery_cell_100_light
    }
  }, [value, theme]);

  return (
    <Image src={renderImage} className='w-[18px] h-[18px] lg:w-4 lg:h-4'/>
  );
}

export default BatteryIcon;