import React from 'react'
import cn from 'classnames'
import { Body } from '../Typography'
import { useLingui } from '@lingui/react'

export interface IRadioButtonProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string
}

const RadioButton: React.FC<IRadioButtonProps> = props => {
  const { i18n } = useLingui()
  const { id, label, className, checked, ...restProps } = props

  return (
    <div className={cn(className, 'flex flex-row gap-2 items-center')}>
      <input
        id={id || label}
        {...restProps}
        className={cn(
          'appearance-none focus:outline-none outline-none w-[16px] h-[16px] border border-solid dark:border-white rounded-full shadow-[0_0_5px_0px_#e3e3e34b_inset]',
          'hover:shadow-[0_0_5px_0px_#3131314b_inset]',
          'before:block before:w-[60%] before:h-[60%] before:my-[23%] before:mx-auto before:rounded-full',
          'checked:before:bg-black dark:checked:before:bg-white'
        )}
        type='radio'
        checked={checked}
      />
      <label htmlFor={id || label}>
        {checked ? (
          <Body.MediumSemiBold>{i18n._(label)}</Body.MediumSemiBold>
        ) : (
          <Body.Medium>{i18n._(label)}</Body.Medium>
        )}
      </label>
    </div>
  )
}

export default RadioButton
