export const Placeholders = {
  'tracking.sidebar.device_empty_list': 'No assets in current map display area'
}

export interface ITeamMember {
  name: string
  role: string
}

export interface IProductionTeam {
  [key: string]: ITeamMember[]
}

export const ProductionTeam: IProductionTeam = {
  'Management Team': [
    {
      name: 'Rob Osterwise',
      role: 'IoT Director'
    },
    {
      name: 'Nilesh Patel',
      role: 'Program Manager'
    }
  ],
  'Product Manager': [
    {
      name: 'Eric Loeliger',
      role: 'Sr Product Manager'
    }
  ],
  'Scrum Master': [
    {
      name: 'Hector Viveros',
      role: 'Agile Delivery Lead'
    }
  ],
  'Tech Lead': [
    {
      name: 'Cesar Gonzalez',
      role: 'IoT Engineering Director'
    }
  ],
  'UX/UI Design': [
    {
      name: 'Christian Delise',
      role: 'Lead UX Designer'
    },
    {
      name: 'Brian Skuse',
      role: 'Sr UI Designer'
    }
  ],
  IoT: [
    {
      name: 'Adway Dogra',
      role: 'Embedded HW/Connectivity'
    },
    {
      name: 'An Nguyen',
      role: 'Hardware Engineer'
    },
    {
      name: 'Allen Smith',
      role: 'IoT Solution Architect'
    },
    {
      name: 'AJ Newton',
      role: 'Software Engineer'
    },
    {
      name: 'Yuwei Lu',
      role: 'IoT Ideation Software Engineer'
    },
    {
      name: 'John Novosel',
      role: 'Mobile Software Engineer'
    },
    {
      name: 'Neeharika Kusampudi',
      role: 'Data Analytics Engineer'
    },
    {
      name: 'Luiz Starling',
      role: 'Customer Solution Manager'
    },
    {
      name: 'Scott Inlow',
      role: 'Solutions Architect'
    }
  ],
  Backend: [
    {
      name: 'Chris Hayes',
      role: 'Sr Software Engineer'
    },
    {
      name: 'Evan Seeds',
      role: 'Sr Software Engineer'
    },
    {
      name: 'Priyanka Madhushri',
      role: 'Sr Software Engineer'
    }
  ],
  Frontend: [
    {
      name: 'Al Tiangco',
      role: 'Full Stack Developer'
    },
    {
      name: 'Alex Padilla',
      role: 'Sr Software Engineer'
    }
  ],
  QA: [
    {
      name: 'Sandy Coutcher',
      role: 'QA Engineer'
    }
  ]
}

// ! Bad practice harcoding name of default company. Since we still dont have a way to know the default company. Will fail if this changes.
export const DefaultCompanyName = "Stanley Black & Decker - Internal"