/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../routes';
import { Text } from '../../atoms';
import {t} from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {CompanyAccount} from '../CompanyAccount/CompanyAccount';

export interface ILink {
  url: string
  label: string
}

const links: ILink[] = [
  {
    url: 'https://www.dewalt.com/legal/terms-of-use',
    label: t`Terms of Use`
  },
  {
    url: 'https://www.stanleyblackanddecker.com/privacy-policy',
    label: t`Privacy Policy`
  },
  {
    url: '',
    label: t`Help`
  }
]

interface CompanyAboutLeftbarProps {};

export const CompanyAboutLeftbar: React.FC<CompanyAboutLeftbarProps> = () => {
  const { i18n } = useLingui()
  const navigate = useNavigate()

  const handleLinkClick = (link: ILink) => {
    if (link.url) window.open(link.url, '_blank')
  }

  const handleCreditsClick = () => {
    navigate(Routes["credits_page"])
  }

  return (
    <div className='mt-[78px] flex flex-col gap-4  dark:text-white'>
      {links.map(item => (
        <div key={item.label}>
          <Text.Headline.H5
            className='cursor-pointer'
            onClick={() => handleLinkClick(item)}>
            {i18n._(item.label)}
          </Text.Headline.H5>
        </div>
      ))}
      <Text.Headline.H5
        className='cursor-pointer'
        onClick={handleCreditsClick}>
        {i18n._("Credits")}
      </Text.Headline.H5>
    </div>
  );
}

export default CompanyAboutLeftbar
