import { IRestQueries } from "../../hooks/types"

export const ADD_GARAGE_MOWER: IRestQueries = {
  method: 'POST',
  path: '/mower'
}

export const EDIT_GARAGE_MOWER: IRestQueries = {
  method: 'PUT',
  path: '' // The path was in the component itself, because the can be path is dynamic
}

export const ADD_GARAGE_BATTERY: IRestQueries = {
  method: 'POST',
  path: '/battery'
}

export const EDIT_GARAGE_BATTERY: IRestQueries = {
  method: 'PUT',
  path: '' // The path was in the component itself, because the can be path is dynamic
}

export const ADD_GARAGE_TOOL_TAG: IRestQueries = {
  method: 'POST',
  path: '/tool_tag'
}

export const EDIT_GARAGE_TOOL_TAG: IRestQueries = {
  method: 'PUT',
  path: '' // The path was in the component itself, because the can be path is dynamic
}