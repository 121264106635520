import React, { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'

import { Routes } from '../../../routes'
import { TextField, Button, Text } from '../../atoms'
import { AppContext } from '../../../App'
import { LoginCardContainer } from '../../molecules'
import { useDynamicMutation, useDynamicQuery } from '../../../hooks'
import { IUserAccount } from '../../../models'
import { useAppDispatch } from '../../../store/hooks'
import { addSignupFields } from '../../../store/slices/signupSlice'

interface LoginPanelProps { }

interface LoginFormInput {
  username: string
  password: string
}

const LoginPanel: React.FC<LoginPanelProps> = () => {
  const dispatch = useAppDispatch();
  const { handleLogin, handleLogout } = useContext(AppContext)
  const navigate = useNavigate()
  const { register, handleSubmit, watch } = useForm<LoginFormInput>()
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const formData = watch()

  const { mutateAsync: loginUser } = useDynamicMutation({
    graphqlEntity: 'users',
    queryKey: 'LOGIN_USER'
  })

  // Temporary since there isn't get user by id
  const { refetch: refetchUsers } = useDynamicQuery<IUserAccount[]>({
    graphqlEntity: 'users',
    queryKey: 'GET_ALL_USERS',
    mapperKey: 'user_entity_to_user',
    options: { enabled: false } // prevents query from being called immediately
  })

  const onSubmit: SubmitHandler<LoginFormInput> = async credentials => {
    try {
      if (!handleLogin) return
      setErrorMessage('')
      setIsLoading(true)

      const loginResponse = await handleLogin(credentials)

      const { success, code, data } = loginResponse ?? {}

      dispatch(addSignupFields({
        username: credentials.username,
        password: credentials.password,
        ...data,
      }))

      if (!success && code === 'NotAuthorizedException') {
        setIsLoading(false)
        // setErrorMessage('Incorrect username or password.')
        navigate(Routes['login_page.failed'], {
          state: {
            credentials
          }
        })
        return
      } else if (!success && code === 'NewPasswordChallenge') {
        setIsLoading(false)
        navigate(Routes['login_page.update-password'])
        return
      }

      if (success) {
        const {
          idToken: {
            payload: { sub: user_id }
          }
        } = data

        // Currently, there is no query for getting a user by id
        // loginUser query in the backend was updating the status by "Active" by default so I can't use that to guard

        const userAccounts = await (await refetchUsers()).data

        const user = (userAccounts as IUserAccount[]).find(
          item => item.id === user_id
        )

        // TODO: Temporary disable, since backend has issue on updateUser mutation
        if (user?.status === 'Invited') {
          await handleLogout?.()
          setIsLoading(false)
          navigate(Routes['login_page.complete-registration'])
        } else if (user?.status === 'Inactive') {
          await handleLogout?.()
          setIsLoading(false)
          setErrorMessage('User is deactivated.')
        } else {
          // Trigger userLogin to set the lastLogin
          await loginUser({ id: user_id });
          setIsLoading(false)
          navigate(Routes['tracking_page.mowers'])
        }
      }
    } catch (e) {
      console.error(e)
      setIsLoading(false)

      // @ts-ignore
      setErrorMessage(e?.error?.message || "Can't establish connection.")
    }
  }

  const isSubmitDisabled = useMemo(() => {
    const { username = '', password = '' } = formData
    return isLoading || !username.trim().length || !password.trim().length
  }, [formData, isLoading])

  const navigateForgotPassword = () => {
    navigate(Routes['login_page.forgot-password'])
  }

  // const navigateSignupPage = () => {
  //   navigate(Routes['login_page.signup'])
  // }

  return (
    <LoginCardContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col my-4'>
          {errorMessage && (
            <div className='bg-[#f8514926] border border-solid border-[#f8514966] rounded py-2 px-3 mb-3 text-center'>
              <Text.Body.Small>{errorMessage}</Text.Body.Small>
            </div>
          )}
          <TextField
            {...register('username', { required: true })}
            className='focus:outline-none focus:border-solid focus:border-white'
            placeholder='Username'
            disabled={isLoading}
          />
          <TextField
            {...register('password', { required: true })}
            className='focus:outline-none focus:border-solid focus:border-white'
            placeholder='Password'
            type={'password'}
            disabled={isLoading}
          />
        </div>

        <div className='flex gap-3'>
          <Button
            type='submit'
            title={!isLoading ? 'Sign In' : 'Signing in...'}
            className='min-w-[116px] h-[43px]'
            disabled={isSubmitDisabled}
          />
          {/* <Button
            color='secondary'
            title='Sign up'
            className='w-[116px] h-[43px] bg-transparent border border-solid border-white'
            onClick={navigateSignupPage}
          /> */}
        </div>
      </form>

      <div className='mt-4' onClick={navigateForgotPassword}>
        <Text.TextLink.MediumUnderlined>
          Forgot Password?
        </Text.TextLink.MediumUnderlined>
      </div>
    </LoginCardContainer>
  )
}

export default LoginPanel
