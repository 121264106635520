import { configureStore } from '@reduxjs/toolkit'
// GraphQL V2
import { deviceSliceRecuder as deviceV2SliceReducer } from './slices/deviceSliceV2'
import { trackingSliceReducer } from './slices/trackingSlice'

// GraphQL V1
import { deviceSliceRecuder } from './slices/deviceSlice'
import { userSliceRecuder } from './slices/userSlice'
import { signupSliceRecuder } from './slices/signupSlice'
import { companySliceReducer } from './slices/companySlice'
import { organizationSettingsSliceRecuder } from './slices/organizationSettingsSlice'
import { organizationCompanyReducer } from './slices/organizationCompanySlice'
import { backgroundTaskSliceReducer } from './slices/backgroundTaskSlice'
import { deviceGroupSliceReducer } from './slices/deviceGroupSlice'
import { trackingGroupsSliceReducer } from './slices/trackingGroupSlice'
import { operatingCompanyReducer } from './slices/operatingCompany'
import mapSlice from './slices/mapSlice'
import { reportsReducer } from './slices/reportsSlice';

export const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  reducer: {
    backgroundTask: backgroundTaskSliceReducer,
    deviceGroup: deviceGroupSliceReducer,
    tracking: trackingSliceReducer,
    trackingGroups: trackingGroupsSliceReducer,
    deviceV2: deviceV2SliceReducer,
    device: deviceSliceRecuder,
    user: userSliceRecuder,
    company: companySliceReducer,
    organizationSettings: organizationSettingsSliceRecuder,
    organizationCompany: organizationCompanyReducer,
    operatingCompany: operatingCompanyReducer,
    map: mapSlice,
    signup: signupSliceRecuder,
    reports: reportsReducer,
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
