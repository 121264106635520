import React, { useCallback } from 'react'
import { IToolTag } from '../../../../models'
import { TDeviceBatteryFaceLevel } from '../../../../models/device_battery'
import { Text } from '../../../atoms'
import { DeviceBatteryFaceLevel } from '../../../molecules'
import { IDevice } from '../../../../models_v2/entity/device'

interface BatteryLevelInformationProps {
  toolTag: Partial<IDevice>
}

const COIN_HEALTH_MAP = {
  '00': 'excellent',
  '01': 'good',
  '10': 'fair',
  '11': 'poor'
}

const BatteryLevelInformation: React.FC<BatteryLevelInformationProps> =
  props => {
    const { toolTag } = props
    const { payload } = toolTag?.latestTelemetry ?? {}
    const { coinHealth } = (payload ?? {}) as any

    const isActive = useCallback(
      (value: TDeviceBatteryFaceLevel) => {
        return (
          value === COIN_HEALTH_MAP[coinHealth as keyof typeof COIN_HEALTH_MAP]
        )
      },
      [coinHealth]
    )

    return (
      <div className='my-3'>
        <Text.Feature.Medium className='dark:text-white'>
          Battery Level
        </Text.Feature.Medium>

        <div className='mt-[20px] flex justify-between'>
          <DeviceBatteryFaceLevel isActive={isActive('poor')} value='poor' />
          <DeviceBatteryFaceLevel isActive={isActive('fair')} value='fair' />
          <DeviceBatteryFaceLevel isActive={isActive('good')} value='good' />
          <DeviceBatteryFaceLevel
            isActive={isActive('excellent')}
            value='excellent'
          />
        </div>
      </div>
    )
  }

export default BatteryLevelInformation
