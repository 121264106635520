import { Device } from '../device'
import { IDeviceFault } from '../entity'
import { IToolTag } from '../tool_tag'

const getDeviceByProductSerial = (
  devices: Device[],
  { productSerial }: Pick<IToolTag, 'productSerial'>
): Device | undefined => {
  const matchedDevice = devices.find(
    item => item.productSerial === productSerial
  )
  return matchedDevice
}

const getDeviceFaultSubsystem = (code: IDeviceFault['faultCode']) => {
  let codeAsNumber = Number(code.replace('E', ''))

  if (codeAsNumber >= 100 && codeAsNumber <= 199) {
    return 'VCM'
  } else if (codeAsNumber >= 200 && codeAsNumber <= 299) {
    return 'Left Deck Motor'
  } else if (codeAsNumber >= 300 && codeAsNumber <= 399) {
    return 'Right Deck Motor'
  } else if (codeAsNumber >= 400 && codeAsNumber <= 499) {
    return 'Center Deck Motor'
  } else if (codeAsNumber >= 500 && codeAsNumber <= 599) {
    return 'Left Drive Motor'
  } else if (codeAsNumber >= 600 && codeAsNumber <= 699) {
    return 'Right Drive Motor'
  } else if (codeAsNumber >= 700 && codeAsNumber <= 999) {
    return 'Not applicable for Ascent'
  } else if (codeAsNumber >= 1000 && codeAsNumber <= 1012) {
    return 'BPM'
  } else if (codeAsNumber >= 1100 && codeAsNumber <= 1163) {
    return 'BMS Battery A'
  } else if (codeAsNumber >= 1164 && codeAsNumber <= 1199) {
    return 'BPM BMS Battery A'
  } else if (codeAsNumber >= 1200 && codeAsNumber <= 1263) {
    return 'BMS Battery B'
  } else if (codeAsNumber >= 1264 && codeAsNumber <= 1299) {
    return 'BPM BMS Battery B'
  } else if (codeAsNumber >= 1300 && codeAsNumber <= 1363) {
    return 'BMS Battery C'
  } else if (codeAsNumber >= 1364 && codeAsNumber <= 1399) {
    return 'BPM BMS Battery C'
  } else if (codeAsNumber >= 1400 && codeAsNumber <= 1463) {
    return 'BMS Battery D'
  } else if (codeAsNumber >= 1464 && codeAsNumber <= 1499) {
    return 'BPM BMS Battery D'
  } else if (codeAsNumber >= 1500 && codeAsNumber <= 1563) {
    return 'BMS Battery E'
  } else if (codeAsNumber >= 1564) {
    return 'BPM BMS Battery E'
  }

  return ''
}

export { getDeviceByProductSerial, getDeviceFaultSubsystem }
