import { useCallback, useContext, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useDynamicQuery, useToolTagSearchV2 } from '../../../hooks'
import {
  selectAllSelectedAssets,
  toggleSelectedAsset
} from '../../../store/slices/organizationCompanySlice'
import GarageGlobalFleetDeviceList from '../GarageGlobalFleetDeviceList'
import { AppRootContext } from '../../../pages/AppRoot'
import { IToolTag } from '../../../models'
import { IDevice } from '../../../models_v2/entity/device'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import { selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors'

const TOOL_TAG_HIDDEN_COLUMNS: string[] = ['actions']

interface AssetAssignTooltagListProps {}

export const AssetAssignTooltagList: React.FC<AssetAssignTooltagListProps> =
  props => {
    const { state, dispatch } = useContext(AppRootContext)
    const appDispatch = useAppDispatch()
    const { searchFilter } = useAppSelector(state => state.organizationSettings)
    const selectedAssets = useAppSelector(selectAllSelectedAssets)
    const isSelectingAssets = useAppSelector(
      state => state.organizationCompany.is_selecting_assets
    )
    const operatingCompany = useAppSelector(selectOperatingCompany)
    const companyTooltags = useAppSelector(
      selectFilteredDevices<IDevice>({
        deviceType: 'tools-and-tags',
        operatingCompany,
        withoutCompanyAssigned: true
      })
    ) as IDevice[]

    const filteredTooltags = useToolTagSearchV2({
      items: companyTooltags,
      search: searchFilter
    }) as unknown as IDevice[]

    const {
      data: toolTagData,
      isFetched: toolTagIsFetched,
      isLoading: toolTagIsLoading
    } = useDynamicQuery({
      queryKey: 'GET_TRACKING_TOOL_TAGS',
      mapperKey: 'device_to_tool_tag'
    })

    useEffect(() => {
      if (!toolTagData || !toolTagIsFetched) return

      dispatch?.({
        type: 'SET_TOOL_TAGS',
        payload: toolTagData as IToolTag[]
      })
    }, [toolTagData, toolTagIsFetched])

    const onRowClassNameBuilder = useCallback(
      (row: unknown) => {
        const item = row as IToolTag
        if (!selectedAssets[item.productSerial!]) return ''
        return 'border-primary !text-primary'
      },
      [selectedAssets]
    )

    const handleRowClick = useCallback(
      (row: unknown) => {
        appDispatch(toggleSelectedAsset({ id: (row as IToolTag).productSerial! }))
      },
      [selectedAssets]
    )

    return (
      <GarageGlobalFleetDeviceList
        type='tool_tag'
        hiddenColumns={TOOL_TAG_HIDDEN_COLUMNS}
        isLoading={toolTagIsLoading}
        /* TODO: To be fixed when GraphQL v2 is finished, cannot be used since this component depends on GraphQL V1 */
        // items={searchFilter ? filteredToolTags : state?.tool_tags ?? []}
        items={filteredTooltags}
        tableProps={{
          onRowClick: !isSelectingAssets ? undefined : handleRowClick,
          rowClassName: onRowClassNameBuilder
        }}
      />
    )
  }
