import { ITelemetryPayload } from './telemetry_payload'

export enum DEVICE_TYPE {
  ASCENT_MOWER = 11,
  SUREPATH_MOWER = 50,
  BATTERY = 500,
  TOOLS_AND_TAGS_GENERIC = 400,
  TOOLS_AND_TAGS_DCE041 = 401,
  TOOLS_AND_TAGS_DCE042 = 402,
  TOOLS_AND_TAGS_DCE045 = 403,
  TOOLS_AND_TAGS_RESERVED_1 = 404,
  TOOLS_AND_TAGS_RESERVED_2 = 405,
  TOOLS_AND_TAGS_RESERVED_3 = 405,
  TOOLS_AND_TAGS_RESERVED_4 = 407,
  TOOLS_AND_TAGS_RESERVED_5 = 408,
  TOOLS_AND_TAGS_RESERVED_6 = 409,
  TOOLS_AND_TAGS_DCPS310 = 410,
  TOOLS_AND_TAGS_DCPS320 = 411,
  TOOLS_AND_TAGS_DCPS330 = 412,
  TOOLS_AND_TAGS_DCPS660 = 413,
  TOOLS_AND_TAGS_DCPS7154 = 414,
  TOOLS_AND_TAGS_DCG418HG = 415
}

export interface ITelemetry {
  /**
   * Device type (example values)
   * 11 = ?? (TODO)
   */
  type: DEVICE_TYPE | string
  /**
   * TODO: No example data from GraphQL
   */
  location: string
  /**
   * This is a JSON stringified value
   */
  payload: string | ITelemetryPayload
  /**
   * Last Update/Refresh (ISO String (ex. "2023-08-11T16:10:39.763Z"))
   */
  timestamp: string
  /**
   * Mower Status (example values)
   * -1 = Unknown
   * 0 = Off
   * 1 = Idle
   * 2 = Driving
   * 3 = Mowing
   * 4 = Charging
   */
  status: string
}
