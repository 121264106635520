import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getSession } from '../integrations/aws-cognito/services'
import { Routes } from '../routes'

interface IUseAuthCheckOptions {
  autoListen?: boolean
  listeningInterval?: number
  /**
   * If session is valid, redirect to the given path.
   */
  redirectTo?: string
  shouldRedirect?: boolean
}

export const useAuthCheck = (options?: IUseAuthCheckOptions) => {
  const {
    shouldRedirect = true,
    autoListen = false,
    listeningInterval = 10000
  } = options ?? {}
  const navigate = useNavigate()

  const checkSession = async () => {
    if (!shouldRedirect) return

    try {
      const session = await getSession()

      // TODO: Revisit this `session.isValid`
      // if (!(session && session?.isValid?.())) navigate(Routes.login_page)
      if (!session) navigate(Routes.login_page)
      else {
        // Identifier for when the user last visit
        // sessionStorage.setItem('last_visit', new Date().toISOString())
        if (options?.redirectTo) navigate(options.redirectTo)
      }
    } catch (e) {
      navigate(Routes.login_page)
    }
  }

  useEffect(() => {
    checkSession()

    if (autoListen) {
      const intervalId = setInterval(checkSession, listeningInterval)
      return () => clearInterval(intervalId)
    }
  }, [shouldRedirect, autoListen, listeningInterval])

  return null
}
