import moment, { utc } from 'moment-timezone'
import { TimelineTimeSteps } from 'react-calendar-timeline'
import { Range } from '../../../models'
import { EDeviceMowerStatus, IDeviceTimelineItem } from '../../../models/device'
import { SurepathPerformanceTimeInterval } from '../SurepathPerformanceCard'
import { SurepathPerformanceTimelineItem } from '../SurepathPerformanceTimeline'
import { IDevice } from '../../../models_v2/entity/device'

export enum ETimelineGroup {
  WHITESPACE = 0,
  JOB_SESSION = 1,
  MANUAL_DRIVE = 2,
  SUREPATH_ENABLED = 3,
  MOWING = 4,
  IDLE = 5
}

export const TIMELINE_TIME_STEPS: TimelineTimeSteps = {
  day: 1,
  hour: 1,
  minute: 30,
  month: 1,
  second: 1,
  year: 1
}

export const TIMELINE_TIME_INTERVALS: SurepathPerformanceTimeInterval[] = [
  6, 12, 24
]

const TIMELINE_GRAPH_RANGE_6: [string, string][] = [
  ['00:00', '06:00'],
  ['06:00', '12:00'],
  ['12:00', '18:00'],
  ['18:00', '24:00']
]

const TIMELINE_GRAPH_RANGE_12: [string, string][] = [
  ['00:00', '12:00'],
  ['12:00', '24:00']
]

const TIMELINE_GRAPH_RANGE_24: [string, string][] = [['00:00', '24:00']]

export const evaluateTimelineGraphRange = (
  date: Date,
  timeInterval: SurepathPerformanceTimeInterval
): Range<number> => {
  const time = moment(date).format('HH:mm')

  let timelineGraphRange = TIMELINE_GRAPH_RANGE_6

  if (timeInterval === 12) {
    timelineGraphRange = TIMELINE_GRAPH_RANGE_12
  } else if (timeInterval === 24) {
    timelineGraphRange = TIMELINE_GRAPH_RANGE_24
  }

  for (let i = 0; i <= timelineGraphRange.length; i++) {
    const [start, end] = timelineGraphRange?.[i] ?? []
    if (start <= time && time < end) {
      const [rangeStart, rangeEnd] = timelineGraphRange[i]
      const [startHour, startMinute] = rangeStart.split(':')
      const [endHour, endMinute] = rangeEnd.split(':')

      const startDate = moment(date)
        .set('minute', Number(startMinute))
        .set('second', 0)
      const endDate = moment(date)
        .set('minute', Number(endMinute))
        .set('second', 0)

      return {
        start: startDate.set('hour', Number(startHour)).valueOf(),
        end: endDate.set('hour', Number(endHour)).valueOf()
      }
    }
  }

  return {
    start: 0,
    end: 0
  }
}

export const groupTimelineItems = (
  data: IDevice['telemetries']
): SurepathPerformanceTimelineItem[] => {
  // const result = data?.reduce(
  //   (acc, curr) => {
  //     const collection = acc.collection
  //     const items = acc.items

  //     if (!collection[curr.mowerStatus]) {
  //       collection[curr.mowerStatus] = curr
  //     } else {
  //       const prevItem = collection[curr.mowerStatus]
  //       const mowerStatus = Number(curr.mowerStatus)
  //       let group = ETimelineGroup.JOB_SESSION

  //       if (mowerStatus === EDeviceMowerStatus.MowingSurePathEnabled) {
  //         group = ETimelineGroup.SUREPATH_ENABLED
  //       }

  //       items.push({
  //         id: Math.random().toString(),
  //         // TODO: Which TimelineGroup does this mowerStatus refer to?
  //         group,
  //         start_time: new Date(prevItem.time).getTime(),
  //         end_time: new Date(curr.time).getTime()
  //       })

  //       collection[curr.mowerStatus] = undefined
  //     }

  //     return {
  //       items,
  //       collection
  //     }
  //   },
  //   {
  //     items: [],
  //     collection: {}
  //   } as {
  //     items: SurepathPerformanceTimelineItem[]
  //     collection: Record<string, any>
  //   }
  // )

  // return result?.items ?? []

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const dataAsAscending = (data ?? []).reduceRight(
    (acc: IDeviceTimelineItem[], curr) => {
      return [
        ...acc,
        {
          ...curr,
          // time: utc(curr.time).toDate()
          time: utc(curr.timestamp).tz(timeZone),
          mowerStatus: curr?.status
        }
      ] as IDeviceTimelineItem[]
    },
    []
  )

  const result = dataAsAscending?.reduce(
    (acc: SurepathPerformanceTimelineItem[], curr, index) => {
      const prevItem: SurepathPerformanceTimelineItem | null =
        acc[index - 1] ?? null
      const nextDeviceItem: IDeviceTimelineItem | null =
        dataAsAscending[index + 1] ?? null

      if (!nextDeviceItem) return acc

      const mowerStatus = Number(curr.mowerStatus)
      let group: ETimelineGroup | null = null

      if (mowerStatus === EDeviceMowerStatus.Idle) {
        group = ETimelineGroup.IDLE
      } else if (mowerStatus === EDeviceMowerStatus.Driving) {
        group = ETimelineGroup.MANUAL_DRIVE
      } else if (
        mowerStatus === EDeviceMowerStatus.Mowing ||
        mowerStatus === EDeviceMowerStatus.MowingSurePathReady
      ) {
        group = ETimelineGroup.MOWING
      } else if (mowerStatus === EDeviceMowerStatus.MowingSurePathEnabled) {
        group = ETimelineGroup.SUREPATH_ENABLED
      }

      if (group === null) return acc

      return [
        ...acc,
        {
          id: Math.random().toString(),
          group,
          // start_time: new Date(
          //   prevItem ? prevItem.end_time : curr.time
          // ).getTime(),
          // end_time: new Date(nextDeviceItem?.time).getTime()
          start_time: moment
            .tz(prevItem ? prevItem.end_time : curr.time, timeZone)
            .valueOf(),
          end_time: moment.tz(nextDeviceItem?.time, timeZone).valueOf()
        }
      ]
    },
    []
  )

  // Pair all items with job session
  // "Mowing should depict all types of mowing including Manual Mowing and Surepath"
  const modifiedResults = result.reduce(
    (acc: SurepathPerformanceTimelineItem[], curr) => {
      // ? As intructed to not include JOB_SESSION for now.
      // const job_session: SurepathPerformanceTimelineItem = {
      //   ...curr,
      //   id: `job_session:${curr.id}`,
      //   group: ETimelineGroup.JOB_SESSION
      // }

      if (curr.group === ETimelineGroup.SUREPATH_ENABLED) {
        return [
          ...acc,
          // job_session,
          {
            ...curr,
            id: `surepath_enabled:${curr.id}`,
            group: ETimelineGroup.MOWING
          },
          curr
        ]
      }

      return [
        ...acc,
        // job_session,
        curr
      ]
    },
    []
  )

  return modifiedResults ?? []
}
