/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { NavigationBarItem } from '../SubNavigationBar/types';
import SubNavigationBar from '../SubNavigationBar';
import { Routes } from '../../../routes';

interface CompanySubNavigationBar {}

export const CompanySubNavigationBar: React.FC<CompanySubNavigationBar> = () => {
  const navigate = useNavigate()
  const matchPage = useMatch(Routes['new_settings.wildcard'])
  const navigationItems: NavigationBarItem[] = useMemo(() => {
    return [
      {
        id: 'company_account',
        label: 'Account'
      },
      {
        id: 'organization',
        label: 'Organization'
      },
      {
        id: 'about',
        label: 'About'
      },
    ]
  }, [])

  const selectedIndex = useMemo(() => {
    return navigationItems.findIndex(
      item => item.id === matchPage?.params['*']?.split('/')?.[0]
    )
  }, [matchPage?.params, navigationItems])

  const handleChange = useCallback((item: NavigationBarItem, index) => {
    navigate(Routes[item.id as keyof typeof Routes])
  }, [])

  
  return (
    <SubNavigationBar
      items={navigationItems}
      selectedIndex={selectedIndex}
      onChange={handleChange}
      showActiveBorder
    />  
  );
}

export default CompanySubNavigationBar;