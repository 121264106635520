import React, { useMemo } from 'react'
import cn from 'classnames'
import { Icon, Text, ProgressBar, Image } from '../../atoms'
import { BatteryCommon } from '../../molecules'
import { ASSETS } from '../../../assets'
import { useAppTheme } from '../../../hooks'
import { useListItemClickEvents } from '../../../hooks/data/useListItemClickEvents'
import { BATTERY_STATE } from '../../../models_v2/entity/telemetry_payload'
import { getBatteryStateOfCharge } from '../../../utils/battery'
import NetworkStatusIndicator from '../../organisms/BatteryDetail/NetworkStatusIndicator'
import { isBatteryOnline } from '../../../utils/battery'
import { IDevice } from '../../../models_v2/entity/device'
import { ITelemetryPayload } from '../../../models_v2/entity/telemetry_payload'
import { BATTERY_STATUS_MAPPING } from '../../../models_v2/common/battery'
import { hasLocation } from '../../../utils/device'

interface BatteryListItemProps {
  // Return type from graphql is wrong compared to the type of IBattery
  battery: IDevice
  isActive?: boolean
}

const BatteryListItem: React.FC<BatteryListItemProps> = props => {
  const { battery, isActive = false } = props
  const { inventory, latestTelemetry } = battery ?? {}
  const { onMapPinClick, onListItemClick } = useListItemClickEvents({
    deviceType: 'battery',
    device: battery
  })
  const { name } = inventory ?? {}
  const { CurrentState } = (latestTelemetry?.payload ?? {}) as ITelemetryPayload

  const { theme } = useAppTheme()

  const batteryStatus = useMemo(
    () =>
      BATTERY_STATUS_MAPPING[
        CurrentState as keyof typeof BATTERY_STATUS_MAPPING
      ] || 'Unknown',
    [CurrentState]
  )

  const isBatteryCharging = useMemo(
    () => CurrentState === BATTERY_STATE.CHARGING,
    [battery]
  )

  const progressBarColor = useMemo(() => {
    if (CurrentState === BATTERY_STATE.CHARGING) return '#2CC947'

    return theme === 'light' ? '#0082FC' : undefined
  }, [theme, battery])

  // TODO: This will be dynamic later base the battery status (Onboard/Available)
  const onboardOrAvailableIndicator = useMemo(() => {
    // TODO: As instructed, assume all batteries are onboarded for now.
    const value = 'onboard'
    const asset =
      value === 'onboard'
        ? ASSETS.IMAGES.BATTERY.onboard
        : ASSETS.IMAGES.BATTERY.available

    return <Image src={asset} className='h-[20px] w-[17px]' />
  }, [])

  return (
    <div
      className={cn(
        'text-black dark:text-white p-3 card cursor-pointer overflow-hidden',
        {
          'outline outline-4 outline-offset-[-4px] outline-interaction-200':
            isActive
        }
      )}
      onClick={onListItemClick}>
      <div className='flex justify-between items-center gap-2'>
        <div className='flex gap-2 items-center max-w-[256px]'>
          {onboardOrAvailableIndicator}
          <div
            className={cn('flex items-center gap-2 cursor-pointer')}
            onClick={e => {
              if (hasLocation(battery as IDevice)) {
                onMapPinClick()
              }

              e.stopPropagation()
            }}>
            <Text.Feature.Medium>{name}</Text.Feature.Medium>
          </div>
          {/* TODO: Condition: show only if it is a subscription battery */}
          {/* <BatteryCommon.PowerEquipButton item={battery} /> */}
        </div>
        <NetworkStatusIndicator isOnline={isBatteryOnline(battery)} />
      </div>
      <div className='flex flex-row gap-4'>
        <div className='h-[102px] flex items-end'>
          <Image
            src={ASSETS.IMAGES.BATTERY.battery}
            className='h-[82px] w-[86px]'
          />
        </div>

        <div className='ml-[18px] my-[15px] flex flex-1 flex-col gap-2'>
          <div className='flex flex-col mt-2'>
            <BatteryCommon.LastSeen value={latestTelemetry?.timestamp} />
            <BatteryCommon.Status value={batteryStatus} />
          </div>

          <div className='flex flex-col gap-1'>
            <div className='flex justify-between'>
              <div className='flex items-center gap-1'>
                {isBatteryCharging && (
                  <Icon
                    name='bolt_vector'
                    className='h-[12px] w-2 mr-1 invert dark:invert-0'
                  />
                )}
                <Text.VariousRegular.Large>
                  {getBatteryStateOfCharge(battery)}%
                </Text.VariousRegular.Large>
              </div>
            </div>

            <ProgressBar
              progressBarColor={progressBarColor}
              value={getBatteryStateOfCharge(battery) / 100}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BatteryListItem
