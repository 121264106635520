import React from 'react'
import cn from 'classnames'
import { TabItem } from './types'
import { Text } from '..'
import { useLingui } from '@lingui/react'

export interface TabListItemProps
  extends TabItem,
    Pick<
      React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      >,
      'onClick'
    > {
  isActive?: boolean
}

const TabListItem: React.FC<TabListItemProps> = props => {
  const { i18n } = useLingui()
  const { label, isActive = false, onClick } = props

  return (
    <div
      className={cn('flex-1 flex justify-center items-center cursor-pointer', {
        'border-solid border-b-[3px] dark:border-white': isActive
      })}
      onClick={onClick}>
      <Text.VariousRegular.Small className='text-center'>
        {i18n._(label)}
      </Text.VariousRegular.Small>
    </div>
  )
}

export default TabListItem
