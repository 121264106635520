import AppBackground from './images/app-background.png'
import AppLogo from './images/app-logo.svg'
import BrandLogo from './images/brand-logo.svg'
import SbdLogo from './images/sbd-logo.png'

import StatusCharging from './images/common/status_charging.png'
import StatusError from './images/common/status_error.png'
import StatusOffline from './images/common/status_offline.png'
import StatusOnline from './images/common/status_online.png'
import StatusDanger from './images/common/status_danger.png'
import StatusDangerHd from './images/common/status_danger_hd.png'
import StatusDefaultHd from './images/common/status_default_hd.png'
import StatusWarningHd from './images/common/status_warning_hd.png'
import StatusDtc from './images/common/status_dtc.png'

// Battery
import Battery from './images/battery/battery.png'
import Subscribed from './images/battery/subscribed.png'
import Onboard from './images/battery/onboard.png'
import Available from './images/battery/available.png'

// Tool Tag
import ToolTag from './images/tool_tag/tool_tag.png'

// Mower
import MowerFrontLeftLargeSitting from './images/mower/mower_front_left_large_sitting.png'
import MowerFrontLeftLargeStanding from './images/mower/mower_front_left_large_standing.png'
import MowerFrontLeftLarge from './images/mower/mower_front_left_large.png'
import MowerFrontLeft from './images/mower/mower_front_left.png'
import MowerFrontRightLarge from './images/mower/mower_front_right_large.png'
import MowerFrontRight from './images/mower/mower_front_right.png'
import MowerFront from './images/mower/mower_front.png'
import MowerRearRight from './images/mower/mower_rear_right.png'
import SurepathMowerFront from './images/mower/surepath_mower_front.png'
import SurepathMowerLogo from './images/mower/surepath_mower_logo.png'
import SurepathTextLogo from './images/mower/surepath_text_logo.png'

// Common
import DialLarge from './images/common/dial_large.png'
import DialLarge2 from './images/common/dial_large_2.png'
import BorderBoxImage from './images/common/border_box.png'
import UserProfile from './images/common/user_profile.png'
import Target from './images/common/target.png'
import Danger from './images/common/danger.png'

// Icons
import AddCircle from './icons/add_circle.svg'
import ArrowLeft from './icons/arrow_left.svg'
import ArrowRightAlt from './icons/arrow_right_alt.svg'
import BatteryCell0 from './icons/battery_cell_0.svg'
import BatteryCell20 from './icons/battery_cell_20.svg'
import BatteryCell40 from './icons/battery_cell_40.svg'
import BatteryCell60 from './icons/battery_cell_60.svg'
import BatteryCell80 from './icons/battery_cell_80.svg'
import BatteryCell100 from './icons/battery_cell_100.svg'
import BatteryCell0Light from './icons/battery_cell_0_light.svg'
import BatteryCell20Light from './icons/battery_cell_20_light.svg'
import BatteryCell40Light from './icons/battery_cell_40_light.svg'
import BatteryCell60Light from './icons/battery_cell_60_light.svg'
import BatteryCell80Light from './icons/battery_cell_80_light.svg'
import BatteryCell100Light from './icons/battery_cell_100_light.svg'
import BatteryErrorError from './icons/battery_error_error.svg'
import BatteryErrorNoBattery from './icons/battery_error_no_battery.svg'
import BatteryErrorTemperature from './icons/battery_error_temperature.svg'
import BatteryGreen0Light from './icons/battery_green_0_light.svg'
import BatteryGreen20Light from './icons/battery_green_20_light.svg'
import BatteryGreen40Light from './icons/battery_green_40_light.svg'
import BatteryGreen60Light from './icons/battery_green_60_light.svg'
import BatteryGreen80Light from './icons/battery_green_80_light.svg'
import BatteryGreen100Light from './icons/battery_green_100_light.svg'
import BatteryGreen0 from './icons/battery_green_0.svg'
import BatteryGreen20 from './icons/battery_green_20.svg'
import BatteryGreen40 from './icons/battery_green_40.svg'
import BatteryGreen60 from './icons/battery_green_60.svg'
import BatteryGreen80 from './icons/battery_green_80.svg'
import BatteryGreen100 from './icons/battery_green_100.svg'
import BatteryWhite0 from './icons/battery_white_0.svg'
import BatteryWhite20 from './icons/battery_white_20.svg'
import BatteryWhite40 from './icons/battery_white_40.svg'
import BatteryWhite60 from './icons/battery_white_60.svg'
import BatteryWhite80 from './icons/battery_white_80.svg'
import BatteryWhite100 from './icons/battery_white_100.svg'
import BoltVector from './icons/bolt_vector.svg'
import BorderBox from './icons/border_box.svg'
import CaretLeft from './icons/caret_left.svg'
import CaretDown from './icons/caret_down.svg'
import CaretRight from './icons/caret_right.svg'
import CellCignal0 from './icons/cell_signal_0.svg'
import CellCignal1 from './icons/cell_signal_1.svg'
import CellCignal2 from './icons/cell_signal_2.svg'
import CellCignal3 from './icons/cell_signal_3.svg'
import CellCignal4 from './icons/cell_signal_4.svg'
import ChevronLeft from './icons/chevron_left.svg'
import ChevronRight from './icons/chevron_right.svg'
import CloseCircle from './icons/close_circle.svg'
import EditPencil from './icons/edit_pencil.svg'
import FaceExcellent from './icons/face_excellent.svg'
import FaceFair from './icons/face_fair.svg'
import FaceGood from './icons/face_good.svg'
import FacePoor from './icons/face_poor.svg'
import Home from './icons/home.svg'
import NavigationDown from './icons/navigation_down.svg'
import Padlock from './icons/padlock.svg'
import PowerEquip from './icons/power_equip.svg'
import Profile from './icons/profile.svg'
import Settings from './icons/settings.svg'
import VisibilityOff from './icons/visibility_off.svg'
import Visibility from './icons/visibility.svg'

// Maps
import MAP_PIN from './images/maps/map-pin.png'
import MAP_PIN_SELECTED from './images/maps/map-pin-selected.png'
import MOWER_FAULT_MAP_PIN from './images/maps/mower_fault_map_pin.svg'

export const ASSETS = {
  IMAGES: {
    APP: {
      background: AppBackground,
      app_logo: AppLogo,
      brand_logo: BrandLogo,
      sbd_logo: SbdLogo
    },
    COMMON: {
      border_box: BorderBoxImage,
      dial_large: DialLarge,
      dial_large_2: DialLarge2,
      user_profile: UserProfile,
      target: Target,
      danger: Danger
    },
    STATUS: {
      charging: StatusCharging,
      error: StatusError,
      offline: StatusOffline,
      online: StatusOnline,
      danger: StatusDanger,
      danger_hd: StatusDangerHd,
      warning_hd: StatusWarningHd,
      default_hd: StatusDefaultHd,
      dtc: StatusDtc
    },
    BATTERY: {
      battery: Battery,
      subscribed: Subscribed,
      onboard: Onboard,
      available: Available
    },
    MOWER: {
      mower_front_left_large_sitting: MowerFrontLeftLargeSitting,
      mower_front_left_large_standing: MowerFrontLeftLargeStanding,
      mower_front_left_large: MowerFrontLeftLarge,
      mower_front_left: MowerFrontLeft,
      mower_front_right_large: MowerFrontRightLarge,
      mower_front_right: MowerFrontRight,
      mower_front: MowerFront,
      mower_rear_right: MowerRearRight,
      surepath_mower_front: SurepathMowerFront,
      surepath_mower_logo: SurepathMowerLogo,
      surepath_text_logo: SurepathTextLogo
    },
    TOOL_TAG: {
      tool_tag: ToolTag
    }
  },
  ICONS: {
    add_circle: AddCircle,
    arrow_left: ArrowLeft,
    arrow_right_alt: ArrowRightAlt,
    battery_cell_0: BatteryCell0,
    battery_cell_20: BatteryCell20,
    battery_cell_40: BatteryCell40,
    battery_cell_60: BatteryCell60,
    battery_cell_80: BatteryCell80,
    battery_cell_0_light: BatteryCell0Light,
    battery_cell_20_light: BatteryCell20Light,
    battery_cell_40_light: BatteryCell40Light,
    battery_cell_60_light: BatteryCell60Light,
    battery_cell_80_light: BatteryCell80Light,
    battery_cell_100_light: BatteryCell100Light,
    battery_cell_100: BatteryCell100,
    battery_green_0_light: BatteryGreen0Light,
    battery_green_20_light: BatteryGreen20Light,
    battery_green_40_light: BatteryGreen40Light,
    battery_green_60_light: BatteryGreen60Light,
    battery_green_80_light: BatteryGreen80Light,
    battery_green_100_light: BatteryGreen100Light,
    battery_green_0: BatteryGreen0,
    battery_green_20: BatteryGreen20,
    battery_green_40: BatteryGreen40,
    battery_green_60: BatteryGreen60,
    battery_green_80: BatteryGreen80,
    battery_green_100: BatteryGreen100,
    battery_white_0: BatteryWhite0,
    battery_white_20: BatteryWhite20,
    battery_white_40: BatteryWhite40,
    battery_white_60: BatteryWhite60,
    battery_white_80: BatteryWhite80,
    battery_white_100: BatteryWhite100,
    battery_error_error: BatteryErrorError,
    battery_error_no_battery: BatteryErrorNoBattery,
    battery_error_temperature: BatteryErrorTemperature,
    bolt_vector: BoltVector,
    border_box: BorderBox,
    caret_left: CaretLeft,
    caret_down: CaretDown,
    caret_right: CaretRight,
    cell_cignal_0: CellCignal0,
    cell_cignal_1: CellCignal1,
    cell_cignal_2: CellCignal2,
    cell_cignal_3: CellCignal3,
    cell_cignal_4: CellCignal4,
    chevron_left: ChevronLeft,
    chevron_right: ChevronRight,
    close_circle: CloseCircle,
    edit_pencil: EditPencil,
    face_excellent: FaceExcellent,
    face_fair: FaceFair,
    face_good: FaceGood,
    face_poor: FacePoor,
    home: Home,
    navigation_down: NavigationDown,
    padlock: Padlock,
    power_equip: PowerEquip,
    profile: Profile,
    settings: Settings,
    visibility_off: VisibilityOff,
    visibility: Visibility
  },
  MAPS: {
    map_pin: MAP_PIN,
    map_pin_selected: MAP_PIN_SELECTED,
    mower_fault_map_pin: MOWER_FAULT_MAP_PIN
  }
}
