import { IToolTag } from '../models'
import { matchSorter } from 'match-sorter'

export const useToolTagSearch = ({
  items,
  search
}: {
  items: IToolTag[]
  search: string
}): IToolTag[] => {
  if (!search) return []

  const results = matchSorter(items ?? [], search.trim(), {
    keys: ['name', 'productSerial'],
    threshold: matchSorter.rankings.CONTAINS
  })

  return results
}
