import React from 'react'
import { Text } from '../..'
import { t } from '@lingui/macro'

interface EmptyStateTableBodyProps {}

export const EmptyStateTableBody: React.FC<EmptyStateTableBodyProps> = () => {
  return (
    <div className='p-4 dark:text-white text-center'>
      <Text.Body.LargeSemiBold>{t`No data available`}</Text.Body.LargeSemiBold>
    </div>
  )
}
