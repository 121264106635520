import React from 'react'
import { Text } from '../../atoms'
import styled from 'styled-components'

export const activityTimelineColors = {
  jobSession: "#d09500",
  driving: "#ffffff",
  enabled: "#0587ff",
  mowing: "#15d492",
  idle: "#ff008a",
  charging: "#0082fc",
}

export interface ActivityTimelineLegendProps {
  type: 'job_session' | 'manual_drive' | 'surepath_enabled' | 'mowing' | 'idle' | 'charging'
  /**
   * Value must be milliseconds
   */
  value?: number
}

const LEGEND_LABEL: Record<ActivityTimelineLegendProps['type'], string> = {
  job_session: 'Job Session',
  manual_drive: 'Driving',
  surepath_enabled: 'Surepath Enabled',
  mowing: 'Mowing',
  idle: 'Idle',
  charging: 'Charging'
}

const ActivityTimelineLegend = (props: ActivityTimelineLegendProps) => {
  const { type } = props

  let Pill = null

  if (type === 'job_session') {
    Pill = <StyledJobSessionPill />
  } else if (type === 'manual_drive') {
    Pill = <StyledManualDrivePill />
  } else if (type === 'surepath_enabled') {
    Pill = <StyledSurepathEnabledPill />
  } else if (type === 'mowing') {
    Pill = <StyledMowingPill />
  } else if (type === 'idle') {
    Pill = <StyledIdlePill />
  } else if (type === 'charging') {
    Pill = <StyledChargingPill />
  }
  

  return (
    <div className='flex items-center gap-2 dark:text-white'>
      {Pill}
      <Text.Body.Small className='mr-1'>{LEGEND_LABEL[type]}</Text.Body.Small>
    </div>
  )
}

export default ActivityTimelineLegend

export const StyledJobSessionPill = styled.div`
  width: 18px;
  height: 7px;
  background: linear-gradient(180deg, ${activityTimelineColors.jobSession} 0%, #ffb800 100%);
  border-radius: 4px;
`

export const StyledManualDrivePill = styled.div`
  width: 18px;
  height: 7px;
  background: linear-gradient(
    180deg,
    ${activityTimelineColors.driving} 0%,
    #ffffff 0.01%,
    rgba(163, 163, 163, 0.85) 100%
  );
   border-radius: 4px;
`

export const StyledSurepathEnabledPill = styled.div`
  width: 18px;
  height: 7px;
  background: linear-gradient(180deg, ${activityTimelineColors.enabled} -15.63%, #0e6abf 84.37%);
  border-radius: 4px;
`

export const StyledMowingPill = styled.div`
  width: 18px;
  height: 7px;
  background: linear-gradient(180deg, ${activityTimelineColors.mowing} 100%, #11d38f 100%);
  border-radius: 4px;
`

export const StyledIdlePill = styled.div`
  width: 18px;
  height: 7px;
  background: linear-gradient(180deg, ${activityTimelineColors.idle} 100%, #cc488f 100%);
  border-radius: 4px;
`

export const StyledChargingPill = styled.div`
  width: 18px;
  height: 7px;
  background: linear-gradient(180deg, ${activityTimelineColors.charging} 100%, #11d38f 100%);
  border-radius: 4px;
`