import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Column } from 'react-table'
import moment from 'moment'
import cn from 'classnames'
import { StatusIndicator, Table, Text, Image } from '../../atoms'
import { TStatus } from '../../atoms/StatusIndicator/types'
import { useLingui } from '@lingui/react'
import { getDeviceFaultSubsystem } from '../../../models/utils'
import { ASSETS } from '../../../assets'
import { AppRootContext } from '../../../pages/AppRoot'
import { useSearchParams } from 'react-router-dom'
import { TMapEvents } from '../../../pages/AppRoot/types'
import { IMetadata } from '../../../models_v2/entity/metadata'
import { IDevice } from '../../../models_v2/entity/device'
import { getAscentMowerHoursFromFault } from '../../../utils/mower'

interface FaultTableProps {
  mower: IDevice
  faults?: IMetadata[]
  onFaultsChange?: (updatedFault: IMetadata, allFaults: IMetadata[]) => void
}

const FaultTable: React.FC<FaultTableProps> = props => {
  const { mower, faults: propFaults = [], onFaultsChange } = props
  const { emitter } = useContext(AppRootContext)
  const { i18n } = useLingui()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedFaultId, setSelectedFaultId] = useState<string>()
  const [faults, setCurrentFaults] = useState(propFaults)

  const isDeviceFaultClickable = useCallback((fault: IMetadata) => {
    const { payload } = fault
    const { location } = payload
    const { latitude, longitude } = location ?? {}

    if (!location || !latitude || !longitude) return false

    return true
  }, [])

  const updateFaultState = useCallback(
    async (fault: IMetadata) => {
      const clonedFaults = [...faults]
      const matchedFaultIndex = clonedFaults.findIndex(
        (item: IMetadata) => item.metadataId === fault.metadataId
      )

      if (matchedFaultIndex === -1) return

      const matchedFault = { ...clonedFaults[matchedFaultIndex] }

      if (fault.state === 'New') {
        matchedFault.state = 'Acknowledged'
      } else if (fault.state === 'Acknowledged') {
        matchedFault.state = 'Fixed'
      } else if (fault.state === 'Fixed') {
        matchedFault.state = 'New'
      }

      clonedFaults[matchedFaultIndex] = matchedFault
      setCurrentFaults(clonedFaults)

      onFaultsChange?.(matchedFault, clonedFaults)
    },
    [faults]
  )

  const handleFaultTableRowClick = useCallback(
    (data: IMetadata) => {
      if (selectedFaultId === data.metadataId) {
        setSelectedFaultId(undefined)
        emitter?.emit('MAP:tracking', {
          type: 'DEVICE_FAULT_UNFOCUS',
          payload: {
            mower_id: mower?.productSerial || ''
          }
        })
        return
      }

      if (!isDeviceFaultClickable(data)) return

      setSelectedFaultId(data.metadataId)

      emitter?.emit('MAP:tracking', {
        type: 'FIT_BOUNDS_SELECTED',
        payload: [
          {
            coordinates: {
              lat: Number(data.payload.location?.latitude),
              lng: Number(data.payload.location?.longitude)
            }
          }
        ]
      })
    },
    [mower?.productSerial, selectedFaultId]
  )

  const RenderStatusCell = useCallback(
    (item: IMetadata) => {
      const { state } = item
      let status: TStatus = 'offline'
      if (state === 'Acknowledged') status = 'warning_hd'
      else if (state === 'Fixed') status = 'default_hd'
      else if (state === 'New') status = 'danger_hd'

      return (
        <div
          className='flex items-center gap-2 cursor-pointer'
          onClick={e => {
            e.stopPropagation()
            updateFaultState(item)
          }}>
          <StatusIndicator
            containerClassName='ml-1'
            showLabel={false}
            value={status}
            hasWrapperInImage={false}
            imageOnClick={() => updateFaultState(item)}
          />
          {state === 'New' && <Text.Body.Small>New</Text.Body.Small>}
        </div>
      )
    },
    [faults]
  )

  const RenderTimestampCell = useCallback(value => {
    return <span>{moment(value).format('MM/DD/YY hh:mm A')}</span>
  }, [])

  const RenderSubsystemCell = useCallback(
    (item: IMetadata) => {
      const { faultCode } = item
      return getDeviceFaultSubsystem(faultCode)
    },
    [i18n.locale]
  )

  const RenderDescriptionCell = useCallback(
    (item: IMetadata) => {
      const { faultCode } = item
      let resolvedCode = faultCode

      // E.g Code with no prefix of E
      if (faultCode.length === 3) {
        resolvedCode = `E${faultCode}`
      }

      // Check if it exists from the lookup collection
      if (!Object.hasOwn(i18n.messages, resolvedCode)) return null
      return i18n._(resolvedCode)
    },
    [i18n.locale]
  )

  const RenderHourMeter = useCallback((item: IMetadata) => {
    const mowerHours = getAscentMowerHoursFromFault(item)

    return (
      <div className='flex items-center gap-1'>
        {mowerHours}
        {item.active && (
          <div className='flex-1 flex justify-end'>
            <Image src={ASSETS.IMAGES.COMMON.danger} />
          </div>
        )}
      </div>
    )
  }, [])

  const columns: Column[] = [
    {
      Header: 'Subsystem',
      id: 'subsystem',
      accessor: row => RenderSubsystemCell(row as IMetadata)
    },
    {
      Header: 'Fault',
      accessor: 'faultCode'
    },
    {
      Header: 'Description',
      id: 'description',
      accessor: row => RenderDescriptionCell(row as IMetadata)
    },
    {
      Header: 'Mower Hours',
      id: 'hour_meter',
      Cell: cell => RenderHourMeter(cell.row.original as IMetadata)
    },
    {
      Header: 'Timestamp',
      accessor: 'createdAt',
      minWidth: 200,
      Cell: cell => RenderTimestampCell(cell.value)
    },
    {
      Header: 'Label',
      accessor: 'state',
      Cell: cell => RenderStatusCell(cell.row.original as IMetadata)
    }
  ]

  useEffect(() => {
    setCurrentFaults(propFaults)
  }, [propFaults])

  useEffect(() => {
    const onMapEvent = (event: TMapEvents) => {
      if (event.type === 'DEVICE_FAULT_MARKER_CLICK') {
        const { fault } = event.payload
        const matchedFault = faults.find(
          item => item.metadataId === fault.metadataId
        )
        if (!matchedFault) return
        handleFaultTableRowClick(matchedFault)
      }
    }

    emitter?.on('MAP:tracking', onMapEvent)

    return () => {
      emitter?.off('MAP:tracking', onMapEvent)

      emitter?.emit('MAP:tracking', {
        type: 'DEVICE_FAULT_UNFOCUS',
        payload: {
          mower_id: mower?.productSerial || ''
        }
      })
      searchParams.delete('faultHistory')
      setSearchParams(searchParams, {
        replace: true
      })
    }
  }, [searchParams])

  const data = useMemo(
    () =>
      faults.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      ),
    [faults]
  )

  return (
    <div className='border-[#363636] border-t xl:pt-3 h-[calc(100%-40px)]'>
      <Table
        enableRowVirtualization
        rowKey='metadataId'
        data={data}
        columns={columns}
        rowClassName={data => {
          const defaultClassName = !isDeviceFaultClickable(data as IMetadata)
            ? 'cursor-auto'
            : ''

          if ((data as IMetadata).metadataId === selectedFaultId) {
            return cn(
              'bg-app-main-light !bg-opacity-50 dark:!bg-black-800',
              defaultClassName
            )
          }

          return cn('bg-app-main-light dark:!bg-[#1E1E1E]', defaultClassName)
        }}
        defaultSortedColumn={{
          key: 'timestamp',
          sort: 'descending'
        }}
        onRowClick={data => handleFaultTableRowClick(data as IMetadata)}
      />
    </div>
  )
}

export default FaultTable
