import { useGroups } from '../../../../hooks/data/useGroups'
import { IGroup } from '../../../../models_v2/entity/groups'
import GroupListItem from '../GroupListItem'
import cn from 'classnames'
import { Button, Icon } from '../../../atoms'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../routes'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  setCurrentActiveGroupIndex,
  setEdit,
  setGroup
} from '../../../../store/slices/deviceGroupSlice'
import {
  currentSelectedGroupIndex,
  groupList
} from '../../../../store/slices/deviceGroupSlice/selectors'
import { IDevice } from '../../../../models_v2/entity/device'
import * as Text from '../../../atoms/Typography'
import { t } from '@lingui/macro'
import { handleSortGroupsAlphabetically } from '../../../../utils/mower'
import { selectOperatingCompany } from '../../../../store/slices/operatingCompany'
import { selectFilteredDevices } from '../../../../store/slices/deviceSliceV2/selectors'
import { useMemo } from 'react'

const GroupList = () => {
  const result = useGroups({ companies: '*' })
  const navigate = useNavigate()
  const reduxDispatch = useAppDispatch()
  const selectedGroupIndex = useAppSelector(currentSelectedGroupIndex)
  const groups = useAppSelector(groupList)
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const mowerDevices = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'mower'
    })
  ) as IDevice[]
  const batteryDevices = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'battery'
    })
  ) as IDevice[]
  const toolTagsDevices = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'tools-and-tags'
    })
  ) as IDevice[]
  const companyMowers = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'mower',
      operatingCompany
    })
  ) as IDevice[]
  const companyBatteries = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'battery',
      operatingCompany
    })
  ) as IDevice[]
  const companyToolTags = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'tools-and-tags',
      operatingCompany
    })
  ) as IDevice[]
  const isGlobalUser = operatingCompany.name === 'Global'

  const handleSetNameForEdit = (group: IGroup, index: number) => {
    const { groupName, assets, groupId } = group
    reduxDispatch(
      setGroup({
        groupName,
        assets: assets as IDevice[],
        groupId
      })
    )
    if (selectedGroupIndex === index) {
      reduxDispatch(setCurrentActiveGroupIndex(false))
    } else {
      reduxDispatch(setCurrentActiveGroupIndex(index))
    }
  }

  const handleRedirectGroupForm = (isEdit: boolean) => {
    reduxDispatch(setEdit(isEdit))
    navigate(Routes['garage_page.global-fleet.groups_create'])
  }

  const renderGroupLists = useMemo(() => {
    if (result.loading)
      return <Text.Body.LargeSemiBold>{t`Loading ...`}</Text.Body.LargeSemiBold>

    const devicesGroupIds = [
      ...mowerDevices,
      ...batteryDevices,
      ...toolTagsDevices
    ]
      .filter(device => !!device.inventory.groupId)
      .map(device => device.inventory.groupId)
    const processedGroups = groups
      .filter(group => group.companyId === operatingCompany.companyId)
      .map(group => {
        let assetCount = 0

        devicesGroupIds.forEach(devicesGroupId => {
          if (devicesGroupId === group.groupId) {
            assetCount += 1
          }
        })

        const device_find: IDevice[] = [
          ...companyMowers,
          ...companyBatteries,
          ...companyToolTags
        ].filter(device => device.inventory.groupId === group.groupId)

        return { ...group, assetCount: assetCount, assets: device_find }
      })
      .sort(handleSortGroupsAlphabetically)

    const mappedGroups = processedGroups.map((group: IGroup, key: number) => (
      <GroupListItem
        key={`${key}`}
        index={key}
        onClick={() => handleSetNameForEdit(group, key)}
        isActive={key === selectedGroupIndex}
        groupName={group.groupName}
        assetCount={group.assetCount}
        isLoading={result.loading}
      />
    ))

    if (!mappedGroups.length)
      return (
        <Text.Body.LargeSemiBold>
          No groups for this company
        </Text.Body.LargeSemiBold>
      )

    return mappedGroups
  }, [
    mowerDevices,
    batteryDevices,
    toolTagsDevices,
    groups,
    companyMowers,
    companyBatteries,
    companyToolTags
  ])

  if (operatingCompany.name === 'Global') return <></>

  return (
    <>
      <div
        className={cn(
          'p-[5px] flex flex-col h-full gap-[20px] overflow-x-hidden overflow-y-auto'
        )}>
        {renderGroupLists}
      </div>
      {!isGlobalUser && (
        <div className={cn('p-[30px] flex flex-col gap-[10px]')}>
          <Button
            disabled={selectedGroupIndex === false}
            className='w-full flex justify-between items-center'
            showOutline={false}
            color='secondary'
            titleSize='md'
            title='Edit Group'
            onClick={() => handleRedirectGroupForm(true)}>
            <Icon name='edit_pencil' className='w-4' />
          </Button>
          <Button
            className='w-full flex justify-between items-center'
            showOutline={false}
            color='secondary'
            titleSize='md'
            title='Add Group'
            onClick={() => handleRedirectGroupForm(false)}>
            <Icon name='add_circle' className='w-4' />
          </Button>
        </div>
      )}
    </>
  )
}
export default GroupList
