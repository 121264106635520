import React from 'react'
import { Text } from '../../atoms'
import { useLingui } from '@lingui/react'

interface DeviceDetailRowInformationProps {
  label: string
  value: string
}

const DeviceDetailRowInformation: React.FC<DeviceDetailRowInformationProps> =
  props => {
    const { i18n } = useLingui()
    const { label, value } = props

    return (
      <div className='flex justify-between'>
        <Text.VariousRegular.Small className='dark:text-black-200'>
          {label && i18n._(label)}
        </Text.VariousRegular.Small>
        <Text.VariousBold.Small className='dark:text-white'>
          {value && i18n._(value)}
        </Text.VariousBold.Small>
      </div>
    )
  }

export default DeviceDetailRowInformation
