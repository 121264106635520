import React, { Fragment } from 'react'
import { Placeholders } from '../../../utils/constants'
import { 
  Text, 
  Divider } from '../../atoms'
import { MowerListItem } from '../../molecules'
import { useAppSelector } from '../../../store/hooks'
import { selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import {IDevice} from '../../../models_v2/entity/device';
import { Mower, compareByName } from '../../../utils/sortingLists'

interface MowerListProps {}

export const MowerList: React.FC<MowerListProps> = props => {
  const deviceIdsSelectedOnMap = useAppSelector(state => state.deviceV2.deviceIdsSelectedOnMap)
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const mowerDevices = (
    useAppSelector(
      selectFilteredDevices<IDevice>({
        deviceType: "mower", 
        operatingCompany,
        productSerials: deviceIdsSelectedOnMap, 
      })) as IDevice[]
    ).sort(compareByName<Mower>(mower => mower.inventory.name));

  if (!mowerDevices.length)
    return (
      <div className='h-full flex flex-col items-center justify-center'>
        <Text.VariousRegular.Medium className='dark:text-white'>
          {Placeholders['tracking.sidebar.device_empty_list']}
        </Text.VariousRegular.Medium>
      </div>
    )
  return (
    <div 
      id="mower-list-container" 
      className='flex flex-col'>
      {mowerDevices.map((item: any) => {
        return (
          <Fragment key={item.productSerial}>
            <MowerListItem mower={item} />
            <Divider size='lg' />
          </Fragment>
        )
      })}
    </div>
  )
}

export default MowerList
