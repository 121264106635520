import React, { useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Alert, Button, Icon } from '../../../components/atoms'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useCognitoUser } from '../../../integrations/aws-cognito/hooks'
import { Password } from '../../../utils/password'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../routes'

interface UpdatePasswordFormProps {}

const styles = {
  fieldset: { borderColor: 'whitesmoke', color: 'whitesmoke' },
  label: { color: 'whitesmoke' },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      borderColor: '#FEBD17'
    }
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      borderColor: '#FEBD17',
      color: 'whitesmoke'
    }
  },
  '& .MuiInputLabel-root.Mui-focused': { color: '#FEBD17' },
  '& .MuiFormHelperText-root': {
    color: '#C4C4C4'
  }
}

interface IFormInput {
  oldPassword: string
  password: string
  confirmPassword: string
}

const PasswordRequirement = () => {
  return (
    <div className='text-[#C4C4C4]'>
      <p>Minimum 12 Characters.</p>
      <p>At least 1 number or special character ($ 0 % &).</p>
      <p>Avoid 3 or more repeated characters (aaa or 111).</p>
    </div>
  )
}

export const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = () => {
  const navigate = useNavigate()

  const { register, handleSubmit, watch, formState, setError, reset } =
    useForm<IFormInput>()

  const user = useCognitoUser()

  const [hideOldPassword, setHideOldPassword] = useState(true)
  const [hidePassword, setHidePassword] = useState(true)
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true)

  const [isLoading, setIsLoading] = useState(false)

  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmit: SubmitHandler<IFormInput> = async formData => {
    try {
      setErrorMessage('')
      setSuccessMessage('')
      setIsLoading(true)

      const { oldPassword, password } = formData

      user?.changePassword(oldPassword, password, (error, result) => {
        setIsLoading(false)

        if (error) {
          if (error.name === 'NotAuthorizedException') {
            setError(
              'oldPassword',
              {
                type: 'focus',
                message: 'Incorrect Password'
              },
              {
                shouldFocus: true
              }
            )
          } else {
            setErrorMessage(error.message)
          }
          return
        }

        if (result === 'SUCCESS') {
          setSuccessMessage('Password changed successfully')
          reset()
        }
      })
    } catch (e: any) {
      setIsLoading(false)
      setErrorMessage(e?.error?.message || "Can't establish connection.")
    }
  }

  const navigateForgotPassword = () => {
    navigate(Routes['login_page.forgot-password'])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='mt-2'>
        <TextField
          {...register('oldPassword', {
            required: Password.messages.requiredCurrentPassword,
            minLength: {
              value: Password.minimumPasswordLength,
              message: Password.messages.minimumPassword
            }
          })}
          label='Current Password'
          type={hideOldPassword ? 'password' : 'text'}
          className='w-full'
          inputProps={{
            style: { color: 'white' }
          }}
          error={!!formState.errors?.oldPassword}
          helperText={formState.errors?.oldPassword?.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Icon name='padlock' className='h-4 w-4' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  onClick={() => setHideOldPassword(prev => !prev)}
                  edge='end'>
                  {!hideOldPassword ? (
                    <Icon name='visibility_off' className='h-4 w-4' />
                  ) : (
                    <Icon name='visibility' className='h-4 w-4' />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          sx={styles}
        />
      </div>
      <div className='mt-4'>
        <TextField
          {...register('password', {
            required: Password.messages.requiredNewPassword,
            minLength: {
              value: Password.minimumPasswordLength,
              message: Password.messages.minimumPassword
            },
            validate: Password.validatePassword
          })}
          label='New Password'
          type={hidePassword ? 'password' : 'text'}
          error={!!formState.errors?.password}
          helperText={
            <>
              {formState.errors?.password?.message && (
                <p>{formState.errors?.password?.message}</p>
              )}
              <PasswordRequirement />
            </>
          }
          className='w-full'
          inputProps={{
            style: { color: 'white' }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Icon name='padlock' className='h-4 w-4' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  onClick={() => setHidePassword(prev => !prev)}
                  edge='end'>
                  {!hidePassword ? (
                    <Icon name='visibility_off' className='h-4 w-4' />
                  ) : (
                    <Icon name='visibility' className='h-4 w-4' />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          sx={styles}
        />
      </div>
      <div className='mt-4'>
        <TextField
          {...register('confirmPassword', {
            required: Password.messages.requiredNewPassword,
            minLength: {
              value: Password.minimumPasswordLength,
              message: Password.messages.minimumPassword
            },
            validate: value => {
              if (watch('password') !== value) {
                return 'New password and confirm password does not match'
              }
              return Password.validatePassword(value)
            }
          })}
          label='Confirm Password'
          type={hideConfirmPassword ? 'password' : 'text'}
          className='w-full'
          inputProps={{
            style: { color: 'white' }
          }}
          error={!!formState.errors?.confirmPassword}
          helperText={formState.errors?.confirmPassword?.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Icon name='padlock' className='h-4 w-4' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  onClick={() => setHideConfirmPassword(prev => !prev)}
                  edge='end'>
                  {!hideConfirmPassword ? (
                    <Icon name='visibility_off' className='h-4 w-4' />
                  ) : (
                    <Icon name='visibility' className='h-4 w-4' />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          sx={styles}
        />
      </div>

      {errorMessage && (
        <Alert variant='error' message={errorMessage} className='my-4' />
      )}
      {successMessage && (
        <Alert variant='success' message={successMessage} className='my-4' />
      )}

      <div className='flex flex-col mt-5 mx-auto w-full md:w-1/2'>
        <Button
          type='submit'
          color='danger'
          title={isLoading ? 'Updating...' : 'Set New Password'}
          disabled={isLoading}
        />
        <Button
          variant='text'
          color='secondary'
          title='Forgot Password'
          titleClassname='underline'
          onClick={navigateForgotPassword}
        />
      </div>
    </form>
  )
}
