import { useMemo } from 'react'
import { matchSorter } from 'match-sorter'
import { IDevice } from '../../models_v2/entity/device'

export const useMowerSearchV2 = ({
  items,
  search
}: {
  items: IDevice[]
  search: string
}): IDevice[] => {
  const reducedDevices = useMemo(() => {
    return items.reduce<{
      collection: Record<string, IDevice>
      modifiedItems: IDevice[]
    }>(
      (acc, curr) => {
        // change source of 'ngbr' to 'ascent' so this should be searchable
        const modifiedItems = [
          ...acc.modifiedItems,
          curr?.inventory?.source === 'ngbr'
            ? {
                ...curr,
                inventory: {
                  ...curr?.inventory,
                  source: 'ascent' as any
                }
              }
            : curr
        ]

        const collection = {
          ...acc.collection,
          [curr.productSerial]: curr
        }

        return {
          collection,
          modifiedItems
        }
      },
      {
        collection: {},
        modifiedItems: []
      }
    )
  }, [items])

  if (!search) return items

  // source is either "ngbr" or "surepath"
  // but search text by the user will be ngbr = "ascent" or "surepath"
  const results = matchSorter(
    reducedDevices?.modifiedItems ?? [],
    search.trim(),
    {
      keys: [
        'inventory.name',
        'inventory.source',
        'productSerial',
        'inventory.factoryModel',
        'inventory.model',
        'inventory.dealer',
        "inventory.groupName"
      ] as (keyof IDevice)[],
      threshold: matchSorter.rankings.CONTAINS
    }
  )

  const modifiedResults = results.reduce<IDevice[]>((acc, curr) => {
    return [...acc, reducedDevices.collection[curr.productSerial] as IDevice]
  }, [])

  return modifiedResults
}
