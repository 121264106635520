import React, {
  useMemo,
  useContext,
  useRef,
  useEffect,
} from 'react'
import {
  useLocation,
} from 'react-router-dom'
import { TrackingSidebarDetailContainer } from '../../molecules'
import {
  MowerList,
  BatteryList,
  ToolTagList
} from '../index'
import { AppRootContext } from '../../../pages/AppRoot'
import {
  useDeviceDetailedView,
  useScrollRestore
} from '../../../hooks'
import { TPageTrackingEvents } from '../../../pages/AppRoot/types'
import GroupTagList from '../GroupList'
import {useAppSelector} from '../../../store/hooks';
import {selectOperatingCompany} from '../../../store/slices/operatingCompany';

interface TrackingSidebarProps {
  tabIndex: number
}

const TrackingSidebar: React.FC<TrackingSidebarProps> = props => {
  const { tabIndex = 0 } = props
  const { emitter } = useContext(AppRootContext)
  const operatingCompany = useAppSelector(selectOperatingCompany)
  // const navigate = useNavigate()
  const location = useLocation()
  const sidebarContentRef = useRef<HTMLDivElement | null>(null)
  const scrollableDivRef = useRef<HTMLDivElement | null>(null)
  useScrollRestore(scrollableDivRef, { key: location.pathname })
  const { isDetailView } = useDeviceDetailedView()

  const renderList = useMemo(() => {
    if (tabIndex === 3 && operatingCompany.name === "Global") {
      return null
    }
    return [
      <MowerList />,
      <BatteryList />,
      <ToolTagList />,
      <GroupTagList />
    ][tabIndex]
  }, [tabIndex, operatingCompany.name])

  useEffect(() => {
    const handleScrollToTop = () => {
      setTimeout(() => {
        sidebarContentRef.current?.scrollIntoView({
          behavior: 'smooth'
        })
      })
    }

    const handlePageTrackingEvent = (event: TPageTrackingEvents) => {
      if (event.type === 'RESET_SCROLL_TO_TOP') handleScrollToTop()
    }

    handleScrollToTop()

    emitter?.on('PAGE:tracking', handlePageTrackingEvent)

    return () => {
      emitter?.off('PAGE:tracking', handlePageTrackingEvent)
    }
  }, [tabIndex])

  return (
    <div ref={scrollableDivRef} className='overflow-y-auto h-full'>
      <div ref={sidebarContentRef}></div>
      {!isDetailView ? (
        renderList
      ) : (
        <TrackingSidebarDetailContainer tabIndex={tabIndex} />
      )}
    </div>
  )
}

export default TrackingSidebar
