export const KEYS = {
  GOOGLE_MAP_API_KEY: 'AIzaSyAXqOGiz3yFOUvcko1UQS3CEX0U-Kbq3xk'
}

export const ZOOM_LEVELS = {
  SATELLITE_VIEW: 5, //Satellite
  STREET_VIEW: 17
}

export const CENTER = {
  lat: 39.57182223734374,
  lng: -98.7890625
}

export const IMAGE_CLUSTER_PATH = {
  default:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer',
  custom: '/assets/maps'
}

export const CLUSTER = {
  min_size: 5
}

export const MAP_ZOOM = {
  MIN: 5,
  MAX: 17
}