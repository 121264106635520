import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useCallback } from 'react'
import { ASSETS } from '../../../../../../assets'
import { Alert, Button, Icon, Image, Text } from '../../../../../atoms'
import cn from 'classnames'
import { useDynamicMutation } from '../../../../../../hooks'
import { ICompany } from '../../../../../../models'

interface DeleteOrganizationCompanyModalProps {
  isOpen: boolean
  data: Partial<ICompany>
  onClose?: () => void
  onDeleted?: (data: Partial<ICompany>) => void
}

const DeleteOrganizationCompanyModal: React.FC<DeleteOrganizationCompanyModalProps> =
  props => {
    const { isOpen = false, data, onClose, onDeleted } = props

    const { id, name, image_url, email, phone } = data ?? {}

    const hasThumbnailImage = !!image_url

    //   const {
    //     mutateAsync: deleteUser,
    //     isLoading: deleteUserIsLoading,
    //     error: deleteUserError
    //   } = useDynamicMutation({
    //     graphqlEntity: 'users',
    //     queryKey: 'DELETE_USER'
    //   })

    //   const handleDeleteUser = useCallback(async () => {
    //     await deleteUser({ id })
    //     onDeleted?.(data)
    //   }, [id])

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={() => onClose?.()}>
          <div
            className='fixed inset-0 bg-black/75 backdrop-blur-xs'
            aria-hidden='true'
          />

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full relative max-w-md transform overflow-hidden rounded-2xl bg-black border border-error p-6 align-middle shadow-xl transition-all text-white'>
                  <div className='absolute right-[18px] top-[18px]'>
                    <Icon
                      name='close_circle'
                      className='cursor-pointer'
                      onClick={onClose}
                    />
                  </div>

                  <div className='flex gap-3'>
                    <div className='self-center h-[85px] w-[85px]'>
                      {hasThumbnailImage && (
                        <Image
                          src={data.image_url!}
                          className={cn('h-full w-full rounded-xl', {
                            'p-[6px]': !hasThumbnailImage,
                            'rounded-xl': hasThumbnailImage
                          })}
                        />
                      )}
                    </div>

                    <div className='flex-1'>
                      <Text.VariousRegular.Large className='!font-bold'>
                        {name}
                      </Text.VariousRegular.Large>

                      <div className='mt-2'>
                        <div className='flex gap-1'>
                          <Text.Body.Medium>Email:</Text.Body.Medium>
                          <Text.Body.Medium className='!font-bold'>
                            {email}
                          </Text.Body.Medium>
                        </div>

                        <div className='flex gap-1'>
                          <Text.Body.Medium>Phone #:</Text.Body.Medium>
                          <Text.Body.Medium className='!font-bold'>
                            {phone}
                          </Text.Body.Medium>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='mt-3 text-center flex flex-col gap-3'>
                    <div>
                      <Text.Body.MediumSemiBold className='italic'>
                        ATTENTION!
                      </Text.Body.MediumSemiBold>
                      <Text.Body.MediumSemiBold className='italic'>
                        This action is permanent!
                      </Text.Body.MediumSemiBold>
                      <Text.Body.Small>
                        Delete this company profile?
                      </Text.Body.Small>
                    </div>

                    {/* {deleteUserError && (
                    <Alert
                      variant='error'
                      message='Error in deleting user profile'
                      className='mb-0'
                    />
                  )} */}

                    <div className='flex flex-col mx-auto w-1/2'>
                      {/* <Button
                      color='danger'
                      title={deleteUserIsLoading ? 'Deleting...' : 'Delete'}
                      disabled={deleteUserIsLoading}
                      onClick={handleDeleteUser}
                    /> */}
                      <Button color='danger' title='Delete' />
                      <Button
                        variant='text'
                        color='secondary'
                        title='Cancel'
                        titleClassname='underline'
                        onClick={onClose}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  }

export default DeleteOrganizationCompanyModal
