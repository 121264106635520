import React from 'react'
import cn from 'classnames'
import AppLogo from '../AppLogo'

interface LoginContainerProps {
  className?: string
  displayLogo?: boolean
}

const LoginContainer: React.FC<LoginContainerProps> = props => {
  const { children, className, displayLogo = true } = props

  return (
    <div
      className={cn(
        'overflow-auto sm-tall:border-0 sm-tall:w-full sm-tall:h-screen h-screen sm:h-auto py-[80px] px-8 m-auto w-full sm:w-[438px] login-panel border-0 border-solid sm:border-[1px] border-black-800',
        className
      )}>
      {displayLogo && <AppLogo />}
      {children}
    </div>
  )
}

export default LoginContainer
