import AboutRoute from './about'
import GarageRoutes from './garage'
import ReportingRoutes from './reporting'
import SettingsRoutes from './settings'
import { NewSettingsRoutes } from './newSettings'
import TrackingRoutes from './tracking'

export const Routes = {
  login_page: '/login',
  // 'login_page.form': '/login/form',
  'login_page.failed': '/login/failed',
  'login_page.update-password': '/login/update-password',
  'login_page.forgot-password': '/login/forgot-password',
  'login_page.forgot-password.code': '/login/forgot-password/code',
  'login_page.signup': '/login/signup',
  'login_page.signup.code': '/login/signup/code',
  'login_page.complete-registration': '/login/complete-registration',
  root_page: '/app',
  profile_page: '/app/profile',
  'profile_page.wildcard': '/app/profile/*',
  'profile_page.edit': '/app/profile/edit',
  ...TrackingRoutes,
  ...GarageRoutes,
  ...ReportingRoutes,
  ...SettingsRoutes,
  ...NewSettingsRoutes,
  ...AboutRoute,
}
