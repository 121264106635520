import { useRouteError } from "react-router-dom";
import { Text } from '../../components/atoms'

interface ErrorPageProps {}

const ErrorPage: React.FC<ErrorPageProps> = props => {
  const error = useRouteError() as any;

  return (
    <div className='w-full h-screen'>
      <div className='h-full flex flex-col items-center justify-center text-center'>
        <Text.Headline.H1>Oops!</Text.Headline.H1>
        <Text.Body.Medium>Sorry, an unexpected error has occurred.</Text.Body.Medium>

        <div className='my-4'>
          <Text.Body.Small className='text-black-500'>{error?.statusText || error?.message}</Text.Body.Small>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
