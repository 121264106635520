import React, { useContext, useMemo, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { TextField, Button, Text } from '../../atoms'

import { Routes } from '../../../routes'
import { LoginCardContainer } from '../../molecules'
import { AppContext } from '../../../App'

interface IProps {}

export interface ICredentials {
  username: string
  password: string
  confirmPassword: string
}

interface IFormData {
  verificationCode: string
}

const LoginForgotPasswordVerificationCode: React.FC<IProps> = () => {
  const { forgotPasswordVerify } = useContext(AppContext)
  const navigate = useNavigate()
  const { state } = useLocation()
  const { register, handleSubmit, watch, formState, setError } =
    useForm<IFormData>()
  const formData = watch()
  const { errors } = formState ?? {}
  const [errorMessage, setErrorMessage] = useState('')
  const [verified, setVerified] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!state || !state?.credentials) {
      navigate(Routes['login_page.forgot-password'])
      return
    }
  }, [])

  const onSubmit: SubmitHandler<IFormData> = async data => {
    try {
      const { verificationCode } = data ?? {}

      if (!forgotPasswordVerify) return

      setIsLoading(true)
      setErrorMessage('')

      const { success } = await forgotPasswordVerify({
        ...((state?.credentials ?? {}) as ICredentials),
        verificationCode
      })

      setIsLoading(false)

      if (!success) {
        setErrorMessage('Error Verification')
        return
      }

      setVerified(true)
    } catch (error) {
      // @ts-ignore
      setErrorMessage(error?.message ?? 'Error Verification')
      setIsLoading(false)
    }
  }

  const isSubmitDisabled = useMemo(() => {
    const { verificationCode = '' } = formData
    return isLoading || !verificationCode.trim().length
  }, [isLoading, formData])

  const navigateToLogin = () => {
    navigate(Routes['login_page'])
  }

  return (
    <LoginCardContainer>
      {verified ? (
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col gap-2'>
            <Text.Headline.H3 className='mt-[50px]'>
              Password changed successfully!
            </Text.Headline.H3>
            <Text.Body.Small>
              Please login using your new credentials. Thank you.
            </Text.Body.Small>
          </div>

          <Button
            type='submit'
            title='Ok, got it'
            className='w-[116px] h-[43px]'
            onClick={navigateToLogin}
          />
        </div>
      ) : (
        <>
          {' '}
          <div className='flex flex-col gap-2'>
            <Text.Headline.H3 className='mt-[50px]'>
              Verification Code
            </Text.Headline.H3>
            <div>
            <Text.Body.Small>
              We emailed you a verification code to {state?.credentials?.username}.
            </Text.Body.Small>
            <Text.Body.Small>
              Enter the code below to confirm and continue resetting your password.
            </Text.Body.Small>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col my-4'>
              {errorMessage && (
                <div className='bg-[#f8514926] border border-solid border-[#f8514966] rounded py-2 px-3 mb-3 text-center'>
                  <Text.Body.Small>{errorMessage}</Text.Body.Small>
                </div>
              )}
              <TextField
                className='focus:outline-none focus:border-solid focus:border-white'
                {...register('verificationCode', {
                  required: {
                    message: 'Verification code is required',
                    value: true
                  }
                })}
                placeholder='Verification Code'
                disabled={isLoading}
              />
              {errors?.verificationCode && (
                <Text.Body.Small className='text-error'>
                  {errors?.verificationCode?.message ?? ''}
                </Text.Body.Small>
              )}
            </div>

            <div className='flex gap-3'>
              <Button
                type='submit'
                title={!isLoading ? 'Submit' : 'Submitting...'}
                className='min-w-[116px] h-[43px]'
                disabled={isSubmitDisabled}
              />
            </div>
          </form>
        </>
      )}
    </LoginCardContainer>
  )

  // return (
  //   <div className='h-screen sm:h-auto py-[80px] px-8 login-panel border-solid border-[1px] border-black-800'>
  //     <AppLogo />
  //     {verified ? (
  //       <div className='flex flex-col gap-5'>
  //         <div className='flex flex-col gap-2'>
  //           <Text.Headline.H1 className='mt-[50px]'>
  //             Password changed successfully!
  //           </Text.Headline.H1>
  //           <Text.Body.Small>
  //             Please login using your new credentials. Thank you.
  //           </Text.Body.Small>
  //         </div>

  //         <Button
  //           type='submit'
  //           title='Ok, got it'
  //           className='w-[116px] h-[43px]'
  //           onClick={navigateToLogin}
  //         />
  //       </div>
  //     ) : (
  //       <>
  //         {' '}
  //         <div className='flex flex-col gap-2'>
  //           <Text.Headline.H1 className='mt-[50px]'>
  //             Verification Code
  //           </Text.Headline.H1>
  //           <div>
  //           <Text.Body.Small>
  //             We emailed you a verification code to {state?.credentials?.username}.
  //           </Text.Body.Small>
  //           <Text.Body.Small>
  //             Enter the code below to confirm and continue resetting your password.
  //           </Text.Body.Small>
  //           </div>
  //         </div>
  //         <form onSubmit={handleSubmit(onSubmit)}>
  //           <div className='flex flex-col my-4'>
  //             {errorMessage && (
  //               <div className='bg-[#f8514926] border border-solid border-[#f8514966] rounded py-2 px-3 mb-3 text-center'>
  //                 <Text.Body.Small>{errorMessage}</Text.Body.Small>
  //               </div>
  //             )}
  //             <TextField
  //               className='focus:outline-none focus:border-solid focus:border-white'
  //               {...register('verificationCode', {
  //                 required: {
  //                   message: 'Verification code is required',
  //                   value: true
  //                 }
  //               })}
  //               placeholder='Verification Code'
  //               disabled={isLoading}
  //             />
  //             {errors?.verificationCode && (
  //               <Text.Body.Small className='text-error'>
  //                 {errors?.verificationCode?.message ?? ''}
  //               </Text.Body.Small>
  //             )}
  //           </div>

  //           <div className='flex gap-3'>
  //             <Button
  //               type='submit'
  //               title={!isLoading ? 'Submit' : 'Submitting...'}
  //               className='min-w-[116px] h-[43px]'
  //               disabled={isSubmitDisabled}
  //             />
  //           </div>
  //         </form>
  //       </>
  //     )}
  //   </div>
  // )
}

export default LoginForgotPasswordVerificationCode
