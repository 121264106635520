import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IDevice } from '../../../models_v2/entity/device'
import { IGroup } from '../../../models_v2/entity/groups'

// This slice refers to the "Add Group/Edit Group Form" in the Garage Page

interface DeviceGroupSliceState {
  groups: IGroup[]
  form: {
    // selected_device_details_ids: Record<string, boolean>
    selected_devices: any[]
    group_name: string
    errorGroupName: string
    edit: boolean
    groupId: string
    current_active_group_index?: number | boolean
    isAllAssets: boolean
  }
}

const initialState: DeviceGroupSliceState = {
  groups: [],
  form: {
    // selected_device_details_ids: {}
    selected_devices: [],
    group_name: '',
    groupId: "",
    errorGroupName: '',
    edit: false,
    current_active_group_index: false,
    isAllAssets: false
  }
}

interface IPropsGroupEdit {
  groupName: string
  assets: IDevice[]
  groupId: string
}

export const deviceGroupSlice = createSlice({
  name: 'deviceGroup',
  initialState,
  reducers: {
    resetState: ( state ) => {
      return {
        ...initialState,
        groups: state.groups
      }
    },
    addDeviceInSelection: (state, action: PayloadAction<any>) => {
      const { productSerial } = action.payload
      const filteredDevices = state.form.selected_devices.filter(
        device => device.productSerial == productSerial
      )

      // If the productSerial is existing in the current devices return
      if (filteredDevices.length) return

      const updatedDevices = [...state.form.selected_devices, action.payload]
      return {
        ...state,
        form: { ...state.form, selected_devices: updatedDevices }
      }
    },
    setGroups: (state, action: PayloadAction<IGroup[]>) => {
      return {
        ...state,
        groups: action.payload
      }
    },
    setGroup: (state, action: PayloadAction<IPropsGroupEdit>) => {
      const { groupName, assets, groupId } = action.payload
      return {
        ...state,
        form: { ...state.form, group_name: groupName, selected_devices: assets, groupId }
      }
    },
    setGroupName: (state, action: PayloadAction<string>) => {
      return { ...state, form: { ...state.form, group_name: action.payload } }
    },
    setErrorGroupName: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        form: { ...state.form, errorGroupName: action.payload }
      }
    },
    setEdit: (state, action: PayloadAction<any>) => {
      return { ...state, form: { ...state.form, edit: action.payload } }
    },
    setCurrentActiveGroupIndex: (state, action: PayloadAction<number | boolean>) => {
      return {
        ...state,
        form: { ...state.form, current_active_group_index: action.payload }
      }
    },
    toggleIsAllDevice:  (state, action: PayloadAction<boolean>) => {
      return {...state, form: {...state.form, isAllAssets: action.payload}}
    },
    toggleDeviceSelection: (state, action: PayloadAction<string>) => {
      const deviceId = action.payload

      const filteredDevices = state.form.selected_devices.filter(
        device => device.productSerial != deviceId
      )

      return {
        ...state,
        form: { ...state.form, selected_devices: filteredDevices }
      }
    },
    addGroup: ( state, action: PayloadAction<IGroup> ) =>{
      state.groups.push(action.payload)
    },
    editGroup: ( state, action: PayloadAction<IGroup> ) => {
      const editedGroup = action.payload
      state.groups = state.groups.map(group => group.groupId===editedGroup.groupId && group.companyId===editedGroup.companyId? {
        ...action.payload,
        groupId: state.form.groupId
      } : group)
    },
    resetDeviceGroupState: () => initialState
  }
})

export const {
  toggleDeviceSelection,
  resetState,
  addDeviceInSelection,
  setGroupName,
  setGroups,
  setGroup,
  setEdit,
  setErrorGroupName,
  setCurrentActiveGroupIndex,
  toggleIsAllDevice,
  addGroup,
  editGroup,
  resetDeviceGroupState
 } = deviceGroupSlice.actions

export const deviceGroupSliceReducer = deviceGroupSlice.reducer
export default deviceGroupSlice.reducer
