import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useDebounce } from 'use-debounce'
import { useMetadataUpdate } from './useMetadataUpdate'
import { MetadataInputDto } from '../../models_v2/dto/metadata_input'
import { removeMetadataUpdateTask } from '../../store/slices/backgroundTaskSlice'

export const useBackgroundTask = () => {
  const metadataUpdateTask = useAppSelector(
    state => state.backgroundTask.metadata_update_task
  )

  const reduxDispatch = useAppDispatch()

  const [mutateMetadata] = useMetadataUpdate()

  const [debouncedMetadataUpdateTask] = useDebounce(
    metadataUpdateTask ?? {},
    1000
  )

  useEffect(() => {
    ;(async () => {
      const promises = Object.values(debouncedMetadataUpdateTask).map(item => {
        const dto = new MetadataInputDto(item)

        reduxDispatch(removeMetadataUpdateTask({ metadataId: item.metadataId }))

        return mutateMetadata({
          variables: dto.toJSON()
        })
      })

      Promise.all(promises)
    })()
  }, [debouncedMetadataUpdateTask])
}
