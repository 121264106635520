// Placeholder: fill out once we get company data & set in state
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ICompany } from '../../models';

const initialState: Partial<ICompany> | null = {
    email: 'employee@companyemail.com',
    status: 'Active',
    date: '01/01/2023',
    phone: '555-345-6789',
    timezone: 'EST UTC-05:00',
    payment: '1234'
}

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCurrentCompany: (state, action: PayloadAction<ICompany>)  => {
      state = action?.payload
    },
    setTimezone: (state, action: PayloadAction<string>)  => {
      state.timezone = action?.payload
    },
    setPayment: (state, action: PayloadAction<string>)  => {
      state.payment = action?.payload
    },
    resetCompanyState: () => initialState
  },
})

export const { setCurrentCompany, setTimezone, setPayment, resetCompanyState } = companySlice.actions
export const companySliceReducer = companySlice.reducer;