import { IInventoryInput } from '../gql/inventory_input'

export interface IInventoryInputDto {
  input: IInventoryInput
}

// Preparation for serializing the payload
export class DeviceInputDto {
  #input: IInventoryInput

  constructor(input: IInventoryInput) {
    this.#input = input
  }

  toJSON(): IInventoryInputDto {
    const { __typename, ...input } = this.#input
    return { input }
  }
}
