import { useContext, useEffect, useMemo, useState } from 'react'
import { Outlet, useMatch, useSearchParams } from 'react-router-dom'
import {
  GarageGlobalFleetBatteryForm,
  GarageGlobalFleetDeviceList,
  GarageGlobalFleetMowerForm,
  GarageGlobalFleetToolTagForm
} from '..'
import { Routes } from '../../../routes'
import { TextField, Text } from '../../atoms'
import {
  DeviceCounterItem,
  DynamicListItem,
  SidebarContainer
} from '../../molecules'
import { selectUserIsAdmin } from '../../../store/slices/userSlice'
import {
  useMowerSearchV2,
  useBatterySearchV2,
  useToolTags,
  useToolTagSearchV2
} from '../../../hooks/data'
import { StyledBorder } from '../../molecules/DeviceCounterItem'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  addDeviceInSelection,
  toggleIsAllDevice
} from '../../../store/slices/deviceGroupSlice'
import {
  currentSelectedGroupIndex,
  groupList,
  isAllAssets
} from '../../../store/slices/deviceGroupSlice/selectors'
import AssetList from './AssetList'
import { AppRootContext } from '../../../pages/AppRoot'
import { IDevice } from '../../../models_v2/entity/device'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import {
  selectDevices,
  selectFilteredDevices
} from '../../../store/slices/deviceSliceV2/selectors'
import { mapGroupNameToDevices } from '../../../utils/devices'

const GarageGlobalFleet: React.FC = () => {
  const dispatch = useAppDispatch()
  const userIsAdmin = useAppSelector(selectUserIsAdmin)
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedGroupIndex = useAppSelector(currentSelectedGroupIndex)
  const isAllAssetsChecker = useAppSelector(isAllAssets)
  const [searchText, setSearchText] = useState<string>('')
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const devices = useAppSelector(selectDevices)
  const areDevicesLoading = devices.length == 0
  const companyMowers = useAppSelector(
    selectFilteredDevices({ deviceType: 'mower', operatingCompany })
  ) as IDevice[]
  const companyBatteries = useAppSelector(
    selectFilteredDevices({ deviceType: 'battery', operatingCompany })
  ) as IDevice[]
  const companyToolTags = useAppSelector(
    selectFilteredDevices({ deviceType: 'tools-and-tags', operatingCompany })
  ) as IDevice[]
  const [currentDevice, setCurrentDevice] = useState('mower')
  const totalAssets = useMemo(() => {
    const total_mowers = companyMowers.length ?? 0
    const total_batteries = companyBatteries.length ?? 0
    const total_toolTags = companyToolTags.length ?? 0

    if (selectedGroupIndex !== false) {
      switch (currentDevice) {
        case 'mower':
          return total_mowers

        case 'battery':
          return total_batteries

        case 'tool_tag':
          return total_toolTags

        default:
          break
      }
    }

    return total_mowers + total_batteries + total_toolTags
  }, [companyMowers, companyBatteries, companyToolTags])

  const isDeviceGroupCreate = useMatch(
    Routes['garage_page.global-fleet.groups_create']
  )
  const hasSearchText = useMemo(() => !!searchText.trim().length, [searchText])
  const { emitter } = useContext(AppRootContext)
  const groups = useAppSelector(groupList)

  const filteredMowers = useMowerSearchV2({
    items: mapGroupNameToDevices(groups, companyMowers),
    search:
      currentDevice == 'mower' && hasSearchText
        ? searchText.trim()
        : isAllAssetsChecker && hasSearchText
        ? searchText.trim()
        : ''
  })

  const filteredBatteries = useBatterySearchV2({
    items: mapGroupNameToDevices(groups, companyBatteries),
    search:
      currentDevice == 'battery' && hasSearchText
        ? searchText.trim()
        : isAllAssetsChecker && hasSearchText
        ? searchText.trim()
        : ''
  })

  const filteredToolTags = useToolTagSearchV2({
    items: mapGroupNameToDevices(groups, companyToolTags),
    search:
      currentDevice == 'tool_tag' && hasSearchText
        ? searchText.trim()
        : isAllAssetsChecker && hasSearchText
        ? searchText.trim()
        : ''
  })
  const [selectedDevice, setSelectedDevice] =
    useState<{ type: string; payload: IDevice } | null>(null)

  const handleOnDeviceRowClick = (data: Record<string, any>) => {
    if (data.inventory.groupName === '') {
      dispatch(addDeviceInSelection(data))
    }
  }

  const handleSetCurrentDevice = (device_type: string) => {
    dispatch(toggleIsAllDevice(false))

    setCurrentDevice(device_type)
  }

  const handleResetSelectedDevice = () => setSelectedDevice(null)

  useEffect(() => {
    dispatch(toggleIsAllDevice(true))
    emitter?.on('FORM:garage.global_fleet', (event: any) => {
      setSelectedDevice(event)
    })

    return () => {
      emitter?.off('FORM:garage.global_fleet')
    }
  }, [])

  useEffect(() => {
    if (isDeviceGroupCreate) {
      searchParams.set('type', 'mower')
      setSearchParams(searchParams)
    }
  }, [isDeviceGroupCreate])

  useEffect(() => {
    handleSetCurrentDevice('mower')
  }, [])

  return (
    <div className='flex flex-1 overflow-hidden bg-app-main-light-100 dark:bg-transparent'>
      <SidebarContainer className='border-r-2 border-black dark:text-white'>
        {userIsAdmin && (
          <div className='p-[30px] flex flex-col h-full gap-[30px]'>
            <DynamicListItem
              className='pl-3 cursor-pointer'
              onClick={() => dispatch(toggleIsAllDevice(!isAllAssetsChecker))}>
              {isAllAssetsChecker && (
                <StyledBorder className='w-[3px] h-[32px] bg-primary rounded-full mr-5' />
              )}

              <div className='flex w-full justify-between'>
                <Text.Body.Medium>All Assets </Text.Body.Medium>
                {areDevicesLoading && (
                  <div className='border-[2px] border-[#a8a8a8] border-t-white rounded-full w-4 h-4 mr-1 animate-spin'></div>
                )}
                {!areDevicesLoading && (
                  <Text.Body.Medium>{totalAssets} Assets</Text.Body.Medium>
                )}
              </div>
            </DynamicListItem>
            {!selectedDevice && <Outlet />}
            {selectedDevice && (
              <>
                {selectedDevice.type === 'PREFILL_MOWER_FORM_VALUE' && (
                  <GarageGlobalFleetMowerForm
                    device={selectedDevice.payload}
                    onClose={handleResetSelectedDevice}
                  />
                )}
                {selectedDevice.type === 'PREFILL_BATTERY_FORM_VALUE' && (
                  <GarageGlobalFleetBatteryForm
                    device={selectedDevice.payload}
                    onClose={handleResetSelectedDevice}
                  />
                )}
                {selectedDevice.type === 'PREFILL_TOOL_TAG_FORM_VALUE' && (
                  <GarageGlobalFleetToolTagForm
                    device={selectedDevice.payload}
                    onClose={handleResetSelectedDevice}
                  />
                )}
              </>
            )}
          </div>
        )}
      </SidebarContainer>

      <div className='garage-global-fleet-container flex flex-col flex-1 overflow-hidden'>
        <div className='p-3 flex flex-col items-start justify-between lg:flex-row lg:items-center gap-3'>
          <div className='flex gap-[20px]'>
            <DeviceCounterItem
              label='MOWERS'
              value={companyMowers.length || '0'}
              isActive={!isAllAssetsChecker && currentDevice === 'mower'}
              onClick={() => handleSetCurrentDevice('mower')}
            />
            <DeviceCounterItem
              label='BATTERIES'
              value={companyBatteries.length || '0'}
              isActive={!isAllAssetsChecker && currentDevice === 'battery'}
              onClick={() => handleSetCurrentDevice('battery')}
            />
            <DeviceCounterItem
              label='TOOLS & TAGS'
              value={companyToolTags.length || '0'}
              isActive={!isAllAssetsChecker && currentDevice === 'tool_tag'}
              onClick={() => handleSetCurrentDevice('tool_tag')}
            />
          </div>
          <div className='w-full lg:max-w-[300px]'>
            <TextField
              placeholder='Search'
              className='min-h-[38px] w-full border-black-500 dark:placeholder-white dark:text-white bg-black/50'
              onChange={e => setSearchText(e.target.value)}
              value={searchText}
            />
          </div>
        </div>

        <div className='flex-1 h-full overflow-x-auto'>
          {isAllAssetsChecker && (
            <AssetList
              isLoading={areDevicesLoading}
              searchText={searchText}
              items={[
                ...filteredMowers,
                ...filteredBatteries,
                ...filteredToolTags
              ]}
            />
          )}

          {!isAllAssetsChecker && currentDevice === 'mower' && (
            <GarageGlobalFleetDeviceList
              type='mower'
              items={filteredMowers}
              isLoading={areDevicesLoading}
              tableProps={{
                onRowClick: (data: Record<string, any>) =>
                  handleOnDeviceRowClick(data)
              }}
            />
          )}

          {!isAllAssetsChecker && currentDevice === 'battery' && (
            <GarageGlobalFleetDeviceList
              type='battery'
              isLoading={areDevicesLoading}
              items={filteredBatteries}
              tableProps={{
                onRowClick: (data: Record<string, any>) =>
                  handleOnDeviceRowClick(data)
              }}
            />
          )}
          {!isAllAssetsChecker && currentDevice === 'tool_tag' && (
            <GarageGlobalFleetDeviceList
              type='tool_tag'
              isLoading={areDevicesLoading}
              items={filteredToolTags}
              tableProps={{
                onRowClick: (data: Record<string, any>) =>
                  handleOnDeviceRowClick(data)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default GarageGlobalFleet
