import { useCallback, useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AppRootContext } from '../../../pages/AppRoot'
import { Divider } from '../../atoms'
import Analytics from './Analytics'
import BasicInformation from './BasicInformation'
import FaultHistorySection from './FaultHistorySection'
import OtherInformation from './OtherInformation'
import { t } from '@lingui/macro'
import SurepathPerformanceHistorySection from './SurepathPerformanceHistorySection'
import {
  EActiveOverlay,
  EActiveOverlayPosition
} from '../../../pages/AppRoot/reducers/types'
import { ActivityTimeline } from './ActivityTimeline'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import { useAppSelector } from '../../../store/hooks'
import { selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors'
import { IDevice } from '../../../models_v2/entity/device'
import { isAscentDevice } from '../../../utils/device'

interface MowerDetailProps {
  productSerial: string
}

const MowerDetail: React.FC<MowerDetailProps> = props => {
  const { productSerial } = props
  const { dispatch } = useContext(AppRootContext)
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const currentMower = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'mower',
      operatingCompany,
      productSerial
    })
  ) as IDevice
  const [searchParams, setSearchParams] = useSearchParams()
  const faultHistoryParam = searchParams.get('faultHistory')

  const handleRemoveOverlay = useCallback(() => {
    dispatch?.({
      type: 'SET_ACTIVE_OVERLAY',
      payload: { name: EActiveOverlay.NONE }
    })
  }, [])

  const handleOpenFaultHistoryTable = useCallback(() => {
    dispatch?.({
      type: 'SET_ACTIVE_OVERLAY',
      payload: { name: EActiveOverlay.FAULT_HISTORY_CARD }
    })
    searchParams.set('faultHistory', 'open')
    setSearchParams(searchParams, { replace: true })
  }, [searchParams, currentMower?.metadata])

  const handleOpenSurepathPerformanceTimeline = useCallback(() => {
    dispatch?.({
      type: 'SET_ACTIVE_OVERLAY',
      payload: {
        name: EActiveOverlay.SUREPATH_PERFORMANCE_CARD,
        position: EActiveOverlayPosition.BOTTOM
      }
    })
    searchParams.set('surepathPerformance', 'open')
    setSearchParams(searchParams, { replace: true })
  }, [searchParams, currentMower])

  const handleOpenActivityTimeline = useCallback(() => {
    dispatch?.({
      type: 'SET_ACTIVE_OVERLAY',
      payload: {
        name: EActiveOverlay.ACTIVITY_TIMELINE,
        position: EActiveOverlayPosition.BOTTOM
      }
    })
  }, [searchParams, currentMower])

  useEffect(
    () => () => {
      handleRemoveOverlay()
    },
    []
  )

  useEffect(
    () => () => {
      dispatch?.({
        type: 'SET_SELECTED_MOWER_ID',
        payload: ''
      })
    },
    []
  )

  useEffect(() => {
    if (faultHistoryParam === 'open') {
      handleOpenFaultHistoryTable()
    }
  }, [faultHistoryParam])

  useEffect(() => {
    const faultHistoryInitialOpen = searchParams.get('faultHistory')

    if (faultHistoryInitialOpen && !currentMower) {
      handleOpenFaultHistoryTable()
    }
  }, [currentMower])

  return (
    <>
      {!currentMower ? (
        <div className='h-full w-full flex justify-center items-center'>
          <p className='dark:text-primary'>{t`Loading...`}</p>
        </div>
      ) : (
        <div className='flex flex-col gap-3'>
          <BasicInformation mower={currentMower!} />
          <Divider className='ml-[-24px] mr-[-24px]' size='md' />
          {isAscentDevice(currentMower) ? (
            <FaultHistorySection
              mower={currentMower!}
              onFaultHistoryClick={handleOpenFaultHistoryTable}
            />
          ) : (
            <SurepathPerformanceHistorySection
              mower={currentMower ?? {}}
              onPerformanceHistoryClick={handleOpenSurepathPerformanceTimeline}
            />
          )}
          {isAscentDevice(currentMower) && (
            <div className='my-2'>
              <ActivityTimeline
                mower={currentMower}
                onClick={handleOpenActivityTimeline}
              />
            </div>
          )}
          <Divider className='ml-[-24px] mr-[-24px]' size='md' />
          <Analytics mower={currentMower!} />
          <Divider className='ml-[-24px] mr-[-24px]' size='md' />
          <OtherInformation mower={currentMower!} />
        </div>
      )}
    </>
  )
}

export default MowerDetail
