import React, { createContext, useReducer } from 'react'
import ReportingPage from './ReportingPage'
import { reducer, initialState } from './reducers'
import { ReportingPageProps, TReportingPageContext } from './types'

export const ReportingPageContext = createContext<TReportingPageContext>({})

const ReportingPageWrapper: React.FC<ReportingPageProps> = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ReportingPageContext.Provider
      value={{
        state,
        dispatch
      }}>
      <ReportingPage />
    </ReportingPageContext.Provider>
  )
}

export default ReportingPageWrapper
