const SettingsRoute = {
  settings_page: '/app/settings',
  'settings_page.wildcard': '/app/settings/*',
  'settings_page.user_administration': '/app/settings/user_administration',
  'settings_page.user_administration.people':
    '/app/settings/user_administration/people',
  'settings_page.user_administration.people.invite':
    '/app/settings/user_administration/people/invite',
  'settings_page.user_administration.active_users':
    '/app/settings/user_administration/active_users',
  'settings_page.user_administration.active_users.invite':
    '/app/settings/user_administration/active_users/invite',
  'settings_page.user_administration.companies':
    '/app/settings/user_administration/companies',
  'settings_page.user_administration.people.detail':
    '/app/settings/user_administration/people/:id',
  'settings_page.user_administration.people.detail.edit':
    '/app/settings/user_administration/people/:id/edit',
  'settings_page.user_administration.active_users.detail':
    '/app/settings/user_administration/active_users/:id',
  'settings_page.user_administration.active_users.detail.edit':
    '/app/settings/user_administration/active_users/:id/edit',
}

export default SettingsRoute
