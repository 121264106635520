import React, { Children } from 'react'
import cn from 'classnames'
import { IBaseTextProps } from '../types'

interface IComponent<
  P = IBaseTextProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLParagraphElement>,
      HTMLParagraphElement
    >
> extends React.FC<P> {
  H1: React.FC<P>
  H2: React.FC<P>
  H3: React.FC<P>
  H4: React.FC<P>
  H5: React.FC<P>
}

const Headline: IComponent = ({ children }) => {
  const subComponentList = Object.keys(Headline)

  const subComponents = subComponentList.map(key => {
    return Children.map(children, child =>
      (child as any)?.type?.name === key ? child : null
    )
  })

  return <>{subComponents.map(component => component)}</>
}

const H1: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-semibold text-[28px] leading-[34px] sm:text-[42px] sm:leading-[46px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
Headline.H1 = H1

const H2: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-semibold text-[24px] leading-[30px] sm:text-[34px] sm:leading-[38px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
Headline.H2 = H2

const H3: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-semibold text-[22px] leading-[28px] sm:text-[28px] sm:leading-[34px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
Headline.H3 = H3

const H4: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-semibold text-[18px] leading-[24px] sm:text-[22px] sm:leading-[28px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
Headline.H4 = H4

const H5: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <span
    className={cn(
      'font-semibold text-[18px] leading-[24px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </span>
)
Headline.H5 = H5

export default Headline
