import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'

import { messages as enMessages } from './locales/en/messages'
import { messages as enFaultCodes } from './locales/en/fault_codes'
import { messages as esMessages } from './locales/es/messages'
import { messages as esFaultCodes } from './locales/es/fault_codes'

i18n.load({
  en: { ...enMessages, ...enFaultCodes },
  es: { ...esMessages, ...esFaultCodes }
})

const language = localStorage.getItem('language') || 'en'
i18n.activate(language)

ReactDOM.render(
  <React.StrictMode>
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      <App />
    </I18nProvider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
)
