import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IMetadata } from '../../../models_v2/entity/metadata'

interface BackgroundTaskSliceState {
  /** Intended for fast lookup since user may trigger the fault many times */
  metadata_update_task: Record<string, IMetadata>
}

const initialState: BackgroundTaskSliceState = {
  metadata_update_task: {}
}

export const backgroundTaskSlice = createSlice({
  name: 'backgroundTask',
  initialState,
  reducers: {
    upsertMetadataUpdateTask: (state, action: PayloadAction<IMetadata>) => {
      state.metadata_update_task[action.payload.metadataId] = action.payload
    },
    removeMetadataUpdateTask: (
      state,
      action: PayloadAction<Pick<IMetadata, 'metadataId'>>
    ) => {
      const { [action.payload.metadataId]: _, ...restMetadataUpdateTask } =
        state.metadata_update_task
      state.metadata_update_task = restMetadataUpdateTask
    }
  }
})

export const { upsertMetadataUpdateTask, removeMetadataUpdateTask } =
  backgroundTaskSlice.actions

export const backgroundTaskSliceReducer = backgroundTaskSlice.reducer
export default backgroundTaskSlice.reducer
