import { IDevice } from "../models_v2/entity/device";
import { IGroup } from "../models_v2/entity/groups";


export const mapGroupNameToDevices = ( groups: IGroup[], devices: IDevice[] ) => {

  return devices.map((item: IDevice) => {
    const group = groups.find((group) => group.groupId === item.inventory.groupId)
    const groupName = group ? group?.groupName : "";

    return {...item, inventory: {...item.inventory, groupName}}
  })
}

//TODO: we’re adding companyName to devices for use inside the <table/> component
//That feels unnecessary we should have accessor logic inside the Table that looks for the needed fields
//Rethink this approach? Some tables will have a field accessor prop ie ‘item.inventory.companyId’ and then the table will look for it.
export const mapCompanyNameToDevices = ( companies: { id: string, label: string }[], devices: IDevice[] ) => {
  return devices.map((item) => {
    const company = companies.find((company) => company.id === item.inventory.companyId)
    const companyName = company ? company?.label : "";
    return {...item, inventory: {...item.inventory, companyName}}
  })
}