export class EventEmitter<T = string> {
  // @ts-ignore
  private events: Record<T, Function[]> = {}

  on(event: T, callback: Function) {
    if (!this.events[event]) {
      this.events[event] = [callback]
      return
    }

    this.events[event].push(callback)
  }

  off(event: T, callback: Function) {
    if (!this.events[event]) return

    const matchedIndex = this.events[event].indexOf(callback)
    this.events[event].splice(matchedIndex, 1)
  }

  emit(event: T, data?: any) {
    if (!this.events[event]) return

    this.events[event].forEach(callback => callback(data))
  }
}
