import cn from 'classnames'
import React, { useContext, useMemo } from 'react'
import { DateCellWrapperProps } from 'react-big-calendar'
import { isDateInRangeFromMonth } from '../../../utils/date'
import moment from 'moment'
import { ReportingPageContext } from '../../../pages/ReportingPage'
import { TReportingType } from '../../../pages/ReportingPage/reducers/types'

interface IDateCellWrapperProps extends DateCellWrapperProps {
  reportingType: TReportingType
  disabled?: boolean
}

const DateCellWrapper: React.FC<IDateCellWrapperProps> = props => {
  const { children, value, reportingType, disabled = false } = props
  const { state } = useContext(ReportingPageContext)

  const isDateToday = useMemo(() => moment().isSame(value, 'date'), [value])

  const isDateCellOffRangeFromCurrentMonth = useMemo(() => {
    return !isDateInRangeFromMonth(
      value,
      state?.[`${reportingType}_current_month`]!
    )
  }, [value, state?.[`${reportingType}_current_month`]])

  const isDateCellSelected = useMemo(() => {
    if (children.props.className.includes('rbc-selected-cell')) {
      return true
    }

    if (state?.[`${reportingType}_date_range`]) {
      const { start, end } = state?.[`${reportingType}_date_range`] ?? {}
      if (!end) {
        return moment(value).isSame(start, 'date')
      }

      return moment(value).isBetween(start, end, null, '[]')
    }

    return false
  }, [value, children.props.className, state?.[`${reportingType}_date_range`]])

  const isDateStartOrEnd = useMemo(() => {
    const { start, end } = state?.[`${reportingType}_date_range`] ?? {}

    const isSameDate =
      (start && moment(value).isSame(start, 'date')) ||
      (end && moment(value).isSame(end, 'date'))

    return isSameDate
  }, [value, children.props.className, state?.[`${reportingType}_date_range`]])

  return (
    <div
      className={cn(
        'w-full bg-black/30 border border-[#4A4A4A] rounded-md text-white m-[2.5px]',
        {
          'cursor-pointer': !disabled,
          invisible: isDateCellOffRangeFromCurrentMonth,
          'border-[#3D6FF9] border-2': isDateToday,
          '!border-primary': isDateCellSelected,
          '!bg-primary': isDateStartOrEnd
        }
      )}>
      {children}
    </div>
  )
}

export default DateCellWrapper
