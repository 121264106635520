import React from 'react'

interface ResizeHandleProps {}

const ResizeHandle: React.FC<ResizeHandleProps> = () => {
  return (
    <div className='flex justify-center h-full'>
      <div className='bg-white rounded-md h-[5px] w-6' />
    </div>
  )
}

export default ResizeHandle
