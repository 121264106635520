import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// Slice to pass sign up data between pages in sign up flow, mostly for 
// form prefills
interface SignupState {
  id: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const initialState: SignupState = {
  id: '',
  username: '',
  password: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
}

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    addSignupFields: (
      state,
      action: PayloadAction<Partial<SignupState>>
    ) => {
      for (const [key, value] of Object.entries(action?.payload)) {
        state[key as keyof SignupState] = value;
      }
    },
    resetSignupState: () => initialState
  }
})

export const { addSignupFields, resetSignupState } = signupSlice.actions
export const signupSliceRecuder = signupSlice.reducer
export default signupSlice.reducer