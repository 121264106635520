import React from 'react'
import cn from 'classnames'
import { Text, Image, StatusIndicator } from '../../atoms'
import { ASSETS } from '../../../assets'
import moment from 'moment'
import { useMemo } from 'react'
import { useListItemClickEvents } from '../../../hooks/data/useListItemClickEvents'
import { IDevice } from '../../../models_v2/entity/device'
import { isToolTagsOnline } from '../../../utils/toolsAndTags'
import NetworkStatusIndicator from '../../organisms/ToolTagDetail/NetworkStatusIndicator'

interface ToolTagListItemProps {
  toolTag: Partial<IDevice>
  isActive?: boolean
}

const ToolTagListItem: React.FC<ToolTagListItemProps> = props => {
  const { toolTag, isActive = false } = props
  const { name = '' } = toolTag?.inventory ?? {}
  const {
    timestamp: last_seen,
    payload,
    status = 'Unassigned'
  } = toolTag?.latestTelemetry ?? {}
  const { lastSeenMower: lastSeenBy = '' } = (payload ?? {}) as any
  const { onMapPinClick, onListItemClick } = useListItemClickEvents({
    deviceType: 'tool_tag',
    device: toolTag
  })

  const lastSeen = useMemo(() => {
    return moment(last_seen).format('hh:mm A MM/DD/YYYY')
  }, [last_seen])

  return (
    <div
      className={cn('text-black dark:text-white p-3 card cursor-pointer', {
        'outline outline-4 outline-offset-[-4px] outline-interaction-200':
          isActive
      })}
      onClick={onListItemClick}>
      <div className='flex justify-between items-center'>
        <div
          className={cn('flex items-center gap-2 cursor-pointer', {})}
          onClick={e => {
            onMapPinClick?.()
            e.stopPropagation()
          }}>
          <Text.Feature.Medium>{name}</Text.Feature.Medium>
          {/* <Image
            src={ASSETS.IMAGES.COMMON.target}
            className='w-3 h-3 invert-0 dark:invert'
          /> */}
        </div>
        <NetworkStatusIndicator isOnline={isToolTagsOnline(toolTag)} />
      </div>
      <div className='flex flex-row'>
        <div className='h-[102px] flex items-end'>
          <Image src={ASSETS.IMAGES.TOOL_TAG.tool_tag} />
        </div>

        <div className='ml-[18px] mt-[15px] flex flex-1 flex-col'>
          <div className='flex flex-col gap-3'>
            <div className='flex mt-2'>
              <div>
                <div className='flex justify-start gap-2'>
                  <Text.VariousRegular.Small>
                    Status:{' '}
                  </Text.VariousRegular.Small>
                  <Text.VariousBold.Small>
                    {status || 'Unassigned'}
                  </Text.VariousBold.Small>
                </div>

                {last_seen && (
                  <div className='flex justify-start gap-2'>
                    <Text.VariousRegular.Small>
                      Last seen:{' '}
                    </Text.VariousRegular.Small>
                    <Text.VariousBold.Small>{lastSeen}</Text.VariousBold.Small>
                  </div>
                )}

                {lastSeenBy && (
                  <div className='flex justify-start gap-2'>
                    <Text.VariousRegular.Small>
                      Last seen by:{' '}
                    </Text.VariousRegular.Small>
                    <Text.VariousBold.Small>
                      {lastSeenBy}
                    </Text.VariousBold.Small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToolTagListItem
