import React from 'react'
import { Text } from '../../../components/atoms'
import { ITeamMember } from '../../../utils/constants'

export interface TeamMemberItemProps {
  label: string
  members: ITeamMember[]
}

export const TeamMemberItem: React.FC<TeamMemberItemProps> = ({ label, members }) => {
  return (
    <div className='flex flex-col sm:flex-row'>
      <Text.Body.Medium className='min-w-[140px] text-center sm:text-left text-black-500'>
        {label}
      </Text.Body.Medium>

      <div className='flex flex-col w-full'>
        {members.map(item => (
          <div className='grid grid-cols-2 gap-4'>
            <Text.Body.MediumSemiBold className='text-right'>
              {item.name}
            </Text.Body.MediumSemiBold>
            <Text.Body.Medium className='text-primary'>
              {item.role}
            </Text.Body.Medium>
          </div>
        ))}
      </div>
    </div>
  )
}
