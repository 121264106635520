import React, { Children } from 'react'
import cn from 'classnames'
import { IBaseTextProps } from '../types'

interface IComponent<P = IBaseTextProps> extends React.FC<P> {
  H4: React.FC<P>
  Large: React.FC<P>
  Medium: React.FC<P>
  Small: React.FC<P>
}

const VariousBold: IComponent = ({ children }) => {
  const subComponentList = Object.keys(VariousBold)

  const subComponents = subComponentList.map(key => {
    return Children.map(children, child =>
      (child as any)?.type?.name === key ? child : null
    )
  })

  return <>{subComponents.map(component => component)}</>
}

const H4: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-bold text-[24px] leading-[28px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
VariousBold.H4 = H4

const Large: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-bold text-[36px] leading-normal normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
VariousBold.Large = Large

const Medium: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-bold text-[18px] leading-[24px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
VariousBold.Medium = Medium

const Small: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-bold text-[12px] leading-normal normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
VariousBold.Small = Small

export default VariousBold
