import { useCallback, useContext, useMemo } from 'react'
import { Resizable } from 're-resizable'
import { Button, Text, ResizeHandle } from '../../atoms'
import { useWindowDimensions } from '../../../hooks'
import { AppRootContext } from '../../../pages/AppRoot'
import { EActiveOverlay } from '../../../pages/AppRoot/reducers/types'
import FaultTable from './FaultTable'
import { IMetadata } from '../../../models_v2/entity/metadata'
import { useAppDispatch } from '../../../store/hooks'
import { upsertMetadataUpdateTask } from '../../../store/slices/backgroundTaskSlice'
import { IDevice } from '../../../models_v2/entity/device'

interface FaultHistoryCardProps {
  mower: IDevice
  onClose?: () => void
}

interface FaultCount {
  new: number
  active: number
}

const FaultHistoryCard: React.FC<FaultHistoryCardProps> = props => {
  const { dispatch } = useContext(AppRootContext)
  const reduxDispatch = useAppDispatch()
  const { mower, onClose } = props
  const { height: windowHeight } = useWindowDimensions()

  const cardContentMaxHeight = useMemo(
    () => windowHeight - windowHeight * 0.25,
    [windowHeight]
  )

  const onCloseClick = () => {
    dispatch?.({
      type: 'SET_ACTIVE_OVERLAY',
      payload: { name: EActiveOverlay.NONE }
    })
    onClose?.()
  }

  const faultsCount = useMemo<FaultCount>(() => {
    return (mower?.metadata ?? []).reduce(
      (acc: FaultCount, curr) => ({
        new: curr.state === 'New' ? acc.new + 1 : acc.new,
        active: curr.active ? acc.active + 1 : acc.active
      }),
      {
        new: 0,
        active: 0
      } as FaultCount
    )
  }, [mower?.metadata])

  const handleFaultsChange = useCallback(
    (updatedFault: IMetadata) => {
      reduxDispatch(upsertMetadataUpdateTask(updatedFault))
    },
    [mower?.productSerial]
  )

  const faults = useMemo<IMetadata[]>(() => {
    if (!mower?.metadata) return []

    const parsedPayload = ((mower?.metadata ?? []) as IMetadata[])
      ?.map(item => {
        try {
          const payload =
            typeof item?.payload === 'string'
              ? JSON.parse(item?.payload)
              : item?.payload

          if (!payload || !payload?.location) return null

          const { latitude, longitude } = payload?.location || {}

          if (!latitude || !longitude) return null

          return {
            ...item,
            payload: {
              ...payload,
              location: {
                longitude: +longitude,
                latitude: +latitude
              }
            }
          } as IMetadata
        } catch (e) {
          console.error(e, item)
          return null
        }
      })
      .filter(item => !!item)

    return parsedPayload as IMetadata[]
  }, [mower?.metadata])

  return (
    <Resizable
      enable={{
        right: true,
        bottom: true,
        bottomRight: true
      }}
      defaultSize={{
        width: '100%',
        height: 300
      }}
      minWidth={300}
      maxWidth='100%'
      minHeight={200}
      maxHeight={cardContentMaxHeight}
      handleComponent={{ bottom: <ResizeHandle /> }}
      className='bg-app-background bg-no-repeat bg-cover rounded-2xl border border-[#363636] overflow-hidden'>
      <div className='mx-6 my-2 flex gap-2 justify-between items-center'>
        <div className='flex gap-2 text-white'>
          <Text.Body.LargeSemiBold className='mr-2'>
            Fault History
          </Text.Body.LargeSemiBold>
          <div className='flex gap-2'>
            <Text.Body.LargeSemiBold className='text-error'>
              {`${faultsCount.active} Active`}
            </Text.Body.LargeSemiBold>
            <Text.Body.LargeSemiBold>/</Text.Body.LargeSemiBold>
          </div>
          <div className='flex gap-2'>
            <Text.Body.LargeSemiBold>
              {`${faultsCount.new} New`}
            </Text.Body.LargeSemiBold>
            <Text.Body.LargeSemiBold>/</Text.Body.LargeSemiBold>
          </div>
          <Text.Body.LargeSemiBold>
            {`${mower?.metadata?.length ?? 0} Total`}
          </Text.Body.LargeSemiBold>
        </div>
        <Button
          variant='outlined'
          size='sm'
          titleSize='sm'
          title='Close'
          className='bg-black/20'
          onClick={onCloseClick}
        />
      </div>
      <FaultTable
        mower={mower}
        faults={faults}
        onFaultsChange={handleFaultsChange}
      />
    </Resizable>
  )
}

export default FaultHistoryCard
