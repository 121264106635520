import React from 'react'
import cn from 'classnames'
import { IMower } from '../../../models'
import { MowerCommon } from '.'
import { Mower } from '../../../models_v2/common/mower'

interface SurepathInfoProps {
  className?: string
  mower: Partial<Mower>
  showGps?: boolean
  showCellCignal?: boolean
}

const SurepathInfo: React.FC<SurepathInfoProps> = props => {
  const { className, mower, showGps = true, showCellCignal = true } = props
  // const { gpsMode, rsrp, mower_status } = mower
  // const { gppFixMode } = gpsMode ?? {}

  return (
    <>
      {showGps && (
        <div className={cn('mt-2', className)}>
          {/* <MowerCommon.SurepathGps value={gppFixMode} /> */}
        </div>
      )}

      <MowerCommon.LastSignal
        className={cn({
          'mt-2': showGps
        })}
        mower={mower}
      />

      {showCellCignal && (
        <div className='flex justify-end mt-2'>
          {/* <MowerCommon.SurepathCellCignal
            value={rsrp}
            noSignal={mower_status === 'OFF'}
          /> */}
        </div>
      )}
    </>
  )
}

export default SurepathInfo
