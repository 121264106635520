const specialOrNumberCharacterRegex = /^(?=.*[\d$0%&])/
const repeatingCharactersRegex = /([a-zA-Z0-9])\1\1/i

const minimumPasswordLength = 12
const minimumPasswordMessage = `Please enter at least ${minimumPasswordLength} characters`
const requiredCurrentPasswordMessage = 'Please enter your current password'
const requiredNewPasswordMessage = 'Please enter your new password'
const requiredConfirmPasswordMessage = 'Please confirm your new password'

const validatePassword = (value: string) => {
  if (!specialOrNumberCharacterRegex.test(value)) {
    return 'Please enter at least 1 number or special character ($ 0 % &).'
  } else if (repeatingCharactersRegex.test(value)) {
    return 'Please enter password that has no 3 or more repeated characters.'
  }
}

export const Password = {
  validatePassword,
  minimumPasswordLength,
  messages: {
    minimumPassword: minimumPasswordMessage,
    requiredCurrentPassword: requiredCurrentPasswordMessage,
    requiredNewPassword: requiredNewPasswordMessage,
    requiredConfirmPassword: requiredConfirmPasswordMessage
  }
}
