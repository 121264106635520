import React, { Fragment, useMemo } from 'react'
import cn from 'classnames'
import { Listbox, Transition } from '@headlessui/react'
import Icon from '../Icon'
import { Text } from '../'

export interface ISelectOption<T> {
  label: string
  value: T
  disabled?: boolean
}

export interface SelectProps<T = any>
  extends Pick<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    'onFocus'
  > {
  options: ISelectOption<T>[]
  value?: T | null
  onChange?: (value: T) => void
  className?: string
  placeholder?: string
  disabled?: boolean
  dense?: boolean
}

function Select<T>(props: SelectProps<T>) {
  const {
    options = [],
    value,
    onChange,
    className,
    placeholder,
    disabled = false,
    dense = false,
    onFocus
  } = props
 
  const selectedOption = useMemo(() => {
    return options.find(item => item.value === value)
  }, [options, value])

  return (
    <div
      className={cn('min-w-[200px] text-white', className)}
      onFocus={onFocus}>
      <Listbox value={value} onChange={onChange} disabled={disabled}>
        {({ open }) => (
          <div className='relative'>
            <Listbox.Button
              className={cn(
                'min-h-[48px] relative w-full cursor-default px-3 py-[5px] bg-black-600 focus:outline-none border border-white rounded disabled:opacity-70 transition-all',
                {
                  'min-h-[40px]': dense,
                  '!bg-primary': open
                }
              )}>
              <Text.Body.Large className='block truncate text-left pr-3'>
                {selectedOption?.label ? selectedOption?.label : placeholder}
              </Text.Body.Large>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <Icon name='caret_down' />
              </span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              enter='transition-opacity duration-75'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity duration-150'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Listbox.Options className='absolute z-10 max-h-[200px] w-full overflow-auto focus:outline-none'>
                {options.map((item, index) => (
                  <Listbox.Option key={index} value={item.value} as={Fragment}>
                    {({ active }) => (
                      <li
                        className={cn(
                          'px-3 py-2 bg-black-500 focus:outline-none cursor-default',
                          {
                            '!bg-primary': active
                          }
                        )}>
                        {item.label}
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  )
}

export default Select
