import { 
  useContext, 
  useState} from "react"
import { 
  useNavigate } from "react-router-dom"
import { AppContext } from "../../../App"
import { Routes } from "../../../routes"


export const useOperatingCompany = () =>{
  const navigate = useNavigate()
  const { handleLogout } = useContext(AppContext)
  const [ isActive, setIsActive ] = useState(false)

  const handleAppLogout = async() =>{
    const didLogout = await handleLogout?.()
    
      if (!didLogout) {
        console.error('Logout failed.')
        return
      }

      navigate(Routes.login_page)
  }

  return {
    navigate,
    handleAppLogout,
    isActive,
    setIsActive: () => setIsActive(prev => !prev)
  }
}