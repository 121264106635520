import React from 'react'
import cn from 'classnames'
import { IToolTag } from '../../../../models'
import { Text, Image } from '../../../atoms'
import { ASSETS } from '../../../../assets'
import moment from 'moment'
import { useMemo } from 'react'
import { getDeviceByProductSerial } from '../../../../models/utils'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'

interface ToolTagListItemProps {
  tool_tag: Partial<IToolTag>
  isActive?: boolean
  onClick?: () => void
  onPreSelect?: () => void
}

const AssetToolTagListItem: React.FC<ToolTagListItemProps> = props => {
  const { tool_tag, isActive = false, onClick, onPreSelect } = props
  const { name = '', last_seen, last_seen_by, status } = tool_tag ?? {}

  const devices = useSelector((state: RootState) => state.device.devices)

  const lastSeen = useMemo(() => {
    return moment(last_seen).format('hh:mm A MM/DD/YYYY')
  }, [last_seen])

  const lastSeenBy = useMemo(() => {
    const result = getDeviceByProductSerial(devices, {
        productSerial: last_seen_by
    })

    return result?.name || result?.sourceId
  }, [devices, last_seen_by])

  return (
    <div
      className={cn('text-black dark:text-white p-3 card', {
        'cursor-pointer': !!onClick,
        'outline outline-4 outline-offset-[-4px] outline-interaction-200':
          isActive
      })}
      onClick={onClick}>
      <div className='flex justify-between items-center'>
        <div
          className={cn('flex items-center gap-2', {
            'cursor-pointer': !!onPreSelect
          })}
          onClick={e => {
            onPreSelect?.()
            e.stopPropagation()
          }}>
          <Text.Feature.Medium>{name}</Text.Feature.Medium>
          <Image
            src={ASSETS.IMAGES.COMMON.target}
            className='w-3 h-3 invert-0 dark:invert'
          />
        </div>
      </div>
      <div className='flex flex-row'>
        <div className='h-[102px] flex items-end'>
          <Image src={ASSETS.IMAGES.TOOL_TAG.tool_tag} />
        </div>

        <div className='ml-[18px] mt-[15px] flex flex-1 flex-col'>
          <div className='flex flex-col gap-3'>
            <div className='flex mt-2'>
              <div>
                {status && (
                  <div className='flex justify-start gap-2'>
                    <Text.VariousRegular.Small>
                      Status:{' '}
                    </Text.VariousRegular.Small>
                    <Text.VariousBold.Small>{status}</Text.VariousBold.Small>
                  </div>
                )}

                {last_seen && (
                  <div className='flex justify-start gap-2'>
                    <Text.VariousRegular.Small>
                      Last seen:{' '}
                    </Text.VariousRegular.Small>
                    <Text.VariousBold.Small>{lastSeen}</Text.VariousBold.Small>
                  </div>
                )}

                {last_seen_by && (
                  <div className='flex justify-start gap-2'>
                    <Text.VariousRegular.Small>
                      Last seen by:{' '}
                    </Text.VariousRegular.Small>
                    <Text.VariousBold.Small>
                      {lastSeenBy}
                    </Text.VariousBold.Small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssetToolTagListItem
