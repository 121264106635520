import { useState } from 'react';
import { BatteryIconListProps } from './types';
import { 
  Popover, 
  Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import BatteryIconListPanel from '../BatteryIconListPanel/BatteryIconListPanel';
import BatteryIconList from '../BatteryIconList/BatteryIconList';
import {  IMower } from '../../../models';
import { IDevice } from '../../../models_v2/entity/device';
import { getMowerBatteriesFromList } from '../../../utils/mower';
import { useAppSelector } from '../../../store/hooks';
import { selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors';
import { selectOperatingCompany } from '../../../store/slices/operatingCompany';


export const BatteryIconListAndPanel = (props: BatteryIconListProps) => {
  const { className, mower } = props
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState<any>()
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const companyBatteries = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: "battery", 
      operatingCompany
    })) as IDevice[]
  
    //TODO: move this filtering to selector logic
  const filteredBatteries = getMowerBatteriesFromList(mower as IMower, companyBatteries)
  const { styles, attributes } = usePopper(referenceElement, undefined, {
    placement: 'right-end',
    modifiers: [
      {
        name: 'offset',
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
    ]
  })

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            key={popoverOpen.toString()}
            ref={setReferenceElement}
            className='focus:outline-none flex items-center'
            onClick={() => {
              setPopoverOpen(!open)
            }}>
            <BatteryIconList 
              batteries={filteredBatteries} 
              mower={mower} 
              className={className} />
          </Popover.Button>
          <div
            onClick={e => {
              e.stopPropagation()
            }}>
            <Popover.Panel
              static
              style={styles.popper}
              {...attributes.popper}
              className='z-10 focus:outline-none  !fixed !transform-none !left-[300px] sm:!left-[350px] lg:!left-[400px] !top-[120px]'>
              {({ close }) => (
                <Transition
                  show={open}
                  enter='transition ease-in-out duration-200 transform'
                  enterFrom='opacity-0 -translate-x-2'
                  enterTo='opacity-100 translate-x-0'
                  leave='transition ease-in-out duration-200 transform'
                  leaveFrom='opacity-100 translate-x-0'
                  leaveTo='opacity-0 -translate-x-2'>
                  <BatteryIconListPanel 
                    batteries={filteredBatteries}  
                    mower={mower} 
                    onClose={close}
                    withOnClick
                  />
                </Transition>
              )}
            </Popover.Panel>
          </div>
        </>
      )}
    </Popover>
  );
}

export default BatteryIconListAndPanel;