import React, { useCallback, useContext, useMemo } from 'react'
import cn from 'classnames'
import { Button, Select, Text, TextField } from '../../atoms'
import { ReportingPageContext } from '../../../pages/ReportingPage'
import moment from 'moment'

interface ReportingScheduleFormProps {
  className?: string
}

const ReportingScheduleForm: React.FC<ReportingScheduleFormProps> = props => {
  const { className } = props

  const { state, dispatch } = useContext(ReportingPageContext)

  const handleToggleSelectItems = useCallback(() => {
    dispatch?.({
      type: 'SET_DATE_RANGE_SELECTION',
      payload: {
        type: 'schedule',
        active: false
      }
    })

    dispatch?.({
      type: 'SET_ASSET_SELECTION',
      payload: {
        type: 'schedule',
        active: !state?.asset_selection?.schedule
      }
    })
  }, [state?.asset_selection?.schedule])

  const handleToggleSelectDateRange = useCallback(() => {
    dispatch?.({
      type: 'SET_ASSET_SELECTION',
      payload: {
        type: 'schedule',
        active: false
      }
    })

    dispatch?.({
      type: 'SET_DATE_RANGE_SELECTION',
      payload: {
        type: 'schedule',
        active: !state?.date_range_selection?.schedule
      }
    })
  }, [state?.date_range_selection?.schedule])

  const assetsSelectedCount = useMemo(() => {
    return Object.keys(state?.schedule_assets_selected ?? {}).length
  }, [state?.schedule_assets_selected])

  const reportDateRange = useMemo(() => {
    const { start, end } = state?.schedule_date_range ?? {}
    if (!start) return ''

    const startDateText = moment(start).format('MM/DD/YY')
    if (!end || moment(start).isSame(end, 'date')) return startDateText

    const endDateText = moment(end).format('MM/DD/YY')
    return `${startDateText} - ${endDateText}`
  }, [state?.schedule_date_range])

  const handleReportFrequencyChange = useCallback(
    (value: string) => {
      let report_name = state?.schedule_form?.report_name || value

      dispatch?.({
        type: 'SET_SCHEDULE_FORM_INPUT',
        payload: {
          report_frequency: value,
          report_name
        }
      })
    },
    [state?.schedule_form?.report_name]
  )

  const handleReportNameChange = useCallback((value: string) => {
    dispatch?.({
      type: 'SET_SCHEDULE_FORM_INPUT',
      payload: {
        report_name: value
      }
    })
  }, [])

  const handleClearFields = useCallback(() => {
    dispatch?.({
      type: 'SET_ASSET_SELECTION',
      payload: {
        type: 'schedule',
        active: false
      }
    })

    dispatch?.({
      type: 'SET_DATE_RANGE_SELECTION',
      payload: {
        type: 'schedule',
        active: false
      }
    })

    dispatch?.({
      type: 'SET_DATE_RANGE',
      payload: {
        type: 'schedule',
        value: null
      }
    })

    dispatch?.({
      type: 'SET_ASSETS',
      payload: {
        type: 'schedule',
        ids: []
      }
    })

    dispatch?.({
      type: 'SET_SCHEDULE_FORM_INPUT',
      payload: {
        report_frequency: '',
        report_name: ''
      }
    })
  }, [])

  const clearFormElementActiveState = useCallback(() => {
    dispatch?.({
      type: 'SET_ASSET_SELECTION',
      payload: {
        type: 'schedule',
        active: false
      }
    })

    dispatch?.({
      type: 'SET_DATE_RANGE_SELECTION',
      payload: {
        type: 'schedule',
        active: false
      }
    })
  }, [])

  const handleScheduleReportSubmit = useCallback(() => {
    clearFormElementActiveState()
    // TODO: implementation
  }, [])

  return (
    <>
      <Text.VariousRegular.Large className='font-bold text-white'>
        Report Planning
      </Text.VariousRegular.Large>

      <div className={cn('text-white mt-8', className)}>
        <div className='flex flex-col gap-6'>
          <div>
            <Text.Body.Medium
              className={cn('mb-2', {
                'text-primary italic': assetsSelectedCount
              })}>
              {assetsSelectedCount
                ? `${assetsSelectedCount} Assets Selected`
                : 'Choose Assets'}
            </Text.Body.Medium>

            <div className='flex flex-col gap-5'>
              <Button
                className={cn({
                  '!bg-black-500 hover:!bg-black-700':
                    !state?.asset_selection?.schedule,
                  '!bg-primary hover:!bg-primary/80':
                    !!state?.asset_selection?.schedule
                })}
                color='secondary'
                titleSize='sm'
                titleClassname='font-semibold'
                title='Select Assets'
                onClick={handleToggleSelectItems}
              />

              <div className='flex flex-col'>
                {reportDateRange && (
                  <Text.Body.Medium
                    className={cn('mb-2', {
                      'text-primary italic': reportDateRange
                    })}>
                    {reportDateRange ? `Report Dates: ${reportDateRange}` : ''}
                  </Text.Body.Medium>
                )}

                <Button
                  className={cn({
                    '!bg-black-500 hover:!bg-black-700':
                      !state?.date_range_selection?.schedule,
                    '!bg-primary hover:!bg-primary/80':
                      !!state?.date_range_selection?.schedule
                  })}
                  color='secondary'
                  titleSize='sm'
                  titleClassname='font-semibold'
                  title='Select Dates'
                  onClick={handleToggleSelectDateRange}
                />
              </div>
            </div>
          </div>

          <div>
            <Text.Body.Medium className='mb-2'>
              Report Frequency
            </Text.Body.Medium>
            <Select
              dense
              placeholder='Select'
              options={[
                {
                  label: 'Monthly',
                  value: 'monthly'
                }
              ]}
              onChange={handleReportFrequencyChange}
              onFocus={clearFormElementActiveState}
              value={state?.schedule_form?.report_frequency}
            />
          </div>

          <div>
            <Text.Body.MediumSemiBold className='mb-2'>
              Report Name
            </Text.Body.MediumSemiBold>
            <TextField
              type='text'
              dense
              className='focus:outline-none focus:border-solid focus:border-white !text-primary w-full'
              onFocus={clearFormElementActiveState}
              onChange={e => handleReportNameChange(e.target.value)}
              value={`${state?.schedule_form?.report_name ?? ''}`}
            />
          </div>

          <div className='flex flex-col gap-4'>
            <Button
              className='w-full bg-primary hover:bg-primary/80'
              titleSize='sm'
              titleClassname='font-semibold'
              title='Clear Fields'
              onClick={handleClearFields}
            />
            <Button
              className='w-full'
              showOutline={false}
              color='primary'
              titleSize='sm'
              titleClassname='font-semibold'
              title='Schedule Report'
              onClick={handleScheduleReportSubmit}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportingScheduleForm
