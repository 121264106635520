import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ICompany } from '../../models'
import { IDevice } from '../../models_v2/entity/device'

export type OperatingCompany = {
  companyId: string,
  name: string
}
type InitialState = OperatingCompany

const initialState: InitialState = {
  companyId: "",
  name: ""
}

export const operatingCompanySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCurrentOperatingCompany: (state, action: PayloadAction<any>) => {
      state.companyId = action.payload.companyId;
      state.name = action.payload.name;
    },
    resetOperatingCompanyState: () => initialState
  },
});

export const { setCurrentOperatingCompany, resetOperatingCompanyState } = operatingCompanySlice.actions
export const selectOperatingCompany = (state: RootState) => state.operatingCompany
export const operatingCompanyReducer = operatingCompanySlice.reducer;