import { IDeviceFault } from './entity'
import { ILocation } from './location'

export type TDevice = 'mower' | 'battery' | 'tool_tag'
export enum EDeviceTypes {
  Mower = 'mower',
  Battery = 'battery',
  ToolTag = 'tool_tag',
}

export enum EMowersDeviceTypes {
  ascent = 11, // (Ascent mowers)
  surepath = 50, // (Surepath mowers)
  batteries = 500 // (Batteries)
}

export enum EDeviceMowerStatus {
  Unknown = -1,
  Off = 0,
  Idle = 1,
  Driving = 2,
  Mowing = 3, // Manual mowing for SurePath
  Charging = 4,
  MowingSurePathReady = 5, // SurePath only
  MowingSurePathEnabled = 6 // SurePath only
}

export interface ISoftware {
  "APP Firmware": string;
  "EPS Firmware": string;
  "GPM Software": string
  "IMU/FSM300 Firmware": string
  "Janus Firmware": string
  "Linux Kernel": string
  "PCB_ID": string
  "STM Firmware": string
  "Ublox Firmware": string
}

export interface IStatusTotal {
  last7Days: IStatusTotalItem[]
  last24Hours: IStatusTotalItem[]
  last30Days: IStatusTotalItem[]
}

export interface IStatusTotalItem {
  mowerStatus: '0' | '1' | '2' | '3' | '4' | '5' | '6'
  total: string
}

// !NOTE: This are actually string values from the API but parsed as JSON will look like this schema
// The Backend team is supporting different schema values both IStandardValueWrapper and ILocation for example
export interface IStandardValueWrapper<T = any> {
  value: T | undefined
  timestamp: string | undefined
}

export interface IDeviceOperationState {
  autoReady: number
  autoVcp: number
  engine: number
  keyPulled: number
  motion: number
  navigationReady: number
  operationMcp: number
  overlapSwitch: number
  ptoSwitch: number
  sicActiveCount: number
}

export interface IDeviceGpsMode {
  gppFixMode: number
  mcpGpsMode: number
  ntripState: number
}

export interface IDeviceRtkFixTime {
  fixType: string
  latitude: number
  longitude: number
  rtkService: string
}

export interface IMowerHour {
  deckOnSeconds: number
  operationalSeconds: number
  poweredOnSeconds: number
  runTimeHours: number
  autoReadyTimeHours?: number
  autoTimeHours?: number
  inMotionTimeHours?: number
  keyOnTimeHours?: number
}

export interface IDeviceTimelineItem {
  time: string
  mowerStatus: EDeviceMowerStatus
}

export interface Device {
  bleBeacon: IStandardValueWrapper<string>
  // Unused for now
  sKey?: string
  BLEDeviceType: IStandardValueWrapper<string> // Device type for tool tags

  location: IStandardValueWrapper<ILocation> // stringified value for the object {\"latitude\":42.269452,\"longitude\":-71.257412,\"altitude\":72.3}
  assignment: string
  productSerial: string
  mowerStatus: IStandardValueWrapper<EDeviceMowerStatus>
  networkStatus: IStandardValueWrapper<string>
  source: string | 'ngbr' | 'surepath'
  sourceId: string
  timestamp: string // unix time as string "1663071748.012"
  deviceType: number
  faults: IStandardValueWrapper<IDeviceFault[]>
  activeFaults: IStandardValueWrapper<IDeviceFault[]>
  statusTotals: IStatusTotal

  // Determine which of the smiley icons to highlight in Tool Tag details
  //  "" = do not highlight any icon
  // "00" = Excellent
  // "01" = Good
  // "10" = Fair
  // "11" = Poor
  coinHealth: IStandardValueWrapper<'' | '00' | '01' | '10' | '11'>
  rssi: IStandardValueWrapper<number>
  rsrq: IStandardValueWrapper<number>
  rsrp: IStandardValueWrapper<number>

  name: string // sourceId?
  model: string // model name? model number?
  factoryModel: string // factory model number
  dealer: string // registering dealer
  softwareVersion: string
  lastSeenMower: IStandardValueWrapper<string>
  mowerHours: IStandardValueWrapper<IMowerHour>

  // Surepath mower fields
  operationState: IStandardValueWrapper<IDeviceOperationState>
  gpsMode: IStandardValueWrapper<IDeviceGpsMode>
  egps?: string
  rtkFixTime: IStandardValueWrapper<IDeviceRtkFixTime>
  connectionEvent?: string
  reverseSSH?: string
  // This is an actual string that isn't a valid JSON
  timeLine?: IDeviceTimelineItem[]
  software: IStandardValueWrapper<ISoftware>
}
