import React, { PropsWithChildren, memo } from 'react'
import cn from 'classnames'
import { Row } from 'react-table'
import { useTableContext } from '../useTableContext'
import { DynamicListItem } from '../../../molecules'
import { useAppTheme } from '../../../../hooks'

interface RowRendererProps extends PropsWithChildren<any> {
  row: Row<object>
}

const BaseRowRenderer: React.FC<RowRendererProps> = props => {
  const { row, children, ...restProps } = props

  const { theme } = useAppTheme()
  const { tableProps } = useTableContext()
  const { onRowClick, rowClassName } = tableProps

  return (
    <DynamicListItem
      {...restProps}
      className={cn(
        'px-0 mb-[10px]',
        {
          '!bg-white': theme === 'light',
          'cursor-pointer': !!onRowClick
        },
        typeof rowClassName === 'function'
          ? rowClassName?.(row.original)
          : rowClassName
      )}
      onClick={() => onRowClick?.(row.original)}>
      {children}
    </DynamicListItem>
  )
}

export const RowRenderer = memo(BaseRowRenderer)
