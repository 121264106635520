import React, { useContext, useEffect } from 'react'
import { useBattery } from '../../../hooks'
import { Divider } from '../../atoms'
import Analytics from './Analytics'
import BasicInformation from './BasicInformation'
import OtherInformation from './OtherInformation'
import { t } from '@lingui/macro'
import { AppRootContext } from '../../../pages/AppRoot'
import DtcInformation from './DtcInformation'

interface BatteryDetailProps {
  id: string
  source: string
}

const BatteryDetail: React.FC<BatteryDetailProps> = props => {
  const { id, source } = props
  const { emitter, state } = useContext(AppRootContext)
  const [battery, isLoading] = useBattery({ id, source })
  const foundBattery = state?.batteries?.find(bat => bat.productSerial===id) as any
  
  useEffect(() => {
    if (!isLoading && battery) {
      setTimeout(() => {
        emitter?.emit('MAP:tracking', {
          type: 'FIT_BOUNDS_SELECTED',
          payload: [battery]
        })
      })
    }
  }, [battery, isLoading])
  
  return (
    <>
      {isLoading ? (
        <div className='h-full w-full flex justify-center items-center'>
          <p className='dark:text-primary'>{t`Loading...`}</p>
        </div>
      ) : (
        <div className='flex flex-col gap-3'>
          <BasicInformation battery={foundBattery} />
          <Divider className='ml-[-24px] mr-[-24px]' size='md' />
          {/* Condition to show if DTC */}
          {/* <DtcInformation battery={foundBattery} /> */}
          {/* <Divider className='ml-[-24px] mr-[-24px]' size='md' /> */}
          <Analytics battery={foundBattery} />
          <Divider className='ml-[-24px] mr-[-24px]' size='md' />
          <OtherInformation battery={foundBattery} />
        </div>
      )}
    </>
  )
}

export default BatteryDetail
