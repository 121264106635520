import React from 'react'
import cn from 'classnames'
import {Divider, Text } from '../../atoms'
import { Trans } from '@lingui/macro'
import { useAppSelector } from '../../../store/hooks'
import { selectedTrackingGroup } from '../../../store/slices/trackingGroupSlice/selectors'
import { IDevice } from '../../../models_v2/entity/device'
import AssetListItem from '../../molecules/AssetListItem'

interface GroupTagDetailProps {
  id: string
  source: string
}

const GroupTagDetail: React.FC<GroupTagDetailProps> = () => {
  const group = useAppSelector(selectedTrackingGroup)

  return (
    <>
      <div className={cn('text-black dark:text-white card')}>
        <div className='flex flex-row gap-3 mb-2'>
          <div className={cn('flex flex-1 flex-col gap-3')}>
            <div className={cn('flex items-center gap-2 cursor-pointer')}>
              <Text.Feature.Medium>{group.groupName}</Text.Feature.Medium>
            </div>
          </div>
          <div className={cn('flex flex-1 flex-col gap-3')}>
            <div className={cn('flex items-center gap-2')}>
              <Text.VariousRegular.Small>
                <Trans>Assets :</Trans>
              </Text.VariousRegular.Small>
              <Text.VariousBold.Small>
                {group.assetCount}
              </Text.VariousBold.Small>
            </div>
            <div className={cn('flex items-center gap-2')}>
              <Text.VariousRegular.Small>
                <Trans>Notifications :</Trans>
              </Text.VariousRegular.Small>
              <Text.VariousBold.Small>
                {group.notifications}
              </Text.VariousBold.Small>
            </div>
          </div>
        </div>
        <Divider size="lg" />
        { group?.assets?.map((asset: IDevice, index) => {
          return (
            <div className={cn('mt-2')} key={`group-detail-asset-${ asset.productSerial }-${ index }`}>
              <AssetListItem asset={asset} model={"mower"} />
            </div>
          )
        })} 
      </div>
    </>
  )
}

export default GroupTagDetail
