import { useEffect } from "react"
import { ICompany } from "../models"
import { useApolloQuery } from "./useApolloQuery"
import { 
  useAppDispatch, 
  useAppSelector } from "../store/hooks"
import { setCompanies } from "../store/slices/organizationCompanySlice"


export const useCompanies = () => {
  const dispatch = useAppDispatch()
  const result = useApolloQuery<ICompany[]>({ query: 'GET_COMPANIES', forceReAuth: true })
  const companies = useAppSelector(state => state.organizationCompany.companies)
  
  useEffect(() => {
    if (result.isFetched && !companies?.length) {
      dispatch(setCompanies(result.data ?? []))
    }
  }, [result.isFetched])

  return result
}
