import { IBattery } from '../models'
import useDynamicQuery from './useDynamicQuery'

export const useBattery = ({
  id,
  source
}: {
  id: string
  source: string
}): [IBattery, boolean] => {
  const { data, isLoading, isFetching } = useDynamicQuery({
    queryKey: 'GET_DEVICE_BY_ID',
    mapperKey: 'device_to_battery_data',
    body: {
      id,
      source
    },
    instance: id
  })

  return [data, isLoading || isFetching]
}
