import React from 'react'
import { HeaderGroup } from 'react-table'
import { Icon } from '../../../atoms'

interface ColumnSorterProps extends HeaderGroup<object> {}

export const ColumnSorter: React.FC<ColumnSorterProps> = ({
  isSorted,
  isSortedDesc
}) => {
  // This is important since only one column will be able to sort at once
  if (!isSorted) return null

  if (isSortedDesc) {
    return <Icon name='caret_down' />
  }

  return <Icon name='caret_down' className='rotate-180' />
}
