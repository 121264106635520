import { IDevice } from '../../../models_v2/entity/device'
import { RootState } from '../../store'
import { DeviceType } from './deviceSlice'
import { OperatingCompany } from '../operatingCompany'
import { createSelector } from '@reduxjs/toolkit'
import {
  isBatteryDevice,
  isMowerDevice,
  hasLocation,
  isToolsAndTagsDevice
} from '../../../utils/device'
import { DefaultCompanyName } from '../../../utils/constants'

export const selectDevices = (state: RootState) => state.deviceV2.devices

export type DeviceFilters = {
  productSerial?: IDevice['productSerial']
  productSerials?: IDevice['productSerial'][]
  deviceType?: DeviceType
  operatingCompany?: OperatingCompany
  name?: string
  groupId?: IDevice['inventory']['groupId']
  withLocationData?: boolean
  withoutCompanyAssigned?: boolean
}

export const selectFilteredDevices = <TDevice>(filters: DeviceFilters) =>
  createSelector(
    [
      (state: RootState) => state.deviceV2,
      (state: RootState) => state.organizationCompany.companies
    ],
    (deviceV2, companies) => {
      const {
        productSerial,
        productSerials,
        deviceType,
        operatingCompany,
        name,
        groupId,
        withLocationData,
        withoutCompanyAssigned
      } = filters ?? {}
      const [defaultCompany = {}] =
        companies?.filter(
          item => item?.inventory?.name === DefaultCompanyName
        ) || []

      // return ALL
      if (!filters) {
        return deviceV2.devices as TDevice[]
        // return ONE
      } else if (productSerial) {
        // use "find" here since we're only looking for 1 exact match
        return deviceV2.devices.find(
          device =>
            // full match
            device.productSerial === productSerial ||
            // match id prefix ie 12341234_Pack1
            device.productSerial.split('_')?.[0] === productSerial
        ) as TDevice
        // return FiLTERED
      } else {
        // This will filters by all optional filters
        return deviceV2.devices.filter(device => {
          // mower is a match until proven false. We have to do this way to ensure it passes
          // all optional filters before returning it
          if (operatingCompany) {
            if (
              operatingCompany.name !== 'Global' &&
              device.inventory.companyId !== operatingCompany.companyId
            ) {
              return false
            }
          }

          if (deviceType) {
            const isMower = isMowerDevice(device)
            const isBattery = isBatteryDevice(device)
            const isToolsAndTags = isToolsAndTagsDevice(device)

            if (deviceType === 'mower' && !isMower) {
              return false
            } else if (deviceType === 'battery' && !isBattery) {
              return false
            } else if (deviceType === 'tools-and-tags' && !isToolsAndTags) {
              return false
            }
          }

          if (name) {
            if (!device?.inventory?.name?.toLowerCase().includes(name)) {
              return false
            }
          }

          if (productSerials && productSerials.length) {
            const idPrefix = device.productSerial.split('_')?.[0]
            if (
              !productSerials.includes(device.productSerial) &&
              !productSerials.includes(idPrefix)
            ) {
              return false
            }
          }

          if (groupId) {
            if (device.inventory.groupId !== groupId) {
              return false
            }
          }

          if (withLocationData) {
            if (!hasLocation(device)) {
              return false
            }
          }

          if (
            withoutCompanyAssigned &&
            device.inventory.companyId !== defaultCompany?.companyId
          ) {
            return false
          }

          return true
        }) as TDevice[]
      }
    }
  )

// export const selectToolTags = (state: RootState) => state.deviceV2.devices
export const selectToolTags = (state: RootState) => [] as any

export const selectDevice = (state: RootState) => state.deviceV2?.device
