import { Transition } from '@headlessui/react'
import cn from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'

import { ASSETS } from '../../../assets'
import { Image, Text } from '../../../components/atoms'
import { LoginCardContainer } from '../../../components/molecules'
import { ProductionTeam as productionTeam } from '../../../utils/constants'
import { CompanyAboutLeftbar } from '../CompanyAboutLeftbar'
import { CompanySubNavigationBar } from '../CompanySubNavigationBar'
import SettingsTemplate from '../../templates/SettingsTemplate'
import {ITeamMember} from '../../../utils/constants';
import { TeamMemberItem } from './'

interface SettingsCreditPageProps {}

export const SettingsCreditPage: React.FC<SettingsCreditPageProps> = () => {
  const animationDuration = 30000
  const [showAnimation, setAnimation] = useState(false)
  const [isAnimationDone, setIsAnimationDone] = useState(false)

  useEffect(() => {
    setAnimation(true)

    setTimeout(() => {
      setIsAnimationDone(true)
    }, animationDuration)
  }, [])

  const renderTransition = useCallback(
    (children: React.ReactNode) => {
      if (!isAnimationDone) {
        return (
          <Transition
            key='slide-up'
            appear
            show={showAnimation}
            enter='transition ease-linear transform'
            style={{
              transitionDuration: `${animationDuration}ms`
            }}
            enterFrom='translate-y-[60%]'
            enterTo='translate-y-[-110%]'>
            {children}
          </Transition>
        )
      }

      return (
        <Transition
          key='fade'
          appear
          show
          enter='transition-opacity duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'>
          {children}
        </Transition>
      )
    },
    [isAnimationDone, showAnimation, animationDuration]
  )

  return (
    <SettingsTemplate
      subNavigation={(
        <CompanySubNavigationBar />
      )}
      leftContent={<CompanyAboutLeftbar />}
      topRightContent={(
        <div className='h-full relative flex items-center justify-center bg-black-600 bg-app-background bg-no-repeat bg-cover'>
        <div className='absolute bottom-0 right-0 overflow-hidden h-full flex items-end'>
          <Image
            src={ASSETS.IMAGES.MOWER.mower_front_left_large_standing}
            className='h-[50%] sm:h-[75%]'
          />
        </div>
  
        <div className='z-10 w-full h-full flex flex-col items-center justify-center pb-2 sm:py-6'>
          <LoginCardContainer
            displayLogo={false}
            className={cn(
              'sm:!w-full lg:!w-[750px] flex items-center flex-col !py-4 overflow-hidden backdrop-blur-sm !m-0',
              {
                '!overflow-auto': isAnimationDone
              }
            )}>
            {renderTransition(
              <>
                <Image src={ASSETS.IMAGES.APP.app_logo} className='h-7 m-auto' />
  
                <Text.Headline.H3 className='tracking-tighter my-3 text-center'>
                  CREDITS
                </Text.Headline.H3>
  
                <div className='flex flex-col gap-3'>
                  {Object.entries(productionTeam).map(([team, members]) => (
                    <TeamMemberItem key={team} label={team} members={members as ITeamMember[]} />
                  ))}
                </div>
              </>
            )}
          </LoginCardContainer>
  
          <div className='text-center mt-2'>
            <Text.Body.MediumSemiBold className='text-white !uppercase tracking-tighter'>
              No interns were harmed in the making of this application
            </Text.Body.MediumSemiBold>
          </div>
        </div>
      </div>
      )}
    />

  )
}
