import React, { Children } from 'react'
import cn from 'classnames'
import { IBaseTextProps } from '../types'

interface IComponent<P = IBaseTextProps> extends React.FC<P> {
  Large: React.FC<P>
  Medium: React.FC<P>
  Small: React.FC<P>
}

const TextLink: IComponent = ({ children }) => {
  const subComponentList = Object.keys(TextLink)

  const subComponents = subComponentList.map(key => {
    return Children.map(children, child =>
      (child as any)?.type?.name === key ? child : null
    )
  })

  return <>{subComponents.map(component => component)}</>
}

const Large: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn('text-[24px] leading-normal normal-case', className)}
    {...restProps}>
    {children}
  </p>
)
TextLink.Large = Large

const Medium: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn('text-[14px] leading-normal normal-case', className)}
    {...restProps}>
    {children}
  </p>
)
TextLink.Medium = Medium

const Small: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn('text-[12px] leading-normal normal-case', className)}
    {...restProps}>
    {children}
  </p>
)
TextLink.Small = Small

export default TextLink
