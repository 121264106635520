import { useMemo } from 'react'
import { IMower } from '../models'
import { matchSorter } from 'match-sorter'

export const useMowerSearch = ({
  items,
  search
}: {
  items: IMower[]
  search: string
}): IMower[] => {
  const reducedMowers = useMemo(() => {
    return (items ?? []).reduce<{
      mowersCollection: Record<string, IMower>
      modifiedMowers: IMower[]
    }>(
      (acc, curr) => {
        // change source of 'ngbr' to 'ascent' so this should be searchable
        const modifiedMowers = [
          ...acc.modifiedMowers,
          curr.source === 'ngbr'
            ? {
                ...curr,
                source: 'ascent'
              }
            : curr
        ]

        const mowersCollection = {
          ...acc.mowersCollection,
          [curr.id]: curr
        }

        return {
          modifiedMowers,
          mowersCollection
        }
      },
      {
        mowersCollection: {},
        modifiedMowers: []
      }
    )
  }, [items])

  if (!search) return []

  // source is either "ngbr" or "surepath"
  // but search text by the user will be ngbr = "ascent" or "surepath"
  const results = matchSorter(
    reducedMowers?.modifiedMowers ?? [],
    search.trim(),
    {
      keys: [
        'name',
        'productSerial',
        'factory_model_number',
        'model_number',
        'registering_dealer',
        'source'
      ] as (keyof IMower)[],
      threshold: matchSorter.rankings.CONTAINS
    }
  )

  const modifiedResults = results.reduce<IMower[]>((acc, curr) => {
    return [...acc, reducedMowers.mowersCollection[curr.id] as IMower]
  }, [])

  return modifiedResults
}
