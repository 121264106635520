import React, { CSSProperties } from 'react'

interface TodayMarkerLineProps {
  style: CSSProperties
}

const TodayMarkerLine: React.FC<TodayMarkerLineProps> = props => {
  const { style } = props

  return <div style={style} className='!w-[3px] !bg-error' />
}

export default TodayMarkerLine
