import React from 'react'
import { DynamicListItem } from '../../../../molecules'
import { Button, Icon, Text } from '../../../../atoms'
import { StyledBorder } from '../../../../molecules/DeviceCounterItem'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../../routes'
import { useAppSelector } from '../../../../../store/hooks'
import { selectSelectedCompany } from '../../../../../store/slices/organizationCompanySlice'

interface SettingsOrganizationCompanySidebarProps {}

const SettingsOrganizationCompanySidebar: React.FC<SettingsOrganizationCompanySidebarProps> =
  () => {
    const selectedCompany = useAppSelector(selectSelectedCompany)
    const navigate = useNavigate()

    const handleAddCompany = () => {
      navigate(Routes.companies_create)
    }

    const handleAssignAssets = () => {
      navigate(Routes['assign_asset.mower'].replace(':id', selectedCompany?.companyId!))
    }
  
    const handleEditCompany = () => {
      navigate(Routes.companies_edit.replace(':id', selectedCompany?.companyId!))
    }

    return (
      <div className='flex flex-col flex-1 justify-between'>
        <div className='w-full'>
          <DynamicListItem className='pl-3'>
            <StyledBorder className='w-[3px] h-[32px] bg-primary rounded-full mr-5' />
            <Text.Body.Medium>Global View</Text.Body.Medium>
          </DynamicListItem>
        </div>

        <div className='flex flex-col gap-3'>
          {selectedCompany && (
            <>
              <Button
                className='flex justify-between items-center'
                showOutline={false}
                color='secondary'
                titleSize='md'
                title='Assign Assets'
                onClick={handleAssignAssets}>
                <Icon name='add_circle' className='w-4' />
              </Button>
              <Button
                className='flex justify-between items-center'
                showOutline={false}
                color='secondary'
                titleSize='md'
                title='Edit Company'
                onClick={handleEditCompany}>
                <Icon name='add_circle' className='w-4' />
              </Button>
            </>
          )}

          <Button
            className='flex justify-between items-center'
            showOutline={false}
            color='secondary'
            titleSize='md'
            title='Add Company'
            onClick={handleAddCompany}>
            <Icon name='add_circle' className='w-4' />
          </Button>
        </div>
      </div>
    )
  }

export default SettingsOrganizationCompanySidebar
