import React from 'react'
import cn from 'classnames'

interface DividerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  size?: 'sm' | 'md' | 'lg'
  orientation?: 'horizontal' | 'vertical'
}

const Divider: React.FC<DividerProps> = props => {
  const {
    size = 'md',
    className,
    orientation = 'horizontal',
    ...restProps
  } = props

  if (orientation === 'vertical') {
    return (
      <div
        className={cn('border-black', className, {
          'border-b': size === 'sm',
          'border-b-2': size === 'md',
          'border-b-4': size === 'lg'
        })}
        {...restProps}>
      </div>
    )
  }

  return (
    <div
      className={cn('border-black', className, {
        'border-b': size === 'sm',
        'border-b-2': size === 'md',
        'border-b-4': size === 'lg'
      })}
      {...restProps}>
    </div>
  )
}

export default Divider
