import React, { useEffect } from 'react'
import { IMower } from '../../../../models'
import { Icon, Text } from '../../../atoms'
import { MowerCommon, SurepathTextLogo } from '../../../molecules'
import { IDevice } from '../../../../models_v2/entity/device'
import { isMowerOnline } from '../../../../utils/mower'

interface SurepathPerformanceHistorySectionProps {
  mower: IDevice,
  onPerformanceHistoryClick: () => void
}

const SurepathPerformanceHistorySection: React.FC<SurepathPerformanceHistorySectionProps> =
  ({ mower, onPerformanceHistoryClick }) => {
    const { latestTelemetry } = mower

    return (
      <div className='flex flex-col gap-2'>
        <div className='flex justify-between gap-4 dark:text-white'>
          <SurepathTextLogo />
          {isMowerOnline(mower) && (
            <>
              <MowerCommon.SurepathGps value={(latestTelemetry?.payload as unknown as IMower).gpsMode?.gppFixMode} />
              <MowerCommon.SurepathCellCignal
                value={(latestTelemetry?.payload as unknown as IMower).signal?.rsrp}
                noSignal={!isMowerOnline(mower)}
              />
            </>
           )} 
        </div>
        <div className='flex justify-end gap-2'>
          <Text.VariousRegular.Medium
            className='cursor-pointer dark:text-white'
            onClick={onPerformanceHistoryClick}>
            Performance History
          </Text.VariousRegular.Medium>
          <Icon
            name='caret_right'
            className='invert dark:filter-none'
            width={9}
          />
        </div>
      </div>
    )
  }

export default SurepathPerformanceHistorySection
