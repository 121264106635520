import React from 'react';
import { useAuthCheck } from '../../hooks';
import { Routes } from '../../routes';

interface AppRootProps {}

const AppRoot: React.FC<AppRootProps> = () => {
  useAuthCheck({ redirectTo: Routes.root_page })
 
  return null;
}

export default AppRoot;