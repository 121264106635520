const TrackingRoutes = {
  tracking_page: '/app/tracking',
  'tracking_page.mowers': '/app/tracking/mowers',
  'tracking_page.batteries': '/app/tracking/batteries',
  'tracking_page.tool_tags': '/app/tracking/tool_tags',
  'tracking_page.mowers.detail': '/app/tracking/mowers/:id',
  'tracking_page.batteries.detail': '/app/tracking/batteries/:id',
  'tracking_page.tool_tags.detail': '/app/tracking/tool_tags/:id',
  'tracking_page.mowers.wildcard': '/app/tracking/mowers/*',
  'tracking_page.batteries.wildcard': '/app/tracking/batteries/*',
  'tracking_page.tool_tags.wildcard': '/app/tracking/tool_tags/*',
  'tracking_page.groups': '/app/tracking/groups',
  'tracking_page.groups.detail': '/app/tracking/groups/:id'
}

export default TrackingRoutes
