import cn from 'classnames'
import { Icon, ProgressBar, Image, Divider, Button } from '../../atoms'
import { BatteryIconListPanelProps, BatterySlot } from './types'
import { useAppTheme } from '../../../hooks'
import * as Text from '../../atoms/Typography'
import { ASSETS } from '../../../assets'
import { IDevice } from '../../../models_v2/entity/device'
import { BatteryPack } from '../../../models_v2/entity/telemetry_payload'
import { useNavigate } from 'react-router-dom'
import { getItemDetailsRoute } from '../../../utils/navigation'
import {
  ITelemetryPayload,
  BATTERY_STATE
} from '../../../models_v2/entity/telemetry_payload'

const batterySlots: BatterySlot[] = ['A', 'B', 'C', 'D', 'E']

export const BatteryIconListPanel: React.FC<BatteryIconListPanelProps> =
  props => {
    const { mower, onClose, batteries, withOnClick } = props
    const navigate = useNavigate()
    const { theme } = useAppTheme()

    const onClick = (battery: IDevice | null) => {
      if (!withOnClick || !battery) {
        return
      }
      navigate(getItemDetailsRoute('battery', battery))
    }

    return (
      <div className='bg-app-main-light-full bg-no-repeat max-h-[600px] overflow-auto bg-cover rounded-2xl border border-[#363636] min-w-[280px] max-w-[280px] dark:bg-app-background'>
        <div className='my-2 flex flex-col gap-2'>
          <div className='flex justify-between px-3 py-2'>
            <Text.Body.MediumSemiBold>
              {mower.name} Cells
            </Text.Body.MediumSemiBold>
            <Button
              variant='outlined'
              size='sm'
              titleSize='sm'
              title='Close'
              className='!bg-black/20 !text-black dark:!text-white'
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                onClose()
              }}
            />
          </div>
          <div className='mx-2 flex gap-2'>
            {batterySlots?.map((slot, index) => {
              const batteryPack = batteries[index] || null

              return (
                <div
                  className={cn(
                    'w-5 h-6 flex items-center justify-center text-black bg-primary rounded-md border border-[#363636]',
                    {
                      '!bg-black !text-white': !batteryPack
                    }
                  )}>
                  {slot}
                </div>
              )
            })}
          </div>
          <Divider className='border-[#363636]' size='sm' />
          {batteries?.map((battery: IDevice | null, idx: number) => {
            const batteryPack = battery?.latestTelemetry
              ?.payload as unknown as BatteryPack

            const isBatteryCharging =
              (battery?.latestTelemetry?.payload as ITelemetryPayload)
                ?.CurrentState === BATTERY_STATE.CHARGING

            const progressBarColor = isBatteryCharging
              ? '#2CC947'
              : theme === 'light'
              ? '#0082FC'
              : undefined

            return (
              <div key={`battery-icon-panels-${idx}`}>
                <div
                  className={cn('text-black dark:text-white p-3 card', {
                    'cursor-pointer': !!withOnClick
                  })}
                  onClick={() => onClick(battery)}>
                  <div className='flex gap-2 items-center'>
                    <Text.Body.MediumSemiBold
                      className={cn(
                        'bg-primary text-black py-1 px-2 rounded-md border border-[#363636]',
                        {
                          '!bg-black !text-white': !batteryPack
                        }
                      )}>
                      {batterySlots[idx]}
                    </Text.Body.MediumSemiBold>
                    {!!batteryPack ? (
                      <>
                        <Text.Body.MediumSemiBold>
                          Battery {battery?.productSerial}
                        </Text.Body.MediumSemiBold>
                        <Image
                          src={ASSETS.IMAGES.BATTERY.subscribed}
                          className='h-[20px] w-[23px]'
                        />
                      </>
                    ) : (
                      '-'
                    )}
                  </div>

                  <div
                    className={cn('flex gap-2', {
                      'opacity-25': !batteryPack
                    })}>
                    <Image
                      src={ASSETS.IMAGES.BATTERY.battery}
                      className='h-[62px] w-[66px]'
                    />
                    {!!batteryPack && (
                      <div className='flex flex-col flex-grow'>
                        <div className='flex items-center gap-1'>
                          {isBatteryCharging && (
                            <Icon
                              name='bolt_vector'
                              className='h-[12px] w-2 mr-1 invert dark:invert-0'
                            />
                          )}
                          <Text.VariousRegular.Large>
                            {batteryPack?.StateOfCharge ?? '-'}%
                          </Text.VariousRegular.Large>
                        </div>
                        <ProgressBar
                          progressBarColor={progressBarColor}
                          value={
                            batteryPack?.StateOfCharge
                              ? Number(batteryPack?.StateOfCharge)
                              : 0
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <Divider className='border-[#363636]' size='sm' />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

export default BatteryIconListPanel
