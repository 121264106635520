import cn from 'classnames'
import { Mower } from '../../../models_v2/common/mower'
import { Battery } from '../../../models_v2/entity/telemetry_payload'
import { IToolTag } from '../../../models'
import { Text } from '../../atoms'
import AssetMowerListItem from './AssetMowerListItem'
import { Fragment } from 'react'
import AssetToolTagListItem from './AssetToolTagListItem'

interface AssetListItemProps {
  asset: Mower | Battery | IToolTag | any
  model: string
  isActive?: boolean
  onClick?: (item: any) => void
  onPreSelect?: (item: any) => void
}

const AssetListItem: React.FC<AssetListItemProps> = props => {
  const { asset, model, isActive = false, onClick, onPreSelect } = props

  const handleDisplayByModel = () => {
    if (model === 'mower') return <AssetMowerListItem mower={asset} />
    if (model === 'tooltag')
      return (
          <AssetToolTagListItem
            tool_tag={asset}
            onClick={onClick ? () => onClick?.(asset) : undefined}
            onPreSelect={onPreSelect ? () => onPreSelect?.(asset) : undefined}
            isActive={isActive}
          />
      )
    else return ''
    // switch(model){
    //     case "mower": <Text.Feature.Medium>{model.toUpperCase()}</Text.Feature.Medium>; break;
    //     case "battery": <Text.Feature.Medium>{model.toUpperCase()}</Text.Feature.Medium>; break;
    //     case "tooltag": <Text.Feature.Medium>{model.toUpperCase()}</Text.Feature.Medium>; break;
    //     default: <Text.Feature.Medium>{model.toUpperCase()}</Text.Feature.Medium>; break;
    // }
  }

  return (
    <div
      className={cn('text-black dark:text-white p-1 card', {
        'cursor-pointer': !!onClick,
        'outline outline-4 outline-offset-[-4px] outline-interaction-200':
          isActive
      })}
      onClick={model === 'tooltag' ? () => onClick?.(asset) : onClick}>
         <Text.Feature.Medium>{model.toUpperCase()}</Text.Feature.Medium>
        <Text.Feature.XSmall>{asset.assignment?.toUpperCase()}</Text.Feature.XSmall>
      {handleDisplayByModel()}
    </div>
  )
}

export default AssetListItem
