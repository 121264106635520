import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../store/hooks"
import { selectUserRole } from "../store/slices/userSlice"
import { useEffect } from "react"
import { Routes } from "../routes"


export const useRedirectNonAdmin = () =>{
  const navigate = useNavigate()
  const userRole = useAppSelector((state) => selectUserRole(state))

  useEffect(() => {
    // Guard against role hasn't been loaded or not administrator
    if  ( userRole==="Administrator" || userRole==="GlobalAdmin" ) return

    navigate(Routes.people)
  }, [userRole])
}