import { IDevice } from '../../models_v2/entity/device'
import { useApolloQuery } from '../useApolloQuery'
import { useAppDispatch } from '../../store/hooks'
import { useEffect } from 'react'
import { setDevices } from '../../store/slices/deviceSliceV2'

export const useDevices = () => {
  const dispatch = useAppDispatch()
  const result = useApolloQuery<IDevice[]>({
    query: 'GET_DEVICES',
    forceReAuth: true,
    // TODO: set this up as param to filter mowers, batteries, etc
    // There is no need to transform the data for now, but we should no longer transform it since we will now follow the GraphQL schema for single source of truth
    transformer: 'devices_v2'
  })

  useEffect(() => {
    if (result.isFetched && result.data) {
      dispatch(setDevices({ devices: result.data }))
    }
  }, [result.isFetched])

  return result
}
