import { memo, useCallback, useMemo, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Icon, Image, Text } from '../../atoms'
import { ASSETS } from '../../../assets'
import { usePopper } from 'react-popper'
import './index.css'
import { t } from '@lingui/macro'
import { DynamicListItem } from '..'
import { useBreakpoint, useWindowDimensions } from '../../../hooks'
import { Mower } from '../../../models_v2/common/mower'
import { IMetadata } from '../../../models_v2/entity/metadata'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../routes'

interface DeviceFaultButtonProps {
  // These faults are only Active
  faults: IMetadata[]
  // Possible other device type
  item?: Partial<Mower>
}

type RenderButton = (props: {
  label: string
  onClick?: () => void
  icon: 'caret_left' | 'caret_right'
  iconPosition?: 'left' | 'right'
}) => JSX.Element

const DeviceFaultButton: React.FC<DeviceFaultButtonProps> = props => {
  const { faults, item } = props
  const { width: windowWidth } = useWindowDimensions()
  const { breakpoint } = useBreakpoint(windowWidth)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState<any>()
  const [popperElement, setPopperElement] = useState<any>()
  const navigate = useNavigate()

  const handleNavigateToFaultHistory = () => {
    const resolvedPathName = Routes['tracking_page.mowers.detail']
    const pathname = resolvedPathName.replace(':id', item?.productSerial || '')
    navigate(`${pathname}?faultHistory=open`)
  }

  const popperOffset = useMemo<[number, number]>(() => {
    const offsetLeft = popperElement?.parentNode?.parentNode?.offsetLeft

    let sidebarWidth = 400
    const sidebarContentPadding = 36

    if (['xl', '2xl'].includes(breakpoint)) {
      sidebarWidth = 400
    } else if (['lg', 'md'].includes(breakpoint)) {
      sidebarWidth = 350
    } else {
      sidebarWidth = 300
    }

    return [55, sidebarWidth - sidebarContentPadding - offsetLeft]
  }, [breakpoint, popperElement?.parentNode?.parentNode?.offsetLeft])

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: popperOffset
        }
      },
      {
        name: 'flip',
        options: {
          padding: {
            top: 12
          }
        }
      }
    ]
  })

  const renderButton: RenderButton = useCallback(props => {
    return (
      <div
        className='flex gap-2 items-center text-black dark:text-white cursor-pointer'
        onClick={e => {
          e.stopPropagation()
          props.onClick?.()
        }}>
        {props.iconPosition === 'left' && (
          <Icon className='invert dark:invert-0' name={props.icon} />
        )}
        <Text.VariousRegular.Medium>{props.label}</Text.VariousRegular.Medium>
        {props.iconPosition === 'right' && (
          <Icon className='invert dark:invert-0' name={props.icon} />
        )}
      </div>
    )
  }, [])

  return (
    <Popover>
      {({ open }) => {
        return (
          <>
            <Popover.Button
              key={popoverOpen.toString()}
              ref={setReferenceElement}
              className='focus:outline-none flex items-center'
              onClick={() => setPopoverOpen(!open)}>
              <Image
                src={ASSETS.IMAGES.COMMON.danger}
                className='min-w-[35px]'
              />
            </Popover.Button>

            <div
              onClick={e => {
                e.stopPropagation()
              }}>
              <Popover.Panel
                static
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className='absolute z-10 focus:outline-none shadow-lg dark:shadow-none'>
                {({ close }) => {
                  return (
                    <Transition
                      show={open}
                      enter='transition ease-in-out duration-200 transform'
                      enterFrom='opacity-0 -translate-x-2'
                      enterTo='opacity-100 translate-x-0'
                      leave='transition ease-in-out duration-200 transform'
                      leaveFrom='opacity-100 translate-x-0'
                      leaveTo='opacity-0 -translate-x-2'>
                      <div className='bg-app-main-light-full min-w-[280px] max-w-[280px] px-3 py-2 dark:bg-black'>
                        <div className='flex justify-between'>
                          {renderButton({
                            icon: 'caret_left',
                            label: t`Close`,
                            onClick: close
                          })}

                          {renderButton({
                            icon: 'caret_right',
                            iconPosition: 'right',
                            label: t`History`,
                            onClick: () => {
                              handleNavigateToFaultHistory()
                              close()
                            }
                          })}
                        </div>
                        <div className='my-2 flex flex-col gap-2 max-h-[200px] lg:max-h-[350px] overflow-y-auto'>
                          <DynamicListItem className='gap-3 py-2 justify-between bg-black/20 dark:bg-[#1E1E1E]'>
                            <Text.Body.Medium className='dark:text-white'>
                              {`${faults?.length} Active ${
                                faults.length === 1 ? 'Fault' : 'Faults'
                              }`}
                            </Text.Body.Medium>
                          </DynamicListItem>
                        </div>
                      </div>
                    </Transition>
                  )
                }}
              </Popover.Panel>
            </div>
          </>
        )
      }}
    </Popover>
  )
}

export default memo(DeviceFaultButton)
