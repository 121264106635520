import { useEffect } from 'react'
import { IDevice } from '../../models_v2/entity/device'
import { useApolloQuery } from '../useApolloQuery'
import { useApolloLazyQuery } from '../useApolloLazyQuery'
import { ApolloClient, useApolloClient } from '@apollo/client'
import { GqlQueryCollection } from '../../services_v2/gql'
import { useAppDispatch } from '../../store/hooks'
import { setDevice } from '../../store/slices/deviceSliceV2'
import { divideDateRange } from '../../utils/date'

interface IParams {
  productSerial: string
  startTime?: string
  endTime?: string
}

interface IGetDeviceTelemetryInChunkParams {
  productSerial: string
  startTime?: string
  endTime?: string
  numDays: number
  numSegments: number
}

interface IOptions {
  enableSetDeviceState?: boolean
}

export const useDevice = (params: IParams, options?: Partial<IOptions>) => {
  const dispatch = useAppDispatch()

  const result = useApolloQuery<IDevice, typeof params>({
    query: 'GET_DEVICE_BY_ID_TELEMETRY',
    options: {
      variables: params
    },
    forceReAuth: true
  })

  useEffect(() => {
    if (!options?.enableSetDeviceState || !result?.isFetched) return

    dispatch(
      setDevice({
        device: result?.data!
      })
    )
  }, [result?.isFetched, result?.data])

  return result
}

export const useLazyDevice = () => {
  const lazyQueryResultTuple = useApolloLazyQuery<IDevice, IParams>({
    query: 'GET_DEVICE_BY_ID_TELEMETRY',
    forceReAuth: true
  })

  return lazyQueryResultTuple
}

export const useGetDevice = () => {
  const apolloClient: ApolloClient<object> = useApolloClient()

  const getDevice = async (variables: IParams): Promise<IDevice> => {
    const queryDocument = GqlQueryCollection['GET_DEVICE_BY_ID_TELEMETRY']
    const result = await apolloClient.query({
      query: queryDocument,
      variables
    })
    const resolvedData = result.data?.getById ?? result.data?.result

    return resolvedData
  }

  return getDevice
}

export const useGetDeviceTelemetryInChunk = (options?: Partial<IOptions>) => {
  const dispatch = useAppDispatch()
  const getDevice = useGetDevice()

  const getDeviceTelemetryInChunk = async (
    params: IGetDeviceTelemetryInChunkParams
  ) => {
    const { productSerial, startTime = '', numDays, numSegments } = params ?? {}

    const ranges = divideDateRange(startTime, numDays, numSegments)

    const deviceRanges = await Promise.all(
      ranges?.map(range => {
        return getDevice({
          startTime: range?.startDate,
          endTime: range?.endDate,
          productSerial
        })
      }) as Promise<IDevice>[]
    )

    const mergeDeviceRanges = deviceRanges?.reduce((acc, curr) => {
      const { telemetries = [] } = acc ?? {}
      const { telemetries: currentTelemetries = [] } = curr ?? {}

      return {
        ...acc,
        ...curr,
        telemetries: [...telemetries, ...currentTelemetries]
      }
    }, {} as IDevice)

    if (options?.enableSetDeviceState) {
      dispatch(
        setDevice({
          device: mergeDeviceRanges
        })
      )
    }

    return mergeDeviceRanges
  }

  return getDeviceTelemetryInChunk
}

export const useBatteryDevice = (params: IParams) => {
  const dispatch = useAppDispatch()

  const result = useApolloQuery<IDevice, typeof params>({
    query: 'GET_BATTERY_DEVICE_BY_ID_TELEMETRY',
    options: {
      variables: params
    },
    forceReAuth: true
  })

  useEffect(() => {
    if (!result?.isFetched) return

    dispatch(
      setDevice({
        device: result?.data!
      })
    )
  }, [result?.isFetched, result?.data])

  return result
}
