import React from 'react'
import cn from 'classnames'
import { Image } from '../../atoms'
import { ASSETS } from '../../../assets'

interface SurepathLogoProps {
  className?: string
}

const SurepathLogo: React.FC<SurepathLogoProps> = props => {
  const { className } = props

  return (
    <Image
      src={ASSETS.IMAGES.MOWER.surepath_mower_logo}
      className={cn('w-[23px] h-[18px] invert dark:invert-0', className)}
    />
  )
}

export default SurepathLogo
