import { RootState } from '../../store'

export const selectDeviceGroupFormSelectedDevices = (state: RootState) => {
  return state.deviceGroup.form.selected_devices
}

export const selectDeviceGroupFormContext = (state: RootState) => {
  return state.deviceGroup
}

export const deviceGroupNameFormContext = (state: RootState) => {
  return state.deviceGroup.form.group_name
}

export const deviceErrorGroupNameFormContext = (state: RootState) => {
  return state.deviceGroup.form.errorGroupName
}

export const deviceEditFormContext = (state: RootState) => {
  return state.deviceGroup.form.edit
}

export const currentSelectedGroupIndex = (state: RootState) => {
  return state.deviceGroup.form.current_active_group_index
}

export const isAllAssets = (state: RootState) => {
  return state.deviceGroup.form.isAllAssets
}

export const groupList = (state: RootState) => {
  return state.deviceGroup.groups
}
