import React, { useCallback, useState } from 'react'
import cn from 'classnames'
import MonthItem from './MonthItem'
import moment from 'moment'
import MonthNavigationButton from './MonthNavigationButton'
import { useBreakpoint, useWindowDimensions } from '../../../../hooks'

interface IMonthSelectorProps {
  className?: string
  currentDate: Date
  onChange?: (date: Date) => void
}

const MonthSelector: React.FC<IMonthSelectorProps> = props => {
  const { className, currentDate, onChange } = props

  const { width: windowWidth } = useWindowDimensions()
  const { breakpoint } = useBreakpoint(windowWidth)

  const [currentDateRange, setCurrentDateRange] = useState<Date[]>([
    currentDate,
    moment(currentDate).add(1, 'month').toDate(),
    moment(currentDate).add(2, 'month').toDate(),
    moment(currentDate).add(3, 'month').toDate(),
    moment(currentDate).add(4, 'month').toDate(),
    moment(currentDate).add(5, 'month').toDate()
  ])

  const handleNavigate = useCallback((direction: 'PREVIOUS' | 'NEXT') => {
    setCurrentDateRange(prev => {
      const ranges = [...prev]

      if (direction === 'PREVIOUS') {
        ranges.pop()
        const newLeadingMonth = moment(ranges[0]).subtract(1, 'month').toDate()
        ranges.unshift(newLeadingMonth)
        return ranges
      } else if (direction === 'NEXT') {
        const trailingMonth = ranges[ranges.length - 1]
        ranges.shift()
        const newTrailingMonth = moment(trailingMonth).add(1, 'month').toDate()
        ranges.push(newTrailingMonth)
        return ranges
      }

      return prev
    })
  }, [])

  const isMonthActive = useCallback(
    (date: Date) => {
      return (
        moment(date).isSame(currentDate, 'month') &&
        moment(date).isSame(currentDate, 'year')
      )
    },
    [currentDate]
  )

  const shouldMonthItemShow = useCallback(
    (index: number): boolean => {
      if (breakpoint === '2xl') return true
      let hiddenCount = 2
      if (breakpoint === 'lg') hiddenCount += 1

      const indices = new Array(hiddenCount)
        .fill(0)
        .map((_, index) => currentDateRange?.length - (index + 1))
      if (indices.includes(index)) return false
      return true
    },
    [currentDateRange, breakpoint]
  )

  return (
    <div
      className={cn('flex justify-between px-4 py-3 lg:py-[20px]', className)}>
      <MonthNavigationButton
        direction='PREVIOUS'
        onClick={() => handleNavigate('PREVIOUS')}
      />

      <div className='mx-2 xl:mx-[12px] flex-1 flex gap-2 xl:gap-[12px] justify-between'>
        {currentDateRange.map((item, index) => {
          if (!shouldMonthItemShow(index)) return null

          return (
            <MonthItem
              key={item.toString()}
              date={item}
              isActive={isMonthActive(item)}
              onClick={() => onChange?.(item)}
            />
          )
        })}
      </div>

      <MonthNavigationButton
        direction='NEXT'
        onClick={() => handleNavigate('NEXT')}
      />
    </div>
  )
}

export default MonthSelector
