import moment from 'moment'
import { IUserAccount } from '../user_account'

export const userAccountStatusResolver = (data: Partial<IUserAccount>) => {
  const { status, invitation_timestamp } = data

  switch (status) {
    case 'Invited':
      return `Invite Sent${
        invitation_timestamp === null
          ? ''
          : ` ${moment(invitation_timestamp).format('MMMM Do, YYYY')}`
      }`
    case 'Active':
      return 'Active'
    case 'Inactive':
      return 'Deactivated'
    default:
      return ''
  }
}
