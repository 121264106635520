import React from 'react'
import cn from 'classnames'
import { IBattery } from '../../../models'
import { Text, Button } from '../../atoms'

interface SubscriptionInfoCardProps {
  className?: string
  item: Partial<IBattery>
  onClose: () => void
}

const SubscriptionInfoCard: React.FC<SubscriptionInfoCardProps> = props => {
  const { item, className, onClose } = props

  return (
    <div
      className={cn(
        'bg-app-main-light dark:bg-app-background min-w-[270px] max-w-[270px] min-h-[380px] rounded-2xl border-[0.5px] border-[#4A4A4A]',
        'flex relative',
        className
      )}>
      <div className='absolute top-3 right-3'>
        <Button
          variant='outlined'
          size='sm'
          titleSize='sm'
          title='Close'
          className='bg-black/20'
          onClick={(e) =>{
            e.stopPropagation()
            onClose()
          }}
        />
      </div>

      <div className='flex-1 flex items-center justify-center'>
        <div className='flex flex-col gap-1 text-center'>
          <Text.Body.Medium>Subscription information</Text.Body.Medium>
          <Text.Body.LargeSemiBold>{ item.productSerial }</Text.Body.LargeSemiBold>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionInfoCard
