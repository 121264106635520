import React from 'react';
import { ASSETS } from '../../../assets';
import Image, { ImageProps } from '../Image';

interface IconProps extends Omit<ImageProps, 'src'> {
  name: keyof typeof ASSETS['ICONS']
}

const Icon: React.FC<IconProps> = (props) => {
  const { name } = props;

  return (
    <Image src={ASSETS.ICONS[name]} {...props}/>
  );
}

export default Icon;