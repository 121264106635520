import React from 'react'
import cn from 'classnames'

interface DynamicListItemProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

const DynamicListItem: React.FC<DynamicListItemProps> = props => {
  const { className, children, ...restProps } = props

  return (
    <div
      className={cn(
        'min-h-[52px] flex items-center bg-black/30 border border-[#4A4A4A] rounded-md dark:text-white px-5',
        className
      )}
      {...restProps}>
      {children}
    </div>
  )
}

export default DynamicListItem
