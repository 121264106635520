import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Device, IBattery, IMower, IToolTag } from '../../models'
import { RootState } from '../store'
import { TDevice } from '../../models/device'
import { ZOOM_LEVELS } from '../../components/organisms/Maps/constants'
import { IDevice } from '../../models_v2/entity/device'

type ClusterWithDevices = {
  devices: (IMower | IBattery | IToolTag)[]
}

type MapCluster = {
  type: TDevice
  clusters: ClusterWithDevices[]
  selectedClusterDevices?: IDevice[]
}

interface DeviceState {
  devices: Device[]
  map_cluster: MapCluster | null
  current_map_zoom: number
}

const initialState: DeviceState = {
  // All Devices
  devices: [],
  map_cluster: null,
  current_map_zoom: ZOOM_LEVELS.SATELLITE_VIEW
}

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setDevices: (state, action: PayloadAction<Device[]>) => {
      state.devices = action.payload
    },
    setMapZoom: (state, action: PayloadAction<number>) => {
      state.current_map_zoom = action.payload
    },
    resetMapZoom: (state) => {
      state.current_map_zoom = ZOOM_LEVELS.SATELLITE_VIEW
    },
    setMapCluster: (state, action: PayloadAction<MapCluster>) => {
      if (state.map_cluster?.type !== action.payload.type) {
        state.map_cluster = action.payload as any
      } else {
        // If same type, only set the clusters initially
        if (!state.map_cluster?.clusters.length) {
          state.map_cluster = action.payload as any
        }
      }
    },
    setSelectedClusterDevices: ( state, action: PayloadAction<IDevice[]> ) =>{
      if ( state.map_cluster ) {
        state.map_cluster.selectedClusterDevices = action.payload
      }
    },
    resetDeviceState: () => initialState
  }
})

export const { 
  setDevices, 
  setMapCluster, 
  setMapZoom, 
  resetMapZoom,
  setSelectedClusterDevices,
  resetDeviceState } = deviceSlice.actions

// selectors are used to "select" more complex pieces of state
export const selectDevices = (state: RootState) => state.device.devices
export const currentMapZoom = (state: RootState) => state.device.current_map_zoom
export const selectClusterFromMarkerId = (state: RootState, id: string) => {
  const matchedCluster = state.device.map_cluster?.clusters.find(item =>
    item.devices?.some(device => device.id === id)
  )

  return matchedCluster
}

export const deviceSliceRecuder = deviceSlice.reducer
export default deviceSlice.reducer
