import React, { Children } from 'react'
import cn from 'classnames'
import { IBaseTextProps } from '../types'

interface IComponent<P = IBaseTextProps> extends React.FC<P> {
  Large: React.FC<P>
  Medium: React.FC<P>
  Small: React.FC<P>
  LargeUnderlined: React.FC<P>
  MediumUnderlined: React.FC<P>
  SmallUnderlined: React.FC<P>
}

const TextLink: IComponent = ({ children }) => {
  const subComponentList = Object.keys(TextLink)

  const subComponents = subComponentList.map(key => {
    return Children.map(children, child =>
      (child as any)?.type?.name === key ? child : null
    )
  })

  return <>{subComponents.map(component => component)}</>
}

const Large: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <span
    className={cn(
      'cursor-pointer font-semibold text-[18px] leading-[28px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </span>
)
TextLink.Large = Large

const Medium: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <span
    className={cn(
      'cursor-pointer font-semibold text-[16px] leading-[24px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </span>
)
TextLink.Medium = Medium

const Small: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <span
    className={cn(
      'cursor-pointer font-semibold text-[14px] leading-[20px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </span>
)
TextLink.Small = Small

// Underlined
const LargeUnderlined: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <span
    className={cn(
      'cursor-pointer text-[18px] leading-[28px] normal-case underline',
      className
    )}
    {...restProps}>
    {children}
  </span>
)
TextLink.LargeUnderlined = LargeUnderlined

const MediumUnderlined: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <span
    className={cn(
      'cursor-pointer text-[16px] leading-[24px] normal-case underline',
      className
    )}
    {...restProps}>
    {children}
  </span>
)
TextLink.MediumUnderlined = MediumUnderlined

const SmallUnderlined: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <span
    className={cn(
      'cursor-pointer text-[12px] leading-[20px] normal-case underline',
      className
    )}
    {...restProps}>
    {children}
  </span>
)
TextLink.SmallUnderlined = SmallUnderlined

export default TextLink
