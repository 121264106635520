import { EExportFormat } from '../../../models/gql'
import { TState, TReducer } from './types'

export const initialState: TState = {
  asset_selection: {
    export: false,
    schedule: false
  },
  date_range_selection: {
    export: false,
    schedule: false
  },
  export_assets_selected: {},
  export_current_month: new Date(),
  export_date_range: null,
  export_form: {
    export_format: EExportFormat.CSV,
    report_type: undefined,
  },
  schedule_assets_selected: {},
  schedule_current_month: new Date(),
  schedule_date_range: null,
  schedule_form: {
    report_frequency: '',
    report_name: ''
  }
}

export const reducer: TReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ASSET_SELECTION': {
      const { type, active } = action.payload

      return {
        ...state,
        asset_selection: {
          ...state?.asset_selection!,
          [type]: active
        }
      }
    }

    case 'SET_ASSETS': {
      const { type, ids = [] } = action.payload

      const collection = ids.reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: true
        }
      }, {})

      return {
        ...state,
        [`${type}_assets_selected`]: collection
      }
    }

    case 'SELECT_ASSET': {
      const { type, id } = action.payload

      const collection = state[`${type}_assets_selected`] ?? {}
      const cloned_collection = { ...collection }
      cloned_collection[id] = true

      return {
        ...state,
        [`${type}_assets_selected`]: cloned_collection
      }
    }

    case 'DESELECT_ASSET': {
      const { type, id } = action.payload

      const collection = state[`${type}_assets_selected`] ?? {}
      const { [id]: _, ...rest_collection } = collection

      return {
        ...state,
        [`${type}_assets_selected`]: rest_collection ?? {}
      }
    }

    case 'SET_CURRENT_MONTH': {
      const { type, date } = action.payload

      return {
        ...state,
        [`${type}_current_month`]: date
      }
    }

    case 'SET_DATE_RANGE_SELECTION': {
      const { type, active } = action.payload

      return {
        ...state,
        date_range_selection: {
          ...state?.date_range_selection!,
          [type]: active
        }
      }
    }

    case 'SET_DATE_RANGE': {
      const { type, value } = action.payload

      return {
        ...state,
        [`${type}_date_range`]: value
      }
    }

    case 'SET_SCHEDULE_FORM_INPUT': {
      return {
        ...state,
        schedule_form: {
          ...(state.schedule_form ?? {}),
          ...(action.payload ?? {})
        }
      }
    }

    case 'SET_EXPORT_FORM_INPUT': {
      return {
        ...state,
        export_form: {
          ...(state.export_form ?? {}),
          ...(action.payload ?? {})
        }
      }
    }

    default: {
      return state
    }
  }
}
