import React from 'react'
import { Divider } from '../atoms'
import { SidebarContainer } from '../molecules'

export type SettingsTemplateProps = {
  subNavigation: React.ReactNode;
  leftContent?: React.ReactNode;
  topRightContent?: React.ReactNode;
  bottomRightContent?: React.ReactNode;
}

export const SettingsTemplate = ({
  subNavigation,
  leftContent = null,
  topRightContent = null,
  bottomRightContent = null,
}: SettingsTemplateProps) => {
  return (
    <div className='h-full flex flex-col'>
      <div className='h-full flex flex-col'>
        {subNavigation}
        <div className='flex flex-1 overflow-hidden '>
          <SidebarContainer className='border-r-2 border-black h-full p-[30px]'>
            {leftContent}s
          </SidebarContainer>
          <div className='flex flex-col flex-1 overflow-hidden bg-app-main-light-100 dark:bg-transparent'>
            {topRightContent}
            {topRightContent && bottomRightContent && <Divider size='md' />}
            {bottomRightContent && (
              <div className='flex-1 h-full overflow-x-auto'>
                {bottomRightContent}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsTemplate
