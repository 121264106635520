import React, { Fragment, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { Menu, Transition } from '@headlessui/react'
import { DropdownMenuProps } from './types'

export * from './types'

const DropdownMenu: React.FC<DropdownMenuProps> = props => {
  const {
    menuButton,
    menuButtonClassName,
    onMenuStateChange,
    items,
    onItemClick,
    size = 'md'
  } = props

  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    onMenuStateChange?.(menuOpen)
  }, [menuOpen])

  return (
    <Menu as='div' className='relative inline-block text-left'>
      {({ open }) => {
        if (onMenuStateChange && open !== menuOpen) setMenuOpen(open)

        return (
          <>
            <Menu.Button
              as='div'
              className={cn('cursor-pointer', menuButtonClassName)}>
              {menuButton}
            </Menu.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-100'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'>
              <Menu.Items
                className={cn(
                  'absolute z-50 right-0 mt-2 min-w-[150px] origin-top-right divide-y divide-gray-100 bg-black-500 rounded',
                  {
                    'min-w-[150px]': size === 'md',
                    'min-w-[250px]': size === 'lg',
                    'min-w-max': size === 'fit'
                  }
                )}
                style={{ outline: 'none' }}>
                <div className='py-1'>
                  {items.map((item, index) => (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <button
                          className={cn(
                            'flex w-full items-center px-3 py-2 text-sm text-white font-semibold focus:outline-none',
                            {
                              'bg-black-700': active
                            }
                          )}
                          onClick={() => onItemClick?.(item)}>
                          {item.label}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )
      }}
    </Menu>
  )
}

export default DropdownMenu
