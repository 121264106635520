import { TState, TReducer } from './types'

export const initialState: TState = {
}

export const reducer: TReducer = (state, action) => {
  switch (action.type) {
    default: {
      return state
    }
  }
}
