import React, { ComponentProps, useMemo } from 'react'
import { Icon, Text } from '../../atoms'
import { Trans } from '@lingui/macro'
import { Tooltip } from '@mui/material'

interface SurepathCellCignalProps {
  // value from mower.rsrp
  value?: number
  // override to display as no cell bars
  noSignal?: boolean
}

const SurepathCellCignal: React.FC<SurepathCellCignalProps> = props => {
  const { value, noSignal = false } = props

  /**
    -50 to -79 dBm: All 4 bars filled in
    -80 to -89 dBm: 3 bars filled in
    -90 to -99 dBm: 2 bars filled in
    -100 to -109 dBm: 1 bar filled in
    -110 to -120 dBm: no bars filled in
   */
  const iconName: ComponentProps<typeof Icon>['name'] = useMemo(() => {
    let icon: ComponentProps<typeof Icon>['name'] = 'cell_cignal_0'
    if (typeof value !== 'number' || noSignal) return icon

    if (value >= -79 && value <= -50) icon = 'cell_cignal_4'
    else if (value >= -89 && value <= -80) icon = 'cell_cignal_3'
    else if (value >= -99 && value <= -90) icon = 'cell_cignal_2'
    else if (value >= -109 && value <= -100) icon = 'cell_cignal_1'
    return icon
  }, [value, noSignal])

  return (
    <div className='flex gap-2'>
      <Text.VariousRegular.Small className='mt-[3px]'>
        <Trans>Cell Signal</Trans>:
      </Text.VariousRegular.Small>
      <Tooltip title={value ?? null}>
        <div>
          <Icon name={iconName} className='w-3 h-3' />
        </div>
      </Tooltip>
    </div>
  )
}

export default SurepathCellCignal
