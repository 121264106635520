import React, {
  Fragment,
  useEffect } from 'react'
import { GroupListItem } from '../../molecules'
import {
  Divider,
  Button,
  Icon
} from '../../atoms'
import { Routes } from '../../../routes'
import { useNavigate } from 'react-router-dom'
import { GroupTagListProps } from './types'
import { useGroups } from '../../../hooks/data/useGroups'
import { IGroup } from '../../../models_v2/entity/groups'
import { 
  useAppDispatch, 
  useAppSelector } from '../../../store/hooks'
import { handleSortGroupsAlphabetically } from '../../../utils/mower'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import { setShowCollapsebleData } from '../../../store/slices/trackingGroupSlice'
import { groupList } from '../../../store/slices/deviceGroupSlice/selectors'
import { Group, compareByName } from '../../../utils/sortingLists'

const GroupTagList: React.FC<GroupTagListProps> = props => {
  useGroups({ companies: '*' })
  const navigate = useNavigate()
  const groups = useAppSelector(groupList)
  const dispatch = useAppDispatch()
  const operatingCompany = useAppSelector(selectOperatingCompany)

  //TODO: Move to this to a selector
  const handleProccessGroups = () => {

    return groups
      .filter(group => operatingCompany.name!=="Global"? group.companyId==operatingCompany.companyId : true)
      .sort(compareByName<Group>(group => group.groupName));
  }

  
  useEffect(() => () => { dispatch(setShowCollapsebleData(null)) }, [])

  return (
    <>
      <div className='flex flex-col'>
        <Button
          className='w-full flex justify-between items-center'
          showOutline={false}
          color='secondary'
          titleSize='md'
          title='Add Group'
          onClick={() => navigate(Routes['garage_page.global-fleet.groups_create'])}>
          <Icon name='add_circle' className='w-4' />
        </Button>
        {handleProccessGroups().length
          ? handleProccessGroups().map((item: IGroup, key) => {
            return (
              <Fragment key={key}>
                <GroupListItem group={item} index={key} />
                <Divider size='lg' />
              </Fragment>
            )
          })
          : 'Loading ...'}
      </div>
    </>
  )
}

export default GroupTagList
