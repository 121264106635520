import React from 'react'
import { t } from '@lingui/macro'
import { Icon, Text } from '../../atoms'

interface BackToListButtonProps {
  onClick?: () => void
}

const BackToListButton: React.FC<BackToListButtonProps> = props => {
  const { onClick } = props

  return (
    <div
      className='w-fit flex gap-2 items-center dark:text-white cursor-pointer'
      onClick={onClick}>
      <Icon name='arrow_left' className='invert dark:invert-0' />
      <Text.VariousRegular.Small>{t`Back to list`}</Text.VariousRegular.Small>
    </div>
  )
}

export default BackToListButton
