// Placeholder: fill out once we get company data & set in state
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUserAccount, ICompany } from '../../models';
import { EDeviceTypes } from '../../models/device';
import { mergeObjects } from '../../utils/objects';
import { RootState } from '../store';

export enum EUserTypes {
  People = 'people',
  Companies = 'companies',
  Dealers = 'dealers',
}

export type OrganizationSettingsState = {
  activeList: EUserTypes | EDeviceTypes;
  userAccounts: IUserAccount[],
  companyAccounts: ICompany[],
  dealerAccounts: number[],
  searchFilter: string,
  selectedItem?: any,
  selectedCompany?: any,
  userProfileForm?: Partial<IUserAccount>

}
const initialState: OrganizationSettingsState = {
  activeList: EUserTypes.People,
  userAccounts: [],
  companyAccounts: [],
  dealerAccounts: [],
  searchFilter: "",
  selectedItem: undefined,
  userProfileForm: undefined,
  selectedCompany: undefined
}

export const organizationSettingsSlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCurrentActiveList: (state, action: PayloadAction<EUserTypes | EDeviceTypes>) => {
      state.activeList = action?.payload
    },
    setUserAccounts: (state, action: PayloadAction<IUserAccount[]>) => {
      state.userAccounts = action?.payload
    },
    addUserAccount: (state, action: PayloadAction<IUserAccount>) => {
      state.userAccounts.push(action?.payload)
    },
    updateUserAccount: (state, action: PayloadAction<IUserAccount>) => {
      const itemIndex = state.userAccounts.findIndex(
        item => item.id === action.payload.id
      )
      state.userAccounts[itemIndex] = action.payload
    },
    partialUpdateUserAccount: (state, action: PayloadAction<Partial<IUserAccount>>) => {
      const itemIndex = state.userAccounts.findIndex(
        item => item.id === action.payload.id
      )
      state.userAccounts[itemIndex] = mergeObjects(state.userAccounts[itemIndex], action.payload) as unknown as IUserAccount
    },
    setCompanyAccounts: (state, action: PayloadAction<ICompany[]>) => {
      state.companyAccounts = action?.payload
    },
    setDealerAccounts: (state, action: PayloadAction<[]>) => {
      state.companyAccounts = action?.payload
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.searchFilter = action?.payload
    },
    setSelectedItem: (state, action: PayloadAction<any>) => {
      state.selectedItem = action?.payload
    },
    setUserProfileForm: (state, action: PayloadAction<Partial<IUserAccount> | undefined>) => {
      state.userProfileForm = action?.payload
    },
    removeSelectedItem: (state) => {
      const { selectedItem, ...rest } = state
      return rest
    },
    resetOrganizationSettingState: () => initialState
  }
})

export const selectSelectedAccountId = (state: OrganizationSettingsState) => state.selectedItem.id


// We got 3 possibles types of accounts lists, fetch the relevant one with activeList
// and get the details for the item in the list with matching id
export const selectActiveAccountDetailsById = (state: OrganizationSettingsState) => {
  if (!state?.selectedItem?.id) return

  const userTypeAccountsMap: Record<EUserTypes, any> = {
    [EUserTypes.People]: state.userAccounts,
    [EUserTypes.Companies]: state.companyAccounts,
    [EUserTypes.Dealers]: state.dealerAccounts,
  }
  return userTypeAccountsMap[state.activeList as EUserTypes].find(
    (item: any) => item.id === state.selectedItem.id
  )
}

export const selectUserAccounts = ( state: RootState ) => state.organizationSettings.userAccounts

export const {
  setCurrentActiveList,
  setDealerAccounts,
  setCompanyAccounts,
  setUserAccounts,
  setSearchFilter,
  setSelectedItem,
  setUserProfileForm,
  addUserAccount,
  updateUserAccount,
  partialUpdateUserAccount,
  removeSelectedItem,
  resetOrganizationSettingState
} = organizationSettingsSlice.actions
export const organizationSettingsSliceRecuder = organizationSettingsSlice.reducer;