export interface ITelemetryPayload {
  Battery: Battery
  ROPS: Rops
  Traction: Traction
  Brake: Brake
  Controls: Controls
  VCM: Vcm
  Platform: Platform
  StartupState: string
  Deck: Deck
  Operation: Operation
  Headlight: Headlight
  PTO: Pto
  Calibration: Calibration
  Seat: Seat
  CurrentState: BATTERY_STATE
}

export interface Battery {
  AggregateStateOfCharge: string
  Board: Board
  BPMState: string
  IsBatteryDoorSwitchOn: boolean
  IsBatteryOrChargerFail: boolean
  IsBPMChargeEnabled: boolean
  IsBPMChargeOvercurrent: boolean
  IsBPMDischargeEnabled: boolean
  IsBPMDischargeOvercurrent: boolean
  IsBPMPrechargeEnabled: boolean
  IsCANLoopbackOn: boolean
  IsChargeDischargeFault: boolean
  IsChargerCommunicationFaulty: boolean
  IsChargeRequested: boolean
  IsChargeUnavailable: boolean
  IsCommunicationTimedOut: boolean
  IsContactorCheckComplete: boolean
  IsContactorFail: boolean
  IsContactorPassed: boolean
  IsContactorWeld: boolean
  IsDischargeEnabled: boolean
  IsDischargeUnavailable: boolean
  IsHatchOpenCharge: boolean
  IsHatchOpenDischarge: boolean
  IsPDMEnabled: boolean
  IsPowerEnabled: boolean
  IsPrechargeContactorFail: boolean
  IsPrechargeEnabled: boolean
  IsPrechargeFailed: boolean
  IsPrechargePassed: boolean
  IsPrechargeTimedOut: boolean
  IsPrechargeVoltsFail: boolean
  IsRegenPowerOn: boolean
  IsShutdownImminent: boolean
  IsSpareLED2On: boolean
  MainCurrent: string
  MainVoltage: string
  NTC1Temperature: string
  NTC2Temperature: string
  NumberOfPacks: string
  Pack1: BatteryPack
  Pack2: BatteryPack
  Pack3: BatteryPack
  Pack4: BatteryPack
  Pack5: BatteryPack
  StandbyBPlusVoltage: string
  SystemMaxCellVoltage: string
  SystemMaxTemperature: string
  SystemMinCellVoltage: string
  SystemMinTemperature: string
  SystemReceiveErrorCount: string
  SystemTransmitErrorCount: string
}

export interface Afe {
  IsOverheated: boolean
  IsTemperatureLow: boolean
  IsPermanentFailureDetected: boolean
  IsAlertActive: boolean
  IsInDeepSleep: boolean
  IsFuseOpen: boolean
  IsInSleep: boolean
}

export interface Left {
  Motor: Motor
  Throttle: Throttle
}

export interface Motor {
  IsContactorVoltsFail: boolean
  IsPrechargeVoltsFail: boolean
  IsVCMCommunicationFaulty: boolean
  IsStalled: boolean
  IsTempSensorFaulty: boolean
  IsThereInternalFault: boolean
  IsOverspeed: boolean
  Speed: string
  IsCommunicationFaulty: boolean
  Temperature: string
  IsMOSFaulty: boolean
  IsOverheated: boolean
  IsSoftwareOvercurrentProtectionOn: boolean
  Controller: Controller
  IsHardwareOvercurrentProtectionOn: boolean
  State: string
  IsHallSensorFaulty: boolean
  Voltage: string
  IsPoweredDown: boolean
  Current: string
  IsCurrentSenseCalibrationFaulty: boolean
  IsUndervoltage: boolean
  IsOvervoltage: boolean
  IsThereChecksumFailure: boolean
}

export interface Controller {
  TransmitErrorCount: string
  Temperature: string
  ReceiveErrorCount: string
  IsOverheated: boolean
}

export interface Throttle {
  IsFaulty: boolean
  Voltage1: string
  Voltage2: string
  Percent: string
  CalibrationState: string
}

export interface Right {
  Motor: Motor
  Throttle: Throttle
}

export interface Deck {
  Left: Left
  Center: Center
  Right: Right
  Setting: Setting
  MaxSpeed: string
}

export interface Headlight {
  IsOn: boolean
}

export enum BATTERY_STATE {
  INITIALIZE = "0",
  STARTUP = "1",
  DISCHARGING = "2",
  FETSOFF = "3",
  CHARGING = "4",
  SYSTEM_UPDATE = "5",
  LOW_POWER = "6",
}
export interface Root {
  Battery: Battery
  Operation: Operation
  VCM: Vcm
  Controls: Controls
  PTO: Pto
  Deck: Deck
  Seat: Seat
  Traction: Traction
  CurrentState: BATTERY_STATE
  Calibration: Calibration
  Platform: Platform
  ROPS: Rops
  StartupState: string
  Brake: Brake
  Headlight: Headlight
}

export interface BatteryPack {
  AFE: Afe
  AnalogBPlusCurrent: string
  AnalogBPlusVoltage: string
  ChargeCurrent: number
  AggregatedStateOfCharge: number
  ChargeVoltage: string
  Current: string
  CurrentState: BATTERY_STATE
  IsADCMismatch: boolean
  IsBLEPaired: boolean
  IsBLEPairingRequested: boolean
  IsBPMChannelShorted: boolean
  IsBPMOverrideOn: boolean
  IsBPMStateSelectA: boolean
  IsBPMStateSelectB: boolean
  IsCellOvervoltage: boolean
  IsCellOvervoltageLatched: boolean
  IsCellUndervoltage: boolean
  IsChargeComplete: boolean
  IsChargeFault: boolean
  IsChargeFETClosed: boolean
  IsChargeOvercurrent: boolean
  IsChargeOverheated: boolean
  IsChargeTemperatureLow: boolean
  IsCommunicationFaulty: boolean
  IsDisabled: boolean
  IsDischargeFault: boolean
  IsDischargeFETClosed: boolean
  IsDischargeOvercurrent1: boolean
  IsDischargeOvercurrent2: boolean
  IsDischargeOvercurrent3: boolean
  IsDischargeOvercurrentLatched: boolean
  IsDischargeOverheated: boolean
  IsDischargeShorted: boolean
  IsDischargeShortedLatched: boolean
  IsDischargeTemperatureLow: boolean
  IsExternalCommunicationFaulty: boolean
  IsFaultPresent: boolean
  IsFETsOffImminent: boolean
  IsFETsOverheated: boolean
  IsHWDTimedOut: boolean
  IsInitializationCompleted: boolean
  IsInternalCommunicationFaulty: boolean
  IsLoadDetected: boolean
  IsOverrideOn: boolean
  IsPositionCopied: boolean
  IsPositionUndefined: boolean
  IsRegenCurrentFail: boolean
  IsShorted: boolean
  IsStartupCompleted: boolean
  IsStateSelectA: boolean
  IsStateSelectB: boolean
  IsStateTransitionFailed: boolean
  IsTimedOut: boolean
  IsUIButtonPressed: boolean
  IsUndefinedFault: boolean
  IsUndervoltage: boolean
  IsWarningPresent: boolean
  MaxCellVoltage: string
  MaxTemperature: string
  MinCellVoltage: string
  MinTemperature: string
  ReceiveErrorCount: string
  StateOfCharge: number
  TransmitErrorCount: string
  Voltage: string
}

export interface Board {
  IsVrefOutOfSpec: boolean
  IsADCOutOfRange: boolean
  IsOverheated: boolean
}

export interface Operation {
  IsSafetySignalsOK: boolean
  IsIgnitionHoldOn: boolean
  IsKeyInStartPosition: boolean
  Is60VAccessorySwitchFaulty: boolean
  IsKeyInputSignalHigh: boolean
  IsKeyPulled: boolean
  Is60VAccessoryConnected: boolean
  IsPDMFaulty: boolean
  Is12VOutOfRange: boolean
}

export interface Vcm {
  IsSystemWakeOn: boolean
  IsVAPPReset: boolean
  IsPDMOK: boolean
  IsShutdownComplete: boolean
  IsVSPNotifyHigh: boolean
  BoardTemperature: string
  IsVAPPNotifyHigh: boolean
  IsPowerOnResetEnabled: boolean
  SCFTrasmitErrorCount: string
  APPTrasmitErrorCount: string
  SCFReceiveErrorCount: string
  IsSensorPowerEnabled: boolean
  IsVSPReset: boolean
  IsFaultCheckPassed: boolean
  IsMotorCommFaulty: boolean
  IsProductIdentified: boolean
  IsSCFTerminatorEnabled: boolean
  APPReceiveErrorCount: string
  IsInternalFail: boolean
  Is12VAccessoryEnabled: boolean
  IsRASRequested: boolean
  IsAPPTerminatorEnabled: boolean
  Is60VAccessoryEnabled: boolean
  InputVoltage: string
  IsCANInStandby: boolean
  IsPowerHoldOn: boolean
  IsShutdownRequested: boolean
  IsParamCRCFail: boolean
}

export interface Controls {
  IsInLeft: boolean
  IsIn: boolean
  IsInSitPosition: boolean
  IsStandSwitchFaulty: boolean
  IsInStandPosition: boolean
  IsSitSwitchFaulty: boolean
  IsInSitPosition1: boolean
  IsInStandPosition1: boolean
  IsInRight: boolean
  IsInSitPosition2: boolean
  IsInStandPosition2: boolean
}

export interface Pto {
  IsSwitchFaulty: boolean
  IsOffRequested: boolean
  IsEngaged: boolean
  IsSwitch1On: boolean
  IsSwitch2On: boolean
}

export interface Center {
  Motor: Motor
}

export interface Setting {
  IsAutoActive: boolean
  IsUpPressed: boolean
  IsDownPressed: boolean
}

export interface Seat {
  IsSwitchFaulty: boolean
  IsOccupied1: boolean
  IsOccupied2: boolean
  IsOccupied: boolean
}

export interface Traction {
  Left: Left
  Right: Right
  Setting: Setting
  IsDirectionFaulty: boolean
  IsOverridden: boolean
  MaxSpeed: string
}

export interface Calibration {
  IsCompleted: boolean
  Target: string
}

export interface Platform {
  IsSwitch1On: boolean
  IsSwitch2On: boolean
  IsOccupied: boolean
  IsSwitchFaulty: boolean
}

export interface Rops {
  IsInstalled: boolean
  IsSwitch2On: boolean
  IsSwitch1On: boolean
}

export interface Brake {
  IsReleased: boolean
}
