import React, { useMemo } from 'react'
import Image from '../../atoms/Image'
import { Text } from '../../atoms'
import { ASSETS } from '../../../assets'

interface IProps {
  value?: string
  label?: string
}

const OnboardOrAvailableIndicator: React.FC<IProps> = props => {
  const { value = 'onboard', label = 'Mower 2378' } = props

  // TODO: As instructed, assume all batteries are onboarded for now.
  const asset = useMemo(
    () =>
      value === 'onboard'
        ? ASSETS.IMAGES.BATTERY.onboard
        : ASSETS.IMAGES.BATTERY.available,
    [value]
  )

  // TODO: Assuming onboard batteries, with mower name
  const displayLabel = useMemo(
    () => (value === 'onboard' ? label : 'Available'),
    [value, label]
  )

  return (
    <div className='flex items-center gap-2 mt-1'>
      <Image src={asset} className='h-[20px] w-[17px]' />
      <Text.VariousRegular.Small>{displayLabel}</Text.VariousRegular.Small>
    </div>
  )
}

export default OnboardOrAvailableIndicator
