import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { convertArrayToMap } from '../../../utils/common'
import { IGroup } from '../../../models_v2/entity/groups'
import { Group } from '../../../models_v2/common/group'

interface GroupsSliceState {
  // Map-like structure for easy lookup and singular updates
  groups: Record<string, IGroup>
}

const initialState: GroupsSliceState = {
  groups: {}
}

export const groupSlice = createSlice({
  name: 'groupV2',
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<IGroup[]>) => {
      state.groups = convertArrayToMap(
        action.payload,
        'productSerial',
        // TODO: Distinction for other device type
        data => new Group(data)
      )
    },
    upsertGroup: (state, { payload }: PayloadAction<IGroup>) => {
      state.groups[`${payload.productSerial}`] = payload
    },
    resetGroupState: () => initialState
  }
})

export const { setGroups, upsertGroup,resetGroupState } = groupSlice.actions

export const groupSliceReducer = groupSlice.reducer
export default groupSlice.reducer
