import React from 'react'
import { Outlet } from 'react-router-dom'

interface IProps {}

const LoginForgotPasswordPage: React.FC<IProps> = () => {
  return <Outlet />
}

export default LoginForgotPasswordPage
