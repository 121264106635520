import React, { createContext, useReducer } from 'react'
import SettingsUserAdministration from './SettingsUserAdministration'
import { reducer, initialState } from './reducers'
import {
  SettingsUserAdministrationProps,
  TSettingsUserAdministrationContext
} from './types'

export const SettingsUserAdministrationContext =
  createContext<TSettingsUserAdministrationContext>({})

const SettingsUserAdministrationWrapper: React.FC<SettingsUserAdministrationProps> =
  () => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
      <SettingsUserAdministrationContext.Provider
        value={{
          state,
          dispatch
        }}>
        <SettingsUserAdministration />
      </SettingsUserAdministrationContext.Provider>
    )
  }

export default SettingsUserAdministrationWrapper
