import moment from 'moment'
import React, { useMemo } from 'react'
import { ASSETS } from '../../../../assets'
import { IToolTag } from '../../../../models'
import { getDeviceByProductSerial } from '../../../../models/utils'
import { Text, Image } from '../../../atoms'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'
import { IDevice } from '../../../../models_v2/entity/device'
import NetworkStatusIndicator from '../NetworkStatusIndicator'
import { isToolTagsOnline } from '../../../../utils/toolsAndTags'

interface BasicInformationProps {
  toolTag: Partial<IDevice>
}

const BasicInformation: React.FC<BasicInformationProps> = props => {
  const { toolTag } = props
  const { name = '' } = toolTag?.inventory ?? {}
  const {
    timestamp: last_seen,
    payload,
    status = 'Unassigned'
  } = toolTag?.latestTelemetry ?? {}
  const { lastSeenMower: lastSeenBy = '' } = (payload ?? {}) as any

  const lastSeen = useMemo(() => {
    return moment(last_seen).format('hh:mm A MM/DD/YYYY')
  }, [last_seen])

  return (
    <div className='dark:text-white'>
      <div className='flex justify-between items-center'>
        <Text.Feature.Medium>{name}</Text.Feature.Medium>
        <NetworkStatusIndicator isOnline={isToolTagsOnline(toolTag)} />
      </div>

      <div className='flex flex-row'>
        <div className='h-[102px] flex items-end'>
          <Image src={ASSETS.IMAGES.TOOL_TAG.tool_tag} />
        </div>

        <div className='ml-[18px] mt-[15px] flex flex-1 flex-col'>
          <div className='flex flex-col gap-3'>
            <div className='flex mt-2'>
              <div>
                <div className='flex justify-start gap-2'>
                  <Text.VariousRegular.Small>
                    Status:{' '}
                  </Text.VariousRegular.Small>
                  <Text.VariousBold.Small>
                    {status || 'Unassigned'}
                  </Text.VariousBold.Small>
                </div>

                {last_seen && (
                  <div className='flex justify-start gap-2'>
                    <Text.VariousRegular.Small>
                      Last seen:{' '}
                    </Text.VariousRegular.Small>
                    <Text.VariousBold.Small>{lastSeen}</Text.VariousBold.Small>
                  </div>
                )}

                {lastSeenBy && (
                  <div className='flex justify-start gap-2'>
                    <Text.VariousRegular.Small>
                      Last seen by:{' '}
                    </Text.VariousRegular.Small>
                    <Text.VariousBold.Small>
                      {lastSeenBy}
                    </Text.VariousBold.Small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicInformation
