import React, { useRef, useEffect, useContext, useState } from 'react'
import { TextField } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { LoginCardContainer } from '../../molecules'
import { Text, Button } from '../../atoms'
import { Routes } from '../../../routes'
import { AppContext } from '../../../App'

interface IFormInput {
  code: string
}

const styles = {
  fieldset: { borderColor: 'whitesmoke', color: 'whitesmoke' },
  label: { color: 'whitesmoke' },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      borderColor: '#FEBD17'
    }
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      borderColor: '#FEBD17',
      color: 'whitesmoke'
    }
  },
  '& .MuiInputLabel-root.Mui-focused': { color: '#FEBD17' } //styles the label
}

const SignupVerificationCode: React.FC = () => {
  const { confirmRegistration } = useContext(AppContext)
  const [helperStatus, setHelperStatus] = useState<any>(null)
  const [verified, setVerified] = useState(false)
  const { register } = useForm<IFormInput>()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!state || !state?.credentials) {
      navigate(Routes['login_page.signup'])
      return
    }
  }, [])

  const onSubmit = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { value = '' } = e.currentTarget
    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    const timeoutId = setTimeout(async () => {
      try {
        if (value.length !== 6 || !confirmRegistration) return

        const { username } = state?.credentials ?? {}

        await confirmRegistration({
          username,
          verificationCode: value
        })

        setHelperStatus('0')
        setVerified(true)
      } catch (e) {
        setHelperStatus('1')
        setVerified(false)
      }
    }, 500)

    timeoutRef.current = timeoutId
  }

  const navigateToLogin = () => {
    navigate(Routes['login_page'])
  }

  return (
    <LoginCardContainer>
      {verified ? (
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col gap-2'>
            <Text.Headline.H3 className='mt-[50px]'>
              Account Verified!
            </Text.Headline.H3>
            <Text.Body.Small>
              Please login using your newly registered account. Thank you.
            </Text.Body.Small>
          </div>

          <Button
            type='submit'
            title='Ok, got it'
            className='w-[116px] h-[43px]'
            onClick={navigateToLogin}
          />
        </div>
      ) : (
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-1'>
            <Text.Headline.H3 className='mt-[50px]'>
              CHECK YOUR EMAIL
            </Text.Headline.H3>
            <Text.Body.Small>
              We sent you a six-digit confirmation code to{' '}
              {state?.credentials?.username || 'youremail@email.com'}. Please
              enter it below to confirm you email address.{' '}
            </Text.Body.Small>
          </div>

          <div className='flex flex-col gap-7'>
            <form className='flex flex-col gap-3'>
              <TextField
                {...register('code', {
                  required: true,
                  maxLength: 6,
                  max: 6,
                  onChange: onSubmit
                })}
                autoFocus
                label='6-Digit Code'
                type={'text'}
                defaultValue=''
                style={{
                  width: '100%',
                  height: '41px'
                }}
                inputProps={{
                  style: { color: 'white', outlineColor: 'white' },
                  maxLength: 6
                }}
                sx={styles}
              />
              {helperStatus && (
                <Text.Body.Small
                  className={
                    helperStatus === '0' ? 'text-success' : 'text-error'
                  }>
                  {helperStatus === '0' ? 'Success' : 'Invalid'}
                </Text.Body.Small>
              )}
            </form>
            <Text.Body.Small>
              <Text.TextLink.SmallUnderlined>
                Resend Code
              </Text.TextLink.SmallUnderlined>{' '}
              or more get information at our{' '}
              <Text.TextLink.SmallUnderlined>
                help center
              </Text.TextLink.SmallUnderlined>
              .
            </Text.Body.Small>
          </div>
        </div>
      )}
    </LoginCardContainer>
  )
}

export default SignupVerificationCode
