import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { TextField } from '@mui/material'

import { Routes } from '../../../routes'
import { Alert, Button, Checkbox, Text } from '../../atoms'
import { LoginCardContainer } from '../../molecules'
import { useCompanies, useDynamicMutation, useDynamicQuery } from '../../../hooks';
import { PatternFormat } from 'react-number-format'
import { ErrorMessage } from '@hookform/error-message'
import { AppContext } from '../../../App'
import {useAppSelector} from '../../../store/hooks';

interface SignupCompleteFormProps {}

interface FormInput {
  firstName: string
  lastName: string
  phoneNumber: string
  termsAccepted: boolean
}


const styles = {
  fieldset: { borderColor: 'whitesmoke', color: 'whitesmoke' },
  label: { color: 'whitesmoke' },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      borderColor: '#FEBD17'
    }
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      borderColor: '#FEBD17',
      color: 'whitesmoke'
    }
  },
  '& .MuiInputLabel-root.Mui-focused': { color: '#FEBD17' } //styles the label
}

const SignupCompleteForm: React.FC<SignupCompleteFormProps> = () => {
  const { username } = useAppSelector(state => state.signup)

  const {
    data: userResponse,
    isSuccess: isSuccessUser
  } = useDynamicQuery({
    graphqlEntity: 'users',
    queryKey: 'GET_USER_BY_EMAIL',
    body: {
      email: username
    },
    instance: `${username}`
  })

  const companies = useCompanies().data
  const invitedCompany = companies?.find(({ companyId }) => companyId === userResponse?.companies[0])

  const navigate = useNavigate()
  const { register, handleSubmit, watch, formState, setValue, getValues, clearErrors } =
    useForm<FormInput>({
      defaultValues: {
        firstName: userResponse?.first_name,
        lastName: userResponse?.last_name,
        phoneNumber: userResponse?.phone_number
      }
    })
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const formData = watch()

  const { mutateAsync: updateUser } = useDynamicMutation({
    graphqlEntity: 'users',
    queryKey: 'UPDATE_USER'
  })

  useEffect(() => {
    if (!username) {
      navigate(Routes['login_page'])
      return
    }
  }, [])

  useEffect(() => {
    if (isSuccessUser) {
      setValue('firstName', userResponse.firstName)
      setValue('lastName', userResponse.lastName)
      setValue('phoneNumber', userResponse.phone)
    }
  }, [isSuccessUser]) 

  const onSubmit: SubmitHandler<FormInput> = async data => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      await updateUser({
        input: {
          id: userResponse.id,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phoneNumber,
          companies: userResponse.companies
        }
      })

      navigate(Routes['tracking_page.mowers'])
    } catch (e) {
      console.log({e})
      setIsLoading(false)
      setErrorMessage('An error has occured in completing the registration.')
    }
  }

  const handleUrlClick = (
    event: React.MouseEvent,
    type: 'terms' | 'privacy_policy'
  ) => {
    event.preventDefault()
    const links = {
      terms: 'https://www.dewalt.com/legal/terms-of-use',
      privacy_policy: 'https://www.stanleyblackanddecker.com/privacy-policy'
    }
    window.open(links[type], '_blank')
  }

  return (
    <LoginCardContainer className='sm:w-full lg:w-[830px] backdrop-blur-sm'>
      <div className='flex flex-col lg:flex-row gap-3'>
        <div className='flex-1 flex flex-col gap-3 mt-[30px]'>
          <Text.Headline.H3>
            {!invitedCompany
              ? "You're invited to join."
              : `You're invited to join ${invitedCompany?.inventory?.name?.trim?.()}.`}
          </Text.Headline.H3>
          <Text.Body.Medium className='text-[#EEEEEE]'>
            Confirm your detailed information to complete your registration to
            Ground Command.
          </Text.Body.Medium>
        </div>
        <div className='flex-1 flex flex-col gap-4'>
          <div className='flex flex-col gap-1'>
            <Text.Headline.H3 className='tracking-tighter'>
              INFORMATION
            </Text.Headline.H3>
            <Text.Body.SmallItalic className='text-[#ECECEC]'>
              All fields are required.
            </Text.Body.SmallItalic>
          </div>

          <form
            className='flex flex-col gap-2'
            onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-3'>
              <TextField
                {...register('firstName', {
                  required: 'Please enter your first name'
                })}
                id='firstName'
                label='First Name'
                type={'text'}
                inputProps={{
                  style: { color: 'white', outlineColor: 'white' }
                }}
                InputLabelProps={{
                  shrink: Boolean(getValues('firstName')?.length)
                }}
                sx={styles}
                error={!!formState.errors?.firstName}
                helperText={formState.errors?.firstName?.message}
              />
              <TextField
                {...register('lastName', {
                  required: 'Please enter your last name'
                })}
                id='lastName'
                label='Last Name'
                type={'text'}
                inputProps={{
                  style: { color: 'white', outlineColor: 'white' }
                }}
                InputLabelProps={{
                  shrink: Boolean(getValues('lastName')?.length)
                }}
                sx={styles}
                error={!!formState.errors?.lastName}
                helperText={formState.errors?.lastName?.message}
              />

              <input
                type='text'
                hidden
                disabled
                {...register('phoneNumber', {
                  required: 'Please input your phone number',
                  validate: {
                    invalidFormat: value => {
                      if (!value?.length) return true
                      return value.length === 10
                        ? true
                        : 'Invalid phone number format'
                    }
                  }
                })}
              />

              <PatternFormat
                id='phoneNumber'
                label='Phone Number'
                format='###-###-####'
                valueIsNumericString
                mask='_'
                inputProps={{
                  style: { color: 'white', outlineColor: 'white' }
                }}
                sx={styles}
                onValueChange={e => {
                  if (e.value.trim().length) clearErrors('phoneNumber')
                  setValue('phoneNumber', e.value)
                }}
                value={formData.phoneNumber}
                customInput={TextField}
                error={!!formState.errors?.phoneNumber}
                helperText={formState.errors?.phoneNumber?.message}
              />

              <TextField
                id='email'
                label='Email'
                type='email'
                defaultValue={userResponse?.email}
                sx={{
                  '& .MuiInputLabel-root.Mui-disabled': {
                    color: 'white'
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: 'white'
                  }
                }}
                disabled
              />
            </div>

            <div className='flex flex-col gap-4'>
              <div>
                <Checkbox
                  {...register('termsAccepted', {
                    validate: value => {
                      if (!value)
                        return 'You must tick the checkbox in order to proceed'
                      return true
                    }
                  })}
                  className='mt-[1px] form-checkbox bg-white text-primary focus:ring-0 p-2'
                  useDefaultCheckboxStyle={false}
                  containerClassName='!items-start'
                  label={
                    <span className='text-[#B4B4B4]'>
                      I have read and accept the
                      <span
                        className='cursor-pointer text-primary'
                        onClick={e => handleUrlClick(e, 'terms')}>
                        {' '}
                        Terms of Use
                      </span>{' '}
                      and{' '}
                      <span
                        className='cursor-pointer text-primary'
                        onClick={e => handleUrlClick(e, 'privacy_policy')}>
                        {' '}
                        Privacy Policy
                      </span>
                      .
                    </span>
                  }
                />
                <ErrorMessage
                  errors={formState.errors}
                  className='text-[#d32f2f] text-xs mt-1 ml-4'
                  name='termsAccepted'
                  as='p'
                />
              </div>

              <div>
                {errorMessage && (
                  <Alert variant='error' message={errorMessage} />
                )}

                <Button
                  type='submit'
                  className='w-full bg-primary hover:bg-primary active:bg-primary text-black'
                  title={!isLoading ? 'Complete Registration' : 'Submitting...'}
                  titleSize='sm'
                  // disabled={isLoading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </LoginCardContainer>
  )
}

export default SignupCompleteForm
