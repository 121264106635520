import React, { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { TextField, Button, Text } from '../../atoms'

import { Routes } from '../../../routes'
import { LoginCardContainer } from '../../molecules'
import { AppContext } from '../../../App'
import { isValidEmail } from '../../../utils/common'
import { Password } from '../../../utils/password'

interface IProps {}

interface IFormData {
  username: string
  password: string
  confirmPassword: string
}

const LoginForgotPassword: React.FC<IProps> = () => {
  const { forgotPassword } = useContext(AppContext)
  const navigate = useNavigate()
  const { register, handleSubmit, watch, formState } = useForm<IFormData>()
  const formData = watch()
  const { errors } = formState ?? {}
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit: SubmitHandler<IFormData> = async credentials => {
    try {
      const { username, password, confirmPassword } = credentials ?? {}
      if (!forgotPassword) return

      setErrorMessage('')
      setIsLoading(true)

      const result = await forgotPassword({
        username,
        password,
        confirm_password: confirmPassword
      })
      const { error, success, code } = result ?? {}

      setIsLoading(false)

      if (error) {
        setErrorMessage(error?.message ?? 'Error')
        return
      }

      if (success && code === 'InputVerificationCode') {
        navigate(Routes['login_page.forgot-password.code'], {
          state: {
            credentials
          }
        })
      }
    } catch (error) {
      // @ts-ignore
      setErrorMessage((error?.message as any) ?? 'Error')
      setIsLoading(false)
    }
  }

  const isSubmitDisabled = useMemo(() => {
    const { username = '', password = '', confirmPassword = '' } = formData
    return (
      isLoading ||
      !username.trim().length ||
      !confirmPassword.trim().length ||
      !password.trim().length
    )
  }, [formData, isLoading])

  const handleEmailValidation = (email: string) => {
    const isValid = isValidEmail(email)
    return isValid ? true : 'Invalid Email'
  }

  return (
    <LoginCardContainer>
      <Text.Headline.H3 className='mt-[50px]'>Forgot Password</Text.Headline.H3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col my-4'>
          {errorMessage && (
            <div className='bg-[#f8514926] border border-solid border-[#f8514966] rounded py-2 px-3 mb-3 text-center'>
              <Text.Body.Small>{errorMessage}</Text.Body.Small>
            </div>
          )}
          <TextField
            className='focus:outline-none focus:border-solid focus:border-white'
            {...register('username', {
              required: {
                message: 'Email is required',
                value: true
              },
              validate: handleEmailValidation
            })}
            disabled={isLoading}
            placeholder='Email'
          />
          {errors?.username && (
            <Text.Body.Small className='text-error mt-1 mb-2'>
              {errors?.username?.message ?? ''}
            </Text.Body.Small>
          )}

          <TextField
            {...register('password', {
              required: Password.messages.requiredNewPassword,
              minLength: {
                value: Password.minimumPasswordLength,
                message: Password.messages.minimumPassword
              },
              validate: Password.validatePassword
            })}
            disabled={isLoading}
            className='focus:outline-none focus:border-solid focus:border-white'
            placeholder='New Password'
            type={'password'}
          />
          {errors?.password && (
            <Text.Body.Small className='text-error mt-1 mb-2'>
              {errors?.password?.message ?? ''}
            </Text.Body.Small>
          )}
          <TextField
            {...register('confirmPassword', {
              required: Password.messages.requiredConfirmPassword,
              minLength: {
                value: Password.minimumPasswordLength,
                message: Password.messages.minimumPassword
              },
              validate: value => {
                if (watch('password') !== value) {
                  return 'New password and confirm password does not match'
                }
                return Password.validatePassword(value)
              }
            })}
            disabled={isLoading}
            className='focus:outline-none focus:border-solid focus:border-white'
            placeholder='Confirm New Password'
            type={'password'}
          />
          {errors?.confirmPassword && (
            <Text.Body.Small className='text-error mt-1 mb-2'>
              {errors?.confirmPassword?.message ?? ''}
            </Text.Body.Small>
          )}
        </div>

        <div className='flex gap-3'>
          <Button
            type='submit'
            title={!isLoading ? 'Submit' : 'Submitting...'}
            className='min-w-[116px] h-[43px]'
            disabled={isSubmitDisabled}
          />
        </div>
      </form>
    </LoginCardContainer>
  )

  // return (
  //   <div className='h-screen sm:h-auto py-[80px] px-8 login-panel border-solid border-[1px] border-black-800'>
  //     <AppLogo />
  //     <Text.Headline.H1 className='mt-[50px]'>Forgot Password</Text.Headline.H1>

  //     <form onSubmit={handleSubmit(onSubmit)}>
  //       <div className='flex flex-col my-4'>
  //         {errorMessage && (
  //           <div className='bg-[#f8514926] border border-solid border-[#f8514966] rounded py-2 px-3 mb-3 text-center'>
  //             <Text.Body.Small>{errorMessage}</Text.Body.Small>
  //           </div>
  //         )}
  //         <TextField
  //           className='focus:outline-none focus:border-solid focus:border-white'
  //           {...register('username', {
  //             required: {
  //               message: 'Email is required',
  //               value: true
  //             },
  //             validate: handleEmailValidation
  //           })}
  //           disabled={isLoading}
  //           placeholder='Email'
  //         />
  //         {errors?.username && (
  //           <Text.Body.Small className='text-error'>
  //             {errors?.username?.message ?? ''}
  //           </Text.Body.Small>
  //         )}

  //         <TextField
  //           {...register('password', {
  //             required: {
  //               message: 'New password is required',
  //               value: true
  //             },
  //             minLength: {
  //               message: 'Minimum of 8 characters',
  //               value: 8
  //             }
  //           })}
  //           disabled={isLoading}
  //           className='focus:outline-none focus:border-solid focus:border-white'
  //           placeholder='New Password'
  //           type={'password'}
  //         />
  //         {errors?.password && (
  //           <Text.Body.Small className='text-error'>
  //             {errors?.password?.message ?? ''}
  //           </Text.Body.Small>
  //         )}
  //         <TextField
  //           {...register('confirmPassword', {
  //             required: {
  //               message: 'Confirm new password is required',
  //               value: true
  //             },
  //             minLength: {
  //               message: 'Minimum of 8 characters',
  //               value: 8
  //             }
  //           })}
  //           disabled={isLoading}
  //           className='focus:outline-none focus:border-solid focus:border-white'
  //           placeholder='Confirm New Password'
  //           type={'password'}
  //         />
  //         {errors?.confirmPassword && (
  //           <Text.Body.Small className='text-error'>
  //             {errors?.confirmPassword?.message ?? ''}
  //           </Text.Body.Small>
  //         )}
  //       </div>

  //       <div className='flex gap-3'>
  //         <Button
  //           type='submit'
  //           title={!isLoading ? 'Submit' : 'Submitting...'}
  //           className='min-w-[116px] h-[43px]'
  //           disabled={isSubmitDisabled}
  //         />
  //       </div>
  //     </form>
  //   </div>
  // )
}

export default LoginForgotPassword
