import React, { useMemo } from 'react'
import { 
  Outlet, 
  useMatch } from 'react-router-dom'
import { GaragePageProps } from './types'
import { Routes } from '../../routes'
import SubNavigationBar from '../../components/organisms/SubNavigationBar'
import { NavigationBarItem } from '../../components/organisms/SubNavigationBar/types'

const GaragePage: React.FC<GaragePageProps> = () => {
  const matchTrackingPage = useMatch(Routes['garage_page.wildcard'])
  const navigationItems: NavigationBarItem[] = useMemo(() => [
    {
      id: 'global-fleet',
      label: 'Global Fleet'
    }
  ], [])

  const selectedIndex = useMemo(() => navigationItems.findIndex(item => item.id === matchTrackingPage?.params['*']?.split('/')?.[0]), 
  [matchTrackingPage?.params, navigationItems])

  return (
    <div className='h-full flex flex-col'>
      <SubNavigationBar items={navigationItems} selectedIndex={selectedIndex} />
      <Outlet />
    </div>
  )
}

export default GaragePage
