import React, { useContext, useEffect, useMemo } from 'react'
import { useMatch } from 'react-router-dom'
import { useJsApiLoader } from '@react-google-maps/api'
import Maps from './Maps'
import { KEYS } from './constants'
import { AppRootContext } from '../../../pages/AppRoot'
import { IToolTag } from '../../../models'
import { IBattery } from '../../../models/battery'
import { Routes } from '../../../routes'
import { useAppSelector } from '../../../store/hooks'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import { selectedTrackingGroup } from '../../../store/slices/trackingGroupSlice/selectors'
import { selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors'
import { IDevice } from '../../../models_v2/entity/device'

export const MapProvider: React.FC = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: KEYS.GOOGLE_MAP_API_KEY
  })
  const selectedGroup = useAppSelector(selectedTrackingGroup)
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const companyBatteries = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'battery',
      operatingCompany,
      withLocationData: true
    })
  ) as IDevice[]
  const companyMowers = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'mower',
      operatingCompany,
      withLocationData: true
    })
  ) as IDevice[]
  const companyTooltags = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'tools-and-tags',
      operatingCompany
    })
  ) as IDevice[]
  const currentGroupDevices = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'mower',
      operatingCompany,
      groupId: selectedGroup.groupId,
      withLocationData: true
    })
  ) as IDevice[]

  // We have to change the 4th condition to verify if we are in battery list
  // so we can set it to false and update state in that page, otherwise we don't want
  // to add batteries to our device state everytime we load the map
  // cause that will display them alongside mowers
  const { state, dispatch } = useContext(AppRootContext)

  const {
    // do not uncomment, we need to have 1 source of truth for mowers from redux, not from the old state
    // otherwise we dont get the map filtering, search, etc. Using appSelector instead.
    // mowers = [],
    selected_mower_ids = [],
    selected_mower_id = '',
    selected_mowers = [],
    pre_selected_mower_id = '',

    // Batteries
    // batteries = [],
    selected_battery_ids = [],
    selected_battery_id = '',
    selected_batteries = [],
    pre_selected_battery_id = '',

    // Tool Tags
    tool_tags = [],
    selected_tool_tag_ids = [],
    selected_tool_tag_id = '',
    selected_tool_tags = [],
    pre_selected_tool_tag_id = ''
  } = state ?? {}

  const isDefaultTracking = useMatch(Routes.root_page)
  const isTracking = useMatch(Routes.tracking_page)
  const isTrackingMower = useMatch(Routes['tracking_page.mowers.wildcard'])
  const isTrackingBatteries = useMatch(
    Routes['tracking_page.batteries.wildcard']
  )
  const isTrackingGroups = useMatch(Routes['tracking_page.groups'])
  const isTrackingToolTags = useMatch(
    Routes['tracking_page.tool_tags.wildcard']
  )
  const isCombinedTrackingMower =
    !!isTrackingMower || !!isTracking || !!isDefaultTracking
  const isTrackingMowerDetails = useMatch(Routes['tracking_page.mowers.detail'])
  const isTrackingBatteryDetails = useMatch(
    Routes['tracking_page.batteries.detail']
  )
  const isTrackingToolTagDetails = useMatch(
    Routes['tracking_page.tool_tags.detail']
  )

  const memoizedMapData = useMemo(() => {
    let mapData = {
      data: [] as Array<any>,
      selected_ids: [] as Array<string>,
      selected_id: '',
      selected_data: [] as Array<any>,
      pre_selected_id: ''
    }

    if (isTrackingMower || isDefaultTracking || isTracking) {
      mapData = {
        // @ts-ignore
        data: companyMowers,
        selected_ids: selected_mower_ids ?? [],
        selected_id: selected_mower_id,
        selected_data: selected_mowers as any,
        pre_selected_id: pre_selected_mower_id as string
      }
    } else if (isTrackingBatteries) {
      mapData = {
        // @ts-ignore
        data: companyBatteries,
        selected_ids: selected_battery_ids,
        selected_id: selected_battery_id,
        selected_data: selected_batteries as Array<IBattery>,
        pre_selected_id: pre_selected_battery_id
      }
    } else if (isTrackingGroups) {
      mapData = {
        data: currentGroupDevices,
        selected_ids: [],
        selected_id: '',
        selected_data: [],
        pre_selected_id: ''
      }
    } else if (isTrackingToolTags) {
      mapData = {
        data: companyTooltags,
        selected_ids: selected_tool_tag_ids ?? [],
        selected_id: selected_tool_tag_id,
        selected_data: selected_tool_tags,
        pre_selected_id: pre_selected_tool_tag_id
      }
    }

    // TODO: we should convert all these locations for ALL devices in the transformers
    const processedData = mapData?.data?.map(item => {
      const telementry_location =
        typeof item?.latestTelemetry?.location === 'string'
          ? JSON.parse(item?.latestTelemetry?.location)
          : item?.latestTelemetry?.location
      return {
        ...item,
        coordinates: {
          lat: Number(telementry_location.latitude),
          lng: Number(telementry_location.longitude)
        }
      }
    })

    return {
      ...mapData,
      data: processedData
    }
  }, [
    isTrackingMower,
    isTrackingBatteries,
    isTrackingToolTags,
    isDefaultTracking,
    isTracking,
    companyMowers,
    companyBatteries,
    selected_mower_ids,
    selected_mower_id,
    selected_mowers,
    pre_selected_mower_id,
    // batteries,
    selected_battery_ids,
    selected_battery_id,
    selected_batteries,
    pre_selected_battery_id,
    tool_tags,
    selected_tool_tag_ids,
    selected_tool_tag_id,
    selected_tool_tags,
    pre_selected_tool_tag_id,
    operatingCompany,
    selectedGroup,
    companyTooltags
  ])

  useEffect(() => {
    if (!companyMowers && !companyBatteries && !companyTooltags) return

    dispatch?.({
      type: 'SET_MOWERS',
      payload: companyMowers as any[]
    })
    dispatch?.({
      type: 'SET_BATTERIES',
      payload: companyBatteries as any[]
    })
    dispatch?.({
      type: 'SET_TOOL_TAGS',
      payload: companyTooltags as any[]
    })
  }, [companyMowers?.length, companyBatteries?.length, companyTooltags?.length])

  if (loadError) {
    return <p>Error loding maps.</p>
  }

  return (
    <>
      {isLoaded && (
        <Maps
          data={memoizedMapData.data}
          selected_ids={memoizedMapData.selected_ids}
          selected_id={memoizedMapData.selected_id}
          selected_data={memoizedMapData.selected_data}
          pre_selected_id={memoizedMapData.pre_selected_id}
          isDefaultTracking={isDefaultTracking}
          isTracking={isTracking}
          isTrackingBatteries={isTrackingBatteries}
          isTrackingToolTags={isTrackingToolTags}
          isTrackingMower={isTrackingMower}
          isCombinedTrackingMower={isCombinedTrackingMower}
          isTrackingMowerDetails={isTrackingMowerDetails}
          isTrackingBatteryDetails={isTrackingBatteryDetails}
          isTrackingToolTagDetails={isTrackingToolTagDetails}
        />
      )}
    </>
  )
}

export default MapProvider
