import React, { useMemo, useState } from 'react'
import { Icon } from '../../atoms'
import styled from 'styled-components'
import { IBattery } from '../../../models'
import { usePopper } from 'react-popper'
import { Popover, Transition } from '@headlessui/react'
import { BatteryCommon } from '../../molecules'
import { useBreakpoint, useWindowDimensions } from '../../../hooks'

interface PowerEquipButtonProps {
  item: Partial<IBattery>
  onClick?: () => void
}

const PowerEquipButton: React.FC<PowerEquipButtonProps> = props => {
  const { item, onClick } = props

  const { width: windowWidth } = useWindowDimensions()
  const { breakpoint } = useBreakpoint(windowWidth)

  const popperOffset = useMemo<[number, number]>(() => {
    if (['xl', '2xl'].includes(breakpoint)) {
      return [-8, 135]
    } else if (['lg', 'md'].includes(breakpoint)) {
      return [-15, 110]
    } else {
      return [-10, 110]
    }
  }, [breakpoint])

  const [popoverOpen, setPopoverOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState<any>()
  const [popperElement, setPopperElement] = useState<any>()
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: popperOffset
        }
      },
      {
        name: 'flip',
        options: {
          padding: {
            top: 12
          }
        }
      }
    ]
  })

  return (
    <Popover>
      {({ open }) => {
        return (
          <>
            {/* @ts-ignore */}
            <StyledPopoverButton
              key={`${popoverOpen.toString()}-${breakpoint}`}
              className='dark:bg-black/70 px-[10px] py-2 border rounded-md border-[#707070] h-full focus:outline-none'
              ref={setReferenceElement}
              onClick={() => {
                setPopoverOpen(!open)
                onClick?.()
              }}>
              <Icon
                name='power_equip'
                className='min-w-[18px] invert dark:invert-0'
              />
            </StyledPopoverButton>

            <Popover.Panel
              static
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className='absolute z-10 focus:outline-none'>
              {({ close }) => {
                return (
                  <Transition
                    show={open}
                    enter='transition ease-in-out duration-200 transform'
                    enterFrom='opacity-0 -translate-x-2'
                    enterTo='opacity-100 translate-x-0'
                    leave='transition ease-in-out duration-200 transform'
                    leaveFrom='opacity-100 translate-x-0'
                    leaveTo='opacity-0 -translate-x-2'>
                    <BatteryCommon.SubscriptionInfoCard
                      item={item}
                      onClose={close}
                    />
                  </Transition>
                )
              }}
            </Popover.Panel>
          </>
        )
      }}
    </Popover>
  )
}

export default PowerEquipButton

const StyledPopoverButton = styled(Popover.Button)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
`
