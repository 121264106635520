import React, { useMemo } from 'react'
import { IMower } from '../../../../models'
import { Icon, StatusIndicator, Text } from '../../../atoms'
import { DynamicListItem } from '../../../molecules'
import { Mower } from '../../../../models_v2/common/mower'

interface FaultHistorySectionProps {
  mower: Partial<Mower>
  onFaultHistoryClick: () => void
}

interface FaultCount {
  new: number
  active: number
}

const FaultHistorySection: React.FC<FaultHistorySectionProps> = props => {
  const { mower, onFaultHistoryClick } = props
  const { metadata = [] } = mower

  const faultsCount = useMemo<FaultCount>(() => {
    return metadata?.reduce(
      (acc: FaultCount, curr) => ({
        new: curr.state === 'New' ? acc.new + 1 : acc.new,
        active: curr.active ? acc.active + 1 : acc.active
      }),
      {
        new: 0,
        active: 0
      } as FaultCount
    )
  }, [metadata])

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex justify-end'>
        <div className='flex gap-2'>
          <Text.VariousRegular.Medium
            className='dark:text-white cursor-pointer'
            onClick={onFaultHistoryClick}>
            {`Fault History (${metadata?.length ?? 0} Total)`}
          </Text.VariousRegular.Medium>
          <Icon
            name='caret_right'
            className='invert dark:filter-none'
            width={9}
          />
        </div>
      </div>

      {(!!faultsCount?.active || !!faultsCount?.new) && (
        <DynamicListItem className='justify-end !bg-transparent dark:!bg-[#1E1E1E] gap-4 !px-4'>
          <div className='flex gap-4'>
            <Text.Body.Medium className='text-error'>
              {`${faultsCount.active} Active`}
            </Text.Body.Medium>
            <Text.Body.Medium className='text-black dark:text-white'>{`${faultsCount.new} New`}</Text.Body.Medium>
          </div>
          <StatusIndicator
            showLabel={false}
            value='danger_hd'
            hasWrapperInImage={false}
          />
        </DynamicListItem>
      )}
    </div>
  )
}

export default FaultHistorySection
