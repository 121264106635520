import React, { useMemo } from 'react'
import moment from 'moment'
import cn from 'classnames'
import { IMower } from '../../../models'
import { Text } from '../../atoms'
import { Trans } from '@lingui/macro'
import { Mower } from '../../../models_v2/common/mower'

interface LastSignalProps {
  className?: string
  mower: Partial<Mower>
}

const LastSignal: React.FC<LastSignalProps> = props => {
  const { className, mower } = props
  // const { last_refresh, last_seen_mower, network_status } = mower

  const lastSignal = useMemo(() => {
    const lastSignalData = moment(mower.latestTelemetry?.timestamp)
    return lastSignalData.format('MM/DD/YYYY hh:mm A')
  }, [])

  // if (network_status?.toLowerCase?.() !== 'offline' && !lastSignal) return null

  // const lastSignal = mower.getLastSignal?.()

  return (
    <div
      className={cn(
        'flex flex-col lg:flex-row justify-start lg:gap-2',
        className
      )}>
      <Text.VariousRegular.Small>
        <Trans>Last Seen:</Trans>
      </Text.VariousRegular.Small>
      <Text.VariousBold.Small className='latest-signal'>
        {lastSignal ? lastSignal : 'Invalid date'}
        {/* {lastSignal.isValid()
          ? lastSignal.format('MM/DD/YYYY hh:mm A')
          : 'Invalid date'} */}
      </Text.VariousBold.Small>
    </div>
  )
}

export default LastSignal
