import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IDevice } from '../../models_v2/entity/device'

interface MapState {
  activeMapMarkerProductSerial?: IDevice['productSerial']
}

const initialState: MapState = {
  activeMapMarkerProductSerial: '',
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setActiveMapMarkerProductSerial: (
      state,
      action: PayloadAction<IDevice['productSerial'] | undefined>
    ) => {
      state.activeMapMarkerProductSerial = action?.payload
    },
    resetMapState: () => initialState
  }
})

export const { setActiveMapMarkerProductSerial, resetMapState } = mapSlice.actions

export const mapSliceRecuder = mapSlice.reducer
export default mapSlice.reducer