import React, { Fragment, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useDynamicQuery } from '../../../hooks'
import { IQueryOptions } from '../../../hooks/types'
import {
  EUserTypes,
  setDealerAccounts,
  setUserAccounts
} from '../../../store/slices/organizationSettingsSlice'
import { setCompanies } from '../../../store/slices/organizationCompanySlice'
import { CompanyOrganizationList } from '../CompanyOrganizationList'
import { CompanyList } from '../CompanyList'

const queryPathMap: Record<EUserTypes, IQueryOptions> = {
  [EUserTypes.People]: {
    graphqlEntity: 'users',
    queryKey: 'GET_ALL_USERS',
    mapperKey: 'user_entity_to_user'
  },
  [EUserTypes.Companies]: {
    graphqlEntity: 'companies',
    queryKey: 'GET_COMPANIES'
  },
  [EUserTypes.Dealers]: {
    graphqlEntity: 'dealers',
    queryKey: 'GET_ALL_DEALERS'
  }
}

const userTypeDispatchMap: Record<EUserTypes, any> = {
  [EUserTypes.People]: setUserAccounts,
  [EUserTypes.Companies]: setCompanies,
  [EUserTypes.Dealers]: setDealerAccounts
}

const OrganizationListWrapper: React.FC = () => {
  const appDispatch = useAppDispatch()
  const activeList = useAppSelector(
    state => state.organizationSettings.activeList
  )
  const { data } = useDynamicQuery<[]>(
    queryPathMap[activeList as keyof typeof queryPathMap]
  )
  const userAccounts = useAppSelector(
    state => state.organizationSettings.userAccounts
  )

  useEffect(() => {
    if (userAccounts?.length) return

    appDispatch(
      userTypeDispatchMap[activeList as keyof typeof queryPathMap](data)
    )
  }, [data?.length, activeList, userAccounts])

  const CurrentList = useMemo(() => {
    switch (activeList) {
      case EUserTypes.People:
        return <CompanyOrganizationList />
      case EUserTypes.Companies:
        return <CompanyList />
      case EUserTypes.Dealers:
        return <Fragment />
      default:
        return <CompanyOrganizationList />
    }
  }, [activeList])

  return <>{CurrentList}</>
}

export default OrganizationListWrapper
