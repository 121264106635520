import { Device } from '../device'
import { IUserEntity } from '../entity'
import { mapDeviceToMower } from './device_to_mower'
import { mapDeviceToToolTag } from './device_to_tool_tag'
import { mapDeviceToBattery } from './device_to_battery'
import { mapUserEntityToUser } from './user_entity_to_user'

import { TMapperKeysV2 } from '../../models_v2/mapper/mappersV2'
import { MAPPER_COLLECTION_V2 } from '../../models_v2/mapper/mappersV2'

export type TMapperKeys =
  | 'device_to_mower'
  | 'device_to_battery'
  | 'device_to_tool_tag'
  | 'device_to_mower_data'
  | 'device_to_battery_data'
  | 'device_to_tool_tag_data'
  | 'user_entity_to_user'
  | TMapperKeysV2

export const MAPPER_COLLECTION: Record<TMapperKeys, (data: any) => any> = {
  ...MAPPER_COLLECTION_V2,
  device_to_mower: (data: Device[]) => {
    return data?.filter(item => item.deviceType === 0).map(mapDeviceToMower)
  },
  device_to_tool_tag: (data: Device[]) => {
    return data?.filter(item => item.deviceType === 1).map(mapDeviceToToolTag)
  },
  // TODO: Data for display only, waiting for deviceType === 2
  device_to_battery: (data: Device[]) => {
    return data
      ?.filter(item => item?.name?.includes?.('DCBPE6060'))
      .map(mapDeviceToBattery)
  },
  device_to_mower_data: (data: Device) => {
    return mapDeviceToMower(data)
  },
  device_to_battery_data: (data: Device) => {
    return mapDeviceToBattery(data)
  },
  device_to_tool_tag_data: (data: Device) => {
    return mapDeviceToToolTag(data)
  },
  user_entity_to_user: (data: IUserEntity[]) => {
    return data?.filter(Boolean).map(mapUserEntityToUser)
  }
}
