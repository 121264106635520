import React, { useMemo } from 'react';
import cn from 'classnames'
import { TDeviceBatteryFaceLevel } from '../../../models/device_battery';
import { Text } from '../../atoms'
import { ReactComponent as FacePoor } from '../../../assets/icons/face_poor.svg'
import { ReactComponent as FaceFair } from '../../../assets/icons/face_fair.svg'
import { ReactComponent as FaceGood } from '../../../assets/icons/face_good.svg'
import { ReactComponent as FaceExcellent } from '../../../assets/icons/face_excellent.svg'


interface DeviceBatteryFaceLevelProps {
  className?: string
  isActive?: boolean
  value: TDeviceBatteryFaceLevel
}

const DeviceBatteryFaceLevel: React.FC<DeviceBatteryFaceLevelProps> = (props) => {
  const { className, isActive = false, value } = props;

  const renderValue = useMemo(() => {
    let Icon: React.FC<any>
    let label = ''

    if (value === 'poor') {
      Icon = FacePoor
      label = 'Poor'
    } else if (value === 'fair') {
      Icon = FaceFair
      label = 'Fair'
    } else if (value === 'good') {
      Icon = FaceGood
      label = 'Good'
    } else if (value === 'excellent') {
      Icon = FaceExcellent
      label = 'Excellent'
    } 

    return {
      // @ts-ignore
      icon: <Icon className='h-[34px] w-[34px]' fill={!isActive ? '#808080' : '#FFFFFF'} />,
      label
    }
  }, [value, isActive])

  return (
    <div className={cn('flex flex-col items-center gap-3', className)}>
      <div className={cn('p-[10px] rounded-full', {
        'bg-vintage_cjs': isActive
      })}>
        {renderValue.icon}
      </div>
      <Text.VariousBold.Small className={cn('text-black-500', {
        'text-white': isActive
      })}>{renderValue.label}</Text.VariousBold.Small>
    </div>
  )
}

export default DeviceBatteryFaceLevel;