import React, { useContext } from 'react'
import {
  ReportingAssetSelector,
  ReportingCalendar,
  ReportingExportsForm,
  SidebarContainer
} from '../../molecules'
import { Divider } from '../../atoms'
import { ReportingPageContext } from '../../../pages/ReportingPage'
import { Transition } from '@headlessui/react'

const ReportingExports = () => {
  const { state } = useContext(ReportingPageContext)

  return (
    <div className='flex flex-1 overflow-hidden'>
      <SidebarContainer className='border-r-2 border-black overflow-y-auto'>
        <div className='p-[30px] pt-[22px]'>
          <ReportingExportsForm />
        </div>
      </SidebarContainer>

      <div className='reporting-calendar-container flex-1 flex flex-col overflow-auto bg-app-main-light-100 dark:bg-transparent'>
        <Transition
          show={state?.asset_selection?.export}
          enter='transition-all ease-in-out duration-150'
          enterFrom='opacity-0 -translate-y-full'
          enterTo='opacity-100 translate-y-0'
          leave='transition-all ease-in-out duration-300'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 -translate-y-full'>
          <ReportingAssetSelector reportingType='export' className='h-full' />
          <Divider size='md' />
        </Transition>

        <ReportingCalendar reportingType='export' />
      </div>
    </div>
  )
}

export default ReportingExports
