import * as GQL_QUERIES from '../services/gql'
import { MAPPER_COLLECTION } from '../models/mapper'
import { ApolloClient } from '@apollo/client'
import { IQueryOptions, IRestQueries } from './types'
import axios from 'axios'
import * as REST_QUERIES from '../services/rest'

interface RestRequestParams {
  params: IQueryOptions
  data?: any
}

interface ApolloRequestParams {
  apolloClient: ApolloClient<object>
  params: IQueryOptions
  data?: any
  isMutation?: boolean
}

export const restRequest = async ({
  params: { queryKey, body = {}, params = {}, endpoint },
  data
}: RestRequestParams) => {
  const {
    method,
    host = process.env.REACT_APP_SERVER_ENDPOINT,
    path,
    headers
  } = (REST_QUERIES as Record<string, IRestQueries>)[queryKey] ?? {}
  const res = await axios.request({
    url: `${host}${endpoint ?? path}`,
    data: data ?? body,
    method,
    params,
    headers
  })
  if (res.data?.errors?.length) {
    throw new Error(`Error in REST request: ${endpoint}`)
  }
  return res.data
}

export const apolloRequest = async ({
  apolloClient,
  params: { queryKey, body = {}, mapperKey, graphqlEntity },
  data,
  isMutation
}: ApolloRequestParams) => {
  const query = (GQL_QUERIES as any)[queryKey] ?? ''
  const params: any = {
    variables: data ?? body,
    context: {
      graphqlEntity: graphqlEntity ?? ''
    }
  }
  // mutations return async mutation which can be done later, in this case we want to return the query to be called later by react query
  if (isMutation) {
    params.mutation = query;
    return apolloClient.mutate(params)

  }
  params.query = query

  // else we will return the query data
  const res = await apolloClient.query(params)

  let result = res?.data?.result
  if (!result) {
    return []
  }
  if (MAPPER_COLLECTION && mapperKey && MAPPER_COLLECTION[mapperKey]) {
    return MAPPER_COLLECTION[mapperKey](result)
  }
  return result
}
