import { gql } from '@apollo/client'

// query device($id: String) {
//   result: getById(id: $id) {

//     productSerial

//     telemetries {

//       type
//       location
//       #payload
//       timestamp
//       #status

//     }

//   }

// }

// query getDevice {

//   getById(id: "1E15PE10012") {

//     productSerial

//     telemetries {

//       type
//       location
//       #payload
//       timestamp
//       #status

//     }

//   }

// }


export const GET_DEVICE_BY_ID_TELEMETRY = gql`
query getDevice($productSerial: String!, $startTime: String!, $endTime: String!) {
  getById(id: $productSerial, endTime: $endTime, startTime: $startTime) {
    productSerial
    inventory {
      source
      groupId
      companyId
      name
      model
      factoryModel
      deviceType
      dealer
      softwareVersion
    }
    telemetries {
      type
      location
      #payload
      timestamp
      status
    }
  }
}

`

// gql`
//   query getById($productSerial: String) {
//       result: getById(productSerial: $productSerial) {
//         productSerial
//         telemetries
//       }
//   }
// `

export const GET_DEVICES_TELEMETRY = gql`
query devices($companies: [String]) {
  result: devices(companies: $companies) {
     telemetries {
      type
      location
      payload
      timestamp
      status
    }
  }
}
`

export const GET_DEVICES = gql`
  query devices($companies: [String]) {
    result: devices(companies: $companies) {
      productSerial
      latestTelemetry {
        type
        location
        payload
        timestamp
        status
      }
      # Huge payload, causes slow response
      # telemetries {
      #  type
      #  location
      #  payload
      #  timestamp
      #  status
      #}
      inventory {
        deviceType
        source
        groupId
        companyId
        name
        model
        factoryModel
        dealer
        softwareVersion
        toolType
        toolMake
        toolModel
        toolSerialNumber
        toolPowerType
      }
      metadata {
        productSerial
        metadataId
        groupId
        faultCode
        payload
        timestamp
        createdAt
        active
        state
        notes
      }
      #analytic {
      #  last24Hours
      #  last7Days
      #  last30Days
      #}
    }
  }
`

export const GET_DEVICE_BY_ID = gql`
  query getById(
    $id: String!
    $user_id: String
    $startTime: String
    $endTime: String
  ) {
    result: getById(
      id: $id
      user: $user_id
      startTime: $startTime
      endTime: $endTime
    ) {
      productSerial
      latestTelemetry {
        type
        location
        #  payload
        timestamp
        status
      }
      # Huge payload, causes slow response
      #telemetries {
      #  type
      #  location
      #  payload
      #  timestamp
      #  status
      #}
      inventory {
        source
        name
        model
        factoryModel
        dealer
        softwareVersion
      }
      metadata {
        productSerial
        metadataId
        groupId
        faultCode
        payload
        timestamp
        createdAt
        active
        state
        notes
      }
      #analytic {
      #  last24Hours
      #  last7Days
      #  last30Days
      #}
    }
  }
`


// export const GET_DEVICES = gql`
//   query devices($companies: [String]) {
//     result: devices(companies: $companies) {
//       productSerial
//       latestTelemetry {
//         type
//         location
//         payload
//         timestamp
//         status
//       }
//       # Huge payload, causes slow response
//       # telemetries {
//       #  type
//       #  location
//       #  payload
//       #  timestamp
//       #  status
//       #}
//       inventory {
//         source
//         name
//         model
//         factoryModel
//         dealer
//         softwareVersion
//       }
//       metadata {
//         productSerial
//         metadataId
//         groupId
//         faultCode
//         payload
//         timestamp
//         createdAt
//         active
//         state
//         notes
//       }
//       #analytic {
//       #  last24Hours
//       #  last7Days
//       #  last30Days
//       #}
//     }
//   }
// `
// export const GET_DEVICES = gql`
//   query devices($companies: [String]) {
//     result: devices(companies: $companies) {

  
// export const GET_GROUPS_BY_COMPANY_ID = gql`
//   query groups($companies: [String]) {
//     result: groups(companies: $companies) {
//       groupName
//     }
//   }
// `

export const GET_GROUPS_BY_COMPANY_ID = gql`
  query Groups {
    result: groups(companyId: "*") {
      companyId
      groupId
      groupName
      productSerial
    }
  }
`

export const GET_COMPANIES  = gql`
  query Companies {
    result: companies {
      inventory {
        companyId
        dealer
        deviceType
        factoryModel
        groupId
        model
        name
        softwareVersion
        source
      }
      companyId
    }
  }
`

export const GET_ALL_USERS = gql`
  query GET_ALL_USERS {
    result: getAllUsers {
      id
      firstName
      lastName
      email
      phone
      company
      companies
      role
      createdAt
      updatedAt
      lastLogin
      status
    }
  }
`

export const GET_BATTERY_DEVICE_BY_ID_TELEMETRY = gql`
query getDevice($productSerial: String!, $startTime: String!, $endTime: String!) {
  getById(id: $productSerial, endTime: $endTime, startTime: $startTime) {
    productSerial
    inventory {
      source
      groupId
      companyId
      name
      model
      factoryModel
      deviceType
      dealer
      softwareVersion
    }
    telemetries {
      type
      location
      payload
      timestamp
      status
    }
  }
}`