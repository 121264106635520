import { IInventoryInputDto } from '../../models_v2/dto/inventory_input'
import { IDevice } from '../../models_v2/entity/device'
import { useAppDispatch } from '../../store/hooks'
import { upsertDevice } from '../../store/slices/deviceSliceV2'
import { useApolloMutation } from '../useApolloMutation'

export const useInventoryUpdate = () => {
  const dispatch = useAppDispatch()

  return useApolloMutation<IDevice, IInventoryInputDto>({
    query: 'UPDATE_INVENTORY',
    options: {
      onCompleted: data => dispatch(upsertDevice({ 
        device: data.result,
      }))
    }
  })
}
