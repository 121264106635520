import React, { forwardRef } from 'react'
import cn from 'classnames'

interface CheckboxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string | React.ReactNode
  containerClassName?: string
  useDefaultCheckboxStyle?: boolean
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    label,
    containerClassName,
    className,
    useDefaultCheckboxStyle = true,
    ...restProps
  } = props

  return (
    <div className={cn('flex items-center gap-2', containerClassName)}>
      <input
        id='checkbox'
        type='checkbox'
        ref={ref}
        className={cn(
          {
            'form-checkbox checked:rounded-sm bg-transparent text-black invert focus:ring-0':
              useDefaultCheckboxStyle
          },
          className
        )}
        {...restProps}
      />
      {label && (
        <label htmlFor='checkbox' className='text-sm'>
          {label}
        </label>
      )}
    </div>
  )
})

export default Checkbox
