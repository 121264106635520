import { 
  useCallback, 
  useEffect, 
  useMemo } from 'react'
import { 
  StatusIndicator, 
  Table, 
  Text } from '../../atoms'
import { Column } from 'react-table'
import { IUserAccount } from '../../../models'
import { TStatus } from '../../atoms/StatusIndicator/types'
import { userAccountStatusResolver } from '../../../models/utils/status'
import { 
  useAppDispatch, 
  useAppSelector } from '../../../store/hooks'
import { useCompanies, useDynamicQuery } from '../../../hooks'
import { 
  EUserTypes,
  setDealerAccounts, 
  setSelectedItem, 
  setUserAccounts } from '../../../store/slices/organizationSettingsSlice';
import { selectCompanyDictionary } from '../../../store/slices/company/selectors';
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import { IQueryOptions } from '../../../hooks/types';

interface CompanyOrganizationListProps { }

const queryPathMap: Record<EUserTypes, IQueryOptions> = {
  [EUserTypes.People]: {
    graphqlEntity: 'users',
    queryKey: 'GET_ALL_USERS',
    mapperKey: 'user_entity_to_user'
  },
  [EUserTypes.Companies]: {
    graphqlEntity: 'companies',
    queryKey: 'GET_COMPANIES',
  },
  [EUserTypes.Dealers]: {
    graphqlEntity: 'dealers',
    queryKey: 'GET_ALL_DEALERS',
  },
}

const statusMap: Record<'Invited' | 'Active' | 'Inactive', TStatus> = {
  'Invited': 'offline',
  'Active': 'online',
  'Inactive': 'error',
}

export const CompanyOrganizationList: React.FC<CompanyOrganizationListProps> = () => {
  const activeList = useAppSelector(state => state.organizationSettings.activeList);
  const { searchFilter } = useAppSelector(state => state.organizationSettings)
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const userAccounts = useAppSelector(state => state.organizationSettings.userAccounts)
  const companyDictionary = useAppSelector(selectCompanyDictionary)
  const reduxDispatch = useAppDispatch()
  const { data: companies } = useCompanies();
  const { data, isLoading, refetch } = useDynamicQuery<[]>(queryPathMap[activeList as keyof typeof queryPathMap])
  const filteredData = useMemo(() =>{
    if (!searchFilter) return userAccounts;

    return userAccounts?.filter((person: IUserAccount) => {
      const companyMatchesOperatingCompany =
        operatingCompany.name === "Global" || person?.companies?.includes(operatingCompany.companyId);
      
      const companyMatch = companies?.some(company => company?.companyId && person?.companies?.includes(company.companyId)
        && company?.inventory?.name?.toLowerCase().includes(searchFilter))

      return companyMatchesOperatingCompany && (
        companyMatch ||
        person.first_name?.toLowerCase().includes(searchFilter) ||
        person.last_name?.toLowerCase().includes(searchFilter) ||
        person.email?.toLowerCase().includes(searchFilter));
    });
  }, [ userAccounts, searchFilter, operatingCompany, companies ])

  const RenderStatusCell = useCallback((item: IUserAccount) => {
    const { status } = item
    return (
      <div className='flex items-center gap-2'>
        <StatusIndicator showLabel={false} value={statusMap[status ?? 'Invited']} />
        <Text.Body.Medium>{userAccountStatusResolver(item)}</Text.Body.Medium>
      </div>
    )
  }, [])
  const userTypeDispatchMap: Record<any, any> = {
    [EUserTypes.People]: setUserAccounts,
    [EUserTypes.Dealers]: setDealerAccounts,
  }

  useEffect(() => {
    refetch()
  }, [activeList, refetch])

  useEffect(() => {
    if (userAccounts?.length) return

    reduxDispatch(
      userTypeDispatchMap[activeList as keyof typeof queryPathMap](data)
    )
  }, [data?.length, activeList, userAccounts])

  const handleOnRowSelected = ( user: IUserAccount[] ) =>{
    reduxDispatch(setSelectedItem(user?.[0]))
  }

  const columns: Column[] = [
    {
      Header: 'First Name',
      accessor: 'first_name',
      width: 150,
      maxWidth: 150
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
      width: 150,
      maxWidth: 150
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Company',
      Cell: cell => {
        const [company] = (cell?.row?.original as IUserAccount)?.companies || []
        return companyDictionary?.[company]?.inventory?.name || ''
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: cell => RenderStatusCell(cell.row.original as IUserAccount)
    }
  ]

  return (
    <Table
      data={filteredData ?? []}
      columns={columns}
      isLoading={isLoading}
      enableRowSelection
      defaultSortedColumn={{
        key: 'first_name',
        sort: 'ascending'
      }}
      columnClassName='text-black dark:text-white'
      onRowsSelected={data => handleOnRowSelected(data as IUserAccount[])}
    />
  )
}
