import { useMemo, useContext, useCallback } from 'react'
import cn from 'classnames'
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom'
import { Text } from '../../components/atoms'
import {
  TrackingSubNavigationBar,
  MapsProvider,
  FaultHistoryCard,
  SurepathPerformanceCard
} from '../../components/organisms'
import { SidebarContainer, SurepathDropdown } from '../../components/molecules'
import { NavigationBarItem } from '../../components/organisms/TrackingSubNavigationBar/types'
import { Routes } from '../../routes'
import { AppRootContext } from '../AppRoot'
import { IMower, IToolTag } from '../../models'
import { IBattery } from '../../models/battery'
import { ESortDirection, IReducedData } from './types'
import { Transition } from '@headlessui/react'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import {
  EActiveOverlay,
  EActiveOverlayPosition
} from '../AppRoot/reducers/types'
import { useAppSelector } from '../../store/hooks'
import { selectSelectedDevice } from '../../store/slices/trackingSlice/selectors'
import { selectOperatingCompany } from '../../store/slices/operatingCompany'
import ActivityTimelineCard from '../../components/organisms/ActivityTimelineCard'
import { IDevice } from '../../models_v2/entity/device'
import {
  selectDevices,
  selectFilteredDevices
} from '../../store/slices/deviceSliceV2/selectors'

interface IDeviceAlphanumericSort {
  alphabetic: Record<string, any>[]
  numeric: Record<string, any>[]
}

const TrackingPage = () => {
  const { i18n } = useLingui()
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const { state, emitter } = useContext(AppRootContext)
  const matchTrackingPage = useMatch(`${Routes.tracking_page}/*`)
  const navigate = useNavigate()
  const selectedDevice = useAppSelector(selectSelectedDevice)
  const navigationItems: NavigationBarItem[] = useMemo(() => {
    const navItems = [
      {
        id: 'mowers',
        label: t`Mowers`,
        queryKey: 'GET_TRACKING_MOWERS'
      },
      {
        id: 'batteries',
        label: t`Batteries`,
        queryKey: 'GET_TRACKING_BATTERIES'
      },
      {
        id: 'tool_tags',
        label: t`Tools & Tags`,
        queryKey: 'GET_TRACKING_TOOL_TAGS'
      }
    ]
    if (operatingCompany.name !== 'Global') {
      navItems.push({
        id: 'groups',
        label: t`Groups`,
        queryKey: 'GET_GROUPS'
      })
    }
    return navItems
  }, [i18n.locale, operatingCompany])
  const selectedIndex = useMemo(
    () =>
      navigationItems.findIndex(
        item => item.id === matchTrackingPage?.params['*']?.split('/')?.[0]
      ),
    [matchTrackingPage?.params, navigationItems]
  )
  const memoizedSelectedItem = useMemo(
    () => navigationItems[selectedIndex],
    [selectedIndex, navigationItems]
  )
  const devices = useAppSelector(selectDevices)
  const companyBatteries = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'battery',
      operatingCompany
    })
  ) as IDevice[]
  const companyMowers = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'mower',
      operatingCompany
    })
  ) as IDevice[]
  const companyTooltags = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'tools-and-tags',
      operatingCompany
    })
  ) as IDevice[]

  const sortData = useCallback(({ items = [], selectedIds = [], sortBy }):
    | IBattery[]
    | IMower[]
    | IToolTag[] => {
    const data = items?.reduce(
      (acc: IReducedData, curr: IBattery | IMower | IToolTag) => {
        const { selected, unselected } = acc ?? {}

        if (selectedIds?.includes(curr?.id)) {
          return {
            ...acc,
            selected: [...selected, curr]
          }
        }

        return {
          ...acc,
          unselected: [...unselected, curr]
        }
      },
      {
        selected: [],
        unselected: []
      } as IReducedData
    )
    const { selected = [], unselected = [] } = data ?? {}
    const selected_alphanumeric = selected?.reduce(
      (acc: any, curr: Record<string, any>) => {
        const firstCharacter = curr?.[sortBy]?.trim()?.[0]

        if (!isNaN(Number(firstCharacter))) {
          return {
            ...acc,
            numeric: [...acc?.numeric, curr]
          }
        }

        return {
          ...acc,
          alphabetic: [...acc?.alphabetic, curr]
        }
      },
      { alphabetic: [], numeric: [] }
    )

    const unselected_alphanumeric = unselected?.reduce(
      (acc: any, curr: Record<string, any>) => {
        const firstCharacter = curr?.[sortBy]?.trim()?.[0]

        if (!isNaN(Number(firstCharacter))) {
          return {
            ...acc,
            numeric: [...acc?.numeric, curr]
          }
        }

        return {
          ...acc,
          alphabetic: [...acc?.alphabetic, curr]
        }
      },
      { alphabetic: [], numeric: [] }
    )

    const sorted_selected_alphanumeric = Object.entries(
      selected_alphanumeric
    )?.reduce((acc, [key, group]) => {
      return {
        ...acc,
        [key]: (group as Record<string, string>[])?.sort(
          (a: Record<string, string>, b: Record<string, string>) =>
            a[sortBy]?.localeCompare(b[sortBy])
        )
      }
    }, {}) as IDeviceAlphanumericSort

    const sorted_unselected_alphanumeric = Object.entries(
      unselected_alphanumeric
    )?.reduce((acc, [key, group]) => {
      return {
        ...acc,
        [key]: (group as Record<string, string>[])?.sort(
          (a: Record<string, string>, b: Record<string, string>) =>
            a[sortBy]?.localeCompare(b[sortBy])
        )
      }
    }, {}) as IDeviceAlphanumericSort

    return [
      ...(sorted_selected_alphanumeric?.alphabetic as any),
      ...sorted_selected_alphanumeric?.numeric,
      ...(sorted_unselected_alphanumeric?.alphabetic as any),
      ...sorted_unselected_alphanumeric?.numeric
    ]
  }, [])
  const params = useParams()

  const memoizedContext = useMemo(() => {
    const { id } = memoizedSelectedItem ?? {}
    let context: any = {
      items: [],
      selectedIds: [],
      preSelectedId: ''
    }

    switch (id) {
      case 'mowers': {
        context = {
          items: Object.values(companyMowers)
        }
        break
      }
      case 'batteries': {
        context = {
          items: companyBatteries
        }
        break
      }
      case 'tool_tags': {
        context = {
          items: companyTooltags || []
          // selectedIds: state?.selected_tool_tag_ids ?? [],
          // preSelectedId: state?.pre_selected_tool_tag_id ?? ''
        }
        break
      }

      default:
        break
    }

    const sortedData = sortData({
      ...context,
      sortingDirection: ESortDirection.ASC,
      sortBy: 'name'
    })

    context.items = sortedData

    return context
  }, [
    companyMowers,
    state?.selected_batteries,
    state?.selected_battery_ids,
    state?.selected_tool_tags,
    state?.selected_tool_tag_ids,
    memoizedSelectedItem,
    state?.pre_selected_mower_id,
    state?.pre_selected_battery_id,
    state?.pre_selected_tool_tag_id,
    sortData,
    companyTooltags
  ])

  const handleSubNavigationClick = useCallback((item: NavigationBarItem) => {
    emitter?.emit('PAGE:tracking', {
      type: 'RESET_SCROLL_TO_TOP'
    })
    navigate({ pathname: `${Routes.tracking_page}/${item.id}` })
  }, [])

  const activeMower = companyMowers.find(
    mower => mower.productSerial === params['*']
  )

  return (
    <div className='h-full flex flex-col'>
      <TrackingSubNavigationBar
        items={navigationItems}
        selectedIndex={selectedIndex}
        onClick={handleSubNavigationClick}
      />
      <div className='flex flex-1 overflow-hidden'>
        <SidebarContainer>
          {devices.length === 0 ? (
            <div className='flex flex-1 items-center justify-center'>
              <Text.VariousRegular.Medium className='text-black dark:text-white'>
                {t`Loading...`}
              </Text.VariousRegular.Medium>
            </div>
          ) : (
            <Outlet context={memoizedContext} />
          )}
        </SidebarContainer>

        <div id='map-container' className='flex-1 bg-black-600 relative'>
          {state?.active_overlay?.name !== EActiveOverlay.NONE && (
            <Transition
              as='div'
              show={true}
              enter='transition ease-in-out duration-200 transform'
              enterFrom={cn('opacity-0 -translate-y-2', {
                'translate-y-2':
                  state?.active_overlay?.position ===
                  EActiveOverlayPosition.BOTTOM
              })}
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in-out duration-200 transform'
              leaveFrom={cn('opacity-100 translate-y-0', {
                'translate-y-0':
                  state?.active_overlay?.position ===
                  EActiveOverlayPosition.BOTTOM
              })}
              leaveTo={cn('opacity-0 -translate-y-2', {
                'translate-y-2':
                  state?.active_overlay?.position ===
                  EActiveOverlayPosition.BOTTOM
              })}
              className={cn('absolute z-[1] w-full', {
                'bottom-0':
                  state?.active_overlay?.position ===
                  EActiveOverlayPosition.BOTTOM
              })}>
              {state?.active_overlay?.name ===
                EActiveOverlay.FAULT_HISTORY_CARD &&
                activeMower && (
                  <FaultHistoryCard mower={activeMower as unknown as IDevice} />
                )}
              {state?.active_overlay?.name ===
                EActiveOverlay.SUREPATH_PERFORMANCE_CARD &&
                activeMower && <SurepathPerformanceCard mower={activeMower} />}
              {state?.active_overlay?.name ===
                EActiveOverlay.ACTIVITY_TIMELINE &&
                activeMower && <ActivityTimelineCard mower={activeMower} />}
            </Transition>
          )}
          <MapsProvider />
          {selectedDevice?.inventory?.source === 'surepath' && (
            <div className='absolute z-[1] right-3 top-3'>
              <SurepathDropdown />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TrackingPage
