import React, { Children } from 'react'
import cn from 'classnames'
import { IBaseTextProps } from '../types'

interface IComponent<P = IBaseTextProps> extends React.FC<P> {
  Large: React.FC<P>
  Medium: React.FC<P>
  Small: React.FC<P>
  LargeSemiBold: React.FC<P>
  MediumSemiBold: React.FC<P>
  SmallSemiBold: React.FC<P>
}

const ButtonText: IComponent = ({ children }) => {
  const subComponentList = Object.keys(ButtonText)

  const subComponents = subComponentList.map(key => {
    return Children.map(children, child =>
      (child as any)?.type?.name === key ? child : null
    )
  })

  return <>{subComponents.map(component => component)}</>
}

const Large: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn('text-[18px] leading-[28px] normal-case', className)}
    {...restProps}>
    {children}
  </p>
)
ButtonText.Large = Large

const Medium: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn('text-[16px] leading-[24px] normal-case', className)}
    {...restProps}>
    {children}
  </p>
)
ButtonText.Medium = Medium

const Small: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn('text-[14px] leading-[20px] normal-case', className)}
    {...restProps}>
    {children}
  </p>
)
ButtonText.Small = Small

// Semibold
const LargeSemiBold: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-semibold text-[18px] leading-[28px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
ButtonText.LargeSemiBold = LargeSemiBold

const MediumSemiBold: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-semibold text-[16px] leading-[24px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
ButtonText.MediumSemiBold = MediumSemiBold

const SmallSemiBold: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-semibold text-[14px] leading-[20px] normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
ButtonText.SmallSemiBold = SmallSemiBold

export default ButtonText
