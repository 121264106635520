import React, { useContext } from 'react'
import { ELanguage } from '../../../models/language'
import { AppRootContext } from '../../../pages/AppRoot'
import { Select } from '../../atoms'
import { useLingui } from '@lingui/react'

interface LanguageSelectorProps {}

export const LanguageSelector: React.FC<LanguageSelectorProps> = props => {
  const { state, dispatch } = useContext(AppRootContext)
  const { i18n } = useLingui()

  return (
    <div className='w-[200px]'>
      <Select
        options={[
          {
            label: 'English',
            value: ELanguage.English
          },
          {
            label: 'Spanish',
            value: ELanguage.Spanish
          }
        ]}
        value={state?.language}
        onChange={value => {
          dispatch?.({
            type: 'SET_LANGUAGE',
            payload: value
          })
          i18n.activate(value)
        }}
      />
    </div>
  )
}
