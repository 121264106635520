import React, { memo, useEffect } from 'react'
import { TableVirtuoso } from 'react-virtuoso'
import cn from 'classnames'
import { TableProps } from './types'
import { useTableContext } from './useTableContext'
import { ColumnRenderer } from './components/ColumnRenderer'
import { TableBodyRenderer } from './components/TableBodyRenderer'
import { LoadingStateTableBody } from './components/LoadingStateTableBody'
import { EmptyStateTableBody } from './components/EmptyStateTableBody'
import { RowRenderer } from './components/RowRenderer'
import { CellRenderer } from './components/CellRenderer'

const Table: React.FC<TableProps> = props => {
  const { tableInstance, tableProps } = useTableContext()

  const {
    data,
    isLoading,
    onTableStateChange,
    tableClassName,
    tableStyle,
    enableRowVirtualization,
    increaseViewportBy
  } = tableProps

  const {
    getTableProps,
    headerGroups,
    state,
    getTableBodyProps,
    rows,
    prepareRow
  } = tableInstance

  useEffect(() => {
    if (onTableStateChange) onTableStateChange(state)
  }, [state])

  if (!enableRowVirtualization) {
    return (
      <div className='h-full flex flex-col' style={{ overflowX: 'auto' }}>
        <div
          {...getTableProps()}
          className={cn('w-full h-full grid px-3 pb-2', tableClassName)}
          style={{ gridTemplateRows: '0fr 1fr', ...tableStyle }}>
          <div>
            {headerGroups.map(headerGroup => (
              <div {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <ColumnRenderer
                    key={column.getHeaderProps().key}
                    column={column}
                  />
                ))}
              </div>
            ))}
          </div>

          {isLoading && !data.length ? (
            <LoadingStateTableBody />
          ) : !isLoading && !data.length ? (
            <EmptyStateTableBody />
          ) : (
            <TableBodyRenderer />
          )}
        </div>
      </div>
    )
  }

  return (
    <TableVirtuoso
      totalCount={data.length}
      increaseViewportBy={{
        top: increaseViewportBy?.top || 0,
        bottom: increaseViewportBy?.bottom || 0
      }}
      components={{
        Table: ({ style, ...props }) => (
          <div
            {...getTableProps()}
            {...props}
            className={cn('w-full h-full table px-3 pb-2', tableClassName)}
            style={{
              ...style,
              ...tableStyle
            }}
          />
        ),
        TableBody: React.forwardRef(({ style, ...props }, ref) => (
          <div
            {...getTableBodyProps()}
            {...props}
            ref={ref}
            className={cn('overflow-x-hidden overflow-y-auto', props.className)}
          />
        )),
        TableRow: props => {
          const index = props['data-index']
          const row = rows[index]
          return <RowRenderer {...props} {...row.getRowProps()} row={row} />
        }
      }}
      fixedHeaderContent={() => {
        return headerGroups.map(headerGroup => (
          <div
            {...headerGroup.getHeaderGroupProps({
              className: 'bg-app-background'
            })}>
            {headerGroup.headers.map(column => (
              <ColumnRenderer
                key={column.getHeaderProps().key}
                column={column}
              />
            ))}
          </div>
        ))
      }}
      itemContent={index => {
        const row = rows[index]
        prepareRow(row)

        return row.cells.map(cell => (
          <CellRenderer
            {...cell.getCellProps({
              style: {
                maxWidth: cell.column.maxWidth,
                minWidth: cell.column.minWidth,
                width: cell.column.width
              }
            })}
            cell={cell}
          />
        ))
      }}
    />
  )
}

export default memo(Table)
