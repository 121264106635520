import React from 'react'
import { SettingsTemplate } from '../../templates'
import { CompanySubNavigationBar } from '../CompanySubNavigationBar'
import * as Text from '../../atoms/Typography';
import { Dialog, Image, Select } from '../../atoms';
import { ASSETS } from '../../../assets';
import { Trans, t } from '@lingui/macro';
import {
  setPayment as setPaymentSlice,
  setTimezone as setTimezoneSlice,
} from '../../../store/slices/companySlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { LanguageSelector } from '../LanguageSelector';
import { selectOperatingCompany } from '../../../store/slices/operatingCompany';

const TIME_ZONES = [
  {
    label: 'EST UTC-05:00',
    value: 'EST UTC-05:00'
  },
  {
    label: 'CST UTC-06:00',
    value: 'CST UTC-06:00'
  },
  {
    label: 'MST UTC-07:00',
    value: 'MST UTC-07:00'
  },
]

const PAYMENTS = [
  {
    label: '1234',
    value: '1234'
  },
  {
    label: '2345',
    value: '2345'
  }
]

export const CompanyAccount = () => {
  const {
    status,
    date,
    timezone,
    payment
  } = useAppSelector(state => state.company)
  const reduxDispatch = useAppDispatch()
  const operatingCompany = useAppSelector(selectOperatingCompany)

  const setTimezone = (value: string) => {
    reduxDispatch(setTimezoneSlice(value))
    // TODO: add mutation query
  }

  const setPayment = (value: string) => {
    reduxDispatch(setPaymentSlice(value))
    // TODO: add mutation query
  }

  return (
    <SettingsTemplate
      subNavigation={(<CompanySubNavigationBar />)}
      leftContent={(
        <>
          <Text.Headline.H3 className="text-white mb-2">Company:</Text.Headline.H3>
          <Image className="mb-4" src={ASSETS.IMAGES.APP.sbd_logo} />
          <Text.Headline.H3 className="text-white mb-4">{operatingCompany.name}</Text.Headline.H3>
          <div>
            <div className="flex gap-2 mb-2">
              <Text.VariousRegular.Small className="text-white">
                <Trans>Status:</Trans>
              </Text.VariousRegular.Small>
              <Text.VariousBold.Small className="text-white">{status}</Text.VariousBold.Small>
            </div>
            <div className="flex gap-2 mb-2">
              <Text.VariousRegular.Small className="text-white">
                <Trans>Since:</Trans>
              </Text.VariousRegular.Small>
              <Text.VariousBold.Small className="text-white">{date}</Text.VariousBold.Small>
            </div>
          </div>
        </>
      )}
      topRightContent={(
        <div className="p-[30px]">
          <Text.Headline.H3 className="text-white mb-6">
            <Trans>Global Settings:</Trans>
          </Text.Headline.H3>
          <div className="flex gap-2 mb-2">
            <Text.VariousRegular.Small className="text-white">
              <Trans>Global Time Zone:</Trans>
            </Text.VariousRegular.Small>
            <Text.VariousRegular.Small className="text-white">{timezone}</Text.VariousRegular.Small>
          </div>
          <Select
            className="w-[200px] mb-4"
            placeholder='Select option'
            options={TIME_ZONES}
            onChange={setTimezone}
            onFocus={() => { }}
            value={timezone}
            dense
          />
          <div className="flex gap-2 mb-2">
            <Text.VariousRegular.Small className="text-white">
              <Trans>Language:</Trans>
            </Text.VariousRegular.Small>
          </div>
          <LanguageSelector />
        </div>
      )}
      bottomRightContent={(
        <div className="p-[30px]">
          <Text.Headline.H3 className="text-white mb-4">
            <Trans>Billing:</Trans>
          </Text.Headline.H3>
          <Text.VariousRegular.Small className="text-white mb-4">Automatic Renewal</Text.VariousRegular.Small>
          <Text.VariousRegular.Small className="text-white mb-4">Payment Method</Text.VariousRegular.Small>
          <Text.VariousRegular.Small className="text-white mb-2">
            <Trans>Card on File:</Trans>
          </Text.VariousRegular.Small>
          <Select
            className="w-[200px]"
            placeholder='Select option'
            options={PAYMENTS}
            onChange={setPayment}
            onFocus={() => { }}
            value={payment}
            dense
          />
        </div>
      )}
    />
  )
}

export default CompanyAccount
