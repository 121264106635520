import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Icon, Text } from '../../atoms'
import { IDevice } from '../../../models_v2/entity/device'
import { DEVICEMOWERSTATUSVALUES } from '../../../models_v2/common/mower'

interface BatteryChargeBarProps {
  /**
   * Value between 0 to 1
   */
  value: any
  orientation?: 'horizontal' | 'vertical'
  device: IDevice
}
const BatteryChargeBar: React.FC<BatteryChargeBarProps> = props => {
  const { value = 0, orientation = 'horizontal', device } = props

  const isMowerCharging = useMemo(
    () =>
      Number(device?.latestTelemetry?.status) ===
      DEVICEMOWERSTATUSVALUES.Charging,
    [device]
  )

  if (orientation === 'horizontal') {
    return (
      <div className='flex items-center gap-3'>
        <StyledBarContainer className='flex-1 rounded h-[30px] progress-bar-battery-level overflow-clip'>
          {!Number.isNaN(value) ? (
            <StyledBar
              className='h-full bg-white'
              style={{
                width: `${value * 100}%`,
                borderRadius: value > 0.99 ? '3px' : 0,
                background: isMowerCharging ? 'linear-gradient(90deg, #2cc947 0%, #2cc947 72.92%, #85c791 100%)' : 'linear-gradient(90deg, #fff 0%, #fff 72.92%, #fff 100%)'
              }}
            />
          ) : (
            <StyledBar
              className='h-full bg-white'
              style={{
                width: `${0 * 100}%`,
                borderRadius: 0 > 0.99 ? '3px' : 0,
                background: isMowerCharging ? 'linear-gradient(90deg, #2cc947 0%, #2cc947 72.92%, #85c791 100%)' : 'linear-gradient(90deg, #fff 0%, #fff 72.92%, #fff 100%)'
              }}
            />
          )}
        </StyledBarContainer>

        <div className='flex items-center'>
          {isMowerCharging && (
            <Icon
              name='bolt_vector'
              className='h-[20px] w-[12px] mb-2 mr-2 invert dark:invert-0'
            />
          )}
          <Text.VariousBold.Large>
            {!Number.isNaN(value)
              ? Intl.NumberFormat('US', {
                  maximumFractionDigits: 2
                }).format(value * 100)
              : '-'}
            %
          </Text.VariousBold.Large>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='flex items-center mb-1'>
        <Icon name='bolt_vector' className='h-[20px] w-[12px] mr-2' />
        <Text.VariousBold.Large>
          {!Number.isNaN(value)
            ? Intl.NumberFormat('US', {
                maximumFractionDigits: 2
              }).format(value * 100)
            : '-'}
          %
        </Text.VariousBold.Large>
      </div>
      <StyledBarContainer className='flex-1 rounded h-[30px] progress-bar-battery-level overflow-clip'>
        {!Number.isNaN(value) ? (
          <StyledBar
            className='h-full bg-white'
            style={{
              width: `${value * 100}%`,
              borderRadius: value > 0.99 ? '3px' : 0
            }}
          />
        ) : (
          <StyledBar
            className='h-full bg-white'
            style={{
              width: `${0 * 100}%`,
              borderRadius: 0 > 0.99 ? '3px' : 0,
              background: isMowerCharging ? 'linear-gradient(90deg, #2cc947 0%, #2cc947 72.92%, #85c791 100%)' : 'linear-gradient(90deg, #fff 0%, #fff 72.92%, #fff 100%)'
            }}
          />
        )}
      </StyledBarContainer>
    </div>
  )
}

const StyledBarContainer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.048) 100%
  );
  border: 1px solid;
  border-top-color: #393939;
  border-left-color: #393939;
  border-right-color: #393939;
  border-bottom-color: #6a6969;
`

// Blue #0082fc
// Blue white #8ac6ff

// #2cc947
// #85c791
const StyledBar = styled.div`
  box-shadow: inset -1px 1px 3px rgba(0, 0, 0, 0.25);
`

export default BatteryChargeBar
