import 'react-big-calendar/lib/css/react-big-calendar.css'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import {
  Calendar,
  momentLocalizer,
  Components,
  Formats,
  SlotInfo
} from 'react-big-calendar'
import moment from 'moment'
import DateCellWrapper from './DateCellWrapper'
import ColumnHeader from './ColumnHeader'
import DateHeader from './DateHeader'
import MonthSelector from './MonthSelector'
import {
  IDateRange,
  TReportingType
} from '../../../pages/ReportingPage/reducers/types'
import { ReportingPageContext } from '../../../pages/ReportingPage'
import { Divider } from '../../atoms'

const localizer = momentLocalizer(moment)
const formats: Formats = {
  weekdayFormat: (date, culture, localizer) =>
    localizer?.format(date, 'dddd', culture)!,
  dateFormat: (date, culture, localizer) =>
    localizer?.format(date, 'D', culture)!
}

interface ReportingCalendarProps {
  reportingType: TReportingType
}

const ReportingCalendar: React.FC<ReportingCalendarProps> = props => {
  const { reportingType } = props
  const { state, dispatch } = useContext(ReportingPageContext)

  const handleMonthChange = useCallback(
    (date: Date) => {
      dispatch?.({
        type: 'SET_CURRENT_MONTH',
        payload: {
          type: reportingType,
          date
        }
      })
    },
    [reportingType]
  )

  const handleSelectSlot = useCallback(
    ({ action, start, end }: SlotInfo) => {
      let dateRange: IDateRange = { start: null, end: null }

      const { start: previousStart, end: previousEnd } =
        state?.[`${reportingType}_date_range`] ?? {}

      if (action === 'doubleClick') {
        dateRange = {
          start: null,
          end: null
        }
      } else if (action === 'click') {
        // `start` here is the only need since this only a click
        const clickedDate = start

        // If both has already values, set the clickDate as start date
        if (previousStart && previousEnd) {
          dateRange = {
            start: clickedDate,
            end: null
          }
        } else {
          if (!previousStart) {
            dateRange = {
              start: clickedDate,
              end: null
            }
          } else {
            // Set both start and end
            // You are clicking now the end date
            if (moment(clickedDate).isBefore(previousStart)) {
              dateRange = {
                start: clickedDate,
                end: previousStart
              }
            } else {
              dateRange = {
                start: previousStart,
                end: clickedDate
              }
            }
          }
        }
      } else if (action === 'select') {
        dateRange = {
          start,
          // Note: The end date given by the library is already on 12 AM, so it is supposed to be end of day of the end date
          end: moment(end).subtract(1, 'day').endOf('day').toDate()
        }
      }
      
      dispatch?.({
        type: 'SET_DATE_RANGE',
        payload: {
          type: reportingType,
          value: dateRange
        }
      })
    },
    [
      reportingType,
      state?.[`${reportingType}_date_range`]?.start,
      state?.[`${reportingType}_date_range`]?.end
    ]
  )

  const reportingTypeCurrentDate = useMemo(() => {
    return state?.[`${reportingType}_current_month`]
  }, [state?.[`${reportingType}_current_month`]])

  const reportingTypeIsDateSelection = useMemo(() => {
    return state?.date_range_selection?.[reportingType]
  }, [state?.date_range_selection?.[reportingType]])

  useEffect(() => {
    const datePickerDisabled = !state?.date_range_selection?.export
    const noEndDate = !state?.export_date_range?.end
    const startDateExists = state?.export_date_range?.start

    if (datePickerDisabled && startDateExists && noEndDate) {
      dispatch?.({
        type: 'SET_DATE_RANGE',
        payload: {
          type: "export",
          value: {
            start: state?.export_date_range?.start,
            end: moment(state?.export_date_range?.start).endOf('day').toDate(),
          }
        }
      })
    }
  }, [state?.date_range_selection?.[reportingType]])

  const components = useMemo<Components>(() => {
    return {
      dateCellWrapper: props => (
        <DateCellWrapper
          {...props}
          reportingType={reportingType}
          disabled={!reportingTypeIsDateSelection}
        />
      ),
      header: ColumnHeader,
      month: {
        dateHeader: DateHeader
      }
    }
  }, [reportingTypeIsDateSelection, reportingType])

  return (
    <>
      <MonthSelector
        currentDate={reportingTypeCurrentDate!}
        onChange={handleMonthChange}
      />
      <Divider size='md' />

      <div className='flex-1 p-4 min-h-[500px]'>
        <StyledCalendar
          localizer={localizer}
          toolbar={false}
          components={components}
          formats={formats}
          defaultView='month'
          selectable={reportingTypeIsDateSelection}
          onNavigate={handleMonthChange}
          onSelectSlot={handleSelectSlot}
          date={reportingTypeCurrentDate}
        />
      </div>
    </>
  )
}

export default ReportingCalendar

const StyledCalendar = styled(Calendar)`
  .rbc-month-view,
  .rbc-month-row,
  .rbc-header {
    border: none;
  }

  .rbc-row.rbc-month-header {
    margin-bottom: 10px;
  }

  .rbc-button-link {
    cursor: default;
    pointer-events: none;
  }
`
