import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApolloClient, ApolloClient } from '@apollo/client'
import { IQueryOptions, TUseQueryOptions } from './types'
import { apolloRequest, restRequest } from './utilities'

const useReactQuery = (
  request: any,
  { queryKey, options, instance }: IQueryOptions
): UseQueryResult<any, unknown> => {
  // * Enabled caching since theres an issue for a slow response time.
  const defaultOptions: TUseQueryOptions = {
    // cacheTime: 1200000, // ms
    // staleTime: 1200000, // ms
    refetchOnWindowFocus: false
  }
  return useQuery({
    ...defaultOptions,
    ...options,
    queryKey: [instance ? `${queryKey}:${instance}` : queryKey],
    queryFn: request
  })
}

const useDynamicQuery = <T extends unknown>(
  param: IQueryOptions
): UseQueryResult<any, unknown> => {
  const queryLanguage =
    param?.queryLanguage || process.env.REACT_APP_QUERY_LANGUAGE
  const apolloClient: ApolloClient<object> = useApolloClient()
  let request
  if (queryLanguage === 'GraphQL') {
    request = () =>
      apolloRequest({
        apolloClient,
        params: param
      })
  } else if (queryLanguage === 'REST') {
    request = () =>
      restRequest({
        params: param
      })
  }
  return useReactQuery(request, param)
}

export default useDynamicQuery
