import { useCallback } from 'react'
import { 
  useAppDispatch, 
  useAppSelector } from '../../../store/hooks'
import {
  selectAllSelectedAssets,
  toggleSelectedAsset } from '../../../store/slices/organizationCompanySlice'
import GarageGlobalFleetDeviceList from '../GarageGlobalFleetDeviceList'
import { IMower } from '../../../models'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import { 
  selectDevices, 
  selectFilteredDevices} from '../../../store/slices/deviceSliceV2/selectors';
import {IDevice} from '../../../models_v2/entity/device';
import { useMowerSearchV2 } from '../../../hooks'


const MOWER_HIDDEN_COLUMNS: string[] = [
  'registering_dealer',
  'has_subscription',
  'network_status',
  'actions',
  "inventory.companyName",
  "inventory.groupName"
]

interface AssetAssignMowerListProps { }

export const AssetAssignMowerList: React.FC<AssetAssignMowerListProps> = () => {
  const appDispatch = useAppDispatch()
  const selectedAssets = useAppSelector(selectAllSelectedAssets)
  const isSelectingAssets = useAppSelector(state => state.organizationCompany.is_selecting_assets)
  const { searchFilter } = useAppSelector(state => state.organizationSettings)
  const operatingCompany = useAppSelector(selectOperatingCompany)  
  const devices = useAppSelector(selectDevices)
  const companyMowers = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: "mower", 
      operatingCompany,
      withoutCompanyAssigned: true
    })) as IDevice[]
  const filteredMowers = useMowerSearchV2({
    items: companyMowers,
    search: searchFilter
  })

  const onRowClassNameBuilder = useCallback((row: unknown) => {
    const item = row as IMower

    if (!selectedAssets[item.productSerial as string]) return ''

    return 'border-primary !text-primary'
  }, [selectedAssets])

  const handleRowClick = useCallback((row: unknown) => {
    appDispatch(toggleSelectedAsset({ id: (row as IMower).productSerial! }))
  }, [selectedAssets])

  return (
    <GarageGlobalFleetDeviceList
      type='mower'
      hiddenColumns={MOWER_HIDDEN_COLUMNS}
      isLoading={devices.length==0}
      items={filteredMowers}
      tableProps={{
        onRowClick: !isSelectingAssets ? undefined : handleRowClick,
        rowClassName: onRowClassNameBuilder
      }}
    />
  )
}
