import { gql } from '@apollo/client'

export const METADATA_SUBSCRIPTION = gql`
  subscription OnMetadataUpdate {
    getMetadata {
      productSerial
      metadataId
      groupId
      faultCode
      payload
      timestamp
      createdAt
      active
      state
      notes
    }
  }
`

export const NEW_DEVICE = gql`
  subscription getDevice {
    productSerial
    latestTelemetry {
      type
      location
      payload
      timestamp
      status
    }
    telemetries {
      type
      location
      payload
      timestamp
      status
    }
    inventory {
      source
      name
      model
      factoryModel
      dealer
      softwareVersion
    }
    metadata {
      productSerial
      faultId
      groupId
      faultCode
      payload
      timestamp
      createdAt
      active
      state
      notes
    }
    #analytic {
    #  last24Hours
    #  last7Days
    #  last30Days
    #}
  }
`

export const SUBSCRIBE_DEVICE = gql`
  subscription MySubscription {
    getDevice {
      inventory {
        companyId
        dealer
        deviceType
        factoryModel
        groupId
        model
        name
        softwareVersion
        source
        toolMake
        toolModel
        toolPowerType
        toolSerialNumber
        toolType
      }
      latestTelemetry {
        location
        payload
        status
        timestamp
        type
      }
      metadata {
        active
        createdAt
        faultCode
        groupId
        metadataId
        notes
        payload
        productSerial
        state
        timestamp
      }
      telemetries {
        location
        payload
        status
        timestamp
        type
      }
      productSerial
    }

  }
`


export const SUBSCRIPTION_GENERATE_EXPORT = gql`
  subscription getReport {
    onExportGenerated {
      url
      generatedAt
    }
  }
`
