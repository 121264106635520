import React, { useState, useContext, useMemo, useEffect } from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import { Text, TabList } from '../../atoms'
import { AppRootContext } from '../../../pages/AppRoot'
import { useMatch, useNavigate } from 'react-router-dom'
import { TabItem } from '../../atoms/TabList/types'
import { t } from '@lingui/macro'
import { Routes } from '../../../routes'

interface AppNavigationBarProps {}
interface StyledNavigationTabBottomProps {
  isHovered: boolean
}

const AppNavigationBar: React.FC<AppNavigationBarProps> = () => {
  const { dispatch, emitter } = useContext(AppRootContext)
  const navigate = useNavigate()
  const [hoveredItemIndex, setHoveredItemIndex] = useState<number | undefined>()

  const isTrackingPage = useMatch(`${Routes['tracking_page']}/*`)
  const isGaragePage = useMatch(`${Routes['garage_page']}/*`)
  const isReportingPage = useMatch(`${Routes['reporting_page']}/*`)

  const [selectedTabIndex, setSelectedTabIndex] = useState<number | null>(null)

  const isCurrentPageFromNavigationBar = useMemo(() => {
    return !!isTrackingPage || !!isGaragePage || !!isReportingPage
  }, [isTrackingPage, isGaragePage, isReportingPage])

  useEffect(() => {
    if (!isCurrentPageFromNavigationBar) {
      setSelectedTabIndex(null)
    } else {
      if (isTrackingPage) {
        setSelectedTabIndex(0)
      } else if (isGaragePage) {
        setSelectedTabIndex(1)
      } else if (isReportingPage) {
        setSelectedTabIndex(2)
      } else {
        setSelectedTabIndex(0)
      }
    }
  }, [isCurrentPageFromNavigationBar, isTrackingPage, isGaragePage])

  const updateTabNavigation = (item: TabItem, index: number) => {
    const tabIdRouteMap: Record<string, string> = {
      tracking: 'tracking_page.mowers',
      garage: 'garage_page.global-fleet.mowers',
      // reporting: 'reporting_page.schedule'
      reporting: 'reporting_page.exports'
    }
    setSelectedTabIndex(index)

    if (Object.keys(tabIdRouteMap).includes(item.id)) {
      const routeKey = tabIdRouteMap[item.id] as keyof typeof Routes
      const routeDestination = Routes[routeKey]
      navigate(routeDestination as string)
    } else if (item.id === 'energy') {
      // TODO
    } else {
      console.warn('Unimplemented action.')
    }
  }

  const handleTabClick = (item: TabItem, index: number) => {
    if (item.id === 'tracking') {
      dispatch?.({
        type: 'RESET_TRACKING'
      })

      setTimeout(() => {
        emitter?.emit('PAGE:tracking', {
          type: 'RESET_SCROLL_TO_TOP'
        })

        emitter?.emit('MAP:tracking', {
          type: 'RESET_MAP'
        })
      }, 0)
    }
    updateTabNavigation(item, index)
  }

  return (
    <TabList
      containerClassName='app-nav-list md:gap-6'
      items={[
        {
          id: 'tracking',
          label: t`TRACKING`
        },
        {
          id: 'garage',
          label: t`GARAGE`
        },
        {
          id: 'reporting',
          label: t`REPORTING`
        }
        // {
        //   id: 'energy',
        //   label: t`ENERGY`
        // }
      ]}
      selectedIndex={selectedTabIndex}
      onTabClick={handleTabClick}
      itemBuilder={({ item, index, isActive }) => {
        const isHovered = hoveredItemIndex === index

        return (
          <div
            key={index}
            onMouseEnter={() => setHoveredItemIndex(index)}
            onMouseLeave={() => setHoveredItemIndex(undefined)}
            className={cn(
              'flex-1 flex justify-center items-center cursor-pointer relative hover:border-white'
            )}>
            <Text.Body.SmallSemiBold className='text-center !font-bold px-2 text-[10px] text-white'>
              {item.label}
            </Text.Body.SmallSemiBold>
            <div className='absolute inset-x-0 bottom-0'>
              {isCurrentPageFromNavigationBar && isActive && (
                <StyledNavigationTabBottomActive
                  isHovered={isHovered}
                  className={cn(
                    'border-solid border-b-[3px] border-b-primary',
                    {
                      'border-b-white': isHovered
                    }
                  )}
                />
              )}

              {(!isCurrentPageFromNavigationBar || !isActive) && (
                <StyledNavigationTabBottom
                  isHovered={isHovered}
                  className={cn('border-solid border-b-[3px]', {
                    'border-b-white': isHovered,
                    'border-b-transparent': hoveredItemIndex !== index
                  })}
                />
              )}
            </div>
          </div>
        )
      }}
    />
  )
}

const StyledNavigationTabBottomActive = styled.div`
  box-shadow: ${(props: StyledNavigationTabBottomProps) =>
    props?.isHovered
      ? '0px 0px 5px #FFFFFF'
      : '0px 0px 5px rgba(254, 189, 24, 0.45)'};
`

const StyledNavigationTabBottom = styled.div`
  box-shadow: ${(props: StyledNavigationTabBottomProps) =>
    props?.isHovered ? '0px 0px 5px #FFFFFF' : ''};
`

export default AppNavigationBar
