import React from 'react'
import { DateHeaderProps } from 'react-big-calendar'
import { Text } from '../../atoms'

interface IDateHeaderProps extends DateHeaderProps {}

const DateHeader: React.FC<IDateHeaderProps> = props => {
  const { label, isOffRange } = props

  if (isOffRange) return null

  return (
    <div className='m-1 text-white pt-[9px] pr-[9px]'>
      <Text.Body.SmallSemiBold>{label}</Text.Body.SmallSemiBold>
    </div>
  )
}

export default DateHeader
