import React from 'react'
import { HeaderProps } from 'react-big-calendar'
import { useBreakpoint, useWindowDimensions } from '../../../hooks'
import { Text } from '../../atoms'

interface ColumnHeaderProps extends HeaderProps {}

const ColumnHeader: React.FC<ColumnHeaderProps> = ({ label }) => {
  const { width: windowWidth } = useWindowDimensions()
  const { breakpoint } = useBreakpoint(windowWidth)

  return (
    <div className='px-[10px] py-2 bg-black rounded-md text-white text-left'>
      <Text.Body.Small className='truncate text-ellipsis'>
        {['2xl', 'xl'].includes(breakpoint) ? label : label.slice(0, 3)}
      </Text.Body.Small>
    </div>
  )
}

export default ColumnHeader
