import React, { Children } from 'react'
import cn from 'classnames'
import { IBaseTextProps } from '../types'

interface IComponent<P = IBaseTextProps> extends React.FC<P> {
  XSmall: React.FC<P>
  Medium: React.FC<P>
}

const Feature: IComponent = ({ children }) => {
  const subComponentList = Object.keys(Feature)

  const subComponents = subComponentList.map(key => {
    return Children.map(children, child =>
      (child as any)?.type?.name === key ? child : null
    )
  })

  return <>{subComponents.map(component => component)}</>
}

const XSmall: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn(
      'font-bold text-[10px] leading-normal normal-case',
      className
    )}
    {...restProps}>
    {children}
  </p>
)
Feature.XSmall = XSmall

const Medium: React.FC<IBaseTextProps> = ({
  className,
  children,
  ...restProps
}) => (
  <p
    className={cn('font-bold text-[18px] leading-6 normal-case', className)}
    {...restProps}>
    {children}
  </p>
)
Feature.Medium = Medium

export default Feature
