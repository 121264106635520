import { Reducer, Dispatch } from 'react'
import {
  ISetMowersEvent,
  ISetMowerEvent,
  ISetSelectedMowersEvent,
  ISetSelectedMowerIdsEvent,
  ISelectMowerEvent,
  IResetTrackingEvent,
  ISetBatteriesEvent,
  ISelectBatteryEvent,
  ISetSelectedBatteriesEvent,
  ISetSelectedBatteryIdsEvent,
  ISetPrevStateEvent,
  ISetToolTagsEvent,
  ISetSelectedToolTagsEvent,
  ISetSelectedToolTagIdsEvent,
  ISelectToolTagEvent,
  IUpsertMowerEvent,
  IUpsertBatteryEvent,
  IUpsertToolTagEvent,
  ISetLanguageEvent,
  IActiveOverlay,
  ISetSelectedMowerId,
  IAddMowerFaultEvent,
  IUpsertPendingFaultUpdateCollectionEvent,
  IRemovePendingFaultUpdateCollectionEvent,
  ISetSelectedBatteryIdEvent,
  ISetSelectedToolTagIdEvent
} from './events'
import {
  IMower,
  IToolTag,
  IBattery,
  IUserAccount,
  Device
} from '../../../../models'
import { ELanguage } from '../../../../models/language'
import { IDeviceFault } from '../../../../models/entity'
import { IGroup } from '../../../../models_v2/entity/groups'

export type StringKeyObj<T> = {
  [key: string]: T
}
export interface IState {
  // Preferences
  language: ELanguage

  // Current user
  current_user: Partial<IUserAccount> | null

  // All Devices
  devices: Device[]

  // Mowers
  mowers: Array<IMower>
  mowers_by_id: StringKeyObj<IMower>
  selected_mowers: Array<IMower>
  selected_mowers_by_id: StringKeyObj<IMower>
  selected_mower_ids: Array<string>
  selected_mower_id: string
  pre_selected_mower_id: string

  prev_selected_mowers: Array<IMower>
  prev_selected_mower_ids: Array<string>
  prev_selected_mower_id: string

  // Batteries
  batteries: Array<IBattery>
  selected_batteries: Array<IBattery>
  selected_battery_ids: Array<string>
  selected_battery_id: string
  pre_selected_battery_id: string

  prev_selected_batteries: Array<IBattery>
  prev_selected_battery_ids: Array<string>
  prev_selected_battery_id: string

  // Tool Tags
  tool_tags: Array<IToolTag>
  selected_tool_tags: Array<IToolTag>
  selected_tool_tag_ids: Array<string>
  selected_tool_tag_id: string
  pre_selected_tool_tag_id: string

  prev_selected_tool_tags: Array<IToolTag>
  prev_selected_tool_tag_ids: Array<string>
  prev_selected_tool_tag_id: string

  active_overlay: {
    name: EActiveOverlay
    position?: EActiveOverlayPosition
  }

  // Groups
  groups: Array<IGroup>
  group_by_id: StringKeyObj<IGroup>
  selected_groups: Array<IGroup>
  selected_groups_by_id: StringKeyObj<IGroup>
  selected_group_ids: Array<string>
  selected_group_id: string
  pre_selected_group_id: string

  prev_selected_groups: Array<IGroup>
  prev_selected_group_ids: Array<string>
  prev_selected_group_id: string

  // Faults pending update
  // Keep track of the pending updates of fault to disregard MOWER_FAULT_UPDATE from live to avoid race condition
  faults_pending_update_collection: Record<string, IDeviceFault>
}

export type TEvents =
  | IResetTrackingEvent
  // Mower
  | ISetMowersEvent
  | ISetMowerEvent
  | ISetSelectedMowersEvent
  | ISetSelectedMowerIdsEvent
  | ISelectMowerEvent
  | IUpsertMowerEvent
  | ISetSelectedMowerId
  | IAddMowerFaultEvent
  | IUpsertPendingFaultUpdateCollectionEvent
  | IRemovePendingFaultUpdateCollectionEvent
  // Battery
  | ISetBatteriesEvent
  | ISetSelectedBatteriesEvent
  | ISetSelectedBatteryIdsEvent
  | ISelectBatteryEvent
  | IUpsertBatteryEvent
  | ISetSelectedBatteryIdEvent
  // ToolTag
  | ISetToolTagsEvent
  | ISetSelectedToolTagsEvent
  | ISetSelectedToolTagIdsEvent
  | ISelectToolTagEvent
  | IUpsertToolTagEvent
  | ISetSelectedToolTagIdEvent

  // Common
  | ISetPrevStateEvent
  | ISetLanguageEvent

  // Modals and overlays
  | IActiveOverlay

export type TDispatch = Dispatch<TEvents>

export type TState = Partial<IState>

export type TReducer = Reducer<TState, TEvents>

export enum EActiveOverlay {
  NONE,
  FAULT_HISTORY_CARD,
  SUREPATH_PERFORMANCE_CARD,
  ACTIVITY_TIMELINE
}

export enum EActiveOverlayPosition {
  DEFAULT,
  TOP,
  BOTTOM
}
export interface IMowerPrevState {
  prev_selected_mowers: Array<IMower>
  prev_selected_mower_ids: Array<string>
  prev_selected_mower_id: string
}

export interface IBatteryPrevState {
  prev_selected_batteries: Array<IBattery>
  prev_selected_battery_ids: Array<string>
  prev_selected_battery_id: string
}

export interface IToolTagPrevState {
  prev_selected_tool_tags: Array<IToolTag>
  prev_selected_tool_tag_ids: Array<string>
  prev_selected_tool_tag_id: string
}
