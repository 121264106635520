import React, { useRef, useEffect } from 'react'

interface CheckboxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  [key: string]: any
}

const Checkbox = React.forwardRef<any, CheckboxProps>((props, ref) => {
  const { indeterminate, ...restProps } = props

  const defaultRef = useRef<any>()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    ;(resolvedRef as any).current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <input
      type='checkbox'
      ref={resolvedRef}
      className='form-checkbox checked:rounded-sm border text-black border-black invert-0 dark:invert focus:ring-0'
      {...restProps}
    />
  )
})

export default Checkbox
