import React from 'react'
import { DeviceDetailRowInformation } from '../../../molecules'
import { t } from '@lingui/macro'
import { Mower } from '../../../../models_v2/common/mower'
import { useAppSelector } from '../../../../store/hooks'
import { trackingGroups } from '../../../../store/slices/trackingGroupSlice/selectors'
import { isSurepathDevice } from '../../../../utils/device'

interface OtherInformationProps {
  mower: Partial<Mower>
}

const OtherInformation: React.FC<OtherInformationProps> = props => {
  const { mower } = props
  const {
    productSerial,
    inventory,
    latestTelemetry } = mower
  const { payload } = latestTelemetry ?? {}
  const {
    model,
    dealer,
    factoryModel 
  } = inventory ?? {}
  const groups = useAppSelector(trackingGroups)
  const assignedGroup = groups.find(group => group.groupId === mower.inventory?.groupId)

  const handleGetSoftwareVersion = () =>{
    if ( isSurepathDevice(mower) ) {

      if ( !payload ) return ""

      return typeof payload==="string"? JSON.parse(payload).software["GPM Software"] : (payload as any).software["GPM Software"]
    }

    return ""
  }
  
  return (
    <div className='flex flex-col gap-1'>
      <DeviceDetailRowInformation
        label={t`Assignment`}
        value={assignedGroup?.groupName || 'Unassigned'} />
      <DeviceDetailRowInformation
        label={t`Serial Number`}
        value={productSerial || ''} />
      <DeviceDetailRowInformation
        label={t`Factory Model #`}
        value={factoryModel || ''} />
      <DeviceDetailRowInformation
        label={t`Model`}
        value={model || ''} />
      <DeviceDetailRowInformation
        label={t`Registering Dealer`}
        value={dealer || ''} />
      {isSurepathDevice(mower) && (
        <DeviceDetailRowInformation
          label={t`Software Version`}
          value={handleGetSoftwareVersion()}
        />
      )}
    </div>
  )
}

export default OtherInformation
