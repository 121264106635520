import React from 'react'
import styled from 'styled-components'
import cn from 'classnames'

interface ActiveBorderProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  className?: string
}

const ActiveBorder: React.FC<ActiveBorderProps> = props => {
  const { className, ...restProps } = props

  return (
    //@ts-ignore
    <StyledBorder className={cn('bg-primary', className)} {...restProps} />
  )
}

export default ActiveBorder

const StyledBorder = styled.div`
  box-shadow: 0px 0px 11px rgba(254, 189, 24, 0.57);
`
