import { useEffect } from 'react'

/**
 * This hook will run the interval as the actual local time, not at the execution time (for example, run the callback every 1 minute of the actual time)
 * @param callback Runs the function every interval of the specified `every` seconds
 * @param every Specify the seconds relative to the actual time that you want the callback to run
 */
export const useActualTimeInterval = (
  callback: () => void,
  every: number,
  deps?: React.DependencyList
) => {
  useEffect(
    () => {
      const nextUpdate = every - (Date.now() % every)
      let interval: Parameters<typeof clearInterval>[0]

      const timeout = setTimeout(() => {
        // Initial hit after timeout
        callback()
        interval = setInterval(callback, every)
      }, nextUpdate)

      return () => {
        clearTimeout(timeout)
        if (interval) {
          clearInterval(interval)
        }
      }
    },
    deps ? deps : []
  )
}
