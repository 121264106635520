import moment from 'moment'
import {
  // EDeviceMowerStatus,
  IMowerHour
  // IStatusTotalItem
} from '../models/device'
import { Mower } from '../models_v2/common/mower'
import { IGroup } from '../models_v2/entity/groups'
import {IMower} from '../models';
import {IDevice} from '../models_v2/entity/device';
import {BatteryPack, ITelemetryPayload} from '../models_v2/entity/telemetry_payload';
import {ITelemetry} from '../models_v2/entity/telemetry';
import { isAscentDevice, isSurepathDevice } from './device';
import { IMetadata } from '../models_v2/entity/metadata';

// Unused function
// export const calculateMowerHoursFromStatusTotals = (
//   statusTotalItems: IStatusTotalItem[] = []
// ): string => {
//   if (!statusTotalItems.length) return ''

//   const totalMinutes = statusTotalItems.reduce((acc, curr) => {
//     if (
//       [
//         EDeviceMowerStatus.Charging,
//         EDeviceMowerStatus.Idle,
//         EDeviceMowerStatus.Mowing,
//         EDeviceMowerStatus.Driving
//       ].includes(Number(curr.mowerStatus) as unknown as EDeviceMowerStatus)
//     ) {
//       return acc + Number(curr.total)
//     }

//     return acc
//   }, 0)

//   const asHours = totalMinutes / 60
//   const formattedAsHours = Intl.NumberFormat('en-US', {
//     maximumFractionDigits: 2
//   }).format(asHours)

//   if (asHours === 0) {
//     return ''
//   } else if (asHours > 0 && asHours < 1) {
//     return `${formattedAsHours} hour`
//   }

//   return `${formattedAsHours} hours`
// }

// return n decimal places WITHOUT rounding
// surprisingly INTL doesn't have that capability
const toNDecimalPlaces = (number: number, decimalPlaces: number) => {
  if (!number) {
    return 0
  }
  const splitNum = number.toString().split('.')
  const num = splitNum?.[0]
  const decimals = splitNum?.[1]?.slice(0,decimalPlaces) ?? 0
  return `${num}.${decimals}`
}

export const isMowerOnline = (mower: Partial<Mower>) => {
  const lastSignalData = moment(mower.latestTelemetry?.timestamp)
  const duration = moment.duration(moment().diff(lastSignalData));
  const mowerLastOnlineMinsAgo = duration.asMinutes()
  return mowerLastOnlineMinsAgo <= 5
}

export const handleSortGroupsAlphabetically = (a: IGroup, b: IGroup) => {
  const groupNameA = (a.groupName || '').toUpperCase(); // handle undefined values
  const groupNameB = (b.groupName || '').toUpperCase();

  if (groupNameA < groupNameB) {
    return -1;
  }
  if (groupNameA > groupNameB) {
    return 1;
  }
  return 0;
}

  // Fn Params
  // mower = 1 mower
  // batteries = ALL batteries
  export const getMowerBatteriesFromList = (mower: IMower, batteries: IDevice[]):  (IDevice | null)[] => {
    const sortedMowerBatteries = batteries
      // get all batteries that match the mower serial
      .filter( b => b.productSerial.split('_')[0] === mower.productSerial)
      // We want to keep the batteries arrays in order ie pack1, pack2, pack3, etc
      .sort((battery1, battery2) => {
        const packN1 = Number(battery1.productSerial.slice(-1))
        const packN2 = Number(battery2.productSerial.slice(-1))

        return packN1 - packN2
      })

    let allBatteriesSorted = []
    const BATTERY_PACKS_PER_MOWER = 5
    // sortedMowerBatteries might be missing batteries because there are packs in a mower 
    // that might not have batteries ie [empty, battery, empty, etc]
    // Let's get the pack numbers from the battery name ie Battery_pack1, Battery_pack2, this extracts the last number from the name
    const packsWithBatteries = sortedMowerBatteries.map(battery => Number(battery.productSerial?.slice(-1)))
    
    // This section takes care of filling up any missing batteries with null
    // ie [pack1, pack4, pack5] to [pack1, null, null, pack4, pack5]
    for (let packIndex = 0; packIndex < BATTERY_PACKS_PER_MOWER; packIndex++) {
      const indexOfBattery = packsWithBatteries.indexOf(packIndex+1)
      const packHasBattery = indexOfBattery !== -1
      if (packHasBattery) {
        allBatteriesSorted.push(sortedMowerBatteries[indexOfBattery])
      } else {
        allBatteriesSorted.push(null)
      }
    }
    return allBatteriesSorted
  }

  export const getMowerBatteriesAggregateCharge = (mower: IMower, batteries: IDevice[]) => {
    const mowerBatteries = getMowerBatteriesFromList(mower as IMower, batteries!)
    const slotsWithBatteries = mowerBatteries.filter(battery => battery !== null);
    if (slotsWithBatteries.length === 0) {
      return 0
    }
    const aggregateCharge = slotsWithBatteries.reduce((acc, battery) => {
      const batteryCharge = (battery?.latestTelemetry.payload as unknown as BatteryPack).StateOfCharge
      const charge = Number(batteryCharge ?? 0)
      return (charge ?? 0) + acc
    }, 0)

    return aggregateCharge / slotsWithBatteries.length
  }

  export const getAscentMowerHoursFromFault = (metadata: IMetadata) => {
    // We'll be getting this field later
    return 0;
  }

  export const getMowerHours = (mower: IDevice) => {
    if (isSurepathDevice(mower)) {
      // @ts-ignore
      const keyOnHours = mower?.latestTelemetry?.payload?.mowerHours?.keyOnTimeHours
      if (!keyOnHours) return 0;
      return toNDecimalPlaces(keyOnHours, 1)
    }

    if (isAscentDevice(mower)) {
      return 0
    }
  }

export const getMowerAggregatedStateOfCharge = (mower: IDevice) =>{
  if ( typeof mower.latestTelemetry.payload === "string" ) {

    return (JSON.parse(mower.latestTelemetry.payload) as ITelemetryPayload).Battery?.AggregateStateOfCharge?? 0
  }

  return mower.latestTelemetry.payload.Battery?.AggregateStateOfCharge?? 0
}

// made battery as any for now since AggregatedSOC from payload object directly is not valid based on ITelemetryPayload
export const getBatteryAggregatedStateOfCharge = ( battery: any ) => {
  if ( typeof battery.latestTelemetry.payload === "string" ) {

    return JSON.parse(battery.latestTelemetry.payload).AggregateStateOfCharge?? 0
  }

  return battery.latestTelemetry.payload?.AggregateStateOfCharge?? 0
}