import { gql } from '@apollo/client'

export const UPDATE_DEVICE = gql`
  mutation UPDATE_DEVICE($input: DeviceInput!) {
    result: putDevice(input: $input) {
      source
      name
      model
      factoryModel
      dealer
      softwareVersion
    }
  }
`

export const UPDATE_FAULT = gql`
  mutation UPDATE_FAULT($input: FaultInput!) {
    result: putFault(input: $input) {
      productSerial
      faultId
      groupId
      faultCode
      payload
      timestamp
      active
      state
      notes
    }
  }
`

export const ADD_USER = gql`
  mutation ADD_USER($input: UserInput!) {
    result: addUser(input: $input) {
      id
      firstName
      lastName
      companies
      createdAt
      email
      lastLogin
      phone
      role
      status
      updatedAt
    }
  }
`

export const DELETE_USER = gql`
  mutation DELETE_USER($id: String!) {
    result: deleteUser(id: $id) {
      id
    }
  }
`

export const UPDATE_USER = gql`
  mutation UPDATE_USER($input: UserInputUpdate!) {
    result: updateUser(input: $input) {
      id
      firstName
      lastName
      companies
      # createdAt
      email
      # lastLogin
      phone
      role
      # status
      # updatedAt
    }
  }
`

export const LOGIN_USER = gql`
  mutation LOGIN_USER($id: String!) {
    result: userLoggedIn(id: $id) {
      id
      firstName
      lastName
      companies
      createdAt
      email
      lastLogin
      phone
      role
      status
      updatedAt
    }
  }
`

export const GENERATE_EXPORT = gql`
  mutation GENERATE_EXPORT($input: ExportInput!) {
    result: generateExport(input: $input) {
      url
      generatedAt
    }
  }
`

// input DeviceInput {
//   serial: String!
//   source: String!
//   name: String
//   model: String
//   factoryModel: String
//   dealer: String
//   softwareVersion: String
// }

// input CompanyInput {
//     companyId: String
//     name: String!
//   }

export const UPDATE_COMPANY = gql`
  mutation ADD_COMPANY($input: CompanyInput!) {
    result: putCompany(input: $input) {
      companyId
    }
  }
`