import { gql } from '@apollo/client'

export const GET_DEVICES = gql`
  query GET_DEVICES {
    result: devices {
      name
      sourceId
      productSerial
    }
  }
`

export const GET_TRACKING_MOWERS = gql`
  query GET_TRACKING_MOWERS {
    result: devices {
      bleBeacon
      location
      productSerial
      mowerStatus
      networkStatus
      source
      sourceId
      timestamp
      deviceType
      name
      model
      factoryModel
      dealer
      softwareVersion
      lastSeenMower
      activeFaults
      faults
      statusTotals {
        last7Days
        last24Hours
        last30Days
      }
      rssi
      rsrp
      rsrq
      operationState
      gpsMode
      egps
      rtkFixTime
      connectionEvent
      reverseSSH
      mowerHours
      software
    }
  }
`

export const GET_TRACKING_BATTERIES = gql`
  query GET_TRACKING_BATTERIES {
    result: devices {
      location
      productSerial
      name
      mowerStatus
      networkStatus
      source
      sourceId
      timestamp
      deviceType
    }
  }
`

export const GET_TRACKING_TOOL_TAGS = gql`
  query GET_TRACKING_TOOL_TAGS {
    result: devices {
      bleBeacon
      location
      productSerial
      name
      mowerStatus
      networkStatus
      source
      sourceId
      timestamp
      deviceType
      lastSeenMower
    }
  }
`

export const GET_DEVICE_BY_ID = gql`
  query GET_DEVICE_BY_ID($id: String!, $source: String!) {
    result: getById(id: $id, source: $source) {
      bleBeacon
      BLEDeviceType
      deviceType
      location
      productSerial
      mowerStatus
      networkStatus
      source
      sourceId
      timestamp
      coinHealth
      name
      model
      factoryModel
      dealer
      softwareVersion
      lastSeenMower
      activeFaults
      faults
      statusTotals {
        last7Days
        last24Hours
        last30Days
      }
      rssi
      rsrp
      rsrq
      operationState
      gpsMode
      egps
      rtkFixTime
      connectionEvent
      reverseSSH
      mowerHours
      software
    }
  }
`

export const GET_TOOLTAG_BY_ID = gql`
  query GET_DEVICE_BY_ID($id: String!, $source: String!) {
    result: getById(id: $id, source: $source) {
      bleBeacon
      BLEDeviceType
      deviceType
      location
      productSerial
      mowerStatus
      networkStatus
      source
      sourceId
      timestamp
      coinHealth
      name
      model
      factoryModel
      dealer
      softwareVersion
      lastSeenMower
      faults
    }
  }
`

export const GET_ALL_USERS = gql`
  query GET_ALL_USERS {
    result: getAllUsers {
      id
      firstName
      lastName
      email
      phone
      companies
      role
      createdAt
      updatedAt
      lastLogin
      status
    }
  }
`

export const GET_USER_BY_EMAIL = gql`
  query GET_USER_BY_EMAIL (
    $email: String!
  ) {
    result: getUserByEmail(
      email: $email
    ) {
      id
      firstName
      lastName
      email
      phone
      companies
      role
      createdAt
      updatedAt
      lastLogin
      status
    }
  }
`

export const GET_DEVICE_TIMELINE_BY_ID = gql`
  query GET_DEVICE_TIMELINE_BY_ID(
    $id: String!
    $source: String!
    $startTime: String
    $endTime: String
  ) {
    result: getById(
      id: $id
      source: $source
      startTime: $startTime
      endTime: $endTime
    ) {
      timeLine
    }
  }
`

export const GET_COMPANIES  = gql`
  query Companies {
    result: companies {
      inventory {
        companyId
        dealer
        deviceType
        factoryModel
        groupId
        model
        name
        softwareVersion
        source
      }
      companyId
    }
  }
`