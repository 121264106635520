import React, { ComponentProps } from 'react'
import { TextField } from '../../atoms'

interface SearchTextFieldProps extends ComponentProps<typeof TextField> { }

export const SearchTextField: React.FC<SearchTextFieldProps> = props => {
  return (
    <TextField
      placeholder='Search'
      className='min-h-[38px] w-full border-black-500 dark:placeholder-white dark:text-white bg-black/50'
      {...props}
    />
  )
}
