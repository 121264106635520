import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import { 
  IProps, 
  TRootAppContext, 
  TEmitterEvents } from './types'
import RootView from './RootView'
import { 
  reducer, 
  initialState } from './reducers'
import mitt from 'mitt'
import { 
  useAuthCheck, 
  useDevices, 
  useRealtimeUpdates} from '../../hooks'
import { useIdleTimer } from 'react-idle-timer'
import moment from 'moment'
import { AppContext } from '../../App'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../routes'
import { getUserAttributes } from '../../integrations/aws-cognito/services'
import { useAppDispatch } from '../../store/hooks'
import { setCompanyAccess } from '../../store/slices/userSlice'
import { useGroups } from '../../hooks/data/useGroups'

export const AppRootContext = createContext<TRootAppContext>({})
  const emitter = mitt<TEmitterEvents>()
  const AppRootPage: React.FC<IProps> = () => {
  useAuthCheck({ autoListen: true })
  const reduxDispatch = useAppDispatch()
  const navigate = useNavigate()
  const { handleLogout } = useContext(AppContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  useDevices()
  useGroups({ companies: "*" })
  useRealtimeUpdates()

  const onIdle = async () => {
    await handleLogout?.()
    navigate(Routes.login_page)
  }

  useIdleTimer({
    timeout: moment.duration(60, 'minutes').asMilliseconds(),
    onIdle
  })

  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        const userAttributes: Record<string, any> =
          (await getUserAttributes()) ?? []
          const customAttribute = userAttributes.find(
          (item: any) => item?.Name === 'custom:company_access'
        )
          if (customAttribute) {
          const companyIds: string[] = JSON.parse(customAttribute.Value)
          
          reduxDispatch(setCompanyAccess(companyIds))
        }
      } catch (e) {
        console.error(e)
      } finally {
        setHasLoaded(true)
      }
    })()
  }, [])

  if (!hasLoaded) return null

  return (
    <AppRootContext.Provider
      value={{
        state,
        dispatch,
        emitter
      }}>
      <RootView />
    </AppRootContext.Provider>
  )
}

export default AppRootPage
