import { Text, StatusIndicator, Image } from '../../../atoms'
import { TStatus } from '../../../atoms/StatusIndicator/types'
import { MowerImage, BatteryChargeBar, MowerCommon } from '../../../molecules'
import { Trans } from '@lingui/macro'
import { ASSETS } from '../../../../assets'
import { isMowerOnline, getMowerHours, getMowerAggregatedStateOfCharge } from '../../../../utils/mower'
import BatteryIconListAndPanel from '../../../molecules/BatteryIconListAndPanel/BatteryIconListAndPanel'
import { IMower } from '../../../../models'
import { IDevice } from '../../../../models_v2/entity/device'
import { isAscentDevice, isSurepathDevice } from '../../../../utils/device'
import { getMowerStatus } from '../../../../utils/getMowerStatus'


interface BasicInformationProps {
  mower: IDevice
}

const BasicInformation: React.FC<BasicInformationProps> = props => {
  const { mower } = props
  let { inventory } = mower ?? {}
  const { name } = inventory ?? {}

  return (
    <div className='dark:text-white'>
      <div className='flex justify-between items-center'>
        <div className='flex items-center gap-2'>
          <Text.Feature.Medium>{name}</Text.Feature.Medium>
          {mower.metadata?.some(metadata => metadata.active) && (
            <Image src={ASSETS.IMAGES.COMMON.danger} className='h-4' />
          )}
        </div>

        <StatusIndicator
          isOnline={isMowerOnline(mower)}
          value={getMowerStatus(mower).toLowerCase() as TStatus}
        />
      </div>
      <div className='flex flex-row relative'>
        {isSurepathDevice(mower) && (
          <MowerCommon.SurepathLogo className='absolute' />
        )}
        <MowerImage mower={mower} />

        <div className='ml-2 mt-[15px] flex flex-1 flex-col'>
          <div className='flex flex-col gap-3'>
            <div className='flex flex-col'>
              <div className='flex items-center justify-between gap-3'>
                <div className='flex flex-col flex-1'>
                  <div className='flex flex-col lg:flex-row lg:gap-2'>
                    <Text.VariousRegular.Small>
                      <Trans>Status</Trans>:{' '}
                    </Text.VariousRegular.Small>
                    <Text.VariousBold.Small className='capitalize'>
                      {getMowerStatus(mower).toUpperCase()}
                    </Text.VariousBold.Small>
                  </div>
                  {isSurepathDevice(mower) ? (
                    <MowerCommon.SurepathInfo
                      showGps={false}
                      showCellCignal={false}
                      mower={mower}
                    />
                  ) : (
                    <MowerCommon.LastSignal mower={mower} />
                  )}
                  <div className='flex flex-col lg:flex-row justify-start lg:gap-2'>
                    <Text.VariousRegular.Small>
                      <Trans>Mower Hours:</Trans>
                    </Text.VariousRegular.Small>
                    <Text.VariousBold.Small>
                      {getMowerHours(mower) ?? 'N/A'}
                    </Text.VariousBold.Small>
                  </div>
                  {isAscentDevice(mower) && (
                    <div className='mt-2 ml-[-5px]'>
                      <BatteryIconListAndPanel
                        mower={mower as Partial<IMower>}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='mt-2'></div>
            </div>
          </div>
        </div>
      </div>

      {isAscentDevice(mower) && (
        <BatteryChargeBar
          value={parseFloat(`${getMowerAggregatedStateOfCharge(mower)}`) / 100}
          device={mower || {}}
        />
      )}
    </div>
  )
}

export default BasicInformation