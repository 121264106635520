import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ICompany, IUserAccount } from '../../models'
import { IDevice } from '../../models_v2/entity/device'
import { DefaultCompanyName } from '../../utils/constants'

interface OrganizationCompanyState {
  companies: ICompany[]
  selected_company_id?: string
  selected_assets: Record<string, boolean>
  is_selecting_assets: boolean
}

const initialState: OrganizationCompanyState = {
  companies: [],
  selected_assets: {},
  is_selecting_assets: false
}

type EditCompanyPayload = ICompany & {
  name: string,
  email?: string,
  phone?: string
}

type AddCompanyPayload = {
  name: string,
  companyId: string
}

export const organizationCompany = createSlice({
  name: 'organizationCompany',
  initialState,
  reducers: {
    resetState: () => initialState,
    setCompanies: (state, action: PayloadAction<ICompany[]>) => {
      state.companies = action.payload;
    },
    setSelectedCompanyId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selected_company_id = action.payload
    },
    removeSelectedCompanyId: ( state ) => {
      // TODO: we shouldn't remove keys from state, instead set to undefined or null
      // look into this.
      const { selected_company_id, ...rest } = state
      return rest
    },
    setIsSelectingAssets: (state, action: PayloadAction<boolean>) => {
      state.is_selecting_assets = action.payload
    },
    
    toggleSelectedAsset: (state, { payload }: PayloadAction<{ id: string }>) => {
      const { id } = payload
      if (!state.selected_assets[id]) {
        state.selected_assets[id] = true
      } else {
        const { [id]: _, ...restAssets } = state.selected_assets
        state.selected_assets = restAssets
      }
    },
    addSelectedAsset: (state, { payload }: PayloadAction<{ id: string }>) => {
      const { id } = payload
      state.selected_assets[id] = true
    },
    setSelectedAssets: (state, { payload }: PayloadAction<{ids: string[]}>) => {
      state.selected_assets = payload.ids.reduce((accu, curr) => ({
        ...accu,
        [curr]: true
      }), {})
    },
    removeSelectedAsset: (
      state,
      { payload }: PayloadAction<{ id: string }>
    ) => {
      const { id } = payload
      const { [id]: _, ...restAssets } = state.selected_assets
      state.selected_assets = restAssets
    },
    clearAssetSelection: state => {
      state.is_selecting_assets = false
      state.selected_assets = {}
    },
    addCompany: ( state, action: PayloadAction<AddCompanyPayload> ) => {
      // TODO: why would the existing company in state not have __typename, 
      // but the one we're adding does? possible divergence of state
      // Wrong typescript for the ICompany compared to the result from api
      const { companyId, name } = action.payload
      state.companies.push({
        // @ts-ignore
        __typename: "Company",
        inventory: {
          // @ts-ignore
          __typename: "inventory",
          companyId,
          name
        },
        companyId
      })
    },
    editCompany: ( state, action: PayloadAction<EditCompanyPayload> ) => {
      state.companies = state.companies.map(company => {
        if (company.companyId === action.payload.companyId) {
          return {
            ...company,
            // @ts-ignore
            inventory: {
              // @ts-ignore
              ...company.inventory,
              name: action.payload.name
            }
          }
        }
        return company
      }) as ICompany[]
    },
    resetOrganizationCompanyState: () => initialState
  }
})

export const {
  resetState,
  setCompanies,
  setSelectedCompanyId,
  setIsSelectingAssets,
  addSelectedAsset,
  setSelectedAssets,
  removeSelectedAsset,
  clearAssetSelection,
  toggleSelectedAsset,
  removeSelectedCompanyId,
  addCompany,
  editCompany,
  resetOrganizationCompanyState
} = organizationCompany.actions

const globalCompany = {
  id: "Global",
  inventory: {
    name: 'Global'
  }
}

export const selectAllCompanies = (state: RootState, { includeGlobal } : { includeGlobal: boolean }) => {
  const companies = state.organizationCompany.companies || []
  const userRole = state.user?.current_user?.role
  
  if (includeGlobal && (userRole=== "GlobalAdmin" || userRole === "GlobalViewer")) {
    return [
      globalCompany,
      ...companies,
    ]
  } else if(userRole === "Administrator" || userRole === "Viewer") {
    const userCompanyId = state.user?.current_user?.companies[0]
    return companies.filter(company => company.companyId === userCompanyId)
  }
  return companies
}

export const selectAllCompaniesForDropdown = (state: RootState, { includeGlobal } : { includeGlobal: boolean }) => {
  return selectAllCompanies(state, { includeGlobal }).map( (company: any) => ({
    id: company?.companyId,
    label: company?.inventory.name
  }))
}

export const selectSelectedCompany = (state: RootState) => {
  const { companies = [], selected_company_id = '' } = state.organizationCompany
  return companies?.find(company => company.companyId === selected_company_id)
}

export const selectAllSelectedAssets = (state: RootState) => {
  return state.organizationCompany.selected_assets
}


type CompanyFilterParams = {
  search?: string,
  alphabetical?: boolean,
  asFormFormat?: boolean,
  limitToUserAccess?: boolean,
}
export const selectFilteredCompanies = (state: RootState, { search, alphabetical, asFormFormat, limitToUserAccess }: CompanyFilterParams) => {
  const { companies } = state.organizationCompany
  let filteredCompanies = companies.filter(company => {
    let isMatch = true;
    if (search?.length) {
      if (!company.name?.includes(search) && !company.email?.includes(search)) {
        isMatch = false;
      }
    }
    if (limitToUserAccess) {
      const isGlobalUser = state?.user?.current_user?.role.toLowerCase().includes('global')
      const userBelongsToCompany = state?.user?.current_user?.companies?.includes(company.companyId)
      if (!isGlobalUser && !userBelongsToCompany) {
        isMatch = false
      }
    }
    return isMatch;
  }) 
  
  if (alphabetical) {
    filteredCompanies = filteredCompanies
      .sort((companyA, companyB) => {
        const nameA = (companyA as IDevice).inventory.name
        const nameB = (companyB as IDevice).inventory.name
        return nameA.localeCompare(nameB)
      })
  }
  // Always leave for last so all filter conditions can be applied to companies before returning as form format
  if (asFormFormat) {
    return filteredCompanies.map((company) => ({ 
      label: (company as IDevice)?.inventory?.name, 
      value: company.companyId
    }))
  }
  return filteredCompanies
}

export const selectAssetsByCompanyDictionary = (state: RootState) => {
  const devices = state.deviceV2.devices 

  const assetsByCompanyDictionary = (devices || [])?.reduce((dictionary, device) => {
    const { companyId } = device?.inventory ?? {}
    const { [companyId]: devices = [] } = dictionary ?? {}

    return {
      ...dictionary,
      [companyId]: [...devices, device]
    }
  }, {} as Record<string, IDevice[]>)

  return assetsByCompanyDictionary
}

export const selectPeopleByCompanyDictionary = (state: RootState) => {
  const userAccounts = state.organizationSettings.userAccounts

  const peopleByCompanyDictionary = (userAccounts || [])?.reduce((dictionary, userAccount) => {
    const { companies = [] } = userAccount ?? {}
    const [company = ''] = companies ?? []
    const { [company as string]: users = [] } = dictionary ?? {}

    return {
      ...dictionary,
      [company as string]: [...users, userAccount]
    }
  }, {} as Record<string, IUserAccount[]>)

  return peopleByCompanyDictionary
}

export const selectDefaultCompany = (state: RootState) => {
  const { companies = [] } = state.organizationCompany
  const [defaultCompany = {}] =
  companies?.filter(
    item => item?.inventory?.name === DefaultCompanyName
  ) || []

  return defaultCompany
}



export const organizationCompanyReducer = organizationCompany.reducer
export default organizationCompany.reducer
