import React, { useMemo } from 'react'
import cn from 'classnames'
import { ASSETS } from '../../../../assets'
import { Text, Image } from '../../../atoms'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

interface NetworkStatusIndicatorProps {
  showLabel?: boolean
  hasWrapperInImage?: boolean
  containerClassName?: string
  imageClassName?: string
  imageOnClick?: () => void
  isOnline?: boolean
}

const NetworkStatusIndicator: React.FC<NetworkStatusIndicatorProps> = props => {
  const {
    showLabel = true,
    hasWrapperInImage = true,
    containerClassName,
    imageClassName,
    imageOnClick,
    isOnline
  } = props
  const { i18n } = useLingui()

  const status = useMemo(
    () => (isOnline ? t`Online` : t`Offline`),
    [isOnline, i18n.locale]
  )

  const statusImage = useMemo(
    () =>
      isOnline ? ASSETS.IMAGES.STATUS.online : ASSETS.IMAGES.STATUS.offline,
    [isOnline]
  )

  return (
    <div className={cn('flex items-center', containerClassName)}>
      {showLabel && (
        <Text.Body.Small className='mr-1'>
          {i18n._(`${status}`)}
        </Text.Body.Small>
      )}
      {/* h-5 w-5 */}
      {hasWrapperInImage ? (
        <div className='relative h-[35px] w-[35px]'>
          <Image
            src={statusImage}
            className='status-indicator absolute top-[4.5px]'
            onClick={e => {
              e.stopPropagation()
              imageOnClick?.()
            }}
          />
        </div>
      ) : (
        <Image
          src={statusImage}
          className={imageClassName}
          onMouseDown={e => {
            e.stopPropagation()
            imageOnClick?.()
          }}
        />
      )}
    </div>
  )
}

export default NetworkStatusIndicator
