import React, {
  useRef,
  useCallback,
  memo,
  useMemo,
  useState,
  useContext,
  useEffect,
  CSSProperties
} from 'react'
import styled from 'styled-components'
import { GoogleMap, MarkerClusterer } from '@react-google-maps/api'
import {
  Clusterer,
  TCalculator,
  Cluster
} from '@react-google-maps/marker-clusterer'
import { PathMatch, useSearchParams } from 'react-router-dom'
import { AppRootContext } from '../../../pages/AppRoot'
import { TMapEvents } from '../../../pages/AppRoot/types'
import microdiff from 'microdiff'
import { CENTER, IMAGE_CLUSTER_PATH, MAP_ZOOM } from './constants'
import { lightMapStyles, mapStyles } from './styles'
import { IMower, IToolTag } from '../../../models'
import { IBattery } from '../../../models/battery'
import MapMarkerList from '../MapMarkerList/MapMarkerList'
import { useAppTheme } from '../../../hooks'
import { setMapCluster, setMapZoom } from '../../../store/slices/deviceSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { TDevice } from '../../../models/device'
import { ICoordinates } from '../../molecules/GroupListItem/reducers/types'
import { setDeviceIdsSelectedOnMap } from '../../../store/slices/deviceSliceV2/deviceSlice'
import { RootState } from '../../../store/store'

type TUseMatchValue = PathMatch<string> | null
type TData = Array<IMower | IBattery | IToolTag>
interface IProps {
  data: TData
  selected_ids: Array<string>
  isDefaultTracking: TUseMatchValue
  isTracking: TUseMatchValue
  isTrackingMower: TUseMatchValue
  isTrackingBatteries: TUseMatchValue
  isTrackingToolTags: TUseMatchValue
  isCombinedTrackingMower: boolean
  selected_id: string
  selected_data: TData
  pre_selected_id: string
  isTrackingMowerDetails: TUseMatchValue
  isTrackingBatteryDetails: TUseMatchValue
  isTrackingToolTagDetails: TUseMatchValue
}

const Maps: React.FC<IProps> = props => {
  const {
    data = [],
    selected_ids = [],
    isTrackingBatteries,
    isTrackingToolTags,
    isCombinedTrackingMower,
    selected_id,
    selected_data,
    pre_selected_id,
    isTrackingMowerDetails,
    isTrackingBatteryDetails,
    isTrackingToolTagDetails
  } = props

  const { dispatch, emitter } = useContext(AppRootContext)
  const reduxDispatch = useAppDispatch()
  const { theme } = useAppTheme()
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const boundChangeTimeoutIdRef = useRef<NodeJS.Timeout | null>(null)
  const onClickClusterTimeoutIdRef = useRef<NodeJS.Timeout | null>(null)
  const [minimumClusterSize, setMinimumClusterSize] = useState<number>(0)
  const markerClustererRef = useRef<MarkerClusterer>()
  // Array of Marker ids that are clustered
  const [clusteredMarkerIds, setClusteredMarkerIds] = useState<string[]>([])
  const [searchParams] = useSearchParams()
  const isShowingFaultMarkers = useMemo(
    () => !!searchParams.get('faultHistory'),
    [searchParams.get('faultHistory')]
  )
  const onLoad = useCallback((map: google.maps.Map) => setMap(map), [])
  const onUnmount = useCallback(() => setMap(null), [])

  const fitBounds = useCallback(
    (data: TData) => {
      if (!map || !data.length) return

      const bounds = new google.maps.LatLngBounds()
      const availableCoordinates = data
        .filter(marker => marker && marker.coordinates)
        .map(marker => marker.coordinates) as ICoordinates[]

      if (!availableCoordinates.length) return

      availableCoordinates.forEach(coordinate => {
        coordinate.lat = Number(coordinate.lat)
        coordinate.lng = Number(coordinate.lng)
        bounds.extend(coordinate)
      })

      map.fitBounds(bounds)
    },
    [map]
  )

  useEffect(() => {
    if (!map || !data.length || isShowingFaultMarkers) return

    const fitBoundsMapData = () => {
      if (!selected_id) {
        return
      }

      const selected = [
        data?.find(
          item =>
            `${item?.productSerial}`.trim().replaceAll(' ', '') === selected_id
        )
      ] as TData
      fitBounds?.(selected as TData)
    }

    const timeoutId = setTimeout(fitBoundsMapData, 500)

    return () => clearTimeout(timeoutId)
  }, [selected_id, selected_data, map, data, isShowingFaultMarkers])

  // Updates markers in state which updates mowers lists via state selectors using these markers
  const updateMarkers = () => {
    const filtered_markers = data
      .filter(device => map?.getBounds()?.contains(device.coordinates!))
      .map(device => device?.productSerial)
      .filter(device => typeof device !== 'undefined') as string[]

    reduxDispatch(
      setDeviceIdsSelectedOnMap({
        deviceIds: filtered_markers
      })
    )
  }

  useEffect(() => {
    if (!map) {
      return
    }
    updateMarkers()
  }, [data.length])
  useEffect(() => {
    const onMapEvent = (event: TMapEvents) => {
      switch (event.type) {
        // This will reset the GoogleMap to the default bounds with all the current `data`(markers) and the initiator of this event is from outside this component
        case 'RESET_MAP': {
          fitBounds?.(data)
          break
        }
        case 'FIT_BOUNDS': {
          // This seems to be triggered by useEffect above already, triggering this here confuses the map
          // Try using this with setTimeout(fitBounds?.(data), 2000) & see how the map fits bounds just fine
          // and then this call messes up the fitbounds & zoom somehow
          // fitBounds?.(data)
          break
        }
        case 'FIT_BOUNDS_SELECTED': {
          fitBounds?.(event.payload ?? [])
          break
        }
        default:
          break
      }
    }

    emitter?.on('MAP:tracking', onMapEvent)

    return () => {
      emitter?.off('MAP:tracking', onMapEvent)
    }
  }, [data, emitter])

  const imageClusterPath = useMemo(() => {
    const hostURL = window?.location?.origin
    const defaultImageClusterPath = `${IMAGE_CLUSTER_PATH.default}/m1.png`
    const customImageClusterPath = `${hostURL}${IMAGE_CLUSTER_PATH.custom}/map-cluster.png`

    return hostURL ? customImageClusterPath : defaultImageClusterPath
  }, [])

  const selectedImageClusterPath = useMemo(() => {
    const hostURL = window?.location?.origin
    const defaultImageClusterPath = `${IMAGE_CLUSTER_PATH.default}/m1.png`
    const customImageClusterPath = `${hostURL}${IMAGE_CLUSTER_PATH.custom}/map-cluster-selected.png`

    return hostURL ? customImageClusterPath : defaultImageClusterPath
  }, [])

  const dynamicOptions = useMemo(() => {
    const defaultOptions = {
      zoomControl: true,
      scrollwheel: true,
      draggable: true,
      disableDoubleClickZoom: false
    }

    if (
      isTrackingMowerDetails ||
      isTrackingBatteryDetails ||
      isTrackingToolTagDetails
    ) {
      return {
        zoomControl: false,
        scrollwheel: false,
        draggable: false,
        disableDoubleClickZoom: true
      }
    }

    return defaultOptions
  }, [
    isTrackingMowerDetails,
    isTrackingBatteryDetails,
    isTrackingToolTagDetails
  ])

  const isTrackingDetails = useMemo(
    () =>
      isTrackingMowerDetails ||
      isTrackingBatteryDetails ||
      isTrackingToolTagDetails,
    [isTrackingMowerDetails, isTrackingBatteryDetails, isTrackingToolTagDetails]
  )

  useEffect(() => {
    handleTrackClusters()
  }, [isCombinedTrackingMower, isTrackingBatteries, isTrackingToolTags, data])

  // here
  const handleOnClickMarker = (data: any) => {
    if (!dispatch) return

    if (isCombinedTrackingMower) {
      dispatch({
        type: 'SET_SELECTED_MOWER_IDS',
        payload: [data.productSerial]
      })
    } else if (isTrackingBatteries) {
      dispatch({
        type: 'SET_SELECTED_BATTERY_IDS',
        payload: [data.productSerial]
      })
    } else if (isTrackingToolTags) {
      dispatch({
        type: 'SET_SELECTED_TOOL_TAG_IDS',
        payload: [data.id]
      })
    }
    fitBounds([data])
  }

  const clusterMarker = useCallback(
    (clusterer: Clusterer) => {
      return (
        <MapMarkerList
          // @ts-ignore
          items={data}
          clusterer={clusterer}
          selected_ids={selected_ids}
          preSelectedId={pre_selected_id}
          clickable={!isTrackingDetails}
          onClick={handleOnClickMarker}
          clusteredMarkerIds={clusteredMarkerIds}
        />
      )
    },
    [data, selected_ids, pre_selected_id, isTrackingDetails, clusteredMarkerIds]
  )

  const customCalculator: TCalculator = useCallback(
    markers => ({
      text: `${markers.length}`,
      index: 0,
      title: ''
    }),
    []
  )

  const handleTrackClusters = () => {
    const { clusters = [], markers: allMarkers } =
      markerClustererRef?.current?.state?.markerClusterer ?? {}

    if (data.length) {
      let type: TDevice = 'mower'
      if (isTrackingBatteries) type = 'battery'
      else if (isTrackingToolTags) type = 'tool_tag'
      // Since clusters is a mutable, create a copy for this
      const newClusters = clusters.map(item => ({
        devices: item.markers.map(marker => (marker as any).raw)
      }))

      reduxDispatch(
        setMapCluster({
          type,
          clusters: newClusters
        })
      )
    }

    const clusteredMarkerIds: string[] = [
      ...new Set(
        allMarkers?.map(marker => marker.get('id') as string) ?? []
      ).keys()
    ]

    const removeMarkerFromClusterMarkerIds = (id: string) => {
      const matchedIndex = clusteredMarkerIds.findIndex(
        markerId => markerId === id
      )
      if (matchedIndex !== -1) clusteredMarkerIds.splice(matchedIndex, 1)
    }

    clusters.forEach(cluster => {
      const map = cluster.getMap()
      const markers = cluster.markers
      const current_zoom = map?.getZoom() as number

      if (
        (current_zoom > 7 && markers.length < 2) ||
        (current_zoom >= 12 && markers.length < 3) ||
        (current_zoom >= 14 && markers.length < 7) ||
        current_zoom >= MAP_ZOOM.MAX
      ) {
        markers.forEach(marker =>
          removeMarkerFromClusterMarkerIds(marker.get('id'))
        )
      }
    })

    setClusteredMarkerIds(clusteredMarkerIds)
  }

  const onBoundChangeHandler = useCallback(() => {
    if (!dispatch || !map) return

    const dynamicMarkerHandler = () => {
      if (!data || !data.length) return

      const filtered_markers = data.filter(item =>
        map?.getBounds()?.contains(item.coordinates!)
      )

      const diff = microdiff(selected_data, filtered_markers)
      if (!diff.length) return

      if (isCombinedTrackingMower) {
        dispatch({
          type: 'SET_SELECTED_MOWERS',
          // @ts-ignore
          payload: filtered_markers ?? ([] as Array<IMower>)
        })
      } else if (isTrackingBatteries) {
        dispatch({
          type: 'SET_SELECTED_BATTERIES',
          payload: (filtered_markers ?? []) as Array<IBattery>
        })
      } else if (isTrackingToolTags) {
        dispatch({
          type: 'SET_SELECTED_TOOL_TAGS',
          payload: (filtered_markers ?? []) as Array<IToolTag>
        })
      }
    }

    if (boundChangeTimeoutIdRef.current)
      clearTimeout(boundChangeTimeoutIdRef.current)

    const timeoutId = setTimeout(dynamicMarkerHandler)

    boundChangeTimeoutIdRef.current = timeoutId
  }, [!!map, selected_data])

  const onZoomChangedHandler = useCallback(() => {
    if (!map) return

    const current_zoom = map?.getZoom() as number

    reduxDispatch(setMapZoom(current_zoom))

    if (!current_zoom) return

    if (current_zoom >= MAP_ZOOM.MAX) {
      // High value so that it won't cluster
      setMinimumClusterSize(999)
    } else if (current_zoom >= 14) {
      setMinimumClusterSize(7)
    } else if (current_zoom >= 12) {
      setMinimumClusterSize(3)
    } else if (current_zoom > 7) {
      setMinimumClusterSize(2)
    } else {
      setMinimumClusterSize(0)
    }
  }, [!!map, MAP_ZOOM])

  const onClickCluster = useCallback(
    (cluster: Cluster) => {
      const clickedMarkers = cluster.getMarkers()

      const handleOnClickCluster = (clickedMarkers: any) => {
        const markers = clickedMarkers.map((marker: any) => (marker as any).raw)
        const selected_marker_ids = markers?.map(
          (item: any) => item.productSerial
        )

        if (!dispatch) return

        if (isCombinedTrackingMower) {
          dispatch({
            type: 'SET_SELECTED_MOWER_IDS',
            payload: selected_marker_ids
          })
        } else if (isTrackingBatteries) {
          dispatch({
            type: 'SET_SELECTED_BATTERY_IDS',
            payload: selected_marker_ids
          })
        } else if (isTrackingToolTags) {
          dispatch({
            type: 'SET_SELECTED_TOOL_TAG_IDS',
            payload: selected_marker_ids
          })
        }

        updateMarkers()
        // reduxDispatch(
        //   setDeviceIdsSelectedOnMap({
        //     deviceIds: selected_marker_ids
        //   })
        // )
      }

      if (onClickClusterTimeoutIdRef.current)
        clearTimeout(onClickClusterTimeoutIdRef.current)

      const timeoutId = setTimeout(() => handleOnClickCluster(clickedMarkers))

      onClickClusterTimeoutIdRef.current = timeoutId
    },
    [data]
  )

  const updateClusterer = (clusterer: Clusterer): Clusterer => {
    const target_selected_ids: string[] = pre_selected_id
      ? [pre_selected_id]
      : []

    clusterer.clusters?.forEach((cluster, index) => {
      const clusterMarkers = cluster.getMarkers()
      const rawMarkers = clusterMarkers.map(
        marker => (marker as any).raw as any
      )
      const markers = rawMarkers?.map(item => item.id)

      if (markers.some(marker => target_selected_ids?.includes(marker))) {
        cluster.clusterIcon.styles = [makeClusterIcon(true)]
      } else {
        cluster.clusterIcon.styles = [makeClusterIcon()]
      }
      cluster.updateIcon()
    })

    return clusterer
  }

  const googleMapOptions = useMemo<google.maps.MapOptions>(() => {
    return {
      styles: theme === 'dark' ? mapStyles : lightMapStyles,
      streetViewControl: false,
      mapTypeControl: true,
      fullscreenControl: false,
      maxZoom: MAP_ZOOM.MAX,
      minZoom: MAP_ZOOM.MIN,
      ...dynamicOptions
    }
  }, [theme, MAP_ZOOM, dynamicOptions])

  const makeClusterIcon = useCallback(
    (selected: boolean = false) => {
      return {
        url: selected ? selectedImageClusterPath : imageClusterPath,
        height: 100,
        width: 100,
        anchorIcon: [48, 46],
        fontWeight: 'bold',
        textSize: 12
      }
    },
    [selectedImageClusterPath, imageClusterPath]
  )

  const markerClustererStyles = useMemo(() => [makeClusterIcon()], [])

  const mapContainerStyle: CSSProperties = useMemo(() => {
    return {
      width: '100%',
      height: '100%'
    }
  }, [])
  const currentZoomMap = useAppSelector(
    (state: RootState) => state.device.current_map_zoom
  )
  const mapCenter = useMemo(() => {
    return (
      data.find(mower => mower.productSerial === pre_selected_id)
        ?.coordinates ?? CENTER
    )
  }, [pre_selected_id])

  useEffect(() => {
    const timeout = setTimeout(() => updateMarkers(), 250)

    return () => clearTimeout(timeout)
  }, [currentZoomMap])

  return (
    <>
      {/** @ts-ignore */}
      <StyledDiv className='h-full w-full'>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={currentZoomMap}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onBoundsChanged={onBoundChangeHandler}
          onIdle={handleTrackClusters}
          onDragEnd={updateMarkers}
          onZoomChanged={onZoomChangedHandler}
          center={mapCenter}
          clickableIcons={false}
          options={googleMapOptions}>
          <MarkerClusterer
            // @ts-ignore
            ref={markerClustererRef}
            calculator={customCalculator}
            minimumClusterSize={minimumClusterSize}
            onClick={(cluster: Cluster) => onClickCluster(cluster)}
            styles={markerClustererStyles}>
            {clusterer => clusterMarker(updateClusterer(clusterer))}
          </MarkerClusterer>
        </GoogleMap>
      </StyledDiv>
    </>
  )
}

export default memo(Maps)

const StyledDiv = styled.div`
  > div > div {
    background-color: black !important;
  }
`
