import React from 'react'
import { Text } from '../../atoms'
import cn from 'classnames'

interface SurepathTextProps {
  containerClassName?: string
  className?: string
  suffix?: string
}

const SurepathText: React.FC<SurepathTextProps> = props => {
  const { containerClassName, className, suffix } = props

  return (
    <div className={cn('flex gap-1 dark:text-white', containerClassName)}>
      <div className='flex items-center'>
        <Text.Feature.Medium className={cn('text-[20px]', className)}>
          SURE
        </Text.Feature.Medium>
        <Text.Feature.Medium
          className={cn('text-[20px] font-normal', className)}>
          PATH
        </Text.Feature.Medium>
      </div>
      <Text.Feature.Medium className={cn('text-[20px]', className)}>
        {suffix}
      </Text.Feature.Medium>
    </div>
  )
}

export default SurepathText
