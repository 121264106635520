import { IDevice } from "../../../../models_v2/entity/device"
import { 
  Icon, 
  Text } from "../../../atoms"


type ActivityTimelineProps = {
  mower: IDevice,
  onClick: VoidFunction
}

export const ActivityTimeline = ({ mower, onClick }: ActivityTimelineProps) =>{

  return (
    <div className='justify-end flex gap-2'>
      <Text.VariousRegular.Medium
        className='dark:text-white cursor-pointer'
        onClick={onClick}>
        Activity Timeline
      </Text.VariousRegular.Medium>
      <Icon
        name='caret_right'
        className='invert dark:filter-none'
        width={9} />
    </div>
  )
}