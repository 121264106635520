import PowerEquipButton from './PowerEquipButton'
import SubscriptionInfoCard from './SubscriptionInfoCard'
import LastSeen from './LastSeen'
import Status from './Status'
import OnboardOrAvailableIndicator from './OnboardOrAvailableIndicator'

export const BatteryCommon = {
  PowerEquipButton,
  SubscriptionInfoCard,
  LastSeen,
  Status,
  OnboardOrAvailableIndicator
}
