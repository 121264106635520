/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { DeviceCounterItem, SearchTextField } from '../../molecules'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import { setSearchFilter } from '../../../store/slices/organizationSettingsSlice'
import { Routes } from '../../../routes'
import { EDeviceTypes } from '../../../models/device'
import { useToolTags } from '../../../hooks'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import { selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors'
import { IDevice } from '../../../models_v2/entity/device'

interface AssetAssignerButtonsProps {}

export const AssetAssignerButtons: React.FC<AssetAssignerButtonsProps> = () => {
  const navigate = useNavigate()
  const { searchFilter } = useAppSelector(state => state.organizationSettings)
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const companyBatteries = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'battery',
      operatingCompany,
      withoutCompanyAssigned: true
    })
  ) as IDevice[]
  const companyMowers = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'mower',
      operatingCompany,
      withoutCompanyAssigned: true
    })
  ) as IDevice[]
  const companyTooltags = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'tools-and-tags',
      operatingCompany,
      withoutCompanyAssigned: true
    })
  ) as IDevice[]
  const reduxDispatch = useAppDispatch()

  const isAssignMower = useMatch(Routes['assign_asset.mower'])
  const isAssignBattery = useMatch(Routes['assign_asset.battery'])
  const isAssignToolTag = useMatch(Routes['assign_asset.tool_tag'])

  const companyIdFromParams = useMemo(() => {
    if (isAssignMower) return isAssignMower.params?.id ?? ''
    if (isAssignBattery) return isAssignBattery.params?.id ?? ''
    if (isAssignToolTag) return isAssignToolTag.params?.id ?? ''

    return ''
  }, [isAssignMower, isAssignBattery, isAssignToolTag])

  const handleCategoryClick = (category: EDeviceTypes) => {
    // reduxDispatch(setCurrentActiveList(category))
    navigate({
      pathname: Routes[`assign_asset.${category}`].replace(
        ':id',
        companyIdFromParams
      )
    })
  }

  const onSearchChange = (value: string) => {
    reduxDispatch(setSearchFilter(value))
  }

  return (
    <div className='p-3 flex flex-col items-start justify-between lg:flex-row lg:items-center gap-3'>
      <div className='flex gap-[20px]'>
        <DeviceCounterItem
          label='MOWERS'
          value={companyMowers.length || '0'}
          isActive={!!isAssignMower}
          onClick={() => handleCategoryClick(EDeviceTypes.Mower)}
        />
        <DeviceCounterItem
          label='BATTERIES'
          value={companyBatteries.length || '0'}
          isActive={!!isAssignBattery}
          onClick={() => handleCategoryClick(EDeviceTypes.Battery)}
        />
        <DeviceCounterItem
          label='TOOLS & TAGS'
          value={companyTooltags?.length || '0'}
          isActive={!!isAssignToolTag}
          onClick={() => handleCategoryClick(EDeviceTypes.ToolTag)}
        />
      </div>

      <div className='w-full lg:max-w-[300px]'>
        <SearchTextField
          onChange={e => onSearchChange(e.target.value)}
          value={searchFilter}
        />
      </div>
    </div>
  )
}

export default AssetAssignerButtons
