import React, { useMemo, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { TextField } from '@mui/material'
import { LoginCardContainer } from '../../molecules'
import { Text, Button } from '../../atoms'
import { isValidEmail } from '../../../utils/common'
import { AppContext } from '../../../App'
import { Routes } from '../../../routes'

interface IFormInput {
  firstName: string
  lastName: string
  email: string
  mobileNumber: string
  password: string
}

const styles = {
  fieldset: { borderColor: 'whitesmoke', color: 'whitesmoke' },
  label: { color: 'whitesmoke' },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      borderColor: '#FEBD17'
    }
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      borderColor: '#FEBD17',
      color: 'whitesmoke'
    }
  },
  '& .MuiInputLabel-root.Mui-focused': { color: '#FEBD17' } //styles the label
}

const SignupForm: React.FC = () => {
  const { signUp } = useContext(AppContext)
  const { register, handleSubmit, watch, formState } = useForm<IFormInput>()
  const [isLoading, setIsLoading] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const formData = watch()
  const navigate = useNavigate()
  const [userCreated, setUserCreated] = useState(false)
  const { errors } = formState ?? {}
  const isSubmitDisabled = useMemo(() => {
    const { email, firstName, lastName, mobileNumber, password } =
      formData ?? {}

    return (
      isLoading ||
      !acceptTerms ||
      !email ||
      !firstName ||
      !lastName ||
      !mobileNumber ||
      !password
    )
  }, [formData, isLoading, acceptTerms])

  const handlerAcceptTerms = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    setAcceptTerms(checked)
  }

  const handleEmailValidation = (email: string) => {
    const isValid = isValidEmail(email)
    return isValid ? true : 'Invalid Email'
  }

  const onSubmit = async (data: IFormInput) => {
    try {
      setIsLoading(true)

      if (!signUp) return setIsLoading(false)

      const {
        email,
        password,
        firstName: first_name,
        lastName: last_name
        // mobileNumber: mobile_number
      } = data

      const defaultAttr = {
        email_verified: 'true',
        phone_number_verified: 'true'
      }

      const { success, code } = await signUp(email, password, {
        // * Custom attributes commented for now, schema need to be setup in the console.
        // first_name,
        // last_name,
        // mobile_number
        name: `${first_name} ${last_name}`
        // ...defaultAttr
      })

      if (!success && code === 'UserNotConfirmed') {
        navigate(Routes['login_page.signup.code'], {
          state: {
            credentials: {
              username: email
            }
          }
        })
        setIsLoading(false)
        return
      } else if (!success) {
        setErrorMessage('Account creation failed.')
        setIsLoading(false)
        return
      }

      setIsLoading(false)
      setUserCreated(true)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      // @ts-ignore
      setErrorMessage(e?.message || "Can't establish connection.")
    }
  }

  const navigateToLogin = () => {
    navigate(Routes['login_page'])
  }

  return (
    <LoginCardContainer>
      {userCreated ? (
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col gap-2'>
            <Text.Headline.H3 className='mt-[50px]'>
              Account Created Successfully!
            </Text.Headline.H3>
            <Text.Body.Small>
              Please login using your newly registered account. Thank you.
            </Text.Body.Small>
          </div>

          <Button
            type='submit'
            title='Ok, got it'
            className='w-[116px] h-[43px]'
            onClick={navigateToLogin}
          />
        </div>
      ) : (
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col gap-1'>
            <Text.Headline.H3 className='mt-[50px]'>
              CREATE ACCOUNT
            </Text.Headline.H3>
            <Text.Body.SmallItalic>
              All fields are required.
            </Text.Body.SmallItalic>
          </div>

          <div className='flex flex-col gap-5'>
            <form
              className='flex flex-col gap-4'
              onSubmit={handleSubmit(onSubmit)}>
              <div className='flex flex-col gap-[28px]'>
                {errorMessage && (
                  <div className='bg-[#f8514926] border border-solid border-[#f8514966] rounded py-2 px-3 mb-3 text-center'>
                    <Text.Body.Small>{errorMessage}</Text.Body.Small>
                  </div>
                )}
                <TextField
                  {...register('firstName', { required: true })}
                  id='first-name'
                  className='z-10'
                  label='First Name'
                  type={'text'}
                  defaultValue=''
                  style={{
                    width: '100%',
                    height: '41px'
                  }}
                  inputProps={{
                    style: { color: 'white', outlineColor: 'white' }
                  }}
                  sx={styles}
                />
                <TextField
                  {...register('lastName', { required: true })}
                  id='last-name'
                  className='z-10'
                  label='Last Name'
                  type={'text'}
                  defaultValue=''
                  style={{
                    width: '100%',
                    height: '41px'
                  }}
                  inputProps={{
                    style: { color: 'white', outlineColor: 'white' }
                  }}
                  sx={styles}
                />
                <TextField
                  {...register('email', {
                    required: {
                      message: 'Email is required',
                      value: true
                    },
                    validate: handleEmailValidation
                  })}
                  id='email'
                  className='z-10'
                  label='Email'
                  type={'email'}
                  defaultValue=''
                  style={{
                    width: '100%',
                    height: '41px'
                  }}
                  inputProps={{
                    style: { color: 'white', outlineColor: 'white' }
                  }}
                  sx={styles}
                />
                {errors?.email && (
                  <Text.Body.Small className='text-error indent-3'>
                    {errors?.email?.message ?? ''}
                  </Text.Body.Small>
                )}
                <TextField
                  {...register('mobileNumber', { required: true })}
                  id='mobile-number'
                  className='z-10'
                  label='Mobile Number'
                  type={'text'}
                  defaultValue=''
                  style={{
                    width: '100%',
                    height: '41px'
                  }}
                  inputProps={{
                    style: { color: 'white', outlineColor: 'white' }
                  }}
                  sx={styles}
                />
                <TextField
                  {...register('password', { required: true })}
                  id='password'
                  className='z-10'
                  label='Password'
                  type={'password'}
                  defaultValue=''
                  style={{
                    width: '100%',
                    height: '41px'
                  }}
                  inputProps={{
                    style: { color: 'white', outlineColor: 'white' }
                  }}
                  sx={styles}
                />
              </div>

              <div className='flex gap-3'>
                <input
                  className='focus:outline-none'
                  type='checkbox'
                  onClick={handlerAcceptTerms}
                  checked={acceptTerms}
                />
                <div>
                  <Text.Body.Small>
                    I have read and accept the{' '}
                    <span className='text-primary'>Terms of Use</span>
                    <span> </span>
                    <span>and</span>
                    <span> </span>
                    <span className='text-primary'>Privacy Policy.</span>
                  </Text.Body.Small>
                </div>
              </div>

              <Button
                className={
                  'bg-primary text-black hover:bg-primary focus:bg-primary active:bg-primary'
                }
                title={!isLoading ? 'Create Account' : 'Creating Account...'}
                disabled={isSubmitDisabled}
                type='submit'
              />

              <div className='w-full flex items-center justify-center'>
                <Text.Body.Small>
                  Already have an account?{' '}
                  <span
                    className='underline cursor-pointer'
                    onClick={navigateToLogin}>
                    Sign in
                  </span>
                </Text.Body.Small>
              </div>
            </form>
          </div>
        </div>
      )}
    </LoginCardContainer>
  )
}

export default SignupForm
