import { IUserAccount } from '..'
import { IUserEntity } from '../entity'
import { IUserFormInput } from '../../components/organisms/SettingsUserAdministration/components/UserProfileForm'

export const mapUserToUserEntity = (
  userAccount: IUserFormInput
): Partial<IUserEntity> => {
  const {
    id,
    first_name,
    last_name,
    email,
    phone_number,
    role,
    status,
    companies
  } = userAccount

  return {
    id,
    firstName: first_name,
    lastName: last_name,
    companies,
    email,
    phone: phone_number,
    role,
    status
  }
}
