// Since GraphQL has been standardized ,we will no longer map the GraphQL to frontend's own schema
// But there are some fields that are still in JSON string, and we want to transform it to JS object thus need this deserializing method

import { IDevice } from '../entity/device'
import { deserializeDevice } from './device'
import { DEVICE_TYPE } from '../entity/telemetry'
import { IBattery } from '../../models/battery'
import { BatteryPack } from '../entity/telemetry_payload'
import { Mower } from '../common/mower'
import {
  isBatteryDevice,
  isMowerDevice,
  isToolsAndTagsDevice
} from '../../utils/device'
import { ToolTag } from '../../utils/sortingLists'

export type TMapperKeysV2 =
  | 'devices_v2'
  | 'device_to_mower_v2'
  | 'device_to_battery_v2'

const devicesToMower = (devices: IDevice[]) => {
  return devices?.map(device => {
    let payload: Mower | null = null
    try {
      payload = JSON.parse(device?.latestTelemetry?.payload as string)
    } catch (e) {
      console.log({ e })
    }
    return {
      ...device,
      latestTelemetry: {
        ...device.latestTelemetry,
        payload
      }
    } as unknown as BatteryPack
  })
}

const devicesToBatteries = (devices: IDevice[]) => {
  return devices?.map(device => {
    let payload: BatteryPack | null = null
    try {
      payload = JSON.parse(device?.latestTelemetry?.payload as string)
    } catch (e) {
      console.log({ e })
    }
    // TODO: implement later, looks like app depends on JSON, we need to remove that dependency if we uncomment
    // let location: IDevice['latestTelemetry']['location'] | null = null
    // try {
    //   location = JSON.parse(device?.latestTelemetry?.location as string)
    // } catch (e){
    //   console.log({e})
    // }
    return {
      ...device,
      latestTelemetry: {
        ...device.latestTelemetry,
        payload
        // location
      }
    } as unknown as BatteryPack
  })
}

const devicesToToolTags = (devices: IDevice[]) => {
  return devices?.map(device => {
    let payload: ToolTag | null = null
    let location: IDevice['latestTelemetry']['location'] | null = null

    try {
      location = JSON.parse(device?.latestTelemetry?.location as string)
    } catch (e) {
      console.log({ e })
    }

    try {
      payload = JSON.parse(device?.latestTelemetry?.payload as string)
    } catch (e) {
      console.log({ e })
    }
    return {
      ...device,
      latestTelemetry: {
        ...device.latestTelemetry,
        payload,
        location
      }
    } as unknown as ToolTag
  })
}

export const MAPPER_COLLECTION_V2: Record<TMapperKeysV2, (data: any) => any> = {
  devices_v2: (devices: IDevice[]) => {
    return [
      ...devicesToMower(devices.filter(device => isMowerDevice(device))),
      ...devicesToBatteries(devices.filter(device => isBatteryDevice(device))),
      ...devicesToToolTags(
        devices.filter(device => isToolsAndTagsDevice(device))
      )
    ]
  },
  device_to_mower_v2: (devices: IDevice[]) => {
    return [...devicesToMower(devices.filter(device => isMowerDevice(device)))]
  },
  device_to_battery_v2: (devices: IDevice[]) => {
    return [
      ...devicesToBatteries(devices.filter(device => isBatteryDevice(device)))
    ]
  }
}
