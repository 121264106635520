import { CellProps } from 'react-table'
import { Icon, StatusIndicator, Table } from '../../../atoms'
import { IDevice } from '../../../../models_v2/entity/device'
import { useCallback, useContext } from 'react'
import { IBattery, IMower, IToolTag } from '../../../../models'
import { TStatus } from '../../../atoms/StatusIndicator/types'
import { TDevice } from '../../../../models/device'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { selectUserIsAdmin } from '../../../../store/slices/userSlice'
import { Routes } from '../../../../routes'
import { useAppDispatch } from '../../../../store/hooks'
import { addDeviceInSelection } from '../../../../store/slices/deviceGroupSlice'
import { AppRootContext } from '../../../../pages/AppRoot'
import {
  isBatteryDevice,
  isMowerDevice,
  isToolsAndTagsDevice
} from '../../../../utils/device'
import { useNavigate } from 'react-router'

interface IAssetListProps {
  searchText: string
  isLoading: boolean
  items: IDevice[]
}

const AssetList: React.FC<IAssetListProps> = props => {
  const reduxDispatch = useAppDispatch()
  const userIsAdmin = useSelector((state: RootState) =>
    selectUserIsAdmin(state)
  )
  const { emitter } = useContext(AppRootContext)
  const navigate = useNavigate()

  const handleEditClick = (item: IDevice) => {
    if (!item.inventory.deviceType) return

    if (isBatteryDevice(item)) {
      return emitter?.emit('FORM:garage.global_fleet', {
        type: 'PREFILL_BATTERY_FORM_VALUE',
        payload: item
      })
    }

    return emitter?.emit('FORM:garage.global_fleet', {
      type: 'PREFILL_MOWER_FORM_VALUE',
      payload: item
    })
  }

  // TODO: Need to change
  const handleNavigateToTrackingDetail = useCallback(
    (type: TDevice, item: IDevice) => {
      const isMower = isMowerDevice(item)
      const isBattery = isBatteryDevice(item)
      const isToolsAndTags = isToolsAndTagsDevice(item)

      let resolvedEventType,
        resolvedPathName = '',
        resolvedBasePathName = ''
      if (isMower) {
        resolvedEventType = 'SELECT_MOWER'
        resolvedPathName = Routes['tracking_page.mowers.detail']
        resolvedBasePathName = Routes['tracking_page.mowers']
      } else if (isBattery) {
        resolvedEventType = 'SELECT_BATTERY'
        resolvedPathName = Routes['tracking_page.batteries.detail']
        resolvedBasePathName = Routes['tracking_page.batteries']
      } else if (isToolsAndTags) {
        resolvedEventType = 'SELECT_TOOL_TAG'
        resolvedPathName = Routes['tracking_page.tool_tags.detail']
        resolvedBasePathName = Routes['tracking_page.tool_tags']
      }
      navigate(resolvedPathName.replace(':id', item.productSerial))
    },
    []
  )

  // TODO: Need to change
  const renderStatusCell = useCallback(
    (item: IMower | IBattery | IToolTag) => (
      <StatusIndicator
        showLabel={false}
        value={item?.network_status?.toLowerCase?.() as TStatus}
      />
    ),
    []
  )

  const renderEditCell = useCallback(
    (type: TDevice, item: IDevice) => (
      <div className='flex justify-end items-center gap-4 mt-1'>
        <Icon
          name='border_box'
          className='w-[18px] h-[18px] cursor-pointer invert dark:filter-none'
          onClick={() => handleNavigateToTrackingDetail(type, item)}
        />
        {userIsAdmin && (
          <Icon
            name='edit_pencil'
            className='w-[18px] h-[18px] cursor-pointer invert dark:filter-none'
            onClick={() => handleEditClick(item)}
          />
        )}
      </div>
    ),
    [userIsAdmin]
  )

  const groupAssigned = {
    Header: 'Group Name',
    accessor: 'inventory.groupName'
  }

  const nameAccessor = {
    Header: 'Name',
    accessor: 'inventory.name'
  }

  const factoryAccessor = {
    Header: 'Factory Model #1',
    accessor: 'inventory.factoryModel'
  }

  const modelAccessor = {
    Header: 'Model',
    accessor: 'inventory.model'
  }

  const statusAccessor = {
    Header: 'Status',
    accessor: 'network_status',
    width: 80,
    maxWidth: 80,
    Cell: (cell: CellProps<any>) =>
      renderStatusCell(cell.row.original as IMower)
  }

  const editAccessor = {
    Header: '',
    accessor: 'actions',
    width: 120,
    maxWidth: 120,
    Cell: (cell: CellProps<any>) =>
      renderEditCell('mower', cell.row.original as IDevice)
  }

  const serialAccessor = {
    Header: 'Serial #',
    accessor: 'productSerial'
  }

  const columns = [
    nameAccessor,
    serialAccessor,
    factoryAccessor,
    modelAccessor,
    groupAssigned,
    statusAccessor,
    editAccessor
  ]

  const handleOnDeviceRowClick = (data: Record<string, any>) => {
    reduxDispatch(addDeviceInSelection(data))
  }
  const tableProps = {
    onRowClick: (data: Record<string, any>) => handleOnDeviceRowClick(data)
  }

  return (
    <>
      <Table
        data={props.items}
        columns={columns}
        isLoading={props.isLoading}
        defaultSortedColumn={{
          key: 'inventory.name',
          sort: 'ascending'
        }}
        rowKey='productSerial'
        columnClassName='text-black dark:text-white'
        {...tableProps}
      />
    </>
  )
}
export default AssetList
