import React, { createContext, useReducer } from 'react'
import ProfilePage from './ProfilePage'
import { reducer, initialState } from './reducers'
import { ProfilePageProps, TProfilePageContext } from './types'

export const ProfilePageContext = createContext<TProfilePageContext>({})

const ProfilePageWrapper: React.FC<ProfilePageProps> = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ProfilePageContext.Provider
      value={{
        state,
        dispatch
      }}>
      <ProfilePage />
    </ProfilePageContext.Provider>
  )
}

export default ProfilePageWrapper
