import { IStandardValueWrapper } from '../models/device'

export const startCase = (value: string) => {
  return `${value.charAt(0).toUpperCase() + value.slice(1)}`
}

export const isValidEmail = (email: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

// `value` maybe a schema for `IStandardValueWrapper` or the actual value in `string format` or a JSON stringified value
export const resolveToStandardValueWrapper = <T>(
  value: string
): IStandardValueWrapper<T> => {
  try {
    const result = JSON.parse(value)

    // Check whether this a standard value wrapper already
    if (typeof result === 'object') {
      if (
        Object.hasOwn(result, 'value') &&
        Object.hasOwn(result, 'timestamp')
      ) {
        return result as IStandardValueWrapper
      }
    }

    return {
      value: result,
      timestamp: undefined
    }
  } catch (e) {
    // Ignore parsing error due to bad data

    return {
      value: undefined,
      timestamp: undefined
    }
  }
}

export const convertDeviceTimeLineToValidJSON = (value: string) => {
  // Remove unnecessary whitespaces and line breaks
  const cleanedString = value.replace(/\s/g, '')

  // Replace '=' with ':' and add double quotes around keys and values
  const jsonString = cleanedString
    .replace(/(\w+)=([^,}\]]+)/g, '"$1":"$2"')
    .replace(/(\d{4}-\d{2}-\d{2})(\d{2}:\d{2}:\d{2}\.\d+)/g, '$1 $2')

  return JSON.parse(jsonString)
}

export function convertArrayToMap<T, I>(
  array: T[],
  key: keyof T & string,
  iterator?: (data: T) => I
): Record<string, I extends T ? I : T> {
  // @ts-ignore
  return array.reduce<Record<string, T extends I ? I : T>>((acc, curr) => {
    return {
      ...acc,
      [curr[key] as keyof T]: iterator ? iterator(curr) : curr
    }
  }, {})
}
