import { RootState } from '../../store'
import { ICompany } from '../../../models'

export const selectCompanyDictionary = (state: RootState) => {
  const companies = state?.organizationCompany?.companies

  return companies?.reduce(
    (companiesDictionary, company) => ({
      ...companiesDictionary,
      [company?.companyId!]: company
    }),
    {} as Record<string, ICompany>
  )
}
