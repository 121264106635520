import React, { useMemo } from 'react'
import { ASSETS } from '../../../../assets'
import { Text, Image } from '../../../atoms'
import { BatteryChargeBar, BatteryCommon } from '../../../molecules'
import { getBatteryStateOfCharge } from '../../../../utils/battery'
import NetworkStatusIndicator from '../NetworkStatusIndicator'
import { isBatteryOnline } from '../../../../utils/battery'
import { IDevice } from '../../../../models_v2/entity/device'
import { ITelemetryPayload } from '../../../../models_v2/entity/telemetry_payload'
import { BATTERY_STATUS_MAPPING } from '../../../../models_v2/common/battery'

interface BasicInformationProps {
  // IBattery type is a wrong type compared to api result, used any for now
  battery: IDevice
}

const BasicInformation: React.FC<BasicInformationProps> = props => {
  const { inventory, latestTelemetry } = props.battery ?? {}
  const { CurrentState } = (latestTelemetry?.payload ?? {}) as ITelemetryPayload

  const batteryStatus = useMemo(
    () =>
      BATTERY_STATUS_MAPPING[
        CurrentState as keyof typeof BATTERY_STATUS_MAPPING
      ] || 'Unknown',
    [CurrentState]
  )

  return (
    <div className='dark:text-white'>
      <div className='flex justify-between items-center gap-2'>
        <div className='flex items-center gap-2 max-w-[256px]'>
          <Text.Feature.Medium>{inventory?.name}</Text.Feature.Medium>
          {/* TODO: Condition: show only if it is a subscription battery */}
          {/* <BatteryCommon.PowerEquipButton item={props.battery} /> */}
        </div>
        <NetworkStatusIndicator isOnline={isBatteryOnline(props.battery)} />
      </div>
      <div className='flex flex-row gap-4'>
        <Image
          src={ASSETS.IMAGES.BATTERY.battery}
          className='h-[104px] w-[104px]'
        />

        <div className='my-[15px] flex flex-1 flex-col gap-2'>
          <div className='flex flex-col mt-2'>
            <BatteryCommon.LastSeen value={latestTelemetry?.timestamp} />
            <BatteryCommon.Status value={batteryStatus} />
            <BatteryCommon.OnboardOrAvailableIndicator />
          </div>
        </div>
      </div>

      <div className='flex flex-1 flex-col'>
        <BatteryChargeBar
          orientation='horizontal'
          value={getBatteryStateOfCharge(props.battery) / 100}
          device={props.battery || {}}
        />
      </div>
    </div>
  )
}

export default BasicInformation
