import { useMemo } from 'react'
import cn from 'classnames'
import { IMower } from '../../../models'
import { Image, Icon, Text, ProgressBar, StatusIndicator } from '../../atoms'
import { TStatus } from '../../atoms/StatusIndicator/types'
import { MowerImage, MowerCommon, DeviceFaultButton } from '..'
import { ASSETS } from '../../../assets'
import { Trans } from '@lingui/macro'
import { useAppTheme } from '../../../hooks'
import BatteryIconListAndPanel from '../BatteryIconListAndPanel/BatteryIconListAndPanel'
import {getMowerAggregatedStateOfCharge, isMowerOnline } from '../../../utils/mower'
import { IDevice } from '../../../models_v2/entity/device'
import {
  hasLocation,
  isAscentDevice,
  isSurepathDevice
} from '../../../utils/device'
import { useListItemClickEvents } from '../../../hooks/data/useListItemClickEvents'
import { getMowerStatus } from '../../../utils/getMowerStatus'
import { DEVICEMOWERSTATUSVALUES } from '../../../models_v2/common/mower'


interface MowerListItemProps {
  mower: IDevice
  isActive?: boolean
}
const network_status = 'OFFLINE'

export const MowerListItem = ({
  mower,
  isActive = false
}: MowerListItemProps) => {
  const { onMapPinClick, onListItemClick } = useListItemClickEvents({
    deviceType: 'mower',
    device: mower
  })
  const { inventory } = mower
  const { name } = inventory ?? {}
  const { theme } = useAppTheme()
  const activeFaults = useMemo(() => {
    return mower.metadata
      ?.filter(item => item.active)
      .sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
  }, [mower.metadata])
  
  const progressBarColor = useMemo(() => {
    if (
      Number(mower?.latestTelemetry?.status) ===
      DEVICEMOWERSTATUSVALUES.Charging
    )
      return '#2CC947'

    return theme === 'light' ? '#0082FC' : undefined
  }, [theme, mower])

  const isMowerCharging = useMemo(
    () =>
      Number(mower?.latestTelemetry?.status) ===
      DEVICEMOWERSTATUSVALUES.Charging,
    [mower]
  )

  return (
    <div
      className={cn(
        'mower-list-item text-black dark:text-white p-3 card cursor-pointer',
        {
          'outline outline-4 outline-offset-[-4px] outline-interaction-200':
            isActive
        }
      )}
      onClick={onListItemClick}>
      <div className='flex justify-between items-center'>
        <div
          className={cn('flex items-center gap-2 cursor-pointer', {})}
          onClick={e => {
            if (hasLocation(mower as IDevice)) {
              onMapPinClick()
            }
            e.stopPropagation()
          }}>
          <div className='flex items-center gap-2'>
            <Text.Feature.Medium className={'inline-block mr-0 max-w-[200px]'}>
              {name}
            </Text.Feature.Medium>
            {hasLocation(mower) && (
              <Image
                src={ASSETS.IMAGES.COMMON.target}
                className='w-3 h-3 invert-0 dark:invert object-cover'
              />
            )}
          </div>
          {!!activeFaults?.length && (
            <DeviceFaultButton faults={activeFaults} item={mower} />
          )}
        </div>
        <StatusIndicator
          isOnline={isMowerOnline(mower)}
          value={network_status?.toLowerCase?.() as TStatus}
        />
      </div>
      <div className='flex flex-row'>
        <div className='relative'>
          <MowerImage mower={mower} />
          {isSurepathDevice(mower) && (
            <MowerCommon.SurepathLogo className='absolute top-0 left-0' />
          )}
        </div>
        <div className='ml-[18px] mt-[15px] flex flex-1 flex-col'>
          <div className='flex flex-col gap-3'>
            <div className='flex justify-end'>
              <div>
                <div className='flex flex-col lg:flex-row justify-start lg:gap-2'>
                  <Text.VariousRegular.Small>
                    <Trans>Status</Trans>:
                  </Text.VariousRegular.Small>
                  <Text.VariousBold.Small>
                    {getMowerStatus(mower).toUpperCase()}
                  </Text.VariousBold.Small>
                </div>
                {isSurepathDevice(mower) ? (
                  <MowerCommon.SurepathInfo
                    mower={mower}
                    showGps={network_status !== 'OFFLINE'}
                    showCellCignal={network_status !== 'OFFLINE'}
                  />
                ) : (
                  <MowerCommon.LastSignal mower={mower} />
                )}
              </div>
            </div>

            {isAscentDevice(mower) && (
              <div className='flex flex-col gap-1'>
                <div className='flex justify-between'>
                  <div className='flex items-center gap-1'>
                    {isMowerCharging && (
                      <Icon
                        name='bolt_vector'
                        className='h-[12px] w-2 mr-1 invert dark:invert-0'
                      />
                    )}
                    <Text.VariousRegular.Large>
                      {getMowerAggregatedStateOfCharge(mower)}%
                    </Text.VariousRegular.Large>
                  </div>
                  <BatteryIconListAndPanel mower={mower as Partial<IMower>} />
                </div>
                <ProgressBar
                  progressBarColor={progressBarColor}
                  value={parseFloat(`${getMowerAggregatedStateOfCharge(mower)}`) / 100}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MowerListItem
