import React, { Fragment, useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'
import { IToolTag } from '../../../models'
import { Placeholders } from '../../../utils/constants'
import { Text, Divider } from '../../atoms'
import { ToolTagListItem } from '../../molecules'
import { ToolTag, compareByName } from '../../../utils/sortingLists'
import { useAppSelector } from '../../../store/hooks'
import { selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import { IDevice } from '../../../models_v2/entity/device'

interface ToolTagListProps {}

interface IOutletContext {
  items: Array<IToolTag>
  selectedIds: Array<string>
  preSelectedId: string
}

const ToolTagList: React.FC<ToolTagListProps> = props => {
  const outletContext = useOutletContext<IOutletContext>()
  const {
    items = [],
    selectedIds = [],
    preSelectedId = ''
  } = outletContext ?? {}
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const toolsAndTagsDevices = (
    useAppSelector(
      selectFilteredDevices<IDevice>({
        deviceType: 'tools-and-tags',
        operatingCompany
        // productSerials: deviceIdsSelectedOnMap,
      })
    ) as IDevice[]
  ).sort(compareByName<ToolTag>(toolTag => toolTag.inventory.name))

  // const filteredItems = useMemo(() => {
  //   return items
  //     .filter(item => item?.coordinates?.lat && item?.coordinates?.lng)
  //     .sort(compareByName<ToolTag>(tooltag => tooltag.name))
  // }, [items])

  if (!toolsAndTagsDevices.length)
    return (
      <div className='h-full flex flex-col items-center justify-center'>
        <Text.VariousRegular.Medium className='dark:text-white'>
          {Placeholders['tracking.sidebar.device_empty_list']}
        </Text.VariousRegular.Medium>
      </div>
    )

  return (
    <div id='tag-list-container' className='flex flex-col'>
      {toolsAndTagsDevices.map(item => {
        return (
          <Fragment key={item.productSerial}>
            <ToolTagListItem toolTag={item} />
            <Divider size='lg' />
          </Fragment>
        )
      })}
    </div>
  )
}

export default ToolTagList
