import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ASSETS } from '../../assets'
import { Image } from '../../components/atoms'
import { useAuthCheck } from '../../hooks'
import { Routes } from '../../routes'

interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = () => {
  const location = useLocation()
  const excluded_auth_redirection_routes = [
    Routes['login_page.forgot-password'],
    Routes['login_page.forgot-password.code']
  ]

  useAuthCheck({
    redirectTo: Routes.root_page,
    shouldRedirect: !excluded_auth_redirection_routes.includes(
      location.pathname
    )
  })

  return (
    <div className='h-screen relative flex items-center justify-center bg-black-600 bg-app-background bg-no-repeat bg-cover'>
      <div className='absolute bottom-0 right-0 overflow-hidden h-full flex items-end'>
        <Image
          src={ASSETS.IMAGES.MOWER.mower_front_left_large_standing}
          className='h-[50%] sm:h-[75%]'
        />
      </div>

      {/*  w-full sm:w-[438px] */}
      <div className='z-10 w-full'>
        <Outlet />
      </div>
    </div>
  )
}

export default LoginPage
