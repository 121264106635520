import React from 'react'
import { Image, Text } from '../../atoms'
import { ASSETS } from '../../../assets'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useLingui } from '@lingui/react'

export interface CircularDialProps {
  size?: number
  label?: string
  value?: string
  gaugeColor?: string
  borderColor?: string
  data: ChartData<'doughnut', Array<number>>
}

ChartJS.register(ArcElement, Tooltip, Legend)

const CircularDial: React.FC<CircularDialProps> = props => {
  const { i18n } = useLingui()
  const {
    size = 175,
    label,
    value,
    gaugeColor = '#00FCDE',
    borderColor = '#000000',
    data
  } = props

  return (
    <div
      className='large-dial inline-block relative'
      style={{ height: size, width: size }}>
      <Image src={ASSETS.IMAGES.COMMON.dial_large_2} />
      {/* <div className='absolute top-[-15px] w-[100px] h-[100px] border border-solid border-red rounded-full'> */}
      <div className='absolute top-[-17px] bottom-0 left-[-2px] right-[-8px]'>
        <Doughnut
          width={100}
          options={{
            layout: {
              padding: {
                left: 25,
                right: 25,
                top: 25,
                bottom: 25
              }
            },
            circumference: 360,
            cutout: 50,
            aspectRatio: 1,
            plugins: {
              tooltip: {
                enabled: false
              }
            }
          }}
          data={data}
        />
        <div className='absolute inset-0 flex items-center justify-center'>
          <div className='text-white'>
            <Text.VariousRegular.Medium>
              {label ?? ''}
            </Text.VariousRegular.Medium>
            <Text.VariousBold.Medium>{value}</Text.VariousBold.Medium>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  )
}

export default CircularDial
