import 'react-calendar-timeline/lib/Timeline.css'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import cn from 'classnames'
import { Icon, Text } from '../../atoms'
import { Range } from '../../../models'
import { SurepathTimelineLegend } from '../../molecules'
import Timeline, {
  DateHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker
} from 'react-calendar-timeline'
import styled from 'styled-components'
import {
  TIMELINE_TIME_STEPS,
  evaluateTimelineGraphRange,
  groupTimelineItems,
  ETimelineGroup
} from './utils'
import { EventEmitter } from '../../../utils/event_emitter'
import {
  SurepathPerformanceEvents,
  SurepathPerformanceTimeInterval
} from '../SurepathPerformanceCard'
import SurepathTimelineItemRenderer from './ItemRenderer'
import TodayMarkerLine from './TodayMarkerLine'
import { useActualTimeInterval, useDynamicQuery } from '../../../hooks'
import { useDebounce } from 'use-debounce'
import { IDevice } from '../../../models_v2/entity/device'
import { useDevice } from '../../../hooks/data/useDevice'

const groups = [
  { id: ETimelineGroup.WHITESPACE, title: '' }, // space above
  { id: ETimelineGroup.JOB_SESSION, title: 'job_session' },
  { id: ETimelineGroup.SUREPATH_ENABLED, title: 'surepath_enabled' },
  { id: ETimelineGroup.MOWING, title: 'mowing' },
  { id: ETimelineGroup.MANUAL_DRIVE, title: 'manual_drive' },
  { id: ETimelineGroup.IDLE, title: 'idle' }
]

export interface SurepathPerformanceTimelineItem {
  id: string
  group: ETimelineGroup
  start_time: number
  end_time: number
  meta?: Record<string, any>
}

interface SurepathPerformanceTimelineProps {
  timeInterval: SurepathPerformanceTimeInterval
  mower?: IDevice
  currentDate: Date
  onChangeDate: (date: Date) => void
  eventEmitter: EventEmitter<SurepathPerformanceEvents>
}

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSSSSSSS'

const SurepathPerformanceTimeline: React.FC<SurepathPerformanceTimelineProps> =
  props => {
    const { mower, currentDate, onChangeDate, eventEmitter, timeInterval } =
      props

    const [visibleTime, setVisibleTime] = useState<Range<number>>(
      evaluateTimelineGraphRange(currentDate, timeInterval)
    )

    const formattedVisibleTime = useMemo(() => {
      return {
        start: moment(visibleTime.start)
          // Subtract 1 day to get the previous timeline when the current view in timeline concides between two days
          .subtract(1, 'day')
          .startOf('day')
          .format(DATE_FORMAT),
        end: moment(visibleTime.end)
          .add(1, 'day')
          .endOf('day')
          .format(DATE_FORMAT)
      }
    }, [visibleTime.start, visibleTime.end])

    const [debouncedFormattedVisibleTime] = useDebounce(
      formattedVisibleTime,
      300
    )

    // * Updates surepath performance timeline
    const {
      data: deviceData = {} as IDevice,
      refetch,
      loading: isFetching
    } = useDevice({
      productSerial: mower?.productSerial || '',
      startTime: debouncedFormattedVisibleTime.start,
      endTime: debouncedFormattedVisibleTime.end
    })
    // * END

    // Refetch the timeline every minute of the actual time
    useActualTimeInterval(refetch, 60000)

    const timelineItems = useMemo(() => {
      return (
        groupTimelineItems((deviceData as IDevice)?.telemetries ?? []) ?? []
      )
    }, [deviceData])

    const todayDate = new Date()
    const tomorrowDate = moment(todayDate).add(1, 'day').startOf('day')

    useEffect(() => {
      const handleDateChanged = (date: Date) =>
        setVisibleTime(evaluateTimelineGraphRange(date, timeInterval))

      const handleTimeIntervalChanged = (
        interval: SurepathPerformanceTimeInterval
      ) => {
        setVisibleTime(evaluateTimelineGraphRange(currentDate, interval))
      }

      eventEmitter.on('date:decrement_one_day', handleDateChanged)
      eventEmitter.on('date:increment_one_day', handleDateChanged)
      eventEmitter.on('time_interval:change', handleTimeIntervalChanged)

      return () => {
        eventEmitter.off('date:decrement_one_day', handleDateChanged)
        eventEmitter.off('date:increment_one_day', handleDateChanged)
        eventEmitter.off('time_interval:change', handleTimeIntervalChanged)
      }
    }, [currentDate, timeInterval])

    const handleDecrementTime = useCallback(() => {
      const newCurrentDate = moment(currentDate)
        .subtract(timeInterval, 'hours')
        .toDate()
      onChangeDate(newCurrentDate)
      setVisibleTime(evaluateTimelineGraphRange(newCurrentDate, timeInterval))
    }, [currentDate, timeInterval])

    const handleIncrementTime = useCallback(() => {
      const newCurrentDate = moment(currentDate)
        .add(timeInterval, 'hours')
        .toDate()
      // Check if end of day will reach
      if (newCurrentDate.valueOf() > tomorrowDate.valueOf()) {
        const endOfDay = moment(currentDate).endOf('day')
        onChangeDate(endOfDay.toDate())
        setVisibleTime({
          start: moment(endOfDay).subtract(timeInterval, 'hours').valueOf(),
          end: endOfDay.valueOf()
        })
        return
      }

      onChangeDate(newCurrentDate)
      setVisibleTime(evaluateTimelineGraphRange(newCurrentDate, timeInterval))
    }, [tomorrowDate, currentDate, timeInterval])

    const handleTimeChange = useCallback(
      (visibleStart: number, visibleEnd: number) => {
        // Don't allow to scroll if more than the today's date
        if (visibleEnd > tomorrowDate.valueOf()) return

        onChangeDate(new Date(visibleEnd))
        setVisibleTime({ start: visibleStart, end: visibleEnd })
      },
      [tomorrowDate, currentDate]
    )

    const shouldIncrementTimeButtonShow = useMemo(() => {
      const newCurrentDate = moment(visibleTime.end).add(1, 'minute').toDate()
      // Check if end of day will reach
      return newCurrentDate.valueOf() >= tomorrowDate.valueOf()
    }, [visibleTime.end, tomorrowDate])

    return (
      <>
        <div className='relative'>
          {isFetching && (
            <div className='absolute inset-x-0 top-0 bottom-[48px] flex items-center justify-center'>
              <Text.VariousRegular.Medium className='dark:text-white'>
                Loading...
              </Text.VariousRegular.Medium>
            </div>
          )}
          <div className='flex gap-2'>
            <div className='flex items-center mb-[46px]'>
              <Icon
                name='caret_left'
                className='cursor-pointer h-[12px] w-[12px]  invert dark:invert-0'
                onClick={handleDecrementTime}
              />
            </div>
            <StyledTimeline
              groups={groups}
              items={timelineItems}
              timeSteps={TIMELINE_TIME_STEPS}
              visibleTimeStart={visibleTime.start}
              visibleTimeEnd={visibleTime.end}
              onTimeChange={handleTimeChange}
              itemRenderer={props => (
                <SurepathTimelineItemRenderer {...props} />
              )}
              itemHeightRatio={1.75}
              sidebarWidth={0}
              canResize={false}
              canMove={false}
              canChangeGroup={false}>
              <TimelineMarkers>
                <TodayMarker date={todayDate} interval={5000}>
                  {({ styles }) => <TodayMarkerLine style={styles} />}
                </TodayMarker>
              </TimelineMarkers>

              <TimelineHeaders>
                <DateHeader
                  className='my-2 [&>div]:dark:!text-white [&>div]:!text-black'
                  unit={timeInterval === 24 ? 'hour' : 'minute'}
                  labelFormat='HH:mm'
                />
              </TimelineHeaders>
            </StyledTimeline>
            <div className='flex items-center mb-[46px]'>
              <Icon
                name='caret_right'
                className={cn(
                  'cursor-pointer h-[12px] w-[12px]  invert dark:invert-0',
                  {
                    invisible: shouldIncrementTimeButtonShow
                  }
                )}
                onClick={handleIncrementTime}
              />
            </div>
          </div>
        </div>
        <div className='mx-3 mt-2 flex flex-wrap gap-x-5'>
          {/* <SurepathTimelineLegend type='job_session' /> */}
          <SurepathTimelineLegend type='surepath_enabled' />
          <SurepathTimelineLegend type='mowing' />
          <SurepathTimelineLegend type='manual_drive' />
          <SurepathTimelineLegend type='idle' />
        </div>
      </>
    )
  }

export default memo(SurepathPerformanceTimeline)

const StyledTimeline = styled(Timeline)`
  width: calc(100% - 34px);
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;

  .rct-header-root {
    background: transparent !important;
    border: 0 !important;
    margin-left: -18px;
    width: calc(100% + 36px) !important;
  }

  .rct-outer {
    border-top: 1px solid #8a8a8a !important;
    border-bottom: 1px solid #8a8a8a !important;
  }

  .rct-horizontal-lines .rct-hl-even,
  .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 0 !important;
    background: none !important;
  }

  .rct-calendar-header {
    border: 0 !important;
  }

  .rct-dateHeader {
    background: transparent !important;
    border: 0 !important;
    color: #fff;
    width: auto !important;
  }

  .rct-vertical-lines .rct-vl {
    border-left: 1px solid #636363 !important;
  }

  .rct-vertical-lines .rct-vl.rct-day-0,
  .rct-vertical-lines .rct-vl.rct-day-6 {
    background: transparent;
  }
`
