import moment from 'moment'
import {
  EDeviceMowerStatus,
  IStatusTotal,
  IStatusTotalItem
} from '../../../../models/device'

export type TUnitOfTime = 'day' | 'week' | 'month'
export enum EUnitOfTime {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month'
}
export interface IParams {
  unit: EUnitOfTime
  duration: number // duration in minutes
}

const day = moment.duration(24, 'hours')
const week = moment.duration(7, 'days')
const month = moment.duration(30, 'days')

export const timeTakenPercentage = ({ unit, duration = 0 }: IParams) => {
  let total_minutes

  switch (unit) {
    case EUnitOfTime.DAY:
      total_minutes = day.asMinutes()
      break
    case EUnitOfTime.WEEK:
      total_minutes = week.asMinutes()
      break
    case EUnitOfTime.MONTH:
      total_minutes = month.asMinutes()
      break
    default:
      total_minutes = day.asMinutes()
      break
  }

  const percentage = (duration / total_minutes) * 100
  const rounded = Number.isNaN(percentage) ? 0 : Math.round(percentage)

  return {
    rounded,
    raw: percentage
  }
}

export const calculateSurepathPercentage = ({
  statusTotals,
  unitOfTime
}: {
  statusTotals: IStatusTotal
  unitOfTime: EUnitOfTime
}) => {
  let selectedStatusTotal: IStatusTotalItem[] = []

  if (unitOfTime === EUnitOfTime.DAY) {
    selectedStatusTotal = statusTotals?.last24Hours ?? []
  } else if (unitOfTime === EUnitOfTime.WEEK) {
    selectedStatusTotal = statusTotals?.last7Days ?? []
  } else if (unitOfTime === EUnitOfTime.MONTH) {
    selectedStatusTotal = statusTotals?.last30Days ?? []
  }

  if (!selectedStatusTotal) return 0

  const mowerStatuses = [
    EDeviceMowerStatus.Mowing,
    EDeviceMowerStatus.MowingSurePathReady,
    EDeviceMowerStatus.MowingSurePathEnabled
  ] as const

  const statusAsKeyValue = mowerStatuses.reduce((acc, curr) => {
    const matchedStatusTotalItem = selectedStatusTotal.find(
      item => item.mowerStatus === curr.toString()
    )

    const totalAsNumber = Number(matchedStatusTotalItem?.total)

    return {
      ...acc,
      [curr]: isNaN(totalAsNumber) ? 0 : totalAsNumber
    }
  }, {} as Record<EDeviceMowerStatus, number>)

  const {
    [EDeviceMowerStatus.Mowing]: mowingMinutes,
    [EDeviceMowerStatus.MowingSurePathReady]: surepathReadyMinutes,
    [EDeviceMowerStatus.MowingSurePathEnabled]: surepathEnabledMinutes
  } = statusAsKeyValue

  // MowerStatus (3+5+6)
  const surepathMowingMinutes =
    mowingMinutes + surepathReadyMinutes + surepathEnabledMinutes

  // 0 divided by anything is not possible
  if (surepathEnabledMinutes === 0) return 0

  const total = surepathEnabledMinutes / surepathMowingMinutes
  return Number((total * 100).toFixed(1))
}

export const getPercentage = (count: number, totalCount: number) => {
  if (totalCount === 0) return 0

  const percentage = (count / totalCount) * 100
  const rounded = Math.round(percentage)

  return rounded
}

/**
 *
 * @param minutes ex. 30 minutes
 * @returns 00:30 as string
 */
export const convertMinutesToHourFormat = (
  minutes: number = 0,
  separator: string = ':'
) => {
  const hours = Math.floor(minutes / 60)
  const minutesRemaining = Math.floor(minutes % 60)
  const formattedTime = `${hours
    .toString()
    .padStart(2, '0')}${separator}${minutesRemaining
    .toString()
    .padStart(2, '0')}`
  return formattedTime
}

// Mower Activities Analytics calculations
export const totalHoursInADay = 24
export const totalHoursInSevenDays = totalHoursInADay * 7
export const totalHoursInThirtyDays = totalHoursInADay * 30
export const hundredPercent = 100

// Convert the time difference to hours
export const calculateTotalUtilizationHours = (
  start_date: number,
  end_date: number
) => {
  return ((end_date - start_date) / (1000 * 60 * 60)).toFixed(2)
}

export const utilizationPercentageSevenDays = (
  totalUtilizationHours: number
) => {
  return (totalUtilizationHours / totalHoursInSevenDays) * hundredPercent
}

export const utilizationPercentageThirtyDays = (
  totalUtilizationHours: number
) => {
  return (totalUtilizationHours / totalHoursInThirtyDays) * hundredPercent
}

export const utilizationPercentageTwentyFourHours = (
  totalUtilizationHours: number
) => {
  return (totalUtilizationHours / totalHoursInADay) * hundredPercent
}
