import { useEffect, useState } from 'react'
import { AppTheme, Theme } from '../utils/theme'

export const useAppTheme = () => {
  const [theme, setTheme] = useState<AppTheme>(Theme.getTheme())

  useEffect(() => {
    const onThemeChanged = () => setTheme(Theme.getTheme())

    window.addEventListener('theme', onThemeChanged)

    return () => {
      window.removeEventListener('theme', onThemeChanged)
    }
  }, [])

  return {
    theme,
    switchTheme: (theme: AppTheme) => Theme.switchTheme(theme),
    toggleTheme: () => Theme.toggleTheme()
  }
}
