import React from 'react'
import { IToolTag } from '../../../../models'
import { DeviceDetailRowInformation } from '../../../molecules'

interface OtherInformationProps {
  toolTag: Partial<IToolTag>
}

const OtherInformation: React.FC<OtherInformationProps> = props => {
  const { toolTag } = props
  const { productSerial = '', latestTelemetry, inventory } = toolTag ?? {}
  const {
    toolMake = '',
    toolModel = '',
    toolPowerType = '',
    toolSerialNumber = '',
    toolType = ''
  } = inventory ?? {}

  return (
    <div className='flex flex-col gap-1'>
      <DeviceDetailRowInformation
        label='Device Type'
        value={latestTelemetry?.payload?.model || ''}
      />
      <DeviceDetailRowInformation label='MAC Address' value={productSerial} />
      <DeviceDetailRowInformation label='Tool Type' value={toolType} />
      <DeviceDetailRowInformation label='Tool Make' value={toolMake} />
      <DeviceDetailRowInformation label='Tool Model' value={toolModel} />
      <DeviceDetailRowInformation
        label='Tool Serial Number'
        value={toolSerialNumber}
      />
      <DeviceDetailRowInformation
        label='Tool Power Type'
        value={toolPowerType}
      />
    </div>
  )
}

export default OtherInformation
