import React, { useContext, useState } from 'react'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import { ASSETS } from '../../assets'
import { Dialog, DropdownMenu, Image } from '../../components/atoms'
import { IDropdownMenuItem } from '../../components/atoms/DropdownMenu'
import { IProps } from './types'
import { AppNavigationBar } from '../../components/molecules'
import { Routes } from '../../routes'
import { AppContext } from '../../App'
import { useAppTheme, useBackgroundTask, useInitCurrentUser } from '../../hooks'
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg'
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg'
import cn from 'classnames'
import { LanguageSelector } from '../../components/organisms'
import { t } from '@lingui/macro'
import { OperatingCompanyDropdown } from '../../components/organisms/OperatingCompany'
import { useAppDispatch } from '../../store/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { resetUserState } from '../../store/slices/userSlice'
import { resetSignupState } from '../../store/slices/signupSlice'
import { resetOrganizationSettingState } from '../../store/slices/organizationSettingsSlice'
import { resetOrganizationCompanyState } from '../../store/slices/organizationCompanySlice'
import { resetOperatingCompanyState } from '../../store/slices/operatingCompany'
import { resetMapState } from '../../store/slices/mapSlice'
import { resetDeviceState } from '../../store/slices/deviceSlice'
import { resetCompanyState } from '../../store/slices/companySlice'
import { resetTrackingState } from '../../store/slices/trackingSlice/trackingSlice'
import { resetTrackingGroupState } from '../../store/slices/trackingGroupSlice'
import { resetGroupState } from '../../store/slices/deviceSliceV2/groupSlice'
import { resetDeviceV2State } from '../../store/slices/deviceSliceV2/deviceSlice'
import { resetDeviceGroupState } from '../../store/slices/deviceGroupSlice/deviceGroupSlice'

const RootPage: React.FC<IProps> = () => {
  const { isLoading: isUserLoading } = useInitCurrentUser()
  useBackgroundTask()
  const { handleLogout } = useContext(AppContext)
  const navigate = useNavigate()
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false)
  const isProfilePage = useMatch(Routes['profile_page.wildcard'])
  const isSettingsPage = useMatch(Routes['new_settings.wildcard'])
  const isProfileIconActive = !!isProfilePage || isProfileMenuOpen
  const isSettingsIconActive = !!isSettingsPage || isSettingsMenuOpen
  const [showLanguageSelector, setShowLanguageSelector] = useState(false)
  const { theme, toggleTheme } = useAppTheme()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const handleResetReduxStore = () => {
    dispatch(resetUserState())
    dispatch(resetSignupState())
    dispatch(resetOrganizationSettingState())
    dispatch(resetOrganizationCompanyState())
    dispatch(resetOperatingCompanyState())
    dispatch(resetMapState())
    dispatch(resetDeviceState())
    dispatch(resetCompanyState())
    dispatch(resetTrackingState())
    dispatch(resetTrackingGroupState())
    dispatch(resetGroupState())
    dispatch(resetDeviceV2State())
    dispatch(resetDeviceGroupState())
  }

  const handleProfileMenuItemClick = async (item: IDropdownMenuItem) => {
    if (item.id === 'my_profile') {
      navigate(Routes.profile_page)
    } else if (item.id === 'logout') {
      const didLogout = await handleLogout?.()
      if (!didLogout) {
        console.error('Logout failed.')
        return
      }

      handleResetReduxStore()
      queryClient?.removeQueries()

      navigate(Routes.login_page)
    }
  }

  const handleSettingsMenuItemClick = async (item: IDropdownMenuItem) => {
    if (item.id === 'settings') {
      navigate(Routes.company_account)
    } else if (item.id === 'toggle_theme') {
      toggleTheme()
    }
  }

  return (
    <>
      {
        <Dialog
          headerTitle={t`Change Language`}
          show={showLanguageSelector}
          onClose={() => setShowLanguageSelector(false)}>
          <LanguageSelector />
        </Dialog>
      }

      <div className='h-screen flex flex-col bg-black-600 bg-app-background bg-no-repeat bg-cover'>
        <div id='nav' className='h-8 min-h-[64px] px-5 py-[16.5px]'>
          <div className='app-nav-menu h-full flex items-center justify-between'>
            <Image
              src={ASSETS.IMAGES.APP.app_logo}
              className='h-4'
              alt='Ground Command'
            />
            <AppNavigationBar />
            <div className='flex items-center gap-3 relative'>
              {isUserLoading && (
                <div className='border-[2px] border-[#a8a8a8] border-t-white rounded-full w-4 h-4 mr-1 animate-spin'></div>
              )}
              <OperatingCompanyDropdown />
              <DropdownMenu
                items={[
                  {
                    id: 'my_profile',
                    label: t`My Profile`
                  },
                  {
                    id: 'logout',
                    label: t`Logout`
                  }
                ]}
                menuButton={
                  <ProfileIcon
                    id='profile-menu-icon'
                    className={cn('text-black-500', {
                      'text-primary': isProfileIconActive
                    })}
                  />
                }
                menuButtonClassName='w-4 h-4'
                onMenuStateChange={setIsProfileMenuOpen}
                onItemClick={handleProfileMenuItemClick}></DropdownMenu>
              <DropdownMenu
                size='fit'
                items={[
                  {
                    id: 'settings',
                    label: t`Settings`
                  },
                  {
                    id: 'toggle_theme',
                    label:
                      theme === 'dark'
                        ? t`Switch to Light Mode`
                        : t`Switch to Dark Mode`
                  }
                ]}
                menuButton={
                  <SettingsIcon
                    id='settings-menu-icon'
                    className={cn('text-black-500', {
                      'text-primary': isSettingsIconActive
                    })}
                  />
                }
                menuButtonClassName='w-4 h-4'
                onMenuStateChange={setIsSettingsMenuOpen}
                onItemClick={handleSettingsMenuItemClick}
              />
            </div>
          </div>
        </div>
        <div className='flex-1 overflow-hidden'>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default RootPage
