import React, { useMemo } from 'react'
import cn from 'classnames'
import { Text } from '../../atoms'
import { Trans } from '@lingui/macro'

interface LabelAndColor {
  label: string
  color: 'danger' | 'warning' | 'success' | 'default'
}

interface SurepathGpsProps {
  // gppFixMode from mower.gpsMode.gppFixMode
  value?: number
}

const SurepathGps: React.FC<SurepathGpsProps> = props => {
  const { value } = props

  /**
   *  Legend:
   *  No Signal (color grey) - Invalid
      Poor Signal (color red) - SPP, Dead Reckoning, Single Mode
      Degraded Signal (color yellow) - DGNSS, Float RTK
      Good Signal (color green) - Fixed RTK
   */
  const gpsLabelAndColor = useMemo<LabelAndColor | null>(() => {
    if (value === 0) {
      return {
        label: 'Invalid',
        color: 'default'
      }
    } else if (value === 1) {
      return {
        label: 'SPP',
        color: 'danger'
      }
    } else if (value === 2) {
      return {
        label: 'DGNSS',
        color: 'warning'
      }
    } else if (value === 3) {
      return {
        label: 'Float RTK',
        color: 'warning'
      }
    } else if (value === 4) {
      return {
        label: 'Fixed RTK',
        color: 'success'
      }
    } else if (value === 5) {
      return {
        label: 'Dead Reckoning',
        color: 'danger'
      }
    } else if (value === 6) {
      return {
        label: 'Single Mode',
        color: 'danger'
      }
    }

    return null
  }, [value])

  return (
    <div className='flex self-center flex-col lg:flex-row justify-start gap-x-2'>
      <Text.VariousRegular.Small>
        <Trans>GPS:</Trans>
      </Text.VariousRegular.Small>

      <div className='w-fit flex flex-col relative'>
        <Text.VariousBold.Small>
          {gpsLabelAndColor?.label || ''}
        </Text.VariousBold.Small>
        <div
          className={cn('absolute bottom-[-2px] w-full h-[2px]', {
            'bg-error': gpsLabelAndColor?.color === 'danger',
            'bg-primary': gpsLabelAndColor?.color === 'warning',
            'bg-battery-green': gpsLabelAndColor?.color === 'success',
            'bg-black-500': gpsLabelAndColor?.color === 'default'
          })}
        />
      </div>
    </div>
  )
}

export default SurepathGps
