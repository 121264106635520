import { RootState } from '../../store'

export const trackingGroups = (state: RootState) => {
  return state.trackingGroups.groups
}

export const selectedTrackingGroup = (state: RootState) => {
  return state.trackingGroups.selectedGroup
}

export const currentIndexCollapsebleData= (state: RootState) => {
  return state.trackingGroups.currentIndexCollapsebleData
}

