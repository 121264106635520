import React, { useContext, useEffect } from 'react'
import { Divider } from '../../atoms'
import BasicInformation from './BasicInformation'
import BatteryLevelInformation from './BatteryLevelInformation'
import OtherInformation from './OtherInformation'
import { t } from '@lingui/macro'
import { AppRootContext } from '../../../pages/AppRoot'
import { useAppSelector } from '../../../store/hooks'
import { selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors'
import { IDevice } from '../../../models_v2/entity/device'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import { IToolTag } from '../../../models'

interface ToolTagDetailProps {
  id: string
  source: string
}

const ToolTagDetail: React.FC<ToolTagDetailProps> = props => {
  const { id, source } = props
  const { emitter } = useContext(AppRootContext)
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const currentToolTag = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: 'tools-and-tags',
      operatingCompany,
      productSerial: id
    })
  ) as IDevice

  useEffect(() => {
    if (currentToolTag) {
      setTimeout(() => {
        emitter?.emit('MAP:tracking', {
          type: 'FIT_BOUNDS_SELECTED',
          payload: [currentToolTag]
        })
      })
    }
  }, [currentToolTag])

  return (
    <>
      {!currentToolTag ? (
        <div className='h-full w-full flex justify-center items-center'>
          <p className='dark:text-primary'>{t`Loading...`}</p>
        </div>
      ) : (
        <div className='flex flex-col gap-3'>
          <BasicInformation toolTag={currentToolTag!} />
          <Divider className='ml-[-24px] mr-[-24px]' size='md' />
          <BatteryLevelInformation toolTag={currentToolTag!} />
          <Divider className='ml-[-24px] mr-[-24px]' size='md' />
          <OtherInformation toolTag={currentToolTag! as unknown as IToolTag} />
        </div>
      )}
    </>
  )
}

export default ToolTagDetail
