/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

export interface ImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}

const Image: React.FC<ImageProps> = props => {
  return <img {...props} />
}

export default Image
