import React, { useMemo } from 'react'
import cn from 'classnames'
import { Image } from '../../atoms'
import { mowerImagesLookup } from './lookup'
import { MowerImageProps, TMowerImageValue } from './types'
import { EMowersDeviceTypes } from '../../../models/device'
import { DEVICE_TYPE } from '../../../models_v2/entity/telemetry'
import { isSurepathDevice } from '../../../utils/device'

const MowerImage: React.FC<MowerImageProps> = props => {
  const { mower, className } = props
  // const { mower_position, source } = mower ?? {}

  const renderImage = useMemo(() => {
    if (isSurepathDevice(mower)) {
      return mowerImagesLookup.surepath_front
    }
    // return (
    //   mowerImagesLookup?.[mower_position as TMowerImageValue] ||
    //   mowerImagesLookup.stand
    // )

    return mowerImagesLookup.stand
  }, [])

  return (
    <Image src={renderImage} className={cn('mower-icon h-[104px] w-[104px]', className)} />
  )
}

export default MowerImage
