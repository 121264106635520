import React from 'react'
import { Image } from '../../atoms'
import { ASSETS } from '../../../assets'

interface SurepathTextLogoProps {}

const SurepathTextLogo: React.FC<SurepathTextLogoProps> = () => {
  return (
    <Image
      src={ASSETS.IMAGES.MOWER.surepath_text_logo}
      className='invert dark:filter-none'
    />
  )
}

export default SurepathTextLogo
