import { useEffect, useState } from 'react'

interface IUseWindowDimensionsReturnValue {
  width: number
  height: number
}

export const useWindowDimensions = (): IUseWindowDimensionsReturnValue => {
  const [dimensions, setDimensions] =
    useState<IUseWindowDimensionsReturnValue>()

  useEffect(() => {
    const handleResize = (event: UIEvent) => {
      const { innerHeight, innerWidth } = event.target as any

      setDimensions({
        width: innerWidth,
        height: innerHeight
      })
    }

    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    })

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    width: dimensions?.width || 0,
    height: dimensions?.height || 0
  }
}
