import { useEffect } from 'react'
import { useCognitoUser } from '../integrations/aws-cognito/hooks'
import { IUserEntity } from '../models/entity'
import { mapUserEntityToUser } from '../models/mapper/user_entity_to_user'
import useDynamicMutation from './useDynamicMutation'
import { useDispatch } from 'react-redux';
import { selectUser, selectUserCompany, setCurrentUser } from '../store/slices/userSlice'
import { setCurrentOperatingCompany } from '../store/slices/operatingCompany';
import { useAppSelector } from '../store/hooks'
import { IDevice } from '../models_v2/entity/device'

// User must be logged in on Cognito. Congnito fetches the user via UserPool tokens that are auto-set by the library.
// Note that the info will not be available during the signup flow because although the user exists
// The library doesn't log them in successfully.
export const useInitCurrentUser = () => {
  const dispatch = useDispatch();
  const currentUser = useAppSelector(state => selectUser(state))
  const userCompany = useAppSelector(state => selectUserCompany(state))
  const { mutateAsync: loginUser, isLoading } = useDynamicMutation({
    graphqlEntity: 'users',
    queryKey: 'LOGIN_USER'
  })
  const cognitoUser = useCognitoUser()

  useEffect(() => {
    if (cognitoUser) {
      const { sub: user_id } =
        cognitoUser.getSignInUserSession()?.getIdToken?.().decodePayload() ?? {}

      loginUser({ id: user_id }).then(({ data }: any) => {
        const userEntity = data?.result as IUserEntity
        dispatch(setCurrentUser(mapUserEntityToUser(userEntity)))
      })
    }
  }, [cognitoUser])

  useEffect(() => {
    if (currentUser) {
      // hack but time saver
      const devs = ["Alex Padilla", "Al Tiangco"];
      const setAsGlobal = currentUser.role === "GlobalAdmin" 
        || currentUser.role === "GlobalViewer"
        || devs.includes(`${currentUser.firstName} ${currentUser.lastName}`)
      dispatch(
        setCurrentOperatingCompany({
          companyId: setAsGlobal ? "Global" : userCompany?.companyId,
          name: setAsGlobal ? "Global" : (userCompany as IDevice)?.inventory.name
        })
      );
    }

  }, [currentUser, userCompany])

  return {
    isLoading
  }
}
