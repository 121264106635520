import { OperationVariables, QueryHookOptions, isApolloError, useQuery, ServerError } from '@apollo/client'
import { GqlQueryCollection, GqlQuery } from '../services_v2/gql'
import { TMapperKeys, MAPPER_COLLECTION } from '../models/mapper'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../App'
import { Routes } from '../routes'

interface IGraphQlQueryResponseWrapper<T = any> {
  getById?: T
  result?: T
}

type UseApolloQueryProps<TData = any, TVariables extends OperationVariables = OperationVariables> = {
  query: GqlQuery,
  transformer?: TMapperKeys,
  options?: QueryHookOptions<IGraphQlQueryResponseWrapper<TData>, TVariables>,
  forceReAuth?: boolean
}

export function useApolloQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>({
  query,
  transformer,
  options,
  forceReAuth
}: UseApolloQueryProps<TData, TVariables>) {
  const queryDocument = GqlQueryCollection[query]
  const result = useQuery<IGraphQlQueryResponseWrapper<TData>, TVariables>(
    queryDocument,
    options
  )
  const navigate = useNavigate()
  const { handleLogout } = useContext(AppContext)

  let resolvedData = result.data?.getById ?? result.data?.result

  if (transformer && resolvedData) {
    resolvedData = MAPPER_COLLECTION[transformer](resolvedData)
  }

  useEffect(() =>{
    if ( forceReAuth && result.error && isApolloError(result.error) && (result.error.networkError as ServerError).statusCode===401 && handleLogout) {
      const logout = async() =>{
        await handleLogout()

        navigate(Routes.login_page)
      }

      logout()
    }
  }, [forceReAuth, result.error])

  return {
    ...result,
    isFetched: resolvedData !== undefined,
    data: resolvedData
  }
}
