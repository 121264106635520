import React, { 
  useCallback, 
  useContext } from 'react'
import { 
  IBattery, 
  IMower, 
  IToolTag } from '../../../models'
import { TDevice } from '../../../models/device'
import { AppRootContext } from '../../../pages/AppRoot'
import { 
  Icon, 
  StatusIndicator, 
  Table } from '../../atoms'
import { TStatus } from '../../atoms/StatusIndicator/types'
import { 
  Column, 
  CellProps } from 'react-table'
import { useNavigate } from 'react-router'
import { Routes } from '../../../routes'
import { TableProps } from '../../atoms/Table'
import { selectUserIsAdmin, selectUserIsNonGlobal } from '../../../store/slices/userSlice'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { MowerCommon } from '../../molecules'
import { IDevice } from '../../../models_v2/entity/device'
import { useAppSelector } from '../../../store/hooks'
import { groupList } from '../../../store/slices/deviceGroupSlice/selectors'
import { 
  mapCompanyNameToDevices, 
  mapGroupNameToDevices } from '../../../utils/devices'
import { selectAllCompaniesForDropdown } from '../../../store/slices/organizationCompanySlice'


interface GarageGlobalFleetDeviceListProps {
  type: TDevice
  items: IDevice[]
  isLoading?: boolean
  hiddenColumns?: string[]
  tableProps?: Partial<TableProps>
}

const GarageGlobalFleetDeviceList: React.FC<GarageGlobalFleetDeviceListProps> =props => {
  const userIsAdmin = useSelector(selectUserIsAdmin)
  const { emitter } = useContext(AppRootContext)
  const {
    type,
    items,
    isLoading = false,
    hiddenColumns = [],
    tableProps } = props
  const navigate = useNavigate()
  const groups = useAppSelector(groupList)
  const companies = useAppSelector(state => selectAllCompaniesForDropdown(state,{ includeGlobal: true }))
  const userIsNonGlobal = useSelector(selectUserIsNonGlobal)

  const handleEditClick = (item: IDevice) => {
    if (type === 'mower') {
      emitter?.emit('FORM:garage.global_fleet', {
        type: 'PREFILL_MOWER_FORM_VALUE',
        payload: item
      })
    } else if (type === 'battery') {
      emitter?.emit('FORM:garage.global_fleet', {
        type: 'PREFILL_BATTERY_FORM_VALUE',
        payload: item
      })
    } else if (type === 'tool_tag') {
      emitter?.emit('FORM:garage.global_fleet', {
        type: 'PREFILL_TOOL_TAG_FORM_VALUE',
        payload: item
      })
    }
  }

  // TODO: Need to change
  const handleNavigateToTrackingDetail = useCallback((type: TDevice, item: IDevice) => {
      let resolvedEventType,
        resolvedPathName = '',
        resolvedBasePathName = ''
      if (type === 'mower') {
        resolvedEventType = 'SELECT_MOWER'
        resolvedPathName = Routes['tracking_page.mowers.detail']
        resolvedBasePathName = Routes['tracking_page.mowers']
      } else if (type === 'battery') {
        resolvedEventType = 'SELECT_BATTERY'
        resolvedPathName = Routes['tracking_page.batteries.detail']
        resolvedBasePathName = Routes['tracking_page.batteries']
      } else if (type === 'tool_tag') {
        resolvedEventType = 'SELECT_TOOL_TAG'
        resolvedPathName = Routes['tracking_page.tool_tags.detail']
        resolvedBasePathName = Routes['tracking_page.tool_tags']
      }
      navigate(resolvedPathName.replace(":id", item.productSerial))
    },
    []
  )

  const renderMowerNameCell = useCallback(
    ({ inventory }: IDevice) => (
      <span className='flex items-center gap-2'>
        {inventory?.name ?? ''}
        {inventory?.source === 'surepath' && <MowerCommon.SurepathLogo />}
      </span>
    ),
    []
  )

  // TODO: Need to change
  const renderStatusCell = useCallback(
    (item: IMower | IBattery | IToolTag) => (
      <StatusIndicator
        showLabel={false}
        value={item?.network_status?.toLowerCase?.() as TStatus}
      />
    ),
    []
  )

  const renderEditCell = useCallback(
    (type: TDevice, item: IDevice) => (
      <div className='flex justify-end items-center gap-4 mt-1'>
        <Icon
          name='border_box'
          className='w-[18px] h-[18px] cursor-pointer invert dark:filter-none'
          onClick={() => handleNavigateToTrackingDetail(type, item)}
        />
        {userIsAdmin && (
          <Icon
            name='edit_pencil'
            className='w-[18px] h-[18px] cursor-pointer invert dark:filter-none'
            onClick={() => handleEditClick(item)}
          />
        )}
      </div>
    ),
    [userIsAdmin]
  )

  const mowerNameAccesor = {
    Header: 'Name',
    accessor: 'inventory.name',
    Cell: (cell: CellProps<any>) => renderMowerNameCell(cell.row.original as IDevice)
  }

  const nameAccessor = {
    Header: 'Name',
    accessor: 'inventory.name'
  }

  const factoryAccessor = {
    Header: 'Factory Model #',
    accessor: 'inventory.factoryModel'
  }

  const modelAccessor = {
    Header: 'Model',
    accessor: 'inventory.model'
  }

  const statusAccessor = {
    Header: 'Status',
    accessor: 'network_status',
    width: 80,
    maxWidth: 80,
    Cell: (cell: CellProps<any>) => renderStatusCell(cell.row.original as IMower)
  }

  const editAccessor = {
    Header: '',
    accessor: 'actions',
    width: 120,
    maxWidth: 120,
    Cell: (cell: CellProps<any>) => renderEditCell(type, cell.row.original as IDevice)
  }

  const serialAccessor = {
    Header: 'Serial #',
    accessor: 'productSerial'
  }

  const dealerAccessor = {
    Header: 'Registering Dealer',
    width: 200,
    accessor: 'inventory.dealer'
  }

  const subscriptionAccessor = {
    Header: 'Subscription',
    accessor: 'subscription'
  }

  const companyOrGroupAccessor = {
    Header: userIsNonGlobal? "Company" : "Group",
    accessor: userIsNonGlobal? "inventory.companyName" : "inventory.groupName"
  }

  const columnDefs: Record<TDevice, Column[]> = {
    mower: [
      mowerNameAccesor,
      serialAccessor,
      factoryAccessor,
      modelAccessor,
      companyOrGroupAccessor,
      dealerAccessor,
      statusAccessor,
      editAccessor
    ].filter(item => !hiddenColumns.includes(item.accessor)),
    battery: [
      nameAccessor,
      serialAccessor,
      factoryAccessor,
      modelAccessor,
      companyOrGroupAccessor,
      subscriptionAccessor,
      statusAccessor,
      editAccessor
    ].filter(item => !hiddenColumns.includes(item.accessor)),
    tool_tag: [
      nameAccessor,
      serialAccessor,
      {
        ...editAccessor,
        width: 200,
        maxWidth: 200
      }
    ].filter(item => !hiddenColumns.includes(item.accessor))
  }
  
  return (
    <Table
      data={userIsNonGlobal? mapCompanyNameToDevices(companies, items) : mapGroupNameToDevices(groups, items)}
      columns={columnDefs[type]}
      isLoading={isLoading}
      defaultSortedColumn={{
        key: 'inventory.name',
        sort: 'ascending'
      }}
      rowKey='productSerial'
      columnClassName='text-black dark:text-white'
      {...tableProps}
    />
  )
}

export default GarageGlobalFleetDeviceList
