import React from 'react'
import { Text } from '../../components/atoms'
import moment from 'moment'

interface TodayLabelProps {}

const TodayLabel: React.FC<TodayLabelProps> = () => {
  const currentDate = moment()

  return (
    <div className='flex flex-col md:flex-row items-center md:gap-4 px-[28px]'>
      <Text.Body.Medium>Today</Text.Body.Medium>
      <Text.VariousRegular.Large className='font-semibold'>
        {currentDate.format('MMMM D, YYYY')}
      </Text.VariousRegular.Large>
    </div>
  )
}

export default TodayLabel
