import {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { SettingsUserAdministrationContext } from '..'
import { useDynamicMutation } from '../../../../hooks'
import { mapUserToUserEntity } from '../../../../models/mapper/user_to_user_entity'
import { Routes } from '../../../../routes'
import { Alert, Button } from '../../../atoms'
import { BackToListButton } from '../../../molecules'
import DeleteUserProfileModal from './DeleteUserProfileModal'
import { 
  useAppDispatch, 
  useAppSelector } from '../../../../store/hooks'
import { 
  setUserProfileForm, 
  setSelectedItem, 
  partialUpdateUserAccount} from '../../../../store/slices/organizationSettingsSlice';
import { IUserAccount } from '../../../../models'
import { useFormContext } from 'react-hook-form'
import { useRedirectNonAdmin } from '../../../../hooks/useRedirectNonAdmin'
import { IUserFormInput } from './UserProfileForm'

export type ActionsEditUserProps = {}

export const ActionsEditUser = () => {
  const { selectedItem } = useAppSelector(state => state.organizationSettings);
  const reduxDispatch = useAppDispatch()
  const { state, dispatch } = useContext(SettingsUserAdministrationContext)
  const [successMessage, setSuccessMessage] = useState('')
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const {
    mutateAsync: updateUserAsync,
    isLoading: updateUserIsLoading,
    error: updateUserError
  } = useDynamicMutation({
    graphqlEntity: 'users',
    queryKey: 'UPDATE_USER'
  })

  const { handleSubmit } = useFormContext()
  const navigate = useNavigate()
  useRedirectNonAdmin()

  const handleCancel = useCallback(() => {
    reduxDispatch(setSelectedItem(undefined))
    reduxDispatch(setUserProfileForm(undefined))
    navigate(Routes.people)
  }, [])

  const onSubmitClick = () => {
    handleSubmit((data) => updateUser(data as IUserFormInput))();
  }

  const updateUser = async (data: IUserFormInput) => {
    setSuccessMessage('')
    // As per UserInputUpdate mutation in GraphQL, there is no email
    const userUpdates =
      mapUserToUserEntity(data) ?? {}
    await updateUserAsync({
      input: {
        id: userUpdates?.id,
        firstName: userUpdates?.firstName,
        lastName: userUpdates?.lastName,
        phone: userUpdates?.phone,
        companies: userUpdates?.companies,
        role: userUpdates?.role,
      }
    })
    setSuccessMessage('Updated user profile successfully')
    reduxDispatch(partialUpdateUserAccount(userUpdates as IUserAccount))
  }

  const handleDeleteProfile = useCallback(() => {
    setShowDeleteUserModal(true)
  }, [])

  useEffect(() => {
    if (!selectedItem) {
      navigate(Routes.people)
    }

    return () => {
      reduxDispatch(setSelectedItem(undefined))
    }
  }, [])

  return (
    <>
      <DeleteUserProfileModal
        isOpen={showDeleteUserModal}
        onClose={() => setShowDeleteUserModal(false)}
        onDeleted={data => {
          setShowDeleteUserModal(false)

          dispatch?.({
            type: 'REMOVE_USER_ACCOUNT',
            payload: {
              id: data.id
            }
          })
          handleCancel()
        }}
        data={state?.user_profile_form ?? {}}
      />
      <div className='h-full overflow-y-auto'>
        <div className='h-full p-[30px]'>
          <div className='flex flex-col h-full'>
            <BackToListButton onClick={handleCancel} />
            <div className='flex-1 flex flex-col gap-3 mt-[78px] mb-3'>
              {updateUserError && (
                <Alert variant='error' message='Error in submitting data' />
              )}
              {successMessage && (
                <Alert variant='success' message={successMessage} />
              )}
              <Button
                title={updateUserIsLoading ? 'Saving...' : 'Save'}
                onClick={onSubmitClick}
                disabled={updateUserIsLoading}
              />
              <Button
                color='secondary'
                title='Cancel'
                className='border border-solid border-white'
                onClick={handleCancel}
              />
            </div>
            <Button
              type='submit'
              className='w-full bg-primary hover:bg-primary active:bg-primary text-black font-bold'
              title='DELETE Profile'
              onClick={handleDeleteProfile}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ActionsEditUser
