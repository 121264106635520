import React from 'react'
import cn from 'classnames'
import { Listbox } from '@headlessui/react'
import { Icon } from '../../atoms'
import SurepathText from '../SurepathText'

const SurepathDropdown: React.FC = () => {
  return (
    <div className={cn()}>
      <Listbox>
        <div className='relative'>
          <Listbox.Button className='min-h-[48px] relative w-full cursor-default pl-3 pr-7 py-[5px] bg-[#363636] focus:outline-none border border-[#B8B8B8] rounded'>
            <SurepathText containerClassName='text-white' />
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              <Icon name='caret_down' className='w-3 h-3' />
            </span>
          </Listbox.Button>

          <Listbox.Options className='focus:outline-none'>
            {/* {options.map((item, index) => (
              <Listbox.Option key={index} value={item.value} as={Fragment}>
                {({ active }) => (
                  <li
                    className={cn(
                      'px-3 py-2 bg-black-600 focus:outline-none cursor-default',
                      {
                        'bg-black-500': active
                      }
                    )}>
                    {item.label}
                  </li>
                )}
              </Listbox.Option>
            ))} */}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  )
}

export default SurepathDropdown
