import React, { useContext, useMemo, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { TextField, Button, Text } from '../../atoms'

import { Routes } from '../../../routes'
import { LoginCardContainer } from '../../molecules'
import { AppContext } from '../../../App'
import { IUserAccount } from '../../../models'
import { useDynamicQuery } from '../../../hooks'
import { Password } from '../../../utils/password'
import {useInitCurrentUser} from '../../../hooks/useInitCurrentUser';
import {GET_USER_BY_EMAIL} from '../../../services/gql/query';
import {useAppSelector, useAppDispatch} from '../../../store/hooks';
import {addSignupFields} from '../../../store/slices/signupSlice';

interface IProps {}

interface NewPasswordFormInput {
  password: string
  confirmPassword: string
}


const LoginUpdatePassword: React.FC<IProps> = () => {
  const { handleSetNewPassword, handleLogin } = useContext(AppContext)
  const navigate = useNavigate()
  const { state } = useLocation()
  const { register, handleSubmit, watch, formState } =
    useForm<NewPasswordFormInput>()
  const formData = watch()
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { username, password: oldPassword} = useAppSelector(state => state.signup)

  useEffect(() => {
    if (!username || !oldPassword) {
      navigate(Routes['login_page'])
      return
    }
  }, [])

  const onSubmit: SubmitHandler<NewPasswordFormInput> =
    async ({ password }) => {
      try {
        if (!handleSetNewPassword) return
        setErrorMessage('')
        setIsLoading(true)
        const credentials = {
          username,
          password,
          oldPassword
        }
        const { success, code, data } = await handleSetNewPassword(
          credentials,
          state?.data
        )

        if (!success && code === 'NewPasswordChallenge') {
          setIsLoading(false)
          navigate(Routes['login_page.update-password'])
          return
        }


        // We aren't logged in at this point because the initial login flow tries to login but
        // because the user is set to change password the auth success returns false
        // At this point we have changed password and can login safely via cognito to get tokens and auth for updateUser graphQL call
        await handleLogin?.({
          username,
          password
        })

        setIsLoading(false)
        navigate(Routes['login_page.complete-registration'])
      } catch (e: any) {
        console.log({e})
        setIsLoading(false)
        setErrorMessage(e?.error?.message || "Can't establish connection.")
      }
    }

  const isSubmitDisabled = useMemo(() => {
    const { password = '', confirmPassword = '' } = formData
    return (
      isLoading || !confirmPassword.trim().length || !password.trim().length
    )
  }, [formData, isLoading])

  return (
    <LoginCardContainer>
      <Text.Headline.H3 className='mt-[50px]'>Update Password</Text.Headline.H3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col my-4'>
          {errorMessage && (
            <div className='bg-[#f8514926] border border-solid border-[#f8514966] rounded py-2 px-3 mb-3 text-center'>
              <Text.Body.Small>{errorMessage}</Text.Body.Small>
            </div>
          )}
          <TextField
            {...register('password', {
              required: Password.messages.requiredNewPassword,
              minLength: {
                value: Password.minimumPasswordLength,
                message: Password.messages.minimumPassword
              },
              validate: Password.validatePassword
            })}
            disabled={isLoading}
            className='focus:outline-none focus:border-solid focus:border-white'
            placeholder='Password'
            type={'password'}
          />
          {formState?.errors?.password && (
            <Text.Body.Small className='text-error mt-1 mb-2'>
              {formState?.errors?.password?.message ?? ''}
            </Text.Body.Small>
          )}
          <TextField
            {...register('confirmPassword', {
              required: Password.messages.requiredConfirmPassword,
              minLength: {
                value: Password.minimumPasswordLength,
                message: Password.messages.minimumPassword
              },
              validate: value => {
                if (watch('password') !== value) {
                  return 'New password and confirm password does not match'
                }
                return Password.validatePassword(value)
              }
            })}
            disabled={isLoading}
            className='focus:outline-none focus:border-solid focus:border-white'
            placeholder='Confirm Password'
            type={'password'}
          />
          {formState?.errors?.confirmPassword && (
            <Text.Body.Small className='text-error mt-1 mb-2'>
              {formState?.errors?.confirmPassword?.message ?? ''}
            </Text.Body.Small>
          )}
        </div>

        <div className='flex gap-3'>
          <Button
            type='submit'
            title={!isLoading ? 'Submit' : 'Submitting...'}
            className='h-[43px]'
            disabled={isSubmitDisabled}
          />
        </div>
      </form>
    </LoginCardContainer>
  )
}

export default LoginUpdatePassword
