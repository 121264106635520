import React from 'react'
import { TableProvider } from './TableProvider'
import BaseTable from './Table'
import { TableProps } from './types'

export * from './types'

const Table: React.FC<TableProps> = props => {
  
  return (
    <TableProvider {...props}>
      <BaseTable {...props} />
    </TableProvider>
  )
}

export default Table
