import moment from 'moment'
import { IDevice } from '../models_v2/entity/device'

export const isBatteryOnline = (battery: Partial<IDevice>) => {
  if (!battery || !battery?.latestTelemetry) return false

  const lastSignalData = moment(battery?.latestTelemetry?.timestamp)
  const duration = moment.duration(moment().diff(lastSignalData))
  const mowerLastOnlineMinsAgo = duration.asMinutes()
  return mowerLastOnlineMinsAgo <= 5
}

// made battery as any for now since AggregatedSOC from payload object directly is not valid based on ITelemetryPayload
export const getBatteryStateOfCharge = (battery: any) => {
  if (typeof battery?.latestTelemetry?.payload === 'string') {
    return +JSON.parse(battery.latestTelemetry.payload).StateOfCharge ?? 0
  }

  return +battery?.latestTelemetry?.payload?.StateOfCharge ?? 0
}
