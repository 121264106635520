interface IUseBreakpointReturnValue {
  breakpoint: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}

export const useBreakpoint = (size: number): IUseBreakpointReturnValue => {
  if (size < 576) return { breakpoint: 'sm' }
  else if (size < 768) return { breakpoint: 'md' }
  else if (size < 992) return { breakpoint: 'lg' }
  else if (size < 1200) return { breakpoint: 'xl' }
  else if (size < 1400) return { breakpoint: '2xl' }
  else return { breakpoint: '2xl' }
}
