import React, { forwardRef } from 'react'
import cn from 'classnames'
import styled from 'styled-components'

interface TextFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  dense?: boolean
  trailingComponent?: React.FC | React.ReactNode
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const { className, dense = false, trailingComponent } = props

  if (!trailingComponent) {
    return (
      <StyledInput
        // @ts-ignore
        ref={ref}
        {...props}
        className={cn(
          'min-h-[35px] indent-2 border-solid border-[1px] border-black-700 bg-black text-[#DDDDDD]',
          // {
          //   'min-h-[35px]': dense
          // },
          className
        )}
      />
    )
  }

  return (
    <div className='relative'>
      <StyledInput
        // @ts-ignore
        ref={ref}
        {...props}
        className={cn(
          'min-h-[64px] indent-2 border-solid border-[1px] border-black-700 bg-black text-[#DDDDDD]',
          {
            'min-h-[40px]': dense
          },
          className
        )}
      />
      <span className='absolute flex items-center top-0 right-0 h-full mx-2 pointer-events-none'>
        {trailingComponent}
      </span>
    </div>
  )
})

const StyledInput = styled.input`
  font-size: 14px;
  font-weight: 400;
  /* color: #DDDDDD; */

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    /* color: #DDDDDD; */
  }
`

export default TextField
