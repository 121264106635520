import { useMemo } from 'react'
import cn from 'classnames'
import { IMower } from '../../../../models'
import { 
  Image, 
  Icon, 
  Text, 
  ProgressBar, 
  StatusIndicator } from '../../../atoms'
import { TStatus } from '../../../atoms/StatusIndicator/types'
import { 
  MowerImage, 
  MowerCommon, 
  DeviceFaultButton } from '../../../molecules'
import { ASSETS } from '../../../../assets'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useAppTheme } from '../../../../hooks'
import { Mower, DEVICEMOWERSTATUSVALUES } from '../../../../models_v2/common/mower'
import BatteryIconListAndPanel from '../../BatteryIconListAndPanel/BatteryIconListAndPanel'
import { isMowerOnline } from '../../../../utils/mower'
import { BatteryPack } from '../../../../models_v2/entity/telemetry_payload'
import { useAppSelector } from '../../../../store/hooks'
import { selectFilteredDevices } from '../../../../store/slices/deviceSliceV2/selectors'
import { selectOperatingCompany } from '../../../../store/slices/operatingCompany'
import { IDevice } from '../../../../models_v2/entity/device'
import { isSurepathDevice } from '../../../../utils/device'


interface AssetMowerListItemProps {
  mower: Partial<Mower>
  isActive?: boolean
  onClick?: () => void
  onPreSelect?: () => void
}

const AssetMowerListItem: React.FC<AssetMowerListItemProps> = props => {
  const { i18n } = useLingui()
  const { mower, isActive = false, onClick, onPreSelect } = props
  const { inventory, latestTelemetry } = mower
  const { source, name } = inventory ?? {}
  const { theme } = useAppTheme()
  const network_status = 'OFFLINE'
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const companyBatteries = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: "battery", 
      operatingCompany
    })) as IDevice[]

  const activeFaults = useMemo(() => {
    return mower.metadata
      ?.filter(item => item.active)
      .sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
  }, [mower.metadata])

  const shouldNetworkStatusShow = useMemo(() => {
    if (source !== 'surepath')
      return network_status?.toLowerCase() !== 'offline'
    return true
  }, [source, network_status])

  const progressBarColor = useMemo(() => {
    if (
      Number(mower?.latestTelemetry?.status) ===
      DEVICEMOWERSTATUSVALUES.Charging
    )
      return '#2CC947'

    return theme === 'light' ? '#0082FC' : undefined
  }, [theme, mower])

  const isMowerCharging = useMemo(
    () =>
      Number(mower?.latestTelemetry?.status) ===
      DEVICEMOWERSTATUSVALUES.Charging,
    [mower]
  )



  const getCalculatedMowerBatteryPercentage = () => {
    if (!companyBatteries?.length) {

      return '-'
    }

    const mowerBatteries = companyBatteries.filter(battery => (battery.latestTelemetry.payload as unknown as BatteryPack).StateOfCharge && battery.productSerial.split("_")[0] === mower.productSerial)

    if (!mowerBatteries.length) return '-'

    const mowerBatteriesChargeCurrents = mowerBatteries.map(battery => parseFloat(`${(battery.latestTelemetry.payload as unknown as BatteryPack).StateOfCharge}`))

    return Math.ceil(((mowerBatteriesChargeCurrents.reduce((accu, curr) => accu + curr, 0) / 500) * 100))
  }


  return (
    <div
      className={cn('text-black dark:text-white p-1 card', {
        'cursor-pointer': !!onClick,
        'outline outline-4 outline-offset-[-4px] outline-interaction-200':
          isActive
      })}
      onClick={onClick}>
      <div className='flex justify-between items-center'>
        <div
          className={cn('flex items-center gap-3', {
            'cursor-pointer': !!onPreSelect
          })}
          onClick={e => {
            onPreSelect?.()
            e.stopPropagation()
          }}>
          <div className='relative'>
            <Text.Feature.Medium className={'inline-block mr-1 max-w-[200px]'}>
              {name}
            </Text.Feature.Medium>
            <div className='flex-shrink-0'>
              {latestTelemetry?.location &&
                latestTelemetry?.location !== 'null' ? (
                <Image
                  src={ASSETS.IMAGES.COMMON.target}
                  className={`${name && name !== '' ? 'absolute left-full' : ''
                    } top-0 w-3 h-3 invert-0 dark:invert object-cover`}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          {!!activeFaults?.length && (
            <DeviceFaultButton faults={activeFaults} item={mower} />
          )}
        </div>
        <StatusIndicator
          isOnline={isMowerOnline(mower)}
          value={network_status?.toLowerCase?.() as TStatus}
        // suffixText={dtc_code ? `DTC ${dtc_code}` : ''}
        />
      </div>
      <div className='flex flex-row'>
        {isSurepathDevice(mower) && (
          <div className='relative'>
            <MowerCommon.SurepathLogo className='absolute' />
          </div>
        )}
        <MowerImage mower={mower} />

        <div className='ml-[18px] mt-[15px] flex flex-1 flex-col'>
          <div className='flex flex-col gap-3'>
            <div className='flex justify-end'>
              <div>
                {shouldNetworkStatusShow && (
                  <div className='flex flex-col lg:flex-row justify-start lg:gap-2'>
                    <Text.VariousRegular.Small>
                      <Trans>Status</Trans>:
                    </Text.VariousRegular.Small>
                    <Text.VariousBold.Small>
                      {mower.getMowerStatus?.()
                        ? i18n._(mower.getMowerStatus())
                        : ''}
                    </Text.VariousBold.Small>
                  </div>
                )}

                {isSurepathDevice(mower) ? (
                  <MowerCommon.SurepathInfo
                    mower={mower}
                    showGps={network_status !== 'OFFLINE'}
                    showCellCignal={network_status !== 'OFFLINE'}
                  />
                ) : (
                  <MowerCommon.LastSignal mower={mower} />
                )}
              </div>
            </div>

            {mower.inventory?.deviceType !== 50 && (
              <div className='flex flex-col gap-1'>
                <div className='flex justify-between'>
                  <div className='flex items-center gap-1'>
                    {isMowerCharging && <Icon
                      name='bolt_vector'
                      className='h-[12px] w-2 mr-1 invert dark:invert-0'
                    />}
                    <Text.VariousRegular.Large>{getCalculatedMowerBatteryPercentage()}%</Text.VariousRegular.Large>
                  </div>
                  <BatteryIconListAndPanel mower={mower as Partial<IMower>} />
                </div>

                <ProgressBar
                  progressBarColor={progressBarColor}
                  // value={total_battery_charge / 100}
                  value={0}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssetMowerListItem
