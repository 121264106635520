import { Transition, Dialog as HeadlessDialog } from '@headlessui/react'
import React, { Fragment } from 'react'
import { Text, Icon } from '../'
import cn from 'classnames'

interface DialogProps {
  show: boolean
  onClose?: (value: boolean) => void
  headerTitle?: string
  borderVariant?: 'primary' | 'danger'
}

export const Dialog: React.FC<DialogProps> = props => {
  const {
    children,
    show,
    onClose,
    headerTitle = '',
    borderVariant = 'danger'
  } = props

  return (
    <Transition appear show={show} as={Fragment}>
      <HeadlessDialog
        as='div'
        onClose={value => onClose?.(value)}
        className='relative z-10'>
        <div
          className='fixed inset-0 bg-black/75 backdrop-blur-xs'
          aria-hidden='true'
        />

        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0'>
          <div className='flex items-center justify-center min-h-full'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'>
              <HeadlessDialog.Panel
                className={cn(
                  'flex flex-col w-full max-h-screen min-h-screen md:max-h-[calc(100vh-6rem)] md:min-h-fit max-w-full md:max-w-xl transform rounded-none md:rounded-xl bg-black border text-white shadow-xl transition-all',
                  {
                    'border-error': borderVariant === 'danger',
                    'border-primary': borderVariant === 'primary'
                  }
                )}>
                <HeadlessDialog.Title
                  as='div'
                  className='flex items-center justify-between gap-4 p-4'>
                  <Text.Headline.H4>{headerTitle}</Text.Headline.H4>
                </HeadlessDialog.Title>
                <div className='absolute right-[18px] top-[18px]'>
                  <Icon
                    name='close_circle'
                    className='cursor-pointer'
                    onClick={() => onClose?.(true)}
                  />
                </div>

                <div className='overflow-auto'>
                  <div className='px-4 pb-4'>{children}</div>
                </div>
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  )
}
