import React, { useContext } from 'react'
import {
  ReportingAssetSelector,
  ReportingCalendar,
  ReportingScheduleForm,
  SidebarContainer
} from '../../molecules'
import { Divider } from '../../atoms'
import { ReportingPageContext } from '../../../pages/ReportingPage'
import { Transition } from '@headlessui/react'

const ReportingSchedule: React.FC = () => {
  const { state } = useContext(ReportingPageContext)

  return (
    <div className='flex flex-1 overflow-hidden'>
      <SidebarContainer className='border-r-2 border-black overflow-y-auto'>
        <div className='p-[30px] pt-[22px]'>
          <ReportingScheduleForm />
        </div>
      </SidebarContainer>

      <div className='flex-1 flex flex-col overflow-auto'>
        <Transition
          show={state?.asset_selection?.schedule}
          enter='transition-all ease-in-out duration-150'
          enterFrom='opacity-0 -translate-y-full'
          enterTo='opacity-100 translate-y-0'
          leave='transition-all ease-in-out duration-300'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 -translate-y-full'>
          <ReportingAssetSelector reportingType='schedule' className='h-full' />
          <Divider size='md' />
        </Transition>

        <ReportingCalendar reportingType='schedule' />
      </div>
    </div>
  )
}

export default ReportingSchedule
