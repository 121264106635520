import React, { useEffect, useMemo } from 'react'
import cn from 'classnames'
import { useFormContext } from 'react-hook-form'
import { ICompany } from '../../../../../../models'
import { Button, Divider, Image, Text, TextField } from '../../../../../atoms'
import { ASSETS } from '../../../../../../assets'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectSelectedCompany } from '../../../../../../store/slices/organizationCompanySlice'
import { ErrorMessage } from '@hookform/error-message'

interface OrganizationCompanyFormProps {
  mode: 'create' | 'edit'
}

export interface CompanyInput
  extends Pick<ICompany, 'name' | 'email' | 'phone' | 'image_url' | "companyId"> { }

const OrganizationCompanyForm: React.FC<OrganizationCompanyFormProps> = props => {
  const { mode } = props
  const selectedCompany = useAppSelector(selectSelectedCompany) as any

  const {
    register,
    watch,
    reset,
    formState: { errors },
  } = useFormContext<CompanyInput>()
  const formData = watch()
  const hasThumbnailImage = useMemo(
    () => !!formData.image_url,
    [formData.image_url]
  )

  useEffect(() => {
    if (!selectedCompany) return

    reset({
      companyId: selectedCompany.companyId,
      name: mode === 'create' ? '' : selectedCompany.inventory.name,
    })
  }, [selectedCompany])

  return (
    <div className='h-full flex flex-col'>
      <div className='h-[102px] flex items-end'>
        {mode === 'edit' && (
          <div className='flex flex-col dark:text-white px-4 py-2'>
            <Text.VariousRegular.Large>
              {selectedCompany?.inventory.name || ''}
            </Text.VariousRegular.Large>
          </div>
        )}
      </div>
      <Divider size='md' />
      <div className='flex-1 flex flex-row items-start p-4 gap-5'>
        <div className='flex flex-col gap-3 max-w-[170px]'>
          <div className='h-[170px] w-[170px] m-auto relative'>
            {!hasThumbnailImage && (
              <Image
                src={ASSETS.IMAGES.COMMON.border_box}
                className='absolute'
              />
            )}
            {hasThumbnailImage && (
              <Image
                src={selectedCompany?.image_url || ''}
                className={cn('h-full w-full rounded-xl', {
                  'p-[6px]': !hasThumbnailImage,
                  'rounded-xl': hasThumbnailImage
                })}
              />
            )}
          </div>
          <Button
            title={hasThumbnailImage ? 'Edit Image' : 'Upload Image'}
            color='secondary'
            className='border border-solid border-white'
          />
        </div>
        <div className='w-[300px] flex flex-col gap-2 dark:text-white'>
          <div className='flex flex-col gap-2'>
            <Text.Body.MediumSemiBold>Company Name</Text.Body.MediumSemiBold>
            <TextField
              {...register('name', {
                required: 'Company name is required',
              })}
              type='text'
              className='min-h-[50px] focus:outline-none focus:border-solid focus:border-white'
            />
            <ErrorMessage
              errors={errors}
              className='text-error'
              name='name'
              as='p'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrganizationCompanyForm
