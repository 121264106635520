export interface Mower {
  inventory: {
    name: string;
  };
}
export interface Battery {
  inventory: {
    name: string;
  };
}
export interface ToolTag {
  inventory: {
    name: string;
  };
}
export interface Group {
  groupName: string;
}

type KeyExtractor<T> = (item: T) => string;

const getCategory = (name: string): number => {
    const firstChar = name.charAt(0);
    if (firstChar === "" || !isNaN(parseInt(firstChar))) return 0; // Blank or number
    if (/[^a-zA-Z0-9]/.test(firstChar)) return 1; // Special character
    return 2; // Letter
};

export const compareByName = <T>(getKey: KeyExtractor<T>) => (itemA: T, itemB: T): number => {
    const nameA = getKey(itemA).toLowerCase();
    const nameB = getKey(itemB).toLowerCase();

    const categoryA = getCategory(nameA);
    const categoryB = getCategory(nameB);

    if (categoryA !== categoryB) {
        return categoryA - categoryB;
    }

    // Sort alphabetically
    return nameA.localeCompare(nameB);
};