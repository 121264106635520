import moment from 'moment'
import { IDevice } from '../entity/device'
import { IMetadata } from '../entity/metadata'
import { IInventory } from '../entity/inventory'
import { ITelemetry } from '../entity/telemetry'

export const MOWER_STATUS = {
  '-1': 'Unknown',
  '0': 'Off',
  '1': 'Idle',
  '2': 'Driving',
  '3': 'Mowing',
  '4': 'Charging',
  '5': 'Mowing (SurePath Ready)',
  '6': 'Mowing (SurePath Enabled)'
}

export const DEVICEMOWERSTATUSVALUES = {
  Unknown: -1,
  Off: 0,
  Idle: 1,
  Driving: 2,
  Mowing: 3, // Manual mowing for SurePath
  Charging: 4,
  MowingSurePathReady: 5, // SurePath only
  MowingSurePathEnabled: 6 // SurePath only
}

export const SUREPATH_MOWER_ACTIVITIES = ['Mowing', 'Driving', 'Idle', 'Off']

export const ASCENT_MOWER_ACTIVITIES = [
  'Mowing',
  'Driving',
  'Idle',
  'Off',
  'Charging'
]

export class Mower implements IDevice {
  productSerial!: string
  assignment!: string
  latestTelemetry!: ITelemetry
  telemetries!: ITelemetry[]
  inventory!: IInventory
  metadata!: IMetadata[]
  mowerHours?: number

  constructor(data: IDevice) {
    Object.assign(this, data)
  }

  getMowerStatus() {
    const { status = '-1' } = this.latestTelemetry ?? {}
    return (
      MOWER_STATUS[status as keyof typeof MOWER_STATUS]?.toUpperCase?.() || ''
    )
  }

  getLastSignal() {
    const { timestamp } = this.latestTelemetry ?? {}
    const lastSignal = moment(timestamp)
    if (!lastSignal.isValid()) return null
    return lastSignal.format('MM/DD/YYYY hh:mm A')
  }

  hasSomeActiveFaults() {
    const faults = this.metadata ?? []
    return faults.some(item => item.active)
  }
}
