import React, { useMemo } from 'react'
import { Text } from '../../atoms'
import cn from 'classnames'
import moment from 'moment'

interface LastSeenProps {
  className?: string
  value: string | number
}

const LastSeen: React.FC<LastSeenProps> = props => {
  const { className, value } = props

  const lastSeen = useMemo(() => {
    return moment(value).format('hh:mm A MM/DD/YYYY')
  }, [value])

  return (
    <div className={cn('flex justify-start gap-2', className)}>
      <Text.VariousRegular.Small>Last seen: </Text.VariousRegular.Small>
      <Text.VariousBold.Small>{lastSeen}</Text.VariousBold.Small>
    </div>
  )
}

export default LastSeen
