import React, { useCallback, useMemo } from 'react'
import { StatusIndicator, Table } from '../../atoms'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useCompanies } from '../../../hooks'
import { ICompany } from '../../../models/company'
import {
  setSelectedCompanyId,
  selectAssetsByCompanyDictionary,
  selectPeopleByCompanyDictionary
} from '../../../store/slices/organizationCompanySlice'
import { IDevice } from '../../../models_v2/entity/device'

interface CompanyListProps {}

interface IExtendedCompanyData extends Omit<ICompany, 'inventory'> {
  inventory: ICompany['inventory'] & {
    peopleCount: number
    assetsCount: number
  }
}

export const CompanyList: React.FC<CompanyListProps> = props => {
  const { searchFilter } = useAppSelector(state => state.organizationSettings)
  const dispatch = useAppDispatch()
  const companies = useAppSelector(state => state.organizationCompany.companies)
  const { loading } = useCompanies()
  const assetsByCompanyDictionary = useAppSelector(
    selectAssetsByCompanyDictionary
  )
  const peopleByCompanyDictionary = useAppSelector(
    selectPeopleByCompanyDictionary
  )

  // TODO: move to filter selector similar to selectDevicesFilter
  const filteredCompanies = useMemo(
    () =>
      companies?.filter(company =>
        searchFilter
          ? (company as IDevice).inventory.name
              .toLowerCase()
              .includes(searchFilter)
          : true
      ),
    [companies, searchFilter]
  )

  const onRowsSelected = (row: Record<string, any>[]) => {
    dispatch(setSelectedCompanyId(row?.[0]?.companyId))
  }

  const renderStatusCell = useCallback(
    (item: ICompany) => <StatusIndicator showLabel={false} value='offline' />,
    []
  )

  /**
   * Memoization of companies
   * Optimized pattern since it will only get trigger once
   * or until there are data changes throughout the component lifetime.
   */
  const data = useMemo(
    () =>
      filteredCompanies?.reduce((companies, company) => {
        const { companyId = '', inventory = {} } = company ?? {}
        const companyData: IExtendedCompanyData = {
          ...company,
          inventory: {
            ...inventory,
            peopleCount:
              (peopleByCompanyDictionary[companyId] || [])?.length || 0,
            assetsCount:
              (assetsByCompanyDictionary[companyId] || [])?.length || 0
          } as IExtendedCompanyData['inventory']
        }

        return [...companies, companyData] as ICompany[]
      }, [] as ICompany[]),
    [filteredCompanies, peopleByCompanyDictionary, assetsByCompanyDictionary]
  )

  return (
    <Table
      data={data || []}
      isLoading={loading}
      rowKey='companyId'
      columns={[
        {
          Header: 'Company',
          accessor: 'inventory.name',
          width: 300
        },
        {
          Header: 'Asset Count',
          accessor: 'inventory.assetsCount',
          width: 100
        },
        {
          Header: 'People Count',
          accessor: 'inventory.peopleCount',
          width: 100
        },
        {
          Header: 'Status',
          accessor: 'status',
          width: 100,
          Cell: cell => renderStatusCell(cell.row.original as ICompany)
        }
      ]}
      defaultSortedColumn={{
        key: 'inventory.name',
        sort: 'ascending'
      }}
      columnClassName='text-black dark:text-white'
      enableRowSelection
      onRowsSelected={onRowsSelected}
    />
  )
}
