import React, { useMemo } from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import { DynamicListItem } from '../..'
import { Text } from '../../../atoms'
import moment from 'moment'

interface MonthItemProps {
  date: Date
  onClick?: () => void
  isActive?: boolean
}

const MonthItem: React.FC<MonthItemProps> = props => {
  const { date, onClick, isActive = false } = props

  const dateMetadata = useMemo(() => {
    const dateAsMomment = moment(date)

    return {
      month: dateAsMomment.format('MMMM').toUpperCase(),
      year: dateAsMomment.format('YYYY')
    }
  }, [date])

  return (
    <StyledDynamicListItem
      className={cn('min-w-[110px] flex-1 px-[12px] py-2', {
        'cursor-pointer': !!onClick
      })}
      onClick={onClick}>
      {isActive && (
        <StyledBorder className='w-[3px] h-[calc(100%-6px)] bg-primary rounded-full mr-2' />
      )}
      <div className='flex-1 flex flex-col'>
        <Text.VariousBold.Small className='font-extrabold'>
          {dateMetadata.month}
        </Text.VariousBold.Small>
        <Text.VariousRegular.Small>
          {dateMetadata.year}
        </Text.VariousRegular.Small>
      </div>
    </StyledDynamicListItem>
  )
}

export default MonthItem

const StyledDynamicListItem = styled(DynamicListItem)`
  box-shadow: 0px 21px 64px rgba(0, 0, 0, 0.5);
`

const StyledBorder = styled.div`
  box-shadow: 0px 0px 11px rgba(254, 189, 24, 0.57);
`
