const GarageRoutes = {
  garage_page: '/app/garage',
  'garage_page.wildcard': '/app/garage/*',
  'garage_page.global-fleet': '/app/garage/global-fleet',
  'garage_page.global-fleet.mowers': '/app/garage/global-fleet/mowers',
  'garage_page.global-fleet.batteries': '/app/garage/global-fleet/batteries',
  'garage_page.global-fleet.tool_tags': '/app/garage/global-fleet/tool_tags',
  'garage_page.global-fleet.groups_create':
    '/app/garage/global-fleet/groups/create',
  'garage_page.global-fleet.groups_edit':
    '/app/garage/global-fleet/groups/:id/edit'
}

export default GarageRoutes
