export const BATTERY_STATUS_MAPPING = {
  '0': 'Initialize',
  '1': 'Startup',
  '2': 'Discharging',
  '3': 'Idle',
  '4': 'Charging',
  '5': 'System Update',
  '6': 'Low Power'
}

export enum BATTERY_STATUS {
  INITIALIZE = 0,
  STARTUP = 1,
  DISCHARGING = 2,
  IDLE = 3,
  CHARGING = 4,
  SYSTEM_UPDATE = 5,
  LOW_POWER = 6
}
