import React, { useContext, useEffect, useRef } from 'react'
import { ApolloClient, useApolloClient } from '@apollo/client'
import { AppRootContext } from '../pages/AppRoot'
import { MAPPER_COLLECTION } from '../models/mapper'
import { METADATA_SUBSCRIPTION, SUBSCRIBE_DEVICE} from '../services_v2/gql/subscription';
import { updateDevice, upsertMetadataByDevice} from '../store/slices/deviceSliceV2';
import {useAppDispatch, useAppSelector} from '../store/hooks';

export const useRealtimeUpdates = () => {
  const { dispatch } = useContext(AppRootContext)
  const reduxDispatch = useAppDispatch()
  const devices = useAppSelector(state => state.deviceV2.devices)

  const apolloClient: ApolloClient<object> = useApolloClient()

  const faultSubscription = useRef<any>()
  const mowerSubscription = useRef<any>()

  useEffect(() => {
    const startMowerSubscription = async () => {
      mowerSubscription.current = apolloClient
        .subscribe({
          query: SUBSCRIBE_DEVICE,
          variables: {}
        })
        .subscribe({
          next: data => {
            // TODO: should update ALL devices
            const device = MAPPER_COLLECTION.devices_v2([data.data.getDevice])[0]
            if (!device?.latestTelemetry) {
              return
            }
            reduxDispatch(updateDevice({
              device: {
                // we don't seem to get ALL the data from subscription only latestTelemetry would be a relevant update.
                // Add more pieces of the device that need update here IF/WHEN we get more data from the subcription in the future
                latestTelemetry: device.latestTelemetry
              }
            }))
          },
          error: err => {
            console.error('error with NEW_DEVICE subscription', err)
          }
        })
    }

    const startFaultSubscription = async () => {
      faultSubscription.current = apolloClient
        .subscribe({
          query: METADATA_SUBSCRIPTION,
          variables: {}
        })
        .subscribe({
          next: data => {
            reduxDispatch(upsertMetadataByDevice({
              productSerial: data.data.getMetadata.productSerial,
              metadata: data.data.getMetadata
            }))
          },
          error: err => {
            console.error('error with NEW_FAULT subscription', err)
          }
        })
    }

    if (!faultSubscription.current && devices.length) {
      startFaultSubscription()
    }

    if (!mowerSubscription.current && devices.length) {
      startMowerSubscription()
    }
    return () => {
      faultSubscription?.current?.unsubscribe()
      mowerSubscription?.current?.unsubscribe()
    }
  }, [devices.length])
}
