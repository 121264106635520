export interface TrackingPageProps {}

export enum ESortDirection {
  ASC = 'Ascending',
  DESC = 'Descending'
}

export interface IReducedData {
  selected: any[]
  unselected: any[]
}
