import React, { useEffect, useMemo, useState } from 'react'
import { TabList, RadioButton, Text, ProgressBar } from '../../../atoms'
import { TabItem } from '../../../atoms/TabList/types'
import { CircularDial } from '../../../molecules'
import { EDeviceMowerStatus } from '../../../../models/device'
import {
  EUnitOfTime,
  timeTakenPercentage,
  convertMinutesToHourFormat
} from './calculations'
import {
  ACTIVE_GAUGE_COLOR,
  DEFAULT_BORDER_COLOR,
  DEFAULT_GAUGE_COLOR,
  SUREPATH_GAUGE_COLOR
} from './constants'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { TMowerActivityTab } from '../types'
import {
  DEVICEMOWERSTATUSVALUES,
  SUREPATH_MOWER_ACTIVITIES,
  ASCENT_MOWER_ACTIVITIES,
  MOWER_STATUS,
  Mower
} from '../../../../models_v2/common/mower'
import { surepathPerformanceColors } from '../../../molecules/SurepathTimelineLegend/index'
import { activityTimelineColors } from '../../../molecules/ActivityTimelineLegend/index'
import {
  useDevice,
  useGetDeviceTelemetryInChunk
} from '../../../../hooks/data/useDevice'
import moment from 'moment'
import { CircularDialProps } from '../../../molecules/CircularDial'
import { isAscentDevice, isSurepathDevice } from '../../../../utils/device'
import { useAppSelector } from '../../../../store/hooks'
import { selectDevice } from '../../../../store/slices/deviceSliceV2/selectors'

interface AnalyticsProps {
  mower: Partial<Mower>
  onMowerActivityTabChange?: (type: TMowerActivityTab) => void
}

interface IMowerStatusSelection {
  id: string
  value: number
  label: string
  index: number
  displayValue?: string
  totalTime?: string
}

export type TMowerActivity =
  | 'Unknown'
  | 'Off'
  | 'Idle'
  | 'Driving'
  | 'Mowing'
  | 'Charging'

export type TMOWER_ACTIVITIES = [
  'Mowing' | 'Driving' | 'Idle' | 'Off' | 'Charging'
]

export interface DeviceQueryParams {
  productSerial: string
  startTime?: string
  endTime?: string
}

const Analytics: React.FC<AnalyticsProps> = props => {
  const { i18n } = useLingui()
  const { mower, onMowerActivityTabChange } = props
  const [unitOfTime, setUnitOfTime] = useState<EUnitOfTime>(EUnitOfTime.DAY)
  const [selectedMowerStatus, setSelectedStatus] =
    useState<IMowerStatusSelection>({
      id: EDeviceMowerStatus.Mowing.toString(),
      value: EDeviceMowerStatus.Mowing,
      label: 'Mowing',
      index: 0
    })
  const { inventory } = mower
  const { source } = inventory ?? {}
  const [isFetchingTelemetry, setIsFetchingTelemetry] = useState(false)
  const device = useAppSelector(selectDevice)

  const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSSSSSSS'
  const initialEndDate = moment().endOf('day').format(DATE_FORMAT)
  const initialStartDate = moment()
    .startOf('day')
    .subtract(1, 'day')
    .format(DATE_FORMAT)

  const { refetch, loading } = useDevice(
    {
      productSerial: mower?.productSerial || '',
      startTime: initialStartDate,
      endTime: initialEndDate
    },
    {
      enableSetDeviceState: true
    }
  )

  const getDeviceTelemetryInChunk = useGetDeviceTelemetryInChunk({
    enableSetDeviceState: true
  })

  const handlerUnitSelection = (unit: EUnitOfTime) => {
    setUnitOfTime(unit)
  }

  const tabItems = useMemo<TabItem[]>(
    () => [
      {
        id: '24_hrs',
        label: t`Last 24 hrs`
      },
      {
        id: '7_days',
        label: t`7 days`
      },
      {
        id: '30_days',
        label: t`30 days`
      }
    ],
    []
  )

  const currentMowerActivity = useMemo(
    () =>
      isAscentDevice(device)
        ? ASCENT_MOWER_ACTIVITIES
        : SUREPATH_MOWER_ACTIVITIES,
    [device, SUREPATH_MOWER_ACTIVITIES, ASCENT_MOWER_ACTIVITIES]
  )

  const isAscent = useMemo(() => isAscentDevice(device), [device])

  const mowerStatus = useMemo<Array<IMowerStatusSelection>>(() => {
    return currentMowerActivity.map((MOWER_ACTIVITY: string, index: number) => {
      const statusData =
        DEVICEMOWERSTATUSVALUES[
          MOWER_ACTIVITY as keyof typeof DEVICEMOWERSTATUSVALUES
        ]

      return {
        id: statusData.toString(),
        value: statusData,
        label: `${i18n._(MOWER_ACTIVITY ?? '')}`,
        index: index
      }
    })
  }, [source, currentMowerActivity])

  const handlerSelectionChange = (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const { id } = e.currentTarget

    const [selected = {}] = mowerStatus?.filter(item => item?.id === id)

    setSelectedStatus(selected as IMowerStatusSelection)
  }

  const refetchDevice = async (params: DeviceQueryParams) => {
    setIsFetchingTelemetry(true)
    await refetch(params)
    setIsFetchingTelemetry(false)
  }

  const handleGetDeviceTelemetryInChunck = async (
    params: DeviceQueryParams & {
      numDays: number
      numSegments: number
    }
  ) => {
    setIsFetchingTelemetry(true)
    await getDeviceTelemetryInChunk(params)
    setIsFetchingTelemetry(false)
  }

  // * Updates for Mower activity
  useEffect(() => {
    const currentTime = moment()
    const endTime = currentTime.endOf('day').format(DATE_FORMAT)
    let timeInPast

    if (unitOfTime === EUnitOfTime.DAY) {
      const timeInPast24hrs = moment(currentTime)
        .startOf('day')
        .subtract(1, 'day')
        .format(DATE_FORMAT) // Calculate time 24 hours ago
      timeInPast = timeInPast24hrs
    } else if (unitOfTime === EUnitOfTime.WEEK) {
      const timeInPast7days = moment(currentTime)
        .startOf('day')
        .subtract(7, 'days')
        .format(DATE_FORMAT) // Calculate time 7 days ago
      timeInPast = timeInPast7days
    } else if (unitOfTime === EUnitOfTime.MONTH) {
      const timeInPast30days = moment(currentTime)
        .startOf('day')
        .subtract(30, 'days')
        .format(DATE_FORMAT) // Calculate time 30 days ago
      timeInPast = timeInPast30days

      handleGetDeviceTelemetryInChunck({
        productSerial: mower?.productSerial || '',
        startTime: timeInPast,
        endTime,
        numDays: 30,
        numSegments: 3
      })

      return
    } else {
      const timeInPast24hrs = moment(currentTime)
        .startOf('day')
        .subtract(1, 'day')
        .format(DATE_FORMAT) // Calculate time 24 hours ago
      timeInPast = timeInPast24hrs
    }

    refetchDevice({
      productSerial: mower?.productSerial || '',
      startTime: timeInPast,
      endTime
    })
  }, [unitOfTime, mower?.productSerial])

  const distinctByMowerStatus = useMemo(() => {
    const { telemetries = [] } = device ?? {}

    return telemetries?.reduce((acc, curr, index) => {
      const { status, timestamp } = curr ?? {}
      const { [status]: distinctStatus = {} } = acc ?? {}
      const { telemetries: distinctStatusTelemetry = [], totalDuration = 0 } =
        distinctStatus ?? {}

      const nextTelemetry = telemetries[index + 1]

      if (!nextTelemetry) return acc

      const currentTimestamp = moment(timestamp)
      const nextTimestamp = moment(nextTelemetry?.timestamp)
      const duration = moment.duration(nextTimestamp.diff(currentTimestamp))

      const minutesDuration = duration?.asMinutes()
      const sum = totalDuration + minutesDuration

      // * -1 or Unknown statuses
      if (status === DEVICEMOWERSTATUSVALUES.Unknown?.toString()) {
        const { [DEVICEMOWERSTATUSVALUES.Off]: offStatus = {} } = acc ?? {}
        const { telemetries: offTelemetry = [], totalDuration = 0 } =
          offStatus ?? {}

        const sum = totalDuration + minutesDuration

        return {
          ...acc,
          [DEVICEMOWERSTATUSVALUES.Off]: {
            ...offStatus,
            totalDuration: sum,
            telemetries: [...offTelemetry, curr]
          }
        }
      }

      return {
        ...acc,
        [status]: {
          ...distinctStatus,
          totalDuration: sum,
          telemetries: [...distinctStatusTelemetry, curr]
        }
      }
    }, {} as Record<string, Record<string, any>>)
  }, [device])

  const mowerStatusPercentages = useMemo<
    Record<TMowerActivity, IMowerStatusSelection>
  >(() => {
    const isSurePath = isSurepathDevice(device)

    const percentages = Object.entries(MOWER_STATUS).reduce(
      (acc, [key, value]) => {
        const { [key]: distinctStatus = {} } = distinctByMowerStatus ?? {}
        const { totalDuration } = distinctStatus ?? {}
        let totalMinutesDuration = totalDuration

        // * Only for SurePath
        // * Surepath Mowers Mowing Calculation
        if (isSurePath && key === DEVICEMOWERSTATUSVALUES.Mowing.toString()) {
          const {
            [DEVICEMOWERSTATUSVALUES.MowingSurePathEnabled]:
              mowingSurePathEnabled = {},
            [DEVICEMOWERSTATUSVALUES.MowingSurePathReady]:
              mowingSurePathReady = {}
          } = distinctByMowerStatus ?? {}

          totalMinutesDuration =
            (totalDuration || 0) +
            (mowingSurePathEnabled?.totalDuration || 0) +
            (mowingSurePathReady?.totalDuration || 0)
        }

        const timeTaken = timeTakenPercentage({
          unit: unitOfTime,
          duration: totalMinutesDuration
        })

        const hourFormat = convertMinutesToHourFormat(totalMinutesDuration)

        return {
          ...acc,
          [value]: {
            id: key,
            label: value,
            value: timeTaken?.rounded,
            displayValue: hourFormat || ''
          }
        }
      },
      {} as Record<TMowerActivity, IMowerStatusSelection>
    )

    return percentages as Record<TMowerActivity, IMowerStatusSelection>
  }, [distinctByMowerStatus, MOWER_STATUS, device, unitOfTime])

  const mowerStatusSelection = useMemo(() => {
    return (
      <div className='flex flex-col items-start gap-1'>
        {(currentMowerActivity as Array<TMowerActivity>).map(item => {
          const { [item]: mowerStatusPercentage } = mowerStatusPercentages ?? {}
          const { id, displayValue } = mowerStatusPercentage ?? {}

          return (
            <RadioButton
              key={id}
              id={id}
              onChange={handlerSelectionChange}
              checked={item === selectedMowerStatus?.label}
              label={`${i18n._(item ?? '')}: ${displayValue}`}
            />
          )
        })}
      </div>
    )
  }, [
    mowerStatusPercentages,
    selectedMowerStatus?.label,
    i18n,
    currentMowerActivity
  ])

  const graphDetails = useMemo<CircularDialProps>(() => {
    const { label, index } = selectedMowerStatus ?? {}
    const mowerStatusesCount = mowerStatus?.length ?? 0
    const backgroundColor = Array(mowerStatusesCount).fill(DEFAULT_GAUGE_COLOR)
    const colors = isAscent ? activityTimelineColors : surepathPerformanceColors
    const activeGaugeColor =
      colors?.[
        label?.toLowerCase?.() as keyof typeof surepathPerformanceColors
      ] ?? ACTIVE_GAUGE_COLOR
    backgroundColor.splice(index, 1, activeGaugeColor)

    const chart_data = (currentMowerActivity as Array<TMowerActivity>).map(
      item => {
        const { [item]: mowerStatusPercentage } = mowerStatusPercentages ?? {}
        const { value } = mowerStatusPercentage ?? {}

        return value
      }
    )

    const { [label as TMowerActivity]: mowerStatusPercentage } =
      mowerStatusPercentages ?? {}
    const { value } = mowerStatusPercentage ?? {}

    return {
      label,
      value: `${value}%`,
      data: {
        datasets: [
          {
            data: chart_data,
            backgroundColor,
            borderColor: [DEFAULT_BORDER_COLOR]
          }
        ]
      }
    }
  }, [
    selectedMowerStatus,
    mowerStatusPercentages,
    currentMowerActivity,
    isAscent
  ])

  const energyOutputPercentage = useMemo(() => {
    if (isAscent) {
      // TODO: Calculation for Ascent mowers
      return 0
    }

    // * Surepath Mowers Calculation
    const {
      [DEVICEMOWERSTATUSVALUES.MowingSurePathEnabled]:
        mowingSurePathEnabled = {},
      [DEVICEMOWERSTATUSVALUES.MowingSurePathReady]: mowingSurePathReady = {},
      [DEVICEMOWERSTATUSVALUES.Mowing]: mowing = {}
    } = distinctByMowerStatus ?? {}

    // * The SUM of status 3,5,6
    const totalMowingDuration =
      (mowing?.totalDuration || 0) +
      (mowingSurePathReady?.totalDuration || 0) +
      (mowingSurePathEnabled?.totalDuration || 0)

    // * 0 divided by anything is not possible
    if (
      !mowingSurePathEnabled?.totalDuration ||
      mowingSurePathEnabled?.totalDuration === 0 ||
      totalMowingDuration === 0
    )
      return 0

    // * Mowing total duration
    const total = mowingSurePathEnabled?.totalDuration / totalMowingDuration

    const percentage = Number(total * 100)

    return Math.round(percentage)
  }, [distinctByMowerStatus, isAscent, device])

  const handleTabChange = (item: TabItem, index: number) => {
    onMowerActivityTabChange?.(item.id as TMowerActivityTab)

    if (item.id === '24_hrs') {
      handlerUnitSelection(EUnitOfTime.DAY)
    } else if (item.id === '7_days') {
      handlerUnitSelection(EUnitOfTime.WEEK)
    } else if (item.id === '30_days') {
      handlerUnitSelection(EUnitOfTime.MONTH)
    }
  }

  return (
    <div className='dark:text-white flex flex-col gap-3'>
      <div className='text-center'>
        <Text.Feature.Medium>
          <Trans>Mower Activity</Trans>
        </Text.Feature.Medium>
      </div>
      <TabList items={tabItems} onChange={handleTabChange} />

      {loading || isFetchingTelemetry ? (
        <div className='flex items-center justify-center'>
          <Text.VariousRegular.Medium className='dark:text-white'>
            Loading...
          </Text.VariousRegular.Medium>
        </div>
      ) : (
        <div className='flex flex-row justify-around items-center mt-3 text-center'>
          <CircularDial {...graphDetails} />
          {mowerStatusSelection}
        </div>
      )}

      {isAscent ? (
        <></>
      ) : (
        <div className='flex flex-col gap-1'>
          <div className='flex gap-1'>
            <Text.Body.Medium>
              {mower.inventory?.deviceType !== 50
                ? `${t`Energy output`} (5 ${t`batteries`}): `
                : `Surepath: `}
            </Text.Body.Medium>
            <Text.Body.MediumSemiBold>
              {energyOutputPercentage}%
            </Text.Body.MediumSemiBold>
          </div>
          <ProgressBar
            value={energyOutputPercentage / 100}
            progressBarColor={
              source !== 'surepath' ? ACTIVE_GAUGE_COLOR : SUREPATH_GAUGE_COLOR
            }
          />
        </div>
      )}
    </div>
  )
}

export default Analytics
