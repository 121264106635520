import moment from 'moment'

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSSSSSSS'
interface DateRange {
  startDate: string
  endDate: string
}

const isDateInRangeFromMonth = (currentMonth: Date, date: Date) =>
  moment(date).isBetween(
    moment(currentMonth).startOf('month'),
    moment(currentMonth).endOf('month'),
    'date',
    '[]'
  )

const isDateBetweenTwoDates = (date: Date, dates: [Date, Date]) => {
  return moment(date).isBetween(moment(dates[0]), moment(dates[1]))
}

const divideDateRange = (
  startDate: string,
  numDays: number,
  numSegments: number,
  segments: DateRange[] = []
): DateRange[] | null => {
  // Check if the range is at least numDays
  if (numDays < 1 || numSegments < 1) {
    console.error('The number of days and segments must be greater than 0.')
    return null
  }

  // Calculate the interval between each segment
  const segmentInterval = Math.floor(numDays / numSegments)

  // Calculate the start date of the segment
  const segmentStartDate = moment(startDate)
    .clone()
    .add(segments.length * segmentInterval, 'days')

  // Calculate the end date of the segment
  let segmentEndDate
  if (segments.length === numSegments - 1) {
    // If it's the last segment, adjust the end date to match the overall end date
    segmentEndDate = moment(startDate)
      .clone()
      .add(numDays - 1, 'days')
  } else {
    segmentEndDate = segmentStartDate.clone().add(segmentInterval - 1, 'days')
  }

  // Add the segment to the array
  segments.push({
    startDate: segmentStartDate.startOf('day').format(DATE_FORMAT),
    endDate: segmentEndDate.endOf('day').format(DATE_FORMAT)
  })

  // If all segments have been created, return the segments array
  if (segments.length === numSegments) {
    return segments
  }

  // Otherwise, recursively call the function for the next segment
  return divideDateRange(startDate, numDays, numSegments, segments)
}

export { isDateInRangeFromMonth, isDateBetweenTwoDates, divideDateRange }
