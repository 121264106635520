import { ReactComponent as DropdownIcon } from "../../../assets/icons/dropdown.svg"
import cn from 'classnames'
import { useOperatingCompany } from './hook'
import DropdownMenu, { IDropdownMenuItem } from '../../atoms/DropdownMenu'
import { useCompanies } from '../../../hooks'
import { 
  useAppDispatch, 
  useAppSelector } from '../../../store/hooks'
import { 
  selectOperatingCompany, 
  setCurrentOperatingCompany } from '../../../store/slices/operatingCompany'
import { selectAllCompaniesForDropdown } from "../../../store/slices/organizationCompanySlice"


const OperatingCompany = () =>{
  const {
    isActive,
    setIsActive } = useOperatingCompany()
  const dropdownCompanies = useAppSelector(state => selectAllCompaniesForDropdown(state,{ includeGlobal: true }))
  const { loading: isCompaniesLoading } = useCompanies()
  const dispatch = useAppDispatch()
  const operatingCompany = useAppSelector(selectOperatingCompany)

  const handleItemSelection = (item: IDropdownMenuItem) =>{
    if ( item.id==="Global" ) {
      
      return dispatch(setCurrentOperatingCompany({ name: "Global", companyId: "Global"}))
    }

    return dispatch(setCurrentOperatingCompany({ name: item.label, companyId: item.id }))
  }

  if ( isCompaniesLoading ) {
    
    return (<div className="border-[2px] border-[#a8a8a8] border-t-white rounded-full w-4 h-4 mr-1 animate-spin"></div>)
  }

  if ( !dropdownCompanies?.length ) {

    return <></>
  }

  return (
    <div className='h-[24px]'>
      <DropdownMenu
        size="fit"
        items={dropdownCompanies}
        menuButton={
          <div className='flex items-center'>
            <p className='text-white absolute text-sm min-w-max -left-2 -translate-x-full'>{ operatingCompany.name }</p>
            <DropdownIcon
              id="profile-menu-icon"
              className={cn('text-black-500', {
                'text-primary': !isActive
              })}
            />
          </div>
        }
        menuButtonClassName='w-4 h-4'
        onMenuStateChange={setIsActive}
        onItemClick={handleItemSelection}>
      </DropdownMenu>
    </div>
  )
}


export default OperatingCompany