import { IUserAccount } from '..'
import { IUserEntity } from '../entity'

export const mapUserEntityToUser = (
  userEntity: IUserEntity
): Partial<IUserAccount> => {
  const {
    id,
    firstName,
    lastName,
    companies,
    email,
    phone,
    role,
    status,
    createdAt,
    lastLogin,
    updatedAt
  } = userEntity ?? {}

  // Since data is only in seconds, convert to milliseconds
  const resolvedLastLogin = !lastLogin ? null : Number(lastLogin) * 1000
  const resolvedCreatedAt = !createdAt ? null : Number(createdAt) * 1000
  const resolvedUpdatedAt = !updatedAt ? null : Number(updatedAt) * 1000

  return {
    id,
    first_name: firstName,
    last_name: lastName,
    companies,
    email,
    phone_number: phone,
    status,
    role,
    last_login: resolvedLastLogin,
    created_date: resolvedCreatedAt,
    updated_date: resolvedUpdatedAt,
    // TODO: Admin may reinvite the user, so maybe we should have another field for invitation date, since created_date should not be modified
    invitation_timestamp: resolvedCreatedAt
  }
}
