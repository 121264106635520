import React from 'react'
import { SettingsTemplate } from '../../templates'
import { CompanySubNavigationBar } from '../CompanySubNavigationBar'
import { Text, Image } from '../../atoms';
import packageJson from '../../../../package.json';
import { ASSETS } from '../../../assets';
import { CompanyAboutLeftbar } from '../CompanyAboutLeftbar';

export const CompanyAbout = () => {
  return (
    <SettingsTemplate
      subNavigation={(
        <CompanySubNavigationBar />
      )}
      leftContent={<CompanyAboutLeftbar />}
      topRightContent={(
        <div className='flex-1 h-full overflow-x-auto'>
          <div className='flex flex-col items-center justify-center py-7 px-4'>
            <Image
              src={ASSETS.IMAGES.APP.app_logo}
              className='h-[100px] min-h-[100px]'
              alt='Ground Command'
            />
            <Text.VariousBold.Large className='dark:text-white'>
              {`v${packageJson.version}`}
            </Text.VariousBold.Large>
          </div>
        </div>
      )}
    />
  )
}

export default CompanyAbout
