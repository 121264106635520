import { IMetadataInput } from '../gql/metadata_input'

export interface IMetadataInputDto {
  input: IMetadataInput
}

// Preparation for serializing the payload
export class MetadataInputDto {
  #input: IMetadataInput

  constructor(input: IMetadataInput) {
    this.#input = input
  }

  toJSON(): IMetadataInputDto {
    const { __typename, ...input } = this.#input
    return { input }
  }
}
