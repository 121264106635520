import { matchSorter } from 'match-sorter'
import { IDevice } from '../../models_v2/entity/device'

export const useBatterySearchV2 = ({
  items,
  search
}: {
  items: IDevice[]
  search: string
}): IDevice[] => {
  
  if (!search) return items

  const results = matchSorter(items, search.trim(), {
    keys: [
      'inventory.name',
      'inventory.source',
      'productSerial',
      'inventory.factoryModel',
      'inventory.model',
      'inventory.dealer',
      "inventory.groupName"
    ] as (keyof IDevice)[],
    threshold: matchSorter.rankings.CONTAINS
  })
  
  return results
}
