import { IBattery, IMower, IToolTag } from '../../../models'
import { TState, TReducer, EActiveOverlay } from './types'
import { ELanguage } from '../../../models/language'
import { IDeviceFault } from '../../../models/entity'
import { EActiveOverlayPosition, StringKeyObj } from './types/common'

export const initialState: TState = {
  language: localStorage.getItem('language')
    ? (localStorage.getItem('language') as ELanguage)
    : ELanguage.English,

  // Mowers
  mowers: [],
  mowers_by_id: {},
  selected_mowers: [],
  selected_mowers_by_id: {},
  selected_mower_ids: [],
  pre_selected_mower_id: '',
  selected_mower_id: '',

  prev_selected_mowers: [],
  prev_selected_mower_ids: [],
  prev_selected_mower_id: '',

  // Batteries
  batteries: [],
  selected_batteries: [],
  selected_battery_ids: [],
  pre_selected_battery_id: '',
  selected_battery_id: '',

  prev_selected_batteries: [],
  prev_selected_battery_ids: [],
  prev_selected_battery_id: '',

  // Tool Tags
  selected_tool_tags: [],
  selected_tool_tag_ids: [],
  pre_selected_tool_tag_id: '',
  selected_tool_tag_id: '',

  prev_selected_tool_tags: [],
  prev_selected_tool_tag_ids: [],
  prev_selected_tool_tag_id: '',

  // Modals and overlays
  active_overlay: {
    name: EActiveOverlay.NONE,
    position: EActiveOverlayPosition.DEFAULT
  },

  faults_pending_update_collection: {}
}

const mowersById = (mowers: IMower[]) =>
  mowers.reduce((acc: any, mower: IMower) => {
    acc[mower.productSerial as string] = mower
    return acc
  }, {})
type OverwriteMowerProps = {
  originalMower: IMower
  mowerToOverwrite: Partial<IMower>
}

// For subscription updates some mower values will be missing (will come as null)
// we don't want to overwrite the existing mower info in state to null.
const mergeMowers = ({
  originalMower,
  mowerToOverwrite
}: OverwriteMowerProps): IMower => {
  const clone = { ...mowerToOverwrite }
  if (!clone.model_number) {
    clone.model_number = originalMower?.model_number || ''
  }
  if (!clone.factory_model_number) {
    clone.factory_model_number = originalMower?.factory_model_number || ''
  }
  if (!clone.registering_dealer) {
    clone.registering_dealer = originalMower?.registering_dealer || ''
  }
  if (!clone.software_version) {
    clone.software_version = originalMower?.software_version || ''
  }
  if (!clone.network_status) {
    clone.network_status = originalMower?.network_status || ''
  }

  if (!clone.software) {
    clone.software = originalMower?.software
  }

  // This name comes up wrong sometimes, so let's make sure it doesn't change
  clone.name = originalMower?.name || ''

  // clone returns empty faults
  if (!clone?.faults?.length) {
    clone.faults = originalMower?.faults || []
  }
  if (!clone?.activeFaults?.length) {
    clone.activeFaults = originalMower?.activeFaults || []
  }

  // The realtime data doesn't return the latest statusTotals
  clone.statusTotals = originalMower?.statusTotals || {}

  // The realtime data doesn't return the latest gpsMode
  clone.gpsMode = originalMower?.gpsMode

  // The realtime data doesn't return the latest signal data
  if (!clone.rsrp) {
    clone.rsrp = originalMower?.rsrp
  }
  if (!clone.rsrq) {
    clone.rsrq = originalMower?.rsrq
  }
  if (!clone.rssi) {
    clone.rssi = originalMower?.rssi
  }

  clone.factory_model_number = originalMower?.factory_model_number || ''
  clone.model_number = originalMower?.model_number || ''
  clone.registering_dealer = originalMower?.registering_dealer || ''

  return clone as IMower
}

// TODO: Will need to fragment these items to different file. As the app gets larger.
export const reducer: TReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      localStorage.setItem('language', action.payload)

      return {
        ...state,
        language: action.payload
      }
    }

    // Mowers
    case 'SET_MOWERS': {
      return {
        ...state,
        mowers: action.payload,
        selected_mowers: action.payload,
        mowers_by_id: mowersById(action.payload),
        selected_mowers_by_id: mowersById(action.payload)
      }
    }
    case 'SET_MOWER': {
      const mergedMower: IMower = mergeMowers({
        originalMower: state?.mowers_by_id?.[action.payload.id] as IMower,
        mowerToOverwrite: action.payload
      })

      // TODO: Restructure whole state management
      // Wrong data structure of the devices (mowers, batteries, tool tags) as these has no single source of truth

      // Update the source of mowers
      const cloned_mowers = [...(state?.mowers ?? [])]
      const matched_mower_index =
        state.mowers?.findIndex(item => item.id === mergedMower.id) ?? -1

      if (matched_mower_index >= 0) {
        cloned_mowers[matched_mower_index] = mergedMower
      }

      const matched_selected_mower_index =
        state.selected_mowers?.findIndex(item => item.id === mergedMower.id) ??
        -1

      if (matched_selected_mower_index === -1) {
        return {
          ...state,
          mowers: cloned_mowers,
          mowers_by_id: {
            ...state.mowers_by_id,
            [action.payload.id]: action.payload
          }
        }
      }

      // Update the source of selected mowers
      const cloned_selected_mowers = [...(state?.selected_mowers ?? [])]
      cloned_selected_mowers[matched_selected_mower_index] = mergedMower

      return {
        ...state,
        mowers: cloned_mowers,
        mowers_by_id: {
          ...state.mowers_by_id,
          [action.payload.id]: mergedMower
        },
        selected_mowers: cloned_selected_mowers,
        selected_mowers_by_id: {
          ...state.selected_mowers_by_id,
          [action.payload.id]: mergedMower
        }
      }
    }
    case 'SET_SELECTED_MOWERS': {
      return {
        ...state,
        selected_mowers: action.payload,
        selected_mowers_by_id: mowersById(action.payload)
      }
    }
    case 'SET_SELECTED_MOWER_IDS': {
      return {
        ...state,
        selected_mower_ids: action.payload
      }
    }
    case 'SELECT_MOWER': {
      const { id = '', data = {} } = action?.payload ?? {}
      const {
        selected_mowers = [],
        selected_mower_ids = [],
        selected_mower_id = ''
      } = state ?? {}
      const exist = selected_mowers?.some(item => item?.id === id)

      const prev_mower_state = {
        prev_selected_mowers: selected_mowers,
        prev_selected_mower_ids: selected_mower_ids,
        prev_selected_mower_id: selected_mower_id
      }

      if (!exist) {
        return {
          ...state,
          ...prev_mower_state,
          selected_mowers: [data, ...selected_mowers] as IMower[],
          selected_mower_id: id,
          // selected_mower_ids: [...new Set([...selected_mower_ids, id])]
          selected_mower_ids: [id]
        }
      }

      return {
        ...state,
        ...prev_mower_state,
        selected_mower_id: id,
        // selected_mower_ids: [...new Set([...selected_mower_ids, id])]
        selected_mower_ids: [id]
      }
    }
    case 'UPSERT_MOWER': {
      const cloned_mowers = [...(state?.mowers ?? [])]
      let matched_mower_index = cloned_mowers.findIndex(
        item => item.id === action.payload?.id
      )

      if (matched_mower_index === -1) {
        cloned_mowers.push(action.payload as IMower)
        return {
          ...state,
          mowers: cloned_mowers,
          mowers_by_id: mowersById(cloned_mowers)
        }
      }

      cloned_mowers[matched_mower_index] = {
        ...cloned_mowers[matched_mower_index],
        ...action.payload
      }

      return {
        ...state,
        mowers: cloned_mowers,
        mowers_by_id: mowersById(cloned_mowers)
      }
    }

    // Batteries
    case 'SET_BATTERIES': {
      return {
        ...state,
        batteries: action.payload,
        selected_batteries: action.payload
      }
    }
    case 'SET_SELECTED_BATTERIES': {
      return {
        ...state,
        selected_batteries: action.payload
      }
    }
    case 'SET_SELECTED_BATTERY_IDS': {
      return {
        ...state,
        selected_battery_ids: action.payload
      }
    }
    case 'SET_SELECTED_BATTERY_ID': {
      return {
        ...state,
        selected_battery_id: action.payload
      }
    }
    case 'SELECT_BATTERY': {
      const { id = '', data = {} } = action?.payload ?? {}
      const {
        selected_batteries = [],
        selected_battery_ids = [],
        selected_battery_id = ''
      } = state ?? {}
      const exist = selected_batteries?.some(item => item?.id === id)

      const prev_battery_state = {
        prev_selected_batteries: selected_batteries,
        prev_selected_battery_ids: selected_battery_ids,
        prev_selected_battery_id: selected_battery_id
      }

      if (!exist) {
        return {
          ...state,
          ...prev_battery_state,
          selected_batteries: [data, ...selected_batteries] as IBattery[],
          selected_battery_id: id,
          // selected_battery_ids: [...new Set([...selected_battery_ids, id])]
          selected_battery_ids: [id]
        }
      }

      return {
        ...state,
        ...prev_battery_state,
        selected_battery_id: id,
        // selected_battery_ids: [...new Set([...selected_battery_ids, id])]
        selected_battery_ids: [id]
      }
    }
    case 'UPSERT_BATTERY': {
      const cloned_batteries = [...(state?.batteries ?? [])]
      let matched_battery_index = cloned_batteries.findIndex(
        item => item.id === action.payload?.id
      )
      if (matched_battery_index === -1) {
        cloned_batteries.push(action.payload as IBattery)
        return {
          ...state,
          batteries: cloned_batteries
        }
      }

      cloned_batteries[matched_battery_index] = {
        ...cloned_batteries[matched_battery_index],
        ...action.payload
      }

      return {
        ...state,
        batteries: cloned_batteries
      }
    }

    // Tool Tags
    case 'SET_TOOL_TAGS': {
      return {
        ...state,
        tool_tags: action.payload,
        selected_tool_tags: action.payload
      }
    }
    case 'SET_SELECTED_TOOL_TAG_ID': {
      return {
        ...state,
        selected_tool_tag_id: action.payload
      }
    }
    case 'SET_SELECTED_TOOL_TAGS': {
      return {
        ...state,
        selected_tool_tags: action.payload
      }
    }
    case 'SET_SELECTED_TOOL_TAG_IDS': {
      return {
        ...state,
        selected_tool_tag_ids: action.payload
      }
    }
    case 'SELECT_TOOL_TAG': {
      const { id = '', data = {} } = action?.payload ?? {}
      const {
        selected_tool_tags = [],
        selected_tool_tag_ids = [],
        selected_tool_tag_id = ''
      } = state ?? {}
      const exist = selected_tool_tags?.some(item => item?.id === id)

      const prev_tool_tag_state = {
        prev_selected_tool_tags: selected_tool_tags,
        prev_selected_tool_tag_ids: selected_tool_tag_ids,
        prev_selected_tool_tag_id: selected_tool_tag_id
      }

      if (!exist) {
        return {
          ...state,
          ...prev_tool_tag_state,
          selected_tool_tags: [data, ...selected_tool_tags] as IToolTag[],
          selected_tool_tag_id: id,
          // selected_tool_tag_ids: [...new Set([...selected_tool_tag_ids, id])]
          selected_tool_tag_ids: [id]
        }
      }

      return {
        ...state,
        ...prev_tool_tag_state,
        selected_tool_tag_id: id,
        // selected_tool_tag_ids: [...new Set([...selected_tool_tag_ids, id])]
        selected_tool_tag_ids: [id]
      }
    }
    case 'UPSERT_TOOL_TAG': {
      const cloned_tool_tags = [...(state?.tool_tags ?? [])]
      let matched_tool_tag_index = cloned_tool_tags.findIndex(
        item => item.id === action.payload?.id
      )
      if (matched_tool_tag_index === -1) {
        cloned_tool_tags.push(action.payload as IToolTag)
        return {
          ...state,
          tool_tags: cloned_tool_tags
        }
      }

      cloned_tool_tags[matched_tool_tag_index] = {
        ...cloned_tool_tags[matched_tool_tag_index],
        ...action.payload
      }

      return {
        ...state,
        tool_tags: cloned_tool_tags
      }
    }

    case 'RESET_TRACKING': {
      const filtered_selected_mowers = state.mowers?.filter(
        item => item.coordinates
      )
      const filtered_selected_batteries = state.batteries?.filter(
        item => item.coordinates
      )
      const filtered_selected_tool_tags = state.tool_tags?.filter(
        item => item.coordinates
      )

      return {
        ...state,
        // Mowers
        mowers_by_id: mowersById(filtered_selected_mowers ?? []),
        selected_mowers: filtered_selected_mowers ?? [],
        selected_mowers_by_id: mowersById(filtered_selected_mowers ?? []),
        selected_mower_ids: [],
        pre_selected_mower_id: '',
        selected_mower_id: '',

        prev_selected_mowers: [],
        prev_selected_mower_ids: [],
        prev_selected_mower_id: '',

        // Batteries
        selected_batteries: filtered_selected_batteries ?? [],
        selected_battery_ids: [],
        pre_selected_battery_id: '',
        selected_battery_id: '',

        prev_selected_batteries: [],
        prev_selected_battery_ids: [],
        prev_selected_battery_id: '',

        // Tool Tags
        selected_tool_tags: filtered_selected_tool_tags ?? [],
        selected_tool_tag_ids: [],
        pre_selected_tool_tag_id: '',
        selected_tool_tag_id: '',

        prev_selected_tool_tags: [],
        prev_selected_tool_tag_ids: [],
        prev_selected_tool_tag_id: ''
      }
    }
    case 'SET_PREV_STATE': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'SET_ACTIVE_OVERLAY': {
      return {
        ...state,
        active_overlay: action.payload
      }
    }
    case 'SET_SELECTED_MOWER_ID': {
      return {
        ...state,
        selected_mower_id: action.payload
      }
    }

    case 'MOWER_FAULT_UPDATE': {
      const { mowerId, overriden_faults } = action.payload

      // Disregard this action if the incoming faultId exist on the `faults_pending_update_collection`
      if (
        !overriden_faults &&
        state.faults_pending_update_collection![action.payload.fault.faultId]
      ) {
        return state
      }

      const mower = state?.mowers_by_id?.[mowerId]

      // Omit __typename as this was being stored in the state, this would be included on calling UPDATE_FAULT if it was not omitted which will cause an error in mutation
      let { __typename: _, ...faultUpdate } = action.payload.fault as {
        __typename: string
      } & IDeviceFault

      // Note: Incoming faultUpdate.payload from realtime subscription comes with a string value
      faultUpdate.payload =
        typeof faultUpdate.payload === 'string'
          ? JSON.parse(faultUpdate.payload)
          : faultUpdate.payload

      const updatedFaults = [...(overriden_faults ?? mower?.faults ?? [])]
      const matchedIndex = updatedFaults.findIndex(
        item => item.faultId === faultUpdate.faultId
      )

      if (matchedIndex === -1) {
        updatedFaults.push(faultUpdate)
      } else {
        updatedFaults[matchedIndex] = faultUpdate
      }

      const mowersByArrayFaultUpdate = (mowers: IMower[]) => {
        return mowers?.map(mower => {
          if (mower.id === mowerId) {
            return {
              ...mower,
              faults: updatedFaults
            }
          }
          return mower
        })
      }

      const mowersByKeyFaultUpdate = (mowers: StringKeyObj<IMower>) => {
        return {
          ...mowers,
          [mowerId]: {
            ...mower,
            faults: updatedFaults
          }
        }
      }

      return {
        ...state,
        mowers: mowersByArrayFaultUpdate(state?.mowers ?? []),
        mowers_by_id: mowersByKeyFaultUpdate(state?.mowers_by_id ?? {}),
        selected_mowers: mowersByArrayFaultUpdate(state?.selected_mowers ?? []),
        selected_mowers_by_id: mowersByKeyFaultUpdate(
          state?.selected_mowers_by_id ?? {}
        )
      }
    }

    case 'UPSERT_PENDING_FAULT_UPDATE_COLLECTION': {
      const { faultId } = action.payload

      return {
        ...state,
        faults_pending_update_collection: {
          ...state?.faults_pending_update_collection,
          [faultId]: action.payload
        }
      }
    }

    case 'REMOVE_PENDING_FAULT_UPDATE_COLLECTION': {
      const ids =
        typeof action.payload === 'string' ? [action.payload] : action.payload
      const remainingFaultPendingUpdates = Object.entries(
        state.faults_pending_update_collection ?? {}
      ).reduce((acc, [key, value]) => {
        if (ids.includes(key)) return acc

        return {
          ...acc,
          [key]: value
        }
      }, {})

      return {
        ...state,
        faults_pending_update_collection: remainingFaultPendingUpdates
      }
    }

    default: {
      return state
    }
  }
}
