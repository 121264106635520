import {
  useCallback,
  useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDynamicMutation } from '../../../../hooks'
import { mapUserToUserEntity } from '../../../../models/mapper/user_to_user_entity'
import { Routes } from '../../../../routes'
import { Alert, Button } from '../../../atoms'
import { BackToListButton } from '../../../molecules'
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import { 
  addUserAccount, 
  setSelectedItem, 
  setUserProfileForm } from '../../../../store/slices/organizationSettingsSlice'
import { useFormContext } from 'react-hook-form'
import { useRedirectNonAdmin } from '../../../../hooks/useRedirectNonAdmin'
import { IUserFormInput } from './UserProfileForm'

export type ActionsInviteUserProps = {}

export const ActionsInviteUser = () => {
  const reduxDispatch = useAppDispatch()
  const [successMessage, setSuccessMessage] = useState('')
  const allCompanies = useAppSelector(state => state.organizationCompany.companies)
  
  const {
    mutateAsync: addUser,
    isLoading: addUserIsLoading,
    isSuccess: addUserIsSuccess,
    error: addUserError
  } = useDynamicMutation({
    graphqlEntity: 'users',
    queryKey: 'ADD_USER'
  })

  const { handleSubmit } = useFormContext()
  const navigate = useNavigate()
  useRedirectNonAdmin()

  const handleCancel = useCallback(() => {
    reduxDispatch(setUserProfileForm(undefined))
    reduxDispatch(setSelectedItem(undefined))
    navigate(Routes.people)
  }, [])

  const onSubmitClick = () => {
    handleSubmit((data) => inviteUser(data as IUserFormInput))();
  }

  const inviteUser = async (data: IUserFormInput) => {
    setSuccessMessage('')
    const payload = mapUserToUserEntity(data)
    const {
      data: { result }
    }: any = await addUser({ input: payload })
    reduxDispatch(addUserAccount(result))
    setSuccessMessage('Invited user successfully')
  }

  return (
    <div className='h-full overflow-y-auto'>
      <div className='h-full p-[30px]'>
        <div className='flex flex-col h-full'>
          <BackToListButton onClick={handleCancel} />
          <div className='flex-1 flex flex-col gap-3 mt-[78px] mb-3'>
            {addUserError && (
              <Alert variant='error' message='Error in submitting data' />
            )}
            {successMessage && (
              <Alert variant='success' message={successMessage} />
            )}
            {!addUserIsSuccess && (
              <Button
                title={addUserIsLoading ? 'Inviting...' : 'Invite'}
                onClick={onSubmitClick}
                disabled={addUserIsLoading}
              />
            )}
            <Button
              color='secondary'
              title='Cancel'
              className='border border-solid border-white'
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionsInviteUser
