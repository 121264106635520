import React, { useMemo } from 'react'
import { IGroupProps } from './types'
import cn from 'classnames'

const GroupListItem: React.FC<IGroupProps> = props => {
  const { groupName, isActive, onClick, index, assetCount } = props

  return (
    <>
      <div key={index} onClick={() => onClick(index)} className={cn('min-h-[52px] flex items-center bg-black/30 border border-[#4A4A4A] rounded-md dark:text-white px-2 pl-3 cursor-pointer')}>
        <div className={cn(`sc-fsQiph cUWkNR ${isActive ? "w-[3px]" : ""} h-[32px] bg-primary rounded-full mr-5`)}></div>
        <div className={cn('flex w-full justify-between')}>
          <p className={cn('font-normal text-[14px] leading-[20px]')}>
            {groupName}
          </p>
          <p className={cn("font-normal text-[14px] leading-[20px]")}>
            {assetCount} 
            Assets</p>
        </div>
      </div>
    </>
  )
}

export default GroupListItem
