import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import { Marker, OverlayView } from '@react-google-maps/api'
import { ASSETS } from '../../../assets'
import { IMetadata } from '../../../models_v2/entity/metadata'

interface DeviceFaultMarkerProps {
  isFaded?: boolean
  overlayViewStyle?: React.CSSProperties
  isFocused?: boolean
  item: IMetadata
  onClick?: () => void
  markerStyle?: {
    zIndex?: number
    opacity?: number
  }
}

const DeviceFaultMarker: React.FC<DeviceFaultMarkerProps> = props => {
  const {
    overlayViewStyle,
    isFaded,
    isFocused = false,
    item,
    onClick,
    markerStyle
  } = props

  const overlayRef = useRef()

  useEffect(() => {
    const { containerRef } = (overlayRef.current as any) ?? {}
    if (containerRef?.current) {
      containerRef.current.style.zIndex = isFocused ? 200 : undefined
    }
  }, [isFocused])

  const { zIndex, opacity } = markerStyle ?? {}

  const { payload, faultCode, metadataId } = item
  const { location } = payload
  const { latitude, longitude } = location ?? {}

  const faultPosition: google.maps.LatLng | google.maps.LatLngLiteral = {
    lat: latitude as number,
    lng: longitude as number
  }

  return (
    <>
      <OverlayView
        // @ts-ignore
        ref={overlayRef}
        position={faultPosition}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <div
          className='rounded-full bg-black px-2 text-white flex items-center'
          style={overlayViewStyle}>
          <span
            className={cn('mr-1 font-normal', {
              '!font-bold': isFocused
            })}>
            {faultCode}
          </span>
        </div>
      </OverlayView>

      {/* @ts-ignore */}
      <Marker
        position={faultPosition}
        onClick={onClick}
        icon={{
          url: ASSETS.MAPS.mower_fault_map_pin,
          anchor: new google.maps.Point(31, 20)
        }}
        options={{
          //@ts-ignore
          id: metadataId,
          raw: item,
          clickable: true
        }}
        zIndex={zIndex}
        opacity={!isFaded ? opacity : 0.1}
      />
    </>
  )
}

export default DeviceFaultMarker
