import { Mower } from '../models_v2/common/mower'
import { IDevice } from '../models_v2/entity/device'
import { DEVICE_TYPE } from '../models_v2/entity/telemetry'

// Trying to keep things flexible here, these should be able to take
// any device not just mowers, batteries, etc, since we could use them in
// generic device filters, etc
// add more if needed
export type AnyDevice =
  | IDevice
  | Mower
  | Partial<Mower>
  | Partial<IDevice>
  | undefined

export const isMowerDevice = (device: AnyDevice) =>
  device?.inventory?.deviceType === DEVICE_TYPE.SUREPATH_MOWER ||
  device?.inventory?.deviceType === DEVICE_TYPE.ASCENT_MOWER

export const isSurepathDevice = (device: AnyDevice) =>
  device?.inventory?.deviceType === DEVICE_TYPE.SUREPATH_MOWER

export const isAscentDevice = (device: AnyDevice) =>
  device?.inventory?.deviceType === DEVICE_TYPE.ASCENT_MOWER

export const isBatteryDevice = (device: AnyDevice) =>
  device?.inventory?.deviceType === DEVICE_TYPE.BATTERY

// * We can also do this in a ranged type condition, specified each types for a tool tag to be more declarative.
export const isToolsAndTagsDevice = (device: AnyDevice) =>
  [
    DEVICE_TYPE.TOOLS_AND_TAGS_GENERIC,
    DEVICE_TYPE.TOOLS_AND_TAGS_DCE041,
    DEVICE_TYPE.TOOLS_AND_TAGS_DCE042,
    DEVICE_TYPE.TOOLS_AND_TAGS_DCE045,
    DEVICE_TYPE.TOOLS_AND_TAGS_RESERVED_1,
    DEVICE_TYPE.TOOLS_AND_TAGS_RESERVED_2,
    DEVICE_TYPE.TOOLS_AND_TAGS_RESERVED_3,
    DEVICE_TYPE.TOOLS_AND_TAGS_RESERVED_4,
    DEVICE_TYPE.TOOLS_AND_TAGS_RESERVED_5,
    DEVICE_TYPE.TOOLS_AND_TAGS_RESERVED_6,
    DEVICE_TYPE.TOOLS_AND_TAGS_DCPS310,
    DEVICE_TYPE.TOOLS_AND_TAGS_DCPS320,
    DEVICE_TYPE.TOOLS_AND_TAGS_DCPS330,
    DEVICE_TYPE.TOOLS_AND_TAGS_DCPS660,
    DEVICE_TYPE.TOOLS_AND_TAGS_DCPS7154,
    DEVICE_TYPE.TOOLS_AND_TAGS_DCG418HG
  ].some(device_type => device_type === device?.inventory?.deviceType)

export const hasLocation = (device: AnyDevice) =>
  device?.latestTelemetry?.location &&
  device?.latestTelemetry?.location !== 'null' &&
  JSON.parse(device?.latestTelemetry?.location)
