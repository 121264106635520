export const isObject = (obj: Record<string, unknown>) => 
  typeof obj === "object" &&
  !Array.isArray(obj) &&
  obj !== null 


// Merge two objects into one. newItem props will always take priority.
export const mergeObjects = (originalItem: Record<string, unknown>, newItem:  Record<string, unknown>) => {
  const mergedItem = { ...originalItem };

  for (const key in newItem) {
    if (Object.prototype.hasOwnProperty.call(newItem, key)) {
      if (
        isObject(newItem[key] as Record<string, unknown>) && 
        isObject(mergedItem[key] as Record<string, unknown>)
      ) {
        mergedItem[key] = mergeObjects(
          mergedItem[key] as Record<string,unknown>, 
          newItem[key] as Record<string,unknown>
        );
      } else {
        mergedItem[key] = newItem[key];
      }
    }
  }

  return mergedItem;
}