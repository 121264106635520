import moment from 'moment'
import { IDevice } from '../entity/device'
import { IMetadata } from '../entity/metadata'
import { IInventory } from '../entity/inventory'
import { ITelemetry } from '../entity/telemetry'
import { IGroup } from '../entity/groups'

export class Group implements IGroup {
  companyId!: string
  groupId!: string
  groupName!: string
  devicesSerialNumber!: string[]
  assetCount?: number | undefined
  assets?: IDevice[] | undefined
  notifications?: number | undefined
  productSerial?: string | undefined
  showCollapsebleData?: boolean | undefined
  
  constructor(data: IGroup) {
    Object.assign(this, data)
  }
}
