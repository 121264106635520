import React from 'react'
import { Text } from '../../atoms'
import cn from 'classnames'

interface StatusProps {
  className?: string
  value: string | number
}

const Status: React.FC<StatusProps> = props => {
  const { className, value } = props

  return (
    <div className={cn('flex justify-start gap-2', className)}>
      <Text.VariousRegular.Small>Status: </Text.VariousRegular.Small>
      <Text.VariousBold.Small>{value}</Text.VariousBold.Small>
    </div>
  )
}

export default Status
