import { IGroup } from '../../models_v2/entity/groups'
import { useApolloMutation } from '../useApolloMutation'


export const useGroupUpdate = () => {

  return useApolloMutation<IGroup, any>({
    query: 'UPDATE_GROUP',
    options: {
      onCompleted: (data: any) => {
      }
    }
  })
}
