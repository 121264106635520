import {
  useEffect,
  useState } from 'react'
import { 
  useForm, 
  SubmitHandler } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { useInventoryUpdate } from '../../../hooks'
import { 
  TextField, 
  Button, 
  Text,
  RadioButton, 
  Alert } from '../../atoms'
import { IDevice } from '../../../models_v2/entity/device'
import { IInventoryInput } from '../../../models_v2/gql/inventory_input'
import { DeviceInputDto } from '../../../models_v2/dto/inventory_input'
import { IInventory } from '../../../models_v2/entity/inventory'
import { useAppDispatch } from '../../../store/hooks'
import { updateDevice } from '../../../store/slices/deviceSliceV2'
import { omit } from 'lodash'

interface IFormInput extends IInventoryInput {}

type GarageGlobalFleetBatteryFormProps = {
  device?: IDevice,
  onClose?: VoidFunction
}

const GarageGlobalFleetBatteryForm = ({ device, onClose }: GarageGlobalFleetBatteryFormProps) => {
  const { register, watch, handleSubmit, reset, setValue } =useForm<IFormInput>()
  // TODO: UX issue, no way to unset the selected device unless component unmount
  const [currentDeviceToEdit, setCurrentDeviceToEdit] = useState<IDevice>()
  const [mutateInventory, { loading: isLoading }] = useInventoryUpdate()
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const dispatch = useAppDispatch()

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    const { name, factoryModel, model } = data ?? {}
    setSuccessMessage('')
    setErrorMessage('')

    try {
      if (!currentDeviceToEdit) {
        const input = new DeviceInputDto({
          productSerial: uuid(),
          // TODO: What is the default source if the form is doing an insert
          source: 'ngbr',
          name,
          factoryModel,
          model
        })

        await mutateInventory({
          variables: input.toJSON()
        })

        reset()
        setSuccessMessage('Battery Added Successfully!')
      } else {
        const { groupName, source, ...restInventoryInput } = currentDeviceToEdit.inventory
        const input = new DeviceInputDto({
          ...omit(restInventoryInput as unknown as IInventory, 'companyName'),
          productSerial: currentDeviceToEdit.productSerial,
          name,
          factoryModel,
          model,
        })

        const response = await mutateInventory({
          variables: input.toJSON()
        })

        if ( response.data ) {
          dispatch(updateDevice({
            device: response.data.result,
          }))
        }

        setSuccessMessage('Battery Updated Successfully!')
      }
    } catch {
      setErrorMessage('Error submitting data.')
    }
  }

  useEffect(() =>{
    if ( device ) {
      setCurrentDeviceToEdit(device)
      setValue('name', device?.inventory?.name || '')
      setValue('factoryModel', device?.inventory?.factoryModel || '')
      setValue('model', device?.inventory?.model || '')
    }
  }, [device])
  
  return (
    <div className='h-full overflow-y-auto'>
      <form className='flex flex-col h-full' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex-1 flex flex-col gap-3 mb-3'>
          {errorMessage && <Alert variant='error' message={errorMessage} />}
          {successMessage && (
            <Alert variant='success' message={successMessage} />
          )}

          <div className='flex flex-col gap-2'>
            <Text.Body.MediumSemiBold>Name</Text.Body.MediumSemiBold>
            <TextField
              {...register('name', { required: true })}
              className='min-h-[50px] focus:outline-none focus:border-solid focus:border-white !text-primary'
              type={'text'}
              disabled={isLoading}
            />
          </div>
          <div className='flex flex-col gap-2'>
            <Text.Body.MediumSemiBold>Factory Model #</Text.Body.MediumSemiBold>
            <TextField
              {...register('factoryModel')}
              className='min-h-[50px] focus:outline-none focus:border-solid focus:border-white !text-primary'
              type={'text'}
            />
          </div>
          <div className='flex flex-col gap-2'>
            <Text.Body.MediumSemiBold>Model</Text.Body.MediumSemiBold>
            <TextField
              {...register('model')}
              className='min-h-[50px] focus:outline-none focus:border-solid focus:border-white !text-primary'
              type={'text'}
              disabled={isLoading}
            />
          </div>

          <div className='flex flex-col gap-2'>
            <Text.Body.MediumSemiBold>Subscription</Text.Body.MediumSemiBold>
            <RadioButton
              id='subscription_yes'
              name='subscription_battery'
              value="yes"
              label='Yes'
              disabled={isLoading}
            />
            <RadioButton
              id='subscription_no'
              name='subscription_battery'
              value="no"
              label='No'
              disabled={isLoading}
            />
          </div>
        </div>

        <Button
          className='w-full'
          title={isLoading ? 'Submitting...' : 'Done'}
          disabled={!watch("name")?.trim().length || isLoading}
          type='submit'
        />
        <Button
          className='w-full mt-2 bg-primary'
          title={"Close"}
          type='button'
          onClick={ onClose }
        />
      </form>
    </div>
  )
}

export default GarageGlobalFleetBatteryForm
