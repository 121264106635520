import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../store';

export type Reports = {
  reportsInProgressCount: number,
  reportName: string,
}
type InitialState = Reports

const initialState: InitialState = {
  reportsInProgressCount: 0,
  reportName: '',
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    addReportInProgress: (state) => {
      state.reportsInProgressCount += 1;
    },
    removeReportInProgress: (state) => {
      state.reportsInProgressCount -= 1;
    },
    updateReportName: (state, action: PayloadAction<string>) => {
      state.reportName = action.payload;
    },
    resetReportsState: () => initialState
  },
});

export const { addReportInProgress, removeReportInProgress, updateReportName } = reportsSlice.actions
export const selectAreReportsDownloadsInProgress = (state: RootState) => {
  return state.reports.reportsInProgressCount > 0
}

export const reportsReducer = reportsSlice.reducer;