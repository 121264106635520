import React, { useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Text } from '../../atoms'
import { LoginCardContainer } from '../../molecules'
import { TextField } from '@mui/material'
import { Routes } from '../../../routes'
import { useForm, SubmitHandler } from 'react-hook-form'
import { AppContext } from '../../../App'

interface LoginFormInput {
  password: string
}

const LoginIncorrectPassword: React.FC = () => {
  const { handleLogin } = useContext(AppContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm<LoginFormInput>()

  useEffect(() => {
    if (!state || !state?.credentials) {
      navigate(Routes['login_page'])
      return
    }
  }, [])

  const onSubmit: SubmitHandler<LoginFormInput> = async credentials => {
    try {
      if (!handleLogin) return
      const { username = '' } = state?.credentials ?? {}
      const updatedCredentials = {
        ...credentials,
        username
      }

      const data = await handleLogin(updatedCredentials)
      const { success } = data ?? {}

      if (!success) {
        navigate(Routes['login_page.failed'], {
          state: {
            credentials: updatedCredentials
          }
        })
        return
      }

      navigate(Routes['tracking_page.mowers'])
    } catch (e) {
      console.error(e)
    }
  }

  const handleNavigateForgotPassword = () => {
    navigate(Routes['login_page.forgot-password'])
  }

  return (
    <LoginCardContainer className='sm:w-[476px]'>
      <div className='flex flex-col gap-7'>
        <div className='flex flex-col gap-2'>
          <Text.Headline.H3 className='mt-[109px]'>
            SOMETHINGS NOT RIGHT.
          </Text.Headline.H3>
          <Text.Body.Small>
            The password you entered is not valid. Please try again or retrieve
            password using the link below.
          </Text.Body.Small>
        </div>

        <div className='flex flex-col gap-7'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register('password', { required: true })}
              error
              id='outlined-error-helper-text'
              autoFocus
              className='z-10 text-error'
              label='Password'
              type={'password'}
              defaultValue=''
              helperText='Retry'
              style={{
                width: '100%',
                height: '41px'
              }}
              inputProps={{ style: { color: 'white' } }}
            />
          </form>
          <Text.Body.Small>
            <Text.TextLink.SmallUnderlined
              onClick={handleNavigateForgotPassword}>
              Forgot Password
            </Text.TextLink.SmallUnderlined>{' '}
            or get more information at our{' '}
            <Text.TextLink.SmallUnderlined>
              help center
            </Text.TextLink.SmallUnderlined>
            .
          </Text.Body.Small>
        </div>
      </div>
    </LoginCardContainer>
  )

  // return (
  //   <div className='w-[476px] h-[664px] min-w-[438px] py-[80px] px-8 login-panel border-solid border-[1px] border-black-800'>
  //     <AppLogo />
  //     <div className='flex flex-col gap-7'>
  //       <div className='flex flex-col gap-2'>
  //         <Text.Headline.H1 className='mt-[109px]'>
  //           SOMETHINGS NOT RIGHT.
  //         </Text.Headline.H1>
  //         <Text.Body.Small>
  //           The password you entered is not valid. Please try again or retrieve
  //           password using the link below.
  //         </Text.Body.Small>
  //       </div>

  //       <div className='flex flex-col gap-7'>
  //         <form onSubmit={handleSubmit(onSubmit)}>
  //           <TextField
  //             {...register('password', { required: true })}
  //             error
  //             id='outlined-error-helper-text'
  //             autoFocus
  //             className='z-10 text-error'
  //             label='Password'
  //             type={'password'}
  //             defaultValue=''
  //             helperText='Retry'
  //             style={{
  //               width: '100%',
  //               height: '41px'
  //             }}
  //             inputProps={{ style: { color: 'white' } }}
  //           />
  //         </form>
  //         <Text.Body.Small>
  //           <Text.TextLink.SmallUnderlined>
  //             Forgot Password
  //           </Text.TextLink.SmallUnderlined>{' '}
  //           or more get information at our{' '}
  //           <Text.TextLink.SmallUnderlined>
  //             help center
  //           </Text.TextLink.SmallUnderlined>
  //           .
  //         </Text.Body.Small>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default LoginIncorrectPassword
