import { 
  useCallback, 
  useEffect, 
  useState } from 'react'
import { BackToListButton } from '../../../../molecules'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../../routes'
import { 
  Alert, 
  Button } from '../../../../atoms'
import DeleteOrganizationCompanyModal from '../SettingsOrganizationMain/components/DeleteOrganizationCompanyModal'
import { 
  useAppDispatch, 
  useAppSelector } from '../../../../../store/hooks'
import { 
  removeSelectedCompanyId, 
  selectSelectedCompany, 
  editCompany as editCompanyReducer,
  addCompany as addCompanyReducer } from '../../../../../store/slices/organizationCompanySlice'
import { useFormContext } from 'react-hook-form'
import { CompanyInput } from '../SettingsOrganizationMain/components'
import { useDynamicMutation } from '../../../../../hooks'


interface SettingsOrganizationModifyCompanySidebarProps {
  mode: 'create' | 'edit'
}

const SettingsOrganizationModifyCompanySidebar = ( props: SettingsOrganizationModifyCompanySidebarProps ) => {
  const { mode } = props
  const selectedCompany = useAppSelector(selectSelectedCompany)
  const { companies } = useAppSelector(state => state.organizationCompany)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showDeleteCompanyModal, setShowDeleteCompanyModal] = useState(false)
  const [companySuccessMessage, setCompanySuccessMessage] = useState('')
  const [companyErrorMessage, setCompanyErrorMessage] = useState("")
  const handleDeleteCompany = () => setShowDeleteCompanyModal(true)
  const { handleSubmit,reset } = useFormContext()
  const { mutateAsync: mutateCompany } = useDynamicMutation({
    graphqlEntity: "companies",
    queryKey: "UPDATE_COMPANY"
  })
  const handleCancel = useCallback(() => {
    navigate(Routes.companies)
    dispatch(removeSelectedCompanyId())
  }, [])
  
  const onSubmitClick = () => {
    handleSubmit(( companyData ) => mode==="create"? createCompany(companyData) : updateCompany(companyData))()
  }

  const checkCompanyDuplication = ( companyData: CompanyInput ) => {
    const companiesArray = Object.values(companies)
    // @ts-ignore
    const foundCompany = companiesArray.find(company => company.inventory.name===companyData.name)

    return !!(foundCompany && (mode==="create" || mode==="edit" && foundCompany?.companyId !== companyData.companyId ))
  }

  const createCompany = async (companyData: CompanyInput) => {
    if ( checkCompanyDuplication(companyData) ) {

      return setCompanyErrorMessage("Company name already exist")
    }

    const { name } = companyData
    const { data: { result } }: any = await mutateCompany({input: { name }})
    setCompanySuccessMessage("")
    dispatch(addCompanyReducer({
      companyId: result.companyId,
      name: name as string
    }))
    setCompanySuccessMessage("Successfully created company")
    reset({ name: "" })
  }

  const updateCompany = async(companyData: CompanyInput) =>{
    if ( checkCompanyDuplication(companyData) ) {

      return setCompanyErrorMessage("Company name already exist")
    } 

    const { name, companyId } = companyData
    setCompanySuccessMessage("")
    await mutateCompany({ input: {
      name,
      companyId
    } })
    dispatch(editCompanyReducer({
      companyId: companyData.companyId,
      name: name as string
    }))
    setCompanySuccessMessage("Successfully edited company")
  }

  useEffect(() =>{
    if ( !companySuccessMessage && !companyErrorMessage ) return

    const companyMessageTimeout = setTimeout(() => {
      setCompanySuccessMessage("")
      setCompanyErrorMessage("")
    }, 2000)

    return () => clearTimeout(companyMessageTimeout)
  }, [companySuccessMessage, companyErrorMessage])

  useEffect(() => () => {
    dispatch(removeSelectedCompanyId())
    reset({ name: "" })
  }, [])

  return (
    <>
      <DeleteOrganizationCompanyModal
        isOpen={showDeleteCompanyModal}
        onClose={() => setShowDeleteCompanyModal(false)}
        onDeleted={data => {
          setShowDeleteCompanyModal(false)
          handleCancel()
        }}
        data={selectedCompany?? {}}/>
      <div className='flex flex-col h-full'>
        <BackToListButton onClick={handleCancel} />
        <div className='flex-1 flex flex-col gap-3 mt-[78px] mb-3'>
          {companyErrorMessage && (
            <Alert variant='error' message={companyErrorMessage} />
          )}
          {companySuccessMessage && (
            <Alert variant='success' message={companySuccessMessage} />
          )}
          <Button title='Save' onClick={onSubmitClick} />
          <Button
            color='secondary'
            title='Cancel'
            className='border border-solid border-white'
            onClick={handleCancel}
          />
        </div>
        {mode === 'edit' && selectedCompany && (
          <Button
            type='submit'
            className='w-full bg-primary hover:bg-primary active:bg-primary text-black font-bold'
            title='DELETE Company'
            onClick={handleDeleteCompany}/>
        )}
      </div>
    </>
  )
}


export default SettingsOrganizationModifyCompanySidebar
