import React, { memo, useMemo, useState } from 'react'
import { IBattery } from '../../../../models'
import { Icon, Text } from '../../../atoms'
import ApexChart from 'react-apexcharts'
import moment from 'moment'
import { useAppTheme } from '../../../../hooks'
import cn from 'classnames'
import { useBatteryDevice } from '../../../../hooks/data/useDevice'
import { useAppSelector } from '../../../../store/hooks'
import { selectDevice } from '../../../../store/slices/deviceSliceV2/selectors'
import { IDevice } from '../../../../models_v2/entity/device'
import { BATTERY_STATUS } from '../../../../models_v2/common/battery'

interface AnalyticsProps {
  battery: Partial<IBattery>
}

interface IHourlyAverageStateOfCharge {
  telemetries: IDevice['telemetries']
  average: number
  time: number
  status: string
}

interface ISeriesData {
  x: number
  y: number
}

interface ISeriesCategory {
  seriesCharged: ISeriesData[]
  seriesCharging: ISeriesData[]
}

const Analytics: React.FC<AnalyticsProps> = props => {
  const { battery } = props
  const { theme } = useAppTheme()
  const device = useAppSelector(selectDevice)
  const [isYesterday, setIsYesterday] = useState(false)

  const minDate = moment().startOf('day')
  const maxDate = moment(minDate).add(1, 'day')

  const initialStartDate = minDate.toISOString()
  const initialEndDate = maxDate.toISOString()

  const { refetch, loading } = useBatteryDevice({
    productSerial: battery?.productSerial || '',
    startTime: initialStartDate,
    endTime: initialEndDate
  })

  const hourlyGrouping = useMemo(() => {
    const { telemetries = [] } = device ?? {}

    const grouped = telemetries?.reduce((acc, curr) => {
      const { timestamp, payload = '' } = curr ?? {}
      const currentTimestamp = moment(timestamp)
      const hourKey = currentTimestamp
        .startOf('hour')
        .format('YYYY-MM-DD HH:00:00')
      const { [hourKey as string]: hourlyData = [] } = acc ?? {}

      const currentHourlyData = {
        ...curr,
        payload: payload ? JSON.parse(payload as string) : {}
      }

      if (!hourlyData)
        return {
          ...acc,
          [hourKey]: [currentHourlyData]
        } as Record<string, IDevice[]>

      return {
        ...acc,
        [hourKey]: [...hourlyData, currentHourlyData]
      } as Record<string, IDevice[]>
    }, {} as Record<string, IDevice[]>)

    return grouped
  }, [device])

  const averageStateOfCharge = useMemo(() => {
    const hourlyAverage = Object.entries(hourlyGrouping || {}).reduce(
      (acc, [key, hourlyTelemetries]) => {
        const sumOfStateOfCharge = hourlyTelemetries?.reduce(
          (acc: number, curr) => acc + (+curr?.payload?.StateOfCharge || 0),
          0
        )
        const average = sumOfStateOfCharge / hourlyTelemetries?.length

        return {
          ...acc,
          [key]: {
            telemetries: hourlyTelemetries,
            average,
            time: moment(key).valueOf(),
            status: hourlyTelemetries[0].payload?.CurrentState
          }
        } as Record<string, IHourlyAverageStateOfCharge>
      },
      {} as Record<string, IHourlyAverageStateOfCharge>
    )

    return hourlyAverage
  }, [hourlyGrouping])

  const { seriesCharged = [], seriesCharging = [] } = useMemo(() => {
    const seriesCategory = Object.values(averageStateOfCharge).reduce(
      (acc, curr) => {
        const { seriesCharged = [], seriesCharging = [] } = acc ?? {}
        const { status, time, average } = curr ?? {}
        const seriesPayload = {
          x: time,
          y: average
        }

        if (+status === BATTERY_STATUS.CHARGING) {
          return {
            ...acc,
            seriesCharging: [...seriesCharging, seriesPayload]
          } as ISeriesCategory
        }

        return {
          ...acc,
          seriesCharged: [...seriesCharged, seriesPayload]
        } as ISeriesCategory
      },
      {
        seriesCharged: [],
        seriesCharging: []
      } as ISeriesCategory
    )

    return seriesCategory
  }, [averageStateOfCharge])

  const annotations = useMemo(() => {
    if (!seriesCharging.length) return

    const firstChargingSeries = seriesCharging[0].x
    const lastChargingSeries = seriesCharging[seriesCharging?.length - 1].x

    const points = {
      x: firstChargingSeries,
      y: 100,
      marker: {
        shape: 'square',
        size: 36,
        strokeWidth: 0,
        fillColor: 'rgba(255,255,255,0.1)',
        offsetX: 22,
        offsetY: -22,
        radius: 0
      },
      image: {
        path: '/assets/icons/bolt_charging.svg',
        offsetX: 22,
        offsetY: -16
      }
    }

    const xaxis = {
      x: firstChargingSeries,
      x2: lastChargingSeries,
      fillColor: 'white',
      opacity: 0.1
    }

    return {
      points: [points],
      xaxis: [xaxis]
    }
  }, [seriesCharging])

  const handleMoveToYesterday = () => {
    setIsYesterday(true)

    const startDate = moment(minDate)
      .subtract('1', 'day')
      .startOf('day')
      .toISOString()
    const endDate = moment(startDate).add(1, 'day').toISOString()

    refetch({
      productSerial: battery?.productSerial || '',
      startTime: startDate,
      endTime: endDate
    })
  }

  const handleMoveToToday = () => {
    setIsYesterday(false)

    refetch({
      productSerial: battery?.productSerial || '',
      startTime: initialStartDate,
      endTime: initialEndDate
    })
  }

  return (
    <div className='dark:text-white'>
      <div className='mt-2 mb-3 text-center'>
        <Text.VariousBold.Medium>Battery Activity</Text.VariousBold.Medium>
      </div>

      <div className='relative'>
        {!isYesterday && (
          <div className='absolute bottom-[9%] left-[5%] z-50'>
            <Icon
              name='caret_left'
              className='cursor-pointer  invert dark:invert-0'
              onClick={handleMoveToYesterday}
            />
          </div>
        )}

        {/* <div className='flex mt-3 gap-2 relative w-full'> */}

        {loading && (
          <div className='absolute w-full h-full items-center justify-center'>
            <div className='flex items-center justify-center'>
              <Text.VariousRegular.Medium className='dark:text-white'>
                Loading...
              </Text.VariousRegular.Medium>
            </div>
          </div>
        )}

        <div className='mt-3'>
          {/* TODO: missing x-axis vertical lines */}
          <ApexChart
            type='bar'
            options={{
              chart: {
                type: 'bar',
                toolbar: {
                  show: false
                },
                selection: {
                  enabled: false
                },
                zoom: {
                  enabled: false
                }
              },
              tooltip: {
                enabled: false
              },
              plotOptions: {
                bar: {
                  borderRadius: 3,
                  columnWidth: 5
                }
              },
              annotations: {
                points: annotations?.points,
                xaxis: annotations?.xaxis
              },
              colors: ['#fff', '#13F300'],
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              yaxis: {
                show: true,
                tickAmount: 4,
                min: 0,
                max: 100,
                labels: {
                  style: {
                    colors: theme === 'dark' ? '#ffffff' : '#000000'
                  },
                  formatter: value => {
                    if ([100, 75, 50, 25].includes(value)) {
                      return `${value}%`
                    }

                    return ''
                  }
                }
              },
              grid: {
                show: true,
                strokeDashArray: 5,
                yaxis: {
                  lines: {
                    show: true
                  }
                }
              },
              xaxis: {
                axisTicks: {
                  show: true
                },
                type: 'datetime',
                axisBorder: {
                  show: false
                },
                min: minDate.valueOf(),
                max: maxDate.valueOf(),
                tickAmount: 4,
                labels: {
                  show: true,
                  style: {
                    colors: theme === 'dark' ? '#ffffff' : '#000000'
                  },
                  formatter(value, _, options) {
                    // Hide the last tick for this axis (6am)
                    if (options?.i === 4) return ''

                    return moment(value).format('h a')
                  }
                }
              }
            }}
            series={[
              {
                name: 'series-charged',
                data: seriesCharged
              },
              {
                name: 'series-charging',
                data: seriesCharging
              }
            ]}
          />
        </div>

        {isYesterday && (
          <div className='absolute right-[-6%] top-[86%] z-50'>
            <Icon
              name='caret_right'
              className={cn('cursor-pointer  invert dark:invert-0')}
              onClick={handleMoveToToday}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(Analytics)
