import React from 'react'
import { Text } from '../..'
import { useLingui } from '@lingui/react'
import { useTableContext } from '../useTableContext'

interface LoadingStateTableBodyProps {}

export const LoadingStateTableBody: React.FC<LoadingStateTableBodyProps> =
  props => {
    const { i18n } = useLingui()
    const { tableProps } = useTableContext()
    const { loadingText } = tableProps

    return (
      <div className='p-4 dark:text-white text-center'>
        <Text.Body.LargeSemiBold>
          {i18n._(loadingText!)}
        </Text.Body.LargeSemiBold>
      </div>
    )
  }
