import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

// * AWS Cognito User Session
import { getSession } from '../integrations/aws-cognito/services'

const {
  REACT_APP_AWS_APPSYNC_REGION,
  REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
  REACT_APP_AWS_APPSYNC_API_KEY,
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_USER_API,
  // Version 2 of graphql for Device
  //REACT_APP_DEVICE_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  //REACT_APP_DEVICE_AWS_APPSYNC_AUTHENTICATION_TYPE,
} = process.env

const userHeaders = {
  aws_appsync_graphqlEndpoint: REACT_APP_USER_API,
}

const deviceHeaders = {
  aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL_ENDPOINT,
}

let authDataHeadersApiKey = {
  aws_appsync_authenticationType: REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
}

let authDataHeadersOIDC = {
  Authentication_Type: REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE
}

const dataHeaders = REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE === "OPENID_CONNECT" ? authDataHeadersOIDC : authDataHeadersApiKey

const deviceV2Headers = {
  aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL_ENDPOINT,
  ...dataHeaders,
}

const commonHeaders = {
  aws_appsync_region: REACT_APP_AWS_APPSYNC_REGION,
  aws_appsync_authenticationType: REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE
}

const userAuthLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    ...commonHeaders,
    ...userHeaders
  }
}))

const deviceAuthLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    ...commonHeaders,
    ...deviceHeaders
  }
}))

const deviceV2AuthLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    ...commonHeaders,
    ...deviceV2Headers
  }
}))

const cognitoAuthorizationHeader = new ApolloLink((operation, forward) => {
  return new Promise(async (resolve, reject) => {
    let token;
    try {
      const userSession = await getSession()
      token = userSession.getIdToken().getJwtToken()
    } catch (e) {
      console.error("Error fetching user from Cognito");
    }


    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : ''
      }
    })

    resolve(forward(operation))
  }) as any
})

const deviceHttpLink = createHttpLink({
  uri: REACT_APP_GRAPHQL_ENDPOINT
})
const deviceV2HttpLink = createHttpLink({
  uri: REACT_APP_GRAPHQL_ENDPOINT
})

const userHttpLink = createHttpLink({
  uri: REACT_APP_USER_API
})

// returns 2nd param if first paratemeter true,
// returns 3rd param if false
// It allows us to setup different endpoints and headers for each
const httpLink = ApolloLink.split(
  operation => operation.getContext().graphqlEntity === 'users',
  from([cognitoAuthorizationHeader, userAuthLink, userHttpLink]),
  from([cognitoAuthorizationHeader, deviceV2AuthLink, deviceV2HttpLink])
)
// We use this to log graphQL errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
         {message, locations,path}
      );
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
})

const appsyncSdkAuth: any = {
  type: REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE as string,
  apiKey: REACT_APP_AWS_APPSYNC_API_KEY as any
}

const link = ApolloLink.from([
  createAuthLink({
    url: REACT_APP_GRAPHQL_ENDPOINT as string,
    region: REACT_APP_AWS_APPSYNC_REGION as string,
    auth: appsyncSdkAuth
  }) as any as ApolloLink,
  // This handles regular HTTP calls. The auth for HTTP is handled via httpLink.
  // It also handles websocket subscription connections. The auth subscriptions is handlded through auth object below
  createSubscriptionHandshakeLink(
    {
      url: REACT_APP_GRAPHQL_ENDPOINT as string,
      region: REACT_APP_AWS_APPSYNC_REGION as string,
      auth: {
        type: "AMAZON_COGNITO_USER_POOLS",
        jwtToken: async () => (await (await getSession()).getIdToken().getJwtToken())
      },
      httpLink,
    } as any,
    httpLink
  )
])

const apolloClient = new ApolloClient({
  link: from([errorLink, link]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      // fetchPolicy: 'cache-and-network',
      // Disable Apollo Client Cache
      // fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})

export const getApolloClient = () => apolloClient

const auth: any = {
  type: REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE as string,
  apiKey: REACT_APP_AWS_APPSYNC_API_KEY as any
}
