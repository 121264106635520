import { 
  createBrowserRouter, 
  Navigate } from 'react-router-dom'
import {
  Root,
  AppRoot,
  ErrorPage,
  LoginPage,
  TrackingPage,
  ProfilePage,
  LoginForgotPasswordPage,
  SignupPage,
  GaragePage,
  ReportingPage,
  AboutPage } from '../pages'
import {
  TrackingSidebar,
  LoginPanel,
  LoginIncorrectPassword,
  LoginUpdatePassword,
  LoginForgotPassword,
  LoginForgotPasswordVerificationCode,
  SignupForm,
  SignupVerificationCode,
  SignupCompleteForm,
  GarageGlobalFleet,
  GarageGlobalFleetBatteryForm,
  GarageGlobalFleetToolTagForm,
  ReportingSchedule,
  ReportingExports } from '../components/organisms'
import { Routes } from '.'
import { CompanyAccount } from '../components/organisms/CompanyAccount'
import { CompanyAbout } from '../components/organisms/CompanyAbout'
import { SettingsCreditPage } from '../components/organisms/SettingsCreditsPage'
import { CompanyOrganizationSubRouter } from '../components/organisms/CompanyOrganizationSubRouter'
import GarageGlobalFleetDeviceGroupForm from '../components/organisms/GarageGlobalFleetDeviceGroupForm'
import GroupList from '../components/organisms/GarageGlobalFleet/GroupList'


export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />
  },
  {
    path: 'login',
    element: <LoginPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <LoginPanel />
      },
      {
        path: 'failed',
        element: <LoginIncorrectPassword />
      },
      {
        path: 'update-password',
        element: <LoginUpdatePassword />
      },
      {
        path: 'forgot-password',
        element: <LoginForgotPasswordPage />,
        children: [
          {
            path: '',
            element: <LoginForgotPassword />
          },
          {
            path: 'code',
            element: <LoginForgotPasswordVerificationCode />
          },
          {
            path: '*',
            index: true,
            element: <Navigate to='/login/forgot-password' />
          }
        ]
      },
      {
        path: 'complete-registration',
        element: <SignupCompleteForm />
      },
      {
        path: 'signup',
        element: <SignupPage />,
        children: [
          {
            path: '',
            element: <SignupForm />
          },
          {
            path: 'code',
            element: <SignupVerificationCode />
          },
          {
            path: '*',
            index: true,
            element: <Navigate to='/login/signup' />
          }
        ]
      },
      {
        path: '*',
        index: true,
        element: <Navigate to='/login' />
      }
    ]
  },
  {
    path: 'app',
    element: <AppRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'tracking',
        element: <TrackingPage />,
        children: [
          {
            path: 'mowers/*',
            element: <TrackingSidebar tabIndex={0} />
          },
          {
            path: 'batteries/*',
            element: <TrackingSidebar tabIndex={1} />
          },
          {
            path: 'tool_tags/*',
            element: <TrackingSidebar tabIndex={2} />
          },
          {
            path: 'groups/*',
            element: <TrackingSidebar tabIndex={3} />
          },
          {
            path: '*',
            index: true,
            element: <Navigate to='/app/tracking/mowers' />
          }
        ]
      },
      {
        path: 'profile',
        element: <ProfilePage />,
        children: [
          {
            path: '*',
            index: true,
            element: <Navigate to='/app/profile' />
          }
        ]
      },
      {
        path: 'garage',
        // Important: To set the default URL upon going to /app/garage route to app/garage/global-feet
        element: <GaragePage />,
        children: [
          {
            path: 'global-fleet',
            element: <GarageGlobalFleet />,
            children: [
              {
                path: 'mowers',
                element: <GroupList />
              },
              {
                path: 'batteries',
                element: <GarageGlobalFleetBatteryForm />
              },
              {
                path: 'tool_tags',
                element: <GarageGlobalFleetToolTagForm />
              },
              {
                path: 'groups/*',
                element: <GarageGlobalFleetDeviceGroupForm />
              },
              {
                path: '*',
                index: true,
                element: <Navigate to='/app/garage/global-fleet/mowers' />
              }
            ]
          },
          {
            path: '*',
            index: true,
            element: <Navigate to='/app/garage/global-fleet' />
          }
        ]
      },
      {
        path: 'reporting',
        element: <ReportingPage />,
        children: [
          {
            path: 'schedule',
            element: <ReportingSchedule />
          },
          {
            path: 'exports',
            element: <ReportingExports />
          },
          {
            path: '*',
            index: true,
            element: <Navigate to={Routes['reporting_page.exports']} />
          }
        ]
      },
      {
        // TODO: rename once we transfer all routes
        path: 'new_settings',
        children: [
          {
            path: 'company_account',
            element: <CompanyAccount />
          },
          {
            path: 'organization/*',
            element: <CompanyOrganizationSubRouter />
          },
          {
            path: 'about',
            element: <CompanyAbout />
          },
          {
            path: 'credits',
            element: <SettingsCreditPage />
          }
        ]
      },
      {
        path: 'about',
        element: <AboutPage />
      },
      {
        path: '*',
        index: true,
        element: <Navigate to='/app/tracking/mowers' />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to='/' />
  }
])
