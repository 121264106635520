import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js'
import UserPool from '../UserPool'
import {
  THandleLoginFn,
  THandleGetSessionFn,
  THandleLogoutFn,
  THandleSetNewPassword,
  THandleForgotPasswordFn,
  THandleForgotPasswordVerifyFn,
  THandleSignUp,
  THandleConfirmRegistration,
  TDeleteUser
} from './types'

export const login: THandleLoginFn = async data =>
  new Promise((resolve, reject) => {
    const { password } = data ?? {}
    const username = data.username.toLowerCase();
    const user = new CognitoUser({
      Username: username,
      Pool: UserPool
    })

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password
    })

    user.authenticateUser(authDetails, {
      onSuccess: data => {
        resolve({
          success: true,
          data
        })
      },
      onFailure: error => {
        switch (error.code) {
          case 'NotAuthorizedException':
            // case 'UserNotFoundException':
            resolve({
              success: false,
              code: 'NotAuthorizedException',
              error
            })
            break
          default:
            break
        }

        reject({
          success: false,
          error
        })
      },
      newPasswordRequired: data => {
        resolve({
          success: false,
          code: 'NewPasswordChallenge',
          data
        })
      }
    })
  })

export const logout: THandleLogoutFn = async () => {
  return new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser()
    if (!user) {
      reject(false)
      localStorage.clear()
      sessionStorage.clear()
      return
    }

    user?.signOut(() => {
      localStorage.clear()
      sessionStorage.clear()
      resolve(true)
    })
  })
}

export const getSession: THandleGetSessionFn = () =>
  new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser()

    if (user) {
      user.getSession((error: Error | null, session: CognitoUserSession) => {
        if (error) {
          reject(error)
        } else {
          resolve(session)
        }
      })
    } else {
      reject(new Error('There is no current user to get in the pool'))
    }
  })

export const getUserAttributes = () =>
  new Promise(async (resolve, reject) => {
    const user = UserPool.getCurrentUser()

    if (!user) {
      reject(new Error('There is no current user to get in the pool'))
    }

    user?.getSession((error: Error) => {
      if (error) reject(error)

      user?.getUserAttributes((error, result) => {
        if (error) reject(error)
        else resolve(result)
      })
    })
  })

export const setNewPassword: THandleSetNewPassword = async credentials => {
  return new Promise((resolve, reject) => {
    const { username, password, oldPassword } = credentials ?? {}

    const user = new CognitoUser({
      Username: username,
      Pool: UserPool
    })

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: oldPassword
    })

    user.authenticateUser(authDetails, {
      onSuccess: () => {},
      onFailure: () => {},
      newPasswordRequired: data => {
        delete data.email_verified
        delete data.email

        user.completeNewPasswordChallenge(password, data, {
          onSuccess: (session: CognitoUserSession) => {
            resolve({
              success: true,
              data: session
            })
          },
          onFailure: (error: any) => {
            reject({
              success: false,
              error
            })
          },
          newPasswordRequired: data => {
            resolve({
              success: false,
              code: 'NewPasswordChallenge',
              data
            })
          }
        })
      }
    })
  })
}

export const updateUserProfile = async (attribute: Record<string, string>) => {
  const user = UserPool.getCurrentUser()

  const attributeList: Array<CognitoUserAttribute> = Object.entries(
    attribute
  ).reduce((acc, [key, value]) => {
    const cognitoAttribute = new CognitoUserAttribute({
      Name: key,
      Value: value
    })

    return [...acc, cognitoAttribute]
  }, [] as Array<CognitoUserAttribute>)

  user?.updateAttributes(attributeList, (error, result) => {
    if (error) {
      console.log('error', error.message || JSON.stringify(error))
      return
    }
    console.log('call result: ' + result)
  })
}

export const forgotPassword: THandleForgotPasswordFn = data =>
  new Promise((resolve, reject) => {
    const { username } = data ?? {}

    const user = new CognitoUser({
      Username: username,
      Pool: UserPool
    })

    user.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        // console.log('CodeDeliveryData from forgotPassword: ' + data)

        resolve({
          success: true,
          data
        })
      },
      onFailure: function (error) {
        // console.log('CodeDeliveryData from forgotPassword: error' + error)

        reject(error)
      },
      //Optional automatic callback
      inputVerificationCode: function (data) {
        // console.log('Code sent to: ' + data)
        resolve({
          success: true,
          data,
          code: 'InputVerificationCode'
        })
      }
    })
  })

export const forgotPasswordVerifyCode: THandleForgotPasswordVerifyFn = data =>
  new Promise((resolve, reject) => {
    const { username, verificationCode, password } = data ?? {}

    const user = new CognitoUser({
      Username: username,
      Pool: UserPool
    })

    user.confirmPassword(verificationCode, password, {
      onSuccess() {
        // console.log('Password confirmed!')
        resolve({
          success: true
        })
      },
      onFailure(err) {
        // console.log('Password not confirmed!')
        reject(err)
      }
    })
  })

export const signUp: THandleSignUp = (username, password, attributes) =>
  new Promise((resolve, reject) => {
    const attributeList: Array<CognitoUserAttribute> = Object.entries(
      attributes
    ).reduce((acc, [key, value]) => {
      const cognitoAttribute = new CognitoUserAttribute({
        Name: key,
        Value: value
      })

      return [...acc, cognitoAttribute]
    }, [] as Array<CognitoUserAttribute>)

    UserPool.signUp(username, password, attributeList, [], (error, result) => {
      if (error) {
        reject(error)
        return
      }
      const cognitoUser = result?.user

      if (!result?.userConfirmed) {
        resolve({
          success: false,
          code: 'UserNotConfirmed'
        })
        return
      }

      resolve({
        success: true,
        data: cognitoUser
      })
    })
  })

export const confirmRegistration: THandleConfirmRegistration = data =>
  new Promise((resolve, reject) => {
    const { username, verificationCode } = data ?? {}

    const user = new CognitoUser({
      Username: username,
      Pool: UserPool
    })

    user.confirmRegistration(verificationCode, true, function (err, result) {
      if (err) {
        reject(err)
        return
      }

      resolve({
        success: true,
        data: result
      })
    })
  })

export const deleteUser: TDeleteUser = () =>
  new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser()

    user?.deleteUser(function (err, result) {
      if (err) {
        alert(err.message || JSON.stringify(err))

        reject(err)
        return
      }
      console.log('call result: ' + result)

      resolve({
        success: true,
        data: result
      })
    })
  })
