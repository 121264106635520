/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TextField } from '../../atoms'
import { DeviceCounterItem } from '../../molecules'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import {
  EUserTypes,
  setCurrentActiveList,
  setSearchFilter
} from '../../../store/slices/organizationSettingsSlice'
import { Routes } from '../../../routes'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import {
  selectUserIsGlobalAdmin,
  selectUserIsGlobalViewer
} from '../../../store/slices/userSlice'

interface CompanyOrganizationButtons {
  enableSearch?: boolean
}

export const CompanyOrganizationButtons: React.FC<CompanyOrganizationButtons> =
  props => {
    const { enableSearch = true } = props
    const location = useLocation()
    const navigate = useNavigate()
    const { userAccounts, dealerAccounts, activeList, searchFilter } =
      useAppSelector(state => state.organizationSettings)
    const operatingCompany = useAppSelector(selectOperatingCompany)
    const userIsGlobalAdmin = useAppSelector(state =>
      selectUserIsGlobalAdmin(state)
    )
    const userIsGlobalViewer = useAppSelector(state =>
      selectUserIsGlobalViewer(state)
    )
    const filteredUserAccounts = userAccounts?.filter(
      account =>
        operatingCompany?.name === 'Global' ||
        account?.companies?.includes(operatingCompany?.companyId)
    )

    const companies = useAppSelector(
      state => state.organizationCompany.companies
    )
    const reduxDispatch = useAppDispatch()

    const handleCategoryClick = (category: EUserTypes) => {
      reduxDispatch(setCurrentActiveList(category))
      navigate({
        pathname: Routes[category.toLowerCase() as keyof typeof Routes]
      })
    }

    const onSearchChange = (e: any) => {
      reduxDispatch(setSearchFilter(e.target.value))
    }

    useEffect(() => {
      reduxDispatch(setSearchFilter(''))
    }, [location.pathname])

    return (
      <div className='p-3 flex flex-col items-start justify-between lg:flex-row lg:items-center gap-3'>
        <div className='flex gap-[20px]'>
          <DeviceCounterItem
            label='PEOPLE'
            value={filteredUserAccounts?.length ?? 0}
            isActive={activeList === EUserTypes.People}
            onClick={() => handleCategoryClick(EUserTypes.People)}
          />
          {(userIsGlobalAdmin || userIsGlobalViewer) && (
            <DeviceCounterItem
              label='COMPANIES'
              value={companies?.length ?? 0}
              isActive={activeList === EUserTypes.Companies}
              onClick={() => handleCategoryClick(EUserTypes.Companies)}
            />
          )}
          <DeviceCounterItem
            label='DEALERS'
            value={dealerAccounts?.length ?? 0}
            isActive={activeList === EUserTypes.Dealers}
            onClick={() => handleCategoryClick(EUserTypes.Dealers)}
          />
        </div>

        {/* Temporarily commented for future feature */}
        {enableSearch && (
          <div className='w-full lg:max-w-[300px]'>
            <TextField
              placeholder='Search'
              className='min-h-[38px] w-full border-black-500 dark:placeholder-white dark:text-white bg-black/50'
              onChange={onSearchChange}
              value={searchFilter}
            />
          </div>
        )}
      </div>
    )
  }

export default CompanyOrganizationButtons
