import { IGroupTag } from "../components/organisms/GroupList/types"
import { IDevice } from "../models_v2/entity/device"
import { Routes } from "../routes"
export const getItemDetailsRoute =
(
  type: 'mower' | 'battery' | 'tool_tag' | 'group',
  item: IDevice | IGroupTag | any,
) => {
  let resolvedPathName = ''
  switch (type) {
    case "mower":
      resolvedPathName = Routes['tracking_page.mowers.detail']
      return { pathname: resolvedPathName.replace(':id', item.productSerial) }
    case "battery":
      resolvedPathName = Routes['tracking_page.batteries.detail']
      return { pathname: resolvedPathName.replace(':id', item.productSerial) }
    case "tool_tag":
      resolvedPathName = Routes['tracking_page.tool_tags.detail']
      return { pathname: resolvedPathName.replace(':id', item.productSerial) }
    case "group":
      resolvedPathName = Routes['tracking_page.groups.detail']
      return { pathname: resolvedPathName.replace(':id', item.group_name) }
  }
}