import React from 'react'
import cn from 'classnames'

interface SidebarContainerProps {
  className?: string
}

const SidebarContainer: React.FC<SidebarContainerProps> = props => {
  const { children, className } = props

  return (
    <div
      id="tracking-list-sidebar"
      className={cn(
        'w-[300px] min-w-[300px] sm:w-[350px] sm:min-w-[350px] lg:w-[400px] lg:min-w-[400px] h-full flex flex-col transition-all bg-app-main-light-full dark:bg-transparent',
        className
      )}>
      {children}
    </div>
  )
}

export default SidebarContainer
