import React, { useCallback } from 'react'
import cn from 'classnames'
import { 
  NavigationBarItem, 
  ISubNavigationBarProps } from './types'
import { ActiveBorder } from '../../atoms'
import { useLingui } from '@lingui/react'

const SubNavigationBar: React.FC<ISubNavigationBarProps> = props => {
  const {
    items,
    selectedIndex = 0,
    onChange,
    showActiveBorder = false,
    trailingComponent
  } = props
  const { i18n } = useLingui()

  const handleNavigationChange = useCallback((item: NavigationBarItem, index: number) => onChange?.(item, index), [])

  return (
    <div className='h-[56px] min-h-[56px] flex flex-row justify-between items-center text-white w-full'>
      <div className='flex h-full items-center'>
        {items?.map((item, index) => (
          <div
            key={item.id}
            onClick={() => handleNavigationChange(item, index)}
            className={cn(
              'h-full flex items-center justify-center w-[120px] text-center text-sm bg-black cursor-pointer',
              {
                '!bg-transparent': selectedIndex === index
              }
            )}>
            <div className='relative p-2'>
              {i18n._(item.label)}

              {showActiveBorder && selectedIndex === index && (
                <div className='absolute inset-x-0 bottom-0'>
                  <ActiveBorder className='h-[3px]' />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className='flex flex-1 h-full justify-end bg-black'>
        {trailingComponent}
      </div>
    </div>
  )
}

export default SubNavigationBar
