import React, { useCallback } from 'react'
import cn from 'classnames'
import { Text } from '..'

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  /**
   * Variant `text` was only handled yet
   */
  variant?: 'text' | 'filled' | 'outlined'
  color?: 'primary' | 'secondary' | 'danger'
  title: string
  titleClassname?: string
  size?: 'sm' | 'md' | 'lg'
  showOutline?: boolean
  children?: React.ReactNode
  titleSize?: 'sm' | 'md' | 'lg'
}

const Button: React.FC<ButtonProps> = props => {
  const {
    className,
    type = 'button',
    variant = 'filled',
    color = 'primary',
    titleSize = 'lg',
    title,
    titleClassname,
    showOutline,
    children,
    size = 'md',
    ...restProps
  } = props

  const renderButtonTitle = useCallback(() => {
    if (titleSize === 'sm') {
      return (
        <Text.ButtonText.Small className={cn('leading-none', titleClassname)}>
          {title}
        </Text.ButtonText.Small>
      )
    } else if (titleSize === 'lg') {
      return (
        <Text.ButtonText.Large className={cn('leading-none', titleClassname)}>
          {title}
        </Text.ButtonText.Large>
      )
    } else {
      return (
        <Text.ButtonText.Medium className={cn('leading-none', titleClassname)}>
          {title}
        </Text.ButtonText.Medium>
      )
    }
  }, [titleSize, titleClassname, title])

  return (
    <button
      type={type}
      {...restProps}
      className={cn(
        'py-2 px-3 rounded min-h-[44px] transition-all text-white disabled:cursor-not-allowed',
        {
          'bg-interaction-100 hover:bg-interaction-200 active:bg-interaction-300 disabled:bg-black-800 disabled:text-black-600':
            color === 'primary',
          'bg-black-800 hover:bg-black-600 active:bg-black-700 active:text-black-300 disabled:!bg-black-800 disabled:!text-black-600 outline outline-1 outline-white':
            color === 'secondary',
          'bg-transparent outline-0': variant === 'text',
          'bg-error hover:bg-error/50': color === 'danger',
          'bg-transparent border border-[#4A4A4A] hover:bg-black/30 active:bg-black/40':
            variant === 'outlined',
          'min-h-[24px] h-[24px] py-0': size === 'sm'
        },
        className
      )}
      style={{
        outlineStyle: variant === 'text' || !showOutline ? 'none' : 'auto'
      }}>
      {renderButtonTitle()}
      {children}
    </button>
  )
}
export default Button
