import { 
  useCallback, 
  useContext, 
  useEffect, 
  useMemo
} from 'react'
import cn from 'classnames'
import { 
  Alert, 
  Button, 
  Select, 
  Text, 
  TextField } from '../../atoms'
import { ReportingPageContext } from '../../../pages/ReportingPage'
import moment from 'moment'
import { useDynamicMutation } from '../../../hooks'
import {
  EExportFormat,
  EExportReportType,
  IExportInput} from '../../../models/gql'
import { useReportSubscription } from '../../../hooks/useReportSubscription'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {addReportInProgress, removeReportInProgress, selectAreReportsDownloadsInProgress, updateReportName} from '../../../store/slices/reportsSlice';


const REPORT_TYPES = [
  {
    label: 'Inventory',
    value: EExportReportType.Inventory
  },
  {
    label: 'Mower Current Hours',
    value: EExportReportType.Hours
  },
  {
    label: 'Mower Activity History',
    value: EExportReportType.Activity
  },
  {
    label: 'Mower Fault History',
    value: EExportReportType.Faults
  }
]

const EXPORT_FORMATS = [
  {
    label: 'CSV',
    value: EExportFormat.CSV
  },
  {
    label: 'JSON',
    value: EExportFormat.JSON
  }
]

interface ReportingExportsFormProps {
  className?: string
}

const ReportingExportsForm: React.FC<ReportingExportsFormProps> = props => {
  const { className } = props
  const reduxDispatch = useAppDispatch();
  const areReportsDownloadsInProgress = useAppSelector(state => selectAreReportsDownloadsInProgress(state))
  const { reportName } = useAppSelector(state => state.reports)
  const { state, dispatch } = useContext(ReportingPageContext)
  const { mutateAsync, data, isLoading, isError, reset } = useDynamicMutation({
    queryKey: 'GENERATE_EXPORT',
    graphqlEntity: 'devices_v2'
  })

  const isReportDateSelectionDisabled = useMemo(() => {
    return [REPORT_TYPES[0].value, REPORT_TYPES[1].value].includes(
      state?.export_form?.report_type!
    )
  }, [state?.export_form?.report_type])

  useEffect(() => {
    reduxDispatch(removeReportInProgress());
  }, [isError])

  useReportSubscription() 

  const handleToggleSelectItems = useCallback(() => {
    dispatch?.({
      type: 'SET_DATE_RANGE_SELECTION',
      payload: {
        type: 'export',
        active: false
      }
    })

    dispatch?.({
      type: 'SET_ASSET_SELECTION',
      payload: {
        type: 'export',
        active: !state?.asset_selection?.export
      }
    })
  }, [state?.asset_selection?.export])

  const handleToggleSelectDateRange = useCallback(() => {
    dispatch?.({
      type: 'SET_ASSET_SELECTION',
      payload: {
        type: 'export',
        active: false
      }
    })

    dispatch?.({
      type: 'SET_DATE_RANGE_SELECTION',
      payload: {
        type: 'export',
        active: !state?.date_range_selection?.export
      }
    })
  }, [state?.date_range_selection?.export])

  const assetsSelectedCount = useMemo(() => {
    return Object.keys(state?.export_assets_selected ?? {}).length
  }, [state?.export_assets_selected])

  const reportDateRange = useMemo(() => {
    const { start, end } = state?.export_date_range ?? {}
    if (!start) return ''

    const startDateText = moment(start).format('MM/DD/YY')
    if (!end || moment(start).isSame(end, 'date')) return startDateText

    const endDateText = moment(end).format('MM/DD/YY')
    return `${startDateText} - ${endDateText}`
  }, [state?.export_date_range])

  const handleReportTypeChange = useCallback(
    (value: EExportReportType) => {
      dispatch?.({
        type: 'SET_EXPORT_FORM_INPUT',
        payload: {
          report_type: value,
        }
      })

      const reportName = REPORT_TYPES.find(
        item => item.value === value
      )?.label.toLowerCase() ?? ''
      reduxDispatch(updateReportName(reportName))

      if ([REPORT_TYPES[0].value, REPORT_TYPES[1].value].includes(value)) {
        dispatch?.({
          type: 'SET_DATE_RANGE_SELECTION',
          payload: {
            type: 'export',
            active: false
          }
        })

        // Reset dates, since those report type doesn't require report dates
        dispatch?.({
          type: 'SET_DATE_RANGE',
          payload: {
            type: 'export',
            value: null
          }
        })
      }
    },
    [reportName]
  )

  const handleExportFormatChange = useCallback((value: EExportFormat) => {
    dispatch?.({
      type: 'SET_EXPORT_FORM_INPUT',
      payload: {
        export_format: value
      }
    })
  }, [])

  const handleReportNameChange = useCallback((value: string) => {
    reduxDispatch(updateReportName(value))
  }, [])

  const handleClearFields = useCallback(() => {
    dispatch?.({
      type: 'SET_ASSET_SELECTION',
      payload: {
        type: 'export',
        active: false
      }
    })

    dispatch?.({
      type: 'SET_DATE_RANGE_SELECTION',
      payload: {
        type: 'export',
        active: false
      }
    })

    dispatch?.({
      type: 'SET_DATE_RANGE',
      payload: {
        type: 'export',
        value: null
      }
    })

    dispatch?.({
      type: 'SET_ASSETS',
      payload: {
        type: 'export',
        ids: []
      }
    })

    dispatch?.({
      type: 'SET_EXPORT_FORM_INPUT',
      payload: {
        export_format: undefined,
        report_type: undefined,
      }
    })
    reduxDispatch(updateReportName(''))
    

    reset()
  }, [])

  const clearFormElementActiveState = useCallback(() => {
    dispatch?.({
      type: 'SET_ASSET_SELECTION',
      payload: {
        type: 'export',
        active: false
      }
    })

    dispatch?.({
      type: 'SET_DATE_RANGE_SELECTION',
      payload: {
        type: 'export',
        active: false
      }
    })
  }, [])

  const handleExportSubmit = useCallback(async () => {
    clearFormElementActiveState()
    const { export_assets_selected, export_form, export_date_range } = state ?? {}
    const startDate = export_date_range?.start
      ? moment(export_date_range.start).startOf('day').toISOString()
      : ''

    let endDate = ''
    if (export_date_range?.end) {
      endDate = moment(export_date_range.end).endOf('day').toISOString()
    } else if (export_date_range?.start) {
      endDate =  moment(export_date_range.start).endOf('day').toISOString()
    }

    const input: IExportInput = {
      devices: Object.keys(export_assets_selected ?? {}),
      reportType: export_form?.report_type!,
      format: export_form?.export_format!,
      startDate,
      endDate,
      downloadFileName: reportName
    }

    reduxDispatch(addReportInProgress());
    // This will kick off the mutation and we will wait for the response via a graphql subscription & download the report for the user on the useReportSubscription hook
    await mutateAsync({ input })
  }, [state])

  return (
    <>
      <Text.VariousRegular.Large className='font-bold dark:text-white'>Export Data</Text.VariousRegular.Large>
      <div className={cn('dark:text-white mt-8', className)}>
        <div className='flex flex-col gap-3'>
          <div className='flex flex-col'>
            <Text.Body.Medium
              className={cn('mb-2', {
                'text-primary italic': assetsSelectedCount
              })}>
              {assetsSelectedCount
                ? `${assetsSelectedCount} Assets Selected`
                : 'Choose Assets'}
            </Text.Body.Medium>
            <Button
              className={cn({
                '!bg-black-500 hover:!bg-black-700':
                  !state?.asset_selection?.export,
                '!bg-primary hover:!bg-primary/80':
                  !!state?.asset_selection?.export
              })}
              color='secondary'
              titleSize='sm'
              titleClassname='font-semibold'
              title='Select Items'
              onClick={handleToggleSelectItems} />
          </div>
          <div>
            <Text.Body.Medium className='mb-2'>Report Type</Text.Body.Medium>
            <Select
              dense
              placeholder='Select'
              options={REPORT_TYPES}
              onFocus={clearFormElementActiveState}
              onChange={handleReportTypeChange}
              value={state?.export_form?.report_type}
            />
          </div>
          <div className='flex flex-col'>
            <Text.Body.Medium
              className={cn('mb-2', {
                'text-primary italic': reportDateRange
              })}>
              {reportDateRange
                ? `Report Dates: ${reportDateRange}`
                : 'Report Dates'}
            </Text.Body.Medium>
            <Button
              className={cn({
                '!bg-black-500 hover:!bg-black-700':
                  !state?.date_range_selection?.export,
                '!bg-primary hover:!bg-primary/80':
                  !!state?.date_range_selection?.export
              })}
              color='secondary'
              titleSize='sm'
              titleClassname='font-semibold'
              title='Select Dates'
              onClick={handleToggleSelectDateRange}
              disabled={isReportDateSelectionDisabled} />
          </div>
          <div>
            <Text.Body.Medium className='mb-2'>Export Format</Text.Body.Medium>
            <Select
              dense
              placeholder='Select option'
              options={EXPORT_FORMATS}
              onChange={handleExportFormatChange}
              onFocus={clearFormElementActiveState}
              value={state?.export_form?.export_format} />
          </div>
          <div>
            <Text.Body.MediumSemiBold className='mb-2'>Report Name</Text.Body.MediumSemiBold>
            <TextField
              type='text'
              dense
              className={cn(
                'focus:outline-none focus:border-solid focus:border-white !text-primary w-full text-right pr-[38px]',
                {
                  'pr-1': !state?.export_form?.export_format
                }
              )}
              onFocus={clearFormElementActiveState}
              onChange={e => handleReportNameChange(e.target.value)}
              value={reportName}
              trailingComponent={
                state?.export_form?.export_format && (
                  <Text.Body.Medium className='text-primary'>
                    {`.${state?.export_form?.export_format.toLowerCase()}`}
                  </Text.Body.Medium>
                )
              } />
          </div>
          <div className='mt-4 flex flex-col gap-4'>
            {isError && (
              <Alert
                className='mb-0'
                variant='error'
                message='There was an error in exporting the report.'/>
            )}
            <Button
              className='w-full bg-primary hover:bg-primary/80'
              titleSize='sm'
              titleClassname='font-semibold'
              title='Clear Fields'
              onClick={handleClearFields}/>
            <Button
              className='w-full'
              showOutline={false}
              color='primary'
              titleSize='sm'
              titleClassname='font-semibold'
              title={
                areReportsDownloadsInProgress
                  ? `Exporting ${state?.export_form?.export_format || ''}`
                  : `Export ${state?.export_form?.export_format || ''}`
              }
              disabled={areReportsDownloadsInProgress}
              onClick={handleExportSubmit}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportingExportsForm
