import { 
  createContext, 
  useEffect } from 'react'
import moment from 'moment-timezone'
import { RouterProvider } from 'react-router-dom'
import { 
  QueryClient, 
  QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ErrorPage } from './pages/'
import './utils/react_route_loader'
import { IAppContext } from './App.types'
import { router } from './routes/router'
import {
  login,
  logout,
  getSession,
  setNewPassword,
  forgotPassword,
  forgotPasswordVerifyCode,
  signUp,
  deleteUser,
  confirmRegistration } from './integrations/aws-cognito/services'
import { ApolloProvider } from '@apollo/client'
import { getApolloClient } from './utils/apolloClientLoader'
import { store } from './store/store'
import { Provider } from 'react-redux'


const apolloClient = getApolloClient()
const client = new QueryClient()

export const AppContext = createContext<Partial<IAppContext>>({})

const App: React.FC = () => {
  const value: IAppContext = {
    handleLogin: login,
    handleLogout: logout,
    getSession,
    handleSetNewPassword: setNewPassword,
    forgotPassword,
    forgotPasswordVerify: forgotPasswordVerifyCode,
    signUp,
    confirmRegistration,
    deleteUser
  }

  // const [precheckDone, setPrecheckDone] = useState(false)

  useEffect(() => {
    const currentDate = moment()

    // For debug:
    console.log(`Device current timezone:`, {
      currentDate: currentDate.toDate(),
      zoneName: currentDate
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .zoneName(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneOffset: currentDate.utcOffset()
    })

    // TODO: Disable for now
    // const value = sessionStorage.getItem('last_visit')
    // // This means that for every time the user closes the tab/browser will auto logout from the app
    // if (value === null) {
    //   sessionStorage.clear()
    //   localStorage.clear()
    // }

    // setPrecheckDone(true)

    const handleStorageEvent = (e: StorageEvent) => {
      if (
        e.key?.startsWith('CognitoIdentityServiceProvider') &&
        !e.storageArea?.length
      ) {
        window.location.reload()
      }
    }

    window.addEventListener('storage', handleStorageEvent, false)
    return () => window.removeEventListener('storage', handleStorageEvent)
  }, [])

  // if (!precheckDone) return null

  return (
    <Provider store={store}>
      <AppContext.Provider value={value}>
        <ApolloProvider client={apolloClient}>
          <QueryClientProvider client={client}>
            <RouterProvider router={router} fallbackElement={ErrorPage} />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ApolloProvider>
      </AppContext.Provider>
    </Provider>
  )
}

export default App
