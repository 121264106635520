import React from 'react'
import { Icon } from '../../../atoms'
import DynamicListItem from '../../DynamicListItem'

interface MonthNavigationButtonProps {
  direction: 'PREVIOUS' | 'NEXT'
  onClick: () => void
}

const MonthNavigationButton: React.FC<MonthNavigationButtonProps> = props => {
  const { onClick, direction } = props

  const iconName = direction === 'PREVIOUS' ? 'chevron_left' : 'chevron_right'

  return (
    <DynamicListItem
      className='w-fit !py-0 !px-3 cursor-pointer'
      onClick={onClick}>
      <Icon name={iconName} className='min-w-[12px] invert dark:filter-none' />
    </DynamicListItem>
  )
}

export default MonthNavigationButton
