import { 
  useCallback, 
  useEffect } from 'react'
import cn from 'classnames'
import { 
  NavigationBarItem, 
  TrackingSubNavigationBarProps } from './types'
import { useLingui } from '@lingui/react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setDeviceIdsSelectedOnMap } from '../../../store/slices/deviceSliceV2'
import { resetMapZoom } from '../../../store/slices/deviceSlice'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'


const TrackingSubNavigationBar: React.FC<TrackingSubNavigationBarProps> =props => {
  const { items, selectedIndex = 0, onClick } = props
  const { i18n } = useLingui()
  const reduxDispatch = useAppDispatch()
  const operatingCompany = useAppSelector(selectOperatingCompany)

  const handleNavigationChange = useCallback((item: NavigationBarItem) => {
    onClick(item)
    reduxDispatch(resetMapZoom());
    reduxDispatch(
      setDeviceIdsSelectedOnMap({
        deviceIds: []
      })
    )
  }, [])

  useEffect(() => {
    reduxDispatch(resetMapZoom());
  }, [])

  useEffect(() =>{
    if ( selectedIndex===-1 && operatingCompany.name==="Global" ) {
      handleNavigationChange({
        id: 'mowers',
        label: "Mowers",
        queryKey: 'GET_TRACKING_MOWERS'
      })
    }
  }, [ selectedIndex, operatingCompany ])

  return (
    <div className='h-[56px] min-h-[56px] flex flex-row justify-between items-center text-white'>
      <div className='flex h-full items-center tracking-subnavigation'>
        {items?.map((item, index) => (
          <div
            key={item.id}
            onClick={() => handleNavigationChange(item)}
            className={cn(
              'h-full flex items-center justify-center w-[120px] text-center text-sm bg-black cursor-pointer',
              {
                '!bg-transparent': selectedIndex === index
              }
            )}>
            {i18n._(item.label)}
          </div>
        ))}
      </div>

      <div className='flex flex-1 h-full justify-end bg-black'>
        {/* TODO: Hide temporarily */}
        {/* <div className='flex items-center gap-5 mr-5'>
        <Select 
          options={[
            {
              label: 'DISTRICT 9',
              value: 'district_9'
            },
            {
              label: 'DISTRICT 10',
              value: 'district_10',
            }
          ]}
          value='district_9'
        />
        <Text.VariousBold.H4>72° F</Text.VariousBold.H4>
      </div> */}
      </div>
    </div>
  )
}


export default TrackingSubNavigationBar
