import { IRestQueries } from "../../hooks/types"

export const GET_TRACKING_MOWERS: IRestQueries = {
  method: 'GET',
  path: '/mower'
}

export const GET_TRACKING_BATTERIES: IRestQueries = {
  method: 'GET',
  path: '/battery'
}

export const GET_TRACKING_TOOL_TAGS: IRestQueries = {
  method: 'GET',
  path: '/tool_tag'
}