/* eslint-disable @typescript-eslint/no-unused-vars */
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import React, { useEffect, useState } from 'react';
import UserPool from '../UserPool';

export const useCognitoUser = () => {
  const user = UserPool.getCurrentUser()
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)

  useEffect(() => {
    user?.getSession((error: Error | null, _: CognitoUserSession) => {
      if (error) {
        console.error('[useCognitoUser getSession] error:', error)
        return;
      }

      setCognitoUser(user)
    })
  }, [])

  return cognitoUser
}