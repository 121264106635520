export const NewSettingsRoutes = {
  new_settings: '/app/new_settings',
  // New hierarchy
  // TODO: we will be changing other existing routes in 3rd ticket
  // so do not remove anything above yet
  company_account: '/app/new_settings/company_account',
  organization: '/app/new_settings/organization/people',
  people: '/app/new_settings/organization/people',
  people_invite: '/app/new_settings/organization/people/invite',
  people_edit: '/app/new_settings/organization/people/:id/edit',
  companies: '/app/new_settings/organization/companies',
  companies_edit: '/app/new_settings/organization/companies/:id/edit',
  companies_create: '/app/new_settings/organization/companies/create',
  dealers: '/app/new_settings/organization/dealers',
  'organization.companies.assign_asset':
    '/app/new_settings/organization/companies/:id/assign_asset',
  about: '/app/new_settings/about',
  'new_settings.wildcard': '/app/new_settings/*',
  credits_page: '/app/new_settings/credits',
  'assign_asset.battery': '/app/new_settings/organization/companies/:id/assign_asset/battery',
  'assign_asset.tool_tag': '/app/new_settings/organization/companies/:id/assign_asset/tool_tag',
  'assign_asset.mower': '/app/new_settings/organization/companies/:id/assign_asset/mower',
}

// TODO: look into these new routes...
// organization.companies
// organization.companies.create
// organization.companies.edit