import { ChangeEvent, MutableRefObject, useEffect } from 'react'

const SESSION_STORAGE_KEY = 'scroll-position'

interface ScrollPositionStore {
  [key: string]: number
}

export const useScrollRestore = (
  ref: MutableRefObject<HTMLElement | null>,
  options: { key: string }
) => {
  const { key } = options

  const getStore = (): ScrollPositionStore => {
    const store = sessionStorage.getItem(SESSION_STORAGE_KEY)
    if (!store) return {}
    return JSON.parse(store)
  }

  const setStore = (key: string, value: number) => {
    const storeAsObject = getStore()
    storeAsObject[key] = value
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storeAsObject))
  }

  useEffect(() => {
    const handleScroll = (_event: Event) => {
      const event = _event as unknown as ChangeEvent<HTMLDivElement>
      setStore(key, event.target.scrollTop ?? 0)
    }

    if (ref.current) {
      ref.current.addEventListener('scroll', handleScroll)
    }

    return () => {
      ref.current?.removeEventListener('scroll', handleScroll)
    }
  }, [key])

  useEffect(() => {
    const store = getStore()
    if (Object.hasOwn(store, key)) {
      ref.current?.scrollTo({
        top: store[key] ?? 0
      })
    }
  }, [key])

  useEffect(() => {
    const handleUnload = () => {
      sessionStorage.removeItem(SESSION_STORAGE_KEY)
    }

    window.addEventListener('unload', handleUnload)

    return () => {
      window.removeEventListener('unload', handleUnload)
    }
  }, [])
}
