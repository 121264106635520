import React, { useMemo } from 'react'
import packageJson from '../../../package.json'
import { ASSETS } from '../../assets'
import { Image, Divider, Text } from '../../components/atoms'
import { SidebarContainer } from '../../components/molecules'
import SubNavigationBar from '../../components/organisms/SubNavigationBar'
import { NavigationBarItem } from '../../components/organisms/SubNavigationBar/types'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

interface ILink {
  url: string
  label: string
}

const AboutPage: React.FC = () => {
  const { i18n } = useLingui()
  const navigationItems: NavigationBarItem[] = useMemo(() => {
    return [
      {
        id: 'about',
        label: t`About`
      }
    ]
  }, [])

  const links: ILink[] = [
    {
      url: 'https://www.dewalt.com/legal/terms-of-use',
      label: t`Terms of Use`
    },
    {
      url: 'https://www.stanleyblackanddecker.com/privacy-policy',
      label: t`Privacy Policy`
    },
    {
      url: '',
      label: t`Help`
    }
  ]

  const handleLinkClick = (link: ILink) => {
    if (link.url) window.open(link.url, '_blank')
  }

  return (
    <div className='h-full flex flex-col'>
      <SubNavigationBar
        items={navigationItems}
        selectedIndex={0}
        showActiveBorder
      />

      <div className='flex flex-1 overflow-hidden'>
        <SidebarContainer className='border-r-2 border-black'>
          <div className='p-[30px] mt-[78px] flex flex-col gap-4 dark:text-white'>
            {links.map(item => (
              <div key={item.label}>
                <Text.Headline.H5
                  className='cursor-pointer'
                  onClick={() => handleLinkClick(item)}>
                  {i18n._(item.label)}
                </Text.Headline.H5>
              </div>
            ))}
          </div>
        </SidebarContainer>

        <div className='flex flex-col flex-1 overflow-hidden bg-app-main-light-100 dark:bg-transparent'>
          <div className='h-[102px] flex items-end' />
          <Divider size='md' />

          <div className='flex-1 h-full overflow-x-auto'>
            <div className='flex flex-col items-center justify-center py-7 px-4'>
              <Image
                src={ASSETS.IMAGES.APP.app_logo}
                className='h-[100px] min-h-[100px]'
                alt='Ground Command'
              />
              <Text.VariousBold.Large className='dark:text-white'>
                {`v${packageJson.version}`}
              </Text.VariousBold.Large>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutPage
