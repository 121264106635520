import React, { Fragment } from 'react'
import { useOutletContext } from 'react-router-dom'
import { IBattery } from '../../../models'
import { Placeholders } from '../../../utils/constants'
import { Text, Divider } from '../../atoms'
import { BatteryListItem } from '../../molecules'
import { useAppSelector } from '../../../store/hooks'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'
import { selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors'
import { IDevice } from '../../../models_v2/entity/device'
import { Battery, compareByName } from '../../../utils/sortingLists'

interface BatteryListProps {}

interface IOutletContext {
  items: Array<IBattery>
  selectedIds: Array<string>
  preSelectedId: string
}

const BatteryList: React.FC<BatteryListProps> = props => {
  const outletContext = useOutletContext<IOutletContext>()
  const { preSelectedId = '' } = outletContext ?? {}
  const deviceIdsSelectedOnMap = useAppSelector(
    state => state.deviceV2.deviceIdsSelectedOnMap
  )
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const companyBatteries = (
    useAppSelector(
      selectFilteredDevices<IDevice>({
        deviceType: 'battery',
        operatingCompany,
        // productSerials: deviceIdsSelectedOnMap,
        withLocationData: true
      })
    ) as IDevice[]
  ).sort(compareByName<Battery>(battery => battery.inventory.name));

  if (!companyBatteries.length)
    return (
      <div className='h-full flex flex-col items-center justify-center'>
        <Text.VariousRegular.Medium className='dark:text-white'>
          {Placeholders['tracking.sidebar.device_empty_list']}
        </Text.VariousRegular.Medium>
      </div>
    )

  return (
    <div id='battery-list-container' className='flex flex-col'>
      <div className='flex p-3 gap-3 items-center'>
        <div className='w-5 h-6 flex items-center justify-center text-black bg-primary rounded-md border border-[#363636] cursor-pointer'>
          O
        </div>
        <div
          className='w-5 h-6 flex items-center justify-center text-white bg-black rounded-md border border-[#363636] cursor-pointer
        '>
          A
        </div>
        <Text.VariousRegular.Medium className='dark:text-white ml-3'>
          Sort Onboard / Available
        </Text.VariousRegular.Medium>
      </div>
      <Divider size='lg' />
      {companyBatteries.map((item: any) => {
        const isActive = preSelectedId === item.id

        return (
          <Fragment key={item.productSerial}>
            <BatteryListItem battery={item} isActive={isActive} />
            <Divider size='lg' />
          </Fragment>
        )
      })}
    </div>
  )
}

export default BatteryList
