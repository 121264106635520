import React, { memo } from 'react'
import { useTableContext } from '../useTableContext'
import { RowRenderer } from './RowRenderer'
import { CellRenderer } from './CellRenderer'

interface TableBodyRendererProps {}

const BaseTableBodyRenderer: React.FC<TableBodyRendererProps> = () => {
  const { tableInstance } = useTableContext()
  const { prepareRow, getTableBodyProps, rows } = tableInstance
  
  return (
    <div {...getTableBodyProps()} className='overflow-x-hidden overflow-y-auto'>
      {rows.map(row => {
        prepareRow(row)
        return (
          <RowRenderer {...row.getRowProps()} row={row}>
            {row.cells.map(cell => (
              <CellRenderer
                {...cell.getCellProps({
                  style: {
                    maxWidth: cell.column.maxWidth,
                    minWidth: cell.column.minWidth,
                    width: cell.column.width
                  }
                })}
                cell={cell}
              />
            ))}
          </RowRenderer>
        )
      })}
    </div>
  )
}

export const TableBodyRenderer = memo(BaseTableBodyRenderer)
