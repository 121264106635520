import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IDevice } from '../../../models_v2/entity/device'
import { IMetadata } from '../../../models_v2/entity/metadata'
import { mergeObjects } from '../../../utils/objects'
import {RecursivePartial} from '../../../utils/typescript';
export type DeviceType = "mower" | "battery" | "tools-and-tags"

interface DeviceSliceState {
  // Map-like structure for easy lookup and singular updates
  devices: IDevice[],
  deviceIdsSelectedOnMap: IDevice["productSerial"][],
  device?: Partial<IDevice>
}

const initialState: DeviceSliceState = {
  devices: [],
  deviceIdsSelectedOnMap: [],
  device: {}
}

type SetDevicesPayload = {
  devices: IDevice[],
}

type DeviceUpdate = IDevice | RecursivePartial<IDevice & {
  // make product serial is required
  productSerial: IDevice['productSerial'] 
}>

type UpdateDevicePayload = {
  device: DeviceUpdate
}

type UpdateManyDevicesWithSameUpdatePayload = {
  device: DeviceUpdate
  devicesProductSerials: IDevice['productSerial'][]
}

type SetDevicePayload = {
  device: IDevice,
}

export const deviceSlice = createSlice({
  name: 'deviceV2',
  initialState,
  reducers: {
    setDevices: (state, action: PayloadAction<SetDevicesPayload>) => {
      state.devices = action.payload.devices
    },
    // Update one device. The device you pass in can be a full or partial device. 
    // Product serial is required to make the match
    updateDevice: ( state, action: PayloadAction<UpdateDevicePayload> ) => {
      const { device } = action.payload
      const deviceIndex = state.devices.findIndex(_device => _device.productSerial === device.productSerial)
      state.devices[deviceIndex] = mergeObjects(
        state.devices[deviceIndex], 
        device as unknown as Record<string, unknown>
      ) as unknown as IDevice
    },
    // Update many devices with the SAME UPDATE. 
    // Must pass list of productSerials to find devices    
    updateManyDevicesWithSameUpdate: ( state, action: PayloadAction<UpdateManyDevicesWithSameUpdatePayload> ) => {
      const { devicesProductSerials, device } = action.payload
      state.devices = state.devices.map(_device => 
        devicesProductSerials.includes(_device.productSerial) 
          ? mergeObjects(
              _device, 
              device as unknown as Record<string, unknown>
            ) as unknown as IDevice
          : _device
        )
    },
    upsertDevice: (state, { payload }: PayloadAction<{
      device: IDevice
    }>) => {
      const { device } = payload
      const idx = state.devices.findIndex(
        existingDevice => existingDevice.productSerial === device.productSerial
      )
      state.devices[idx] = device
    },
    upsertMetadataByDevice: (
      state,
      {
        payload
      }: PayloadAction<{
        productSerial: IDevice["productSerial"],
        metadata: IMetadata
      }>
    ) => {
      const { productSerial, metadata } = payload
      const deviceIdx = state.devices.findIndex(existingDevice => 
        existingDevice.productSerial === productSerial
      )
      let  metaDataEntryIndex
      if (state.devices[deviceIdx]?.metadata) {
        metaDataEntryIndex = state.devices[deviceIdx].metadata.findIndex(
          entry => entry.metadataId === metadata.metadataId
        )
      } else {
        if (!state.devices[deviceIdx]?.metadata) {
          state.devices[deviceIdx] = {} as IDevice
        }
        state.devices[deviceIdx].metadata = []
      }

      if (metaDataEntryIndex) {
        state.devices[deviceIdx].metadata[metaDataEntryIndex] = metadata
      } else {
        state.devices[deviceIdx].metadata.push(metadata)
      }
    },
    setDeviceIdsSelectedOnMap: (state, { payload }: PayloadAction<{
      deviceIds: IDevice["productSerial"][],
    }>) => {
      state.deviceIdsSelectedOnMap = payload.deviceIds
    },
    resetDeviceV2State: () => initialState,
    setDevice: (state, action: PayloadAction<SetDevicePayload>) => {
      state.device = action?.payload?.device
    }
  }
})

export const { 
  setDevices, 
  updateDevice,
  updateManyDevicesWithSameUpdate,
  upsertDevice, 
  upsertMetadataByDevice, 
  setDeviceIdsSelectedOnMap,
  resetDeviceV2State,
  setDevice
 } = deviceSlice.actions

export const deviceSliceRecuder = deviceSlice.reducer


export default deviceSlice.reducer
