/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useMemo } from 'react'
import { PathMatch, useMatch } from 'react-router-dom'
import { Routes } from '../routes'
import { AppRootContext } from '../pages/AppRoot'
import { useAppDispatch } from '../store/hooks'
import { setSelectedDeviceDetailsId } from '../store/slices/trackingSlice'

interface IUseDeviceDetailedView {
  matchedRoute: PathMatch<string> | null
  isMowerDetailView: boolean
  isBatteryDetailView: boolean
  isToolTagDetailView: boolean
  isGroupDetailView: boolean
  isDetailView: boolean
}

export const useDeviceDetailedView = (): IUseDeviceDetailedView => {
  const reduxDispatch = useAppDispatch()

  const { state, dispatch } = useContext(AppRootContext)
  const matchTrackingMowerDetailView = useMatch(
    Routes['tracking_page.mowers.detail']
  )
  const matchTrackingBatteryDetailView = useMatch(
    Routes['tracking_page.batteries.detail']
  )
  const matchTrackingToolTagDetailView = useMatch(
    Routes['tracking_page.tool_tags.detail']
  )
  const matchTrackingGroupTagDetailView = useMatch(
    Routes['tracking_page.groups.detail']
  )

  const matchedRoute = useMemo(() => {
    return (
      matchTrackingMowerDetailView ||
      matchTrackingBatteryDetailView ||
      matchTrackingToolTagDetailView ||
      matchTrackingGroupTagDetailView
    )
  }, [
    matchTrackingMowerDetailView,
    matchTrackingBatteryDetailView,
    matchTrackingToolTagDetailView,
    matchTrackingGroupTagDetailView
  ])

  useEffect(() => {
    if (
      !!matchTrackingMowerDetailView &&
      state?.selected_mower_id !== matchedRoute?.params?.id
    ) {
      dispatch?.({
        type: 'SET_SELECTED_MOWER_ID',
        payload: matchedRoute?.params?.id ?? ''
      })
    } else if (
      !!matchTrackingBatteryDetailView &&
      state?.selected_battery_id !== matchedRoute?.params?.id
    ) {
      dispatch?.({
        type: 'SET_SELECTED_BATTERY_ID',
        payload: matchedRoute?.params?.id ?? ''
      })
    } else if (
      !!matchTrackingToolTagDetailView &&
      state?.selected_tool_tag_id !== matchedRoute?.params?.id
    ) {
      dispatch?.({
        type: 'SET_SELECTED_TOOL_TAG_ID',
        payload: matchedRoute?.params?.id ?? ''
      })
    }
  }, [])

  // For v2
  useEffect(() => {
    reduxDispatch(setSelectedDeviceDetailsId(matchedRoute?.params?.id ?? ''))

    return () => {
      reduxDispatch(setSelectedDeviceDetailsId(undefined))
    }
  }, [])

  return {
    matchedRoute,
    isMowerDetailView: !!matchTrackingMowerDetailView,
    isBatteryDetailView: !!matchTrackingBatteryDetailView,
    isToolTagDetailView: !!matchTrackingToolTagDetailView,
    isGroupDetailView: !!matchTrackingGroupTagDetailView,
    isDetailView:
      !!matchTrackingMowerDetailView ||
      !!matchTrackingBatteryDetailView ||
      !!matchTrackingToolTagDetailView ||
      !!matchTrackingGroupTagDetailView
  }
}
