import React, { createContext, useMemo } from 'react'
import { ITableProviderContext } from './types'
import { useTable, useFlexLayout, useSortBy, useRowSelect } from 'react-table'
import { sortingFns } from './utils/sorting'
import IndeterminateCheckbox from './components/IndeterminateCheckbox'
import { TableProps } from './types'

export const TableContext = createContext<Partial<ITableProviderContext>>({})

interface TableProviderProps extends TableProps {
  rowKey?: string
}

export const TableProvider: React.FC<TableProviderProps> = props => {
  const {
    data,
    columns,
    loadingText = 'Loading...',
    enableSorting = true,
    defaultSortedColumn,
    enableRowSelection = false,
    enableRowVirtualization = false,
    increaseViewportBy = {
      top: 200,
      bottom: 200
    },
    onRowsSelected,
    tableState,
    rowKey = props.rowKey ?? 'id'
  } = props

  const memoizedColumns = useMemo(() => {
    return columns.map(column => ({
      ...column,
      sortType: column?.sortType ?? sortingFns.alphanumeric
    }))
  }, [columns])

  const memoizedData = useMemo(() => data, [data])

  const tableInstance = useTable(
    {
      data: memoizedData,
      columns: memoizedColumns,
      getRowId: (row: any, _, parent) =>
        parent ? [parent.id, row[rowKey]].join('.') : row[rowKey],
      autoResetSortBy: false,
      initialState: {
        ...tableState,
        sortBy: defaultSortedColumn
          ? [
              {
                id: defaultSortedColumn.key,
                desc: defaultSortedColumn?.sort === 'descending'
              }
            ]
          : []
      }
    },
    useFlexLayout,
    enableSorting ? useSortBy : () => {},
    enableRowSelection ? useRowSelect : () => {},
    hooks => {
      enableRowSelection &&
        hooks.visibleColumns.push((columns, { instance }) => [
          {
            id: 'selection',
            width: 50,
            maxWidth: 50,
            Cell: ({ row }) => {
              return (
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps({
                    onChange: () => {
                      const nextSelection = !row.isSelected
                      instance.toggleAllRowsSelected(false)
                      row.toggleRowSelected(nextSelection)

                      setTimeout(() => {
                        onRowsSelected?.(
                          instance.selectedFlatRows.map(item => item.original)
                        )

                        row.toggleRowSelected(nextSelection)
                      }, 0)
                    }
                  })}
                />
              )
            }
          },
          ...columns
        ])
    }
  )

  const context: ITableProviderContext = {
    tableInstance,
    tableProps: {
      ...props,
      // Pass the defaults to this method
      enableSorting,
      loadingText,
      enableRowSelection,
      enableRowVirtualization,
      rowKey,
      increaseViewportBy
    }
  }

  return (
    <TableContext.Provider value={context}>
      {props.children}
    </TableContext.Provider>
  )
}
