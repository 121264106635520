import { useCallback, useContext, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRootContext } from '../../../pages/AppRoot'
import BackToListButton from '../BackToListButton'
import { useDeviceDetailedView } from '../../../hooks'
import { Routes } from '../../../routes'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  resetMapZoom,
  selectClusterFromMarkerId
} from '../../../store/slices/deviceSlice'
import MowerDetail from '../../organisms/MowerDetail'
import GroupTagDetail from '../../organisms/GroupTagDetail'
import BatteryDetail from '../../organisms/BatteryDetail'
import ToolTagDetail from '../../organisms/ToolTagDetail'

type TrackingSidebarDetailContainerProps = {
  tabIndex: number
}

const TrackingSidebarDetailContainer = (
  props: TrackingSidebarDetailContainerProps
) => {
  const { tabIndex } = props
  const { dispatch, state, emitter } = useContext(AppRootContext)
  const navigate = useNavigate()
  const sidebarContentRef = useRef<HTMLDivElement | null>(null)
  const reduxDispatch = useAppDispatch()
  const {
    matchedRoute,
    isMowerDetailView,
    isToolTagDetailView,
    isGroupDetailView,
    isBatteryDetailView
  } = useDeviceDetailedView()

  const markerCluster = useAppSelector(state =>
    selectClusterFromMarkerId(state, matchedRoute?.params?.id ?? '')
  )

  const handleBackClick = useCallback(() => {
    reduxDispatch(resetMapZoom())

    if (!dispatch) return

    const {
      prev_selected_mowers,
      prev_selected_mower_ids,
      prev_selected_mower_id,
      prev_selected_batteries,
      prev_selected_battery_ids,
      prev_selected_battery_id,
      prev_selected_tool_tag_id,
      prev_selected_tool_tag_ids,
      prev_selected_tool_tags
    } = state ?? {}
    let prev_state: any = {}

    if (tabIndex === 0) {
      prev_state = {
        selected_mowers: prev_selected_mowers,
        selected_mower_ids: prev_selected_mower_ids,
        selected_mower_id: prev_selected_mower_id,
        prev_selected_mowers: [],
        prev_selected_mower_ids: [],
        prev_selected_mower_id: ''
      }
    } else if (tabIndex === 1) {
      prev_state = {
        selected_batteries: prev_selected_batteries,
        selected_battery_ids: prev_selected_battery_ids,
        selected_battery_id: prev_selected_battery_id
      }
    } else if (tabIndex === 2) {
      prev_state = {
        selected_tool_tags: prev_selected_tool_tags,
        selected_tool_tag_ids: prev_selected_tool_tag_ids,
        selected_tool_tag_id: prev_selected_tool_tag_id
      }
    }

    dispatch({
      type: 'SET_PREV_STATE',
      payload: prev_state
    })

    if (isMowerDetailView) {
      navigate(Routes['tracking_page.mowers'])
    } else if (isBatteryDetailView) {
      navigate(Routes['tracking_page.batteries'])
    } else if (isToolTagDetailView) {
      navigate(Routes['tracking_page.tool_tags'])
    } else if (isGroupDetailView) {
      navigate(Routes['tracking_page.groups'])
    }
  }, [
    tabIndex,
    state,
    dispatch,
    navigate,
    emitter,
    isMowerDetailView,
    isToolTagDetailView,
    isGroupDetailView,
    isBatteryDetailView,
    markerCluster
  ])

  //TODO:  we need to update to new state. This is still context state.
  const renderDetailedView = () => {
    const deviceId = matchedRoute?.params?.id ?? ''

    if (!!isMowerDetailView) {
      return <MowerDetail productSerial={deviceId} />
    } else if (!!isBatteryDetailView) {
      if (!state?.batteries?.length) return null

      const matchedDevice = state?.batteries?.find(
        item => item.productSerial === deviceId
      )

      return (
        <BatteryDetail id={deviceId} source={matchedDevice?.source || ''} />
      )
    } else if (!!isToolTagDetailView) {
      if (!state?.tool_tags?.length) return null

      const matchedDevice = state?.tool_tags?.find(item => item.id === deviceId)

      return (
        <ToolTagDetail id={deviceId} source={matchedDevice?.source || ''} />
      )
    } else if (!!isGroupDetailView) {
      return <GroupTagDetail id={deviceId} source={''} />
    }

    return null
  }

  useEffect(() => {
    sidebarContentRef.current?.scrollIntoView()
  }, [])

  return (
    <div>
      <div ref={sidebarContentRef}></div>
      <div className='p-4'>
        <BackToListButton onClick={handleBackClick} />
        <div className='mt-3'>{renderDetailedView()}</div>
      </div>

      <style>
        {`
          .spinner {
            width: 40px;
            height: 40px;
            margin: 0 auto;
            border-radius: 50%;
            border: 4px solid #ccc;
            border-top-color: #333;
            animation: spin 0.8s infinite linear;
          }

          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  )
}

export default TrackingSidebarDetailContainer
