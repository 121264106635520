import { IBattery } from '../models'
import { matchSorter } from 'match-sorter'

export const useBatterySearch = ({
  items,
  search
}: {
  items: IBattery[]
  search: string
}): IBattery[] => {
  if (!search) return []

  const subscribedBatteries = items.filter(item => item.has_subscription)
  const unsubscribedBatteries = items.filter(item => !item.has_subscription)

  const results = matchSorter(items, search.trim(), {
    keys: ['name', 'productSerial', 'factory_model_number', 'model_number'],
    threshold: matchSorter.rankings.CONTAINS
  })

  let mergedResults = results
  // Merge & Dedup
  if (
    ['yes', 'ye', 'y'].includes(search.toLowerCase().trim()) &&
    subscribedBatteries.length
  ) {
    mergedResults.push(...subscribedBatteries)
    mergedResults.reduce((acc: IBattery[], curr) => {
      if (acc.find(item => item.id === curr.id)) return acc
      return [...acc, curr]
    }, [])
  } else if (
    ['no', 'n'].includes(search.toLowerCase().trim()) &&
    unsubscribedBatteries.length
  ) {
    mergedResults.push(...unsubscribedBatteries)
    mergedResults.reduce((acc: IBattery[], curr) => {
      if (acc.find(item => item.id === curr.id)) return acc
      return [...acc, curr]
    }, [])
  }

  return mergedResults
}
