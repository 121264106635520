import {
  OperationVariables,
  useMutation,
  MutationHookOptions
} from '@apollo/client'
import { GqlMutationCollection, GqlMutation } from '../services_v2/gql'

interface IGraphQlQueryResponseWrapper<T = any> {
  result: T
}

export function useApolloMutation<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>({
  query,
  options
}: {
  query: GqlMutation
  options?: MutationHookOptions<IGraphQlQueryResponseWrapper<TData>, TVariables>
}) {
  const queryDocument = GqlMutationCollection[query]

  return useMutation<IGraphQlQueryResponseWrapper<TData>, TVariables>(
    queryDocument,
    options
  )
}
