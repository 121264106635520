import { setActiveMapMarkerProductSerial } from '../../store/slices/mapSlice'
import { getItemDetailsRoute } from '../../utils/navigation'
import { AnyDevice } from '../../utils/device'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const useListItemClickEvents = ({
  deviceType,
  device
}: {
  deviceType: 'mower' | 'battery' | 'tool_tag' | 'group'
  device: AnyDevice
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return {
    onMapPinClick: () => {
      dispatch(setActiveMapMarkerProductSerial(device?.productSerial))
    },
    onListItemClick: () => {
      dispatch(setActiveMapMarkerProductSerial(device?.productSerial))
      navigate(getItemDetailsRoute(deviceType, device))
    }
  }
}
